import styled, { css } from 'styled-components'
import styleVals from '../../constants/styles'

export const FooterWrapper = styled.div`
    padding: ${styleVals.measurements.spacing14} ${styleVals.measurements.spacing10} ${styleVals.measurements.spacing150};
    background: ${styleVals.colors.iconGrey};
    color: white;
    padding-bottom: ${styleVals.measurements.spacing180};
`
export const RestoName = styled.h1`
    margin: 0;
    padding: 0;
    margin-bottom: ${styleVals.measurements.spacing14};
    font-size: ${styleVals.measurements.spacing16};
    font-weight: bold;
    ${props => props.isLanguageArab && css`
        text-align: right;
    `}
`
export const SectionWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    font-size: ${styleVals.measurements.spacing13};
    ${props => props.withImage && css`
        justify-content: space-between;
        > span > img {
            height: ${styleVals.measurements.spacing22} !important;
        }
        @media (max-width: 342px) {
            span:nth-child(2) {
                margin-top: ${styleVals.measurements.spacing14};
            }
        }
    `}
    ${props => props.withLinks && css`
        > a {
                color: white;
                > sup {
                    font-weight: bold;
                    display: inline-block;
                }
            }
    `}
    ${props => !props.withNoMarginTop && css`
        margin-top: ${styleVals.measurements.spacing14};
    `}
`
export const CategoryText = styled.span`
    color: white;
    :active, :hover {
        text-decoration: underline;
        cursor: pointer;
    }
`
