export const TRANSLATIONS_ARAB = {
    offer_title: "عرض خاص",
    THANK_YOU: "شكراً لك",
    GO_TO_MAIN_MENU: "الذهاب إلى القائمة الرئيسة",
    THERE_ARENT_ANY_ADDED_ITEM: "لا توجد هناك أي عناصر مضافة",
    ADD_ITEMS: "إضافة عناصر",
    ITEMS: "العناصر التفاصيل",
    CHECKOUT: "الدفع",
    SELECT_OUTLET: "قم بتزويدنا بالموقع الخاص بك من فضلك من أجل اختيار أقرب نقطة التقاط",
    SELECT_LOCATION_MANUALLY: "إدخال الموقع يدوياً",
    DETECT_LOCATION: "تحقق من الموقع تلقائياً",
    NO_RESULT_FOUND: "لا توجد نتائج",
    MOVE_MAP_SLOWLY: "من فضلك قم بتحريك الخريطة ببطء.",
    GEOCODER_FAILED: "فشل كود الموقع",
    USE_CURRENT_LOCATION: "قم باستخدام موقعي الحالي",
    ENTER_LOCATION: "إدخال موقع*",
    NOT_ACCEPTING_ORDER_NOW: "نأسف لعدم قدرتنا على قبول طلبات توصيل الوجبات الجاهزة في الوقت الحالي.",
    HOME: "الصفحة الرئيسة",
    VIEW_MENU: "عرض القائمة",
    INSTRUCTION_ADDED: "تم إضافة التعليمات",
    CLEAR: "حذف",
    ADDITIONAL_INSTRUCTIONS: "تعليمات إضافية (اختياري)",
    ADD_INSTRUCTIONS: "إضافة تعليمات",
    YOUR_PREVIOUS_CUSTOMIZATION: "الإعدادات مخصصة سابقة",
    REMOVE_COMBO_FROM_CART_POPUP: "هذا العنصر يحتوي على إعدادات مخصصة متعددة. ربما تحتاج إلى إزالة هذا العنصر من السلة.",
    ADD: "إضافة",
    NO_ITEM_FOUND: "لم يتم إيجاد أي عنصر",
    CLOSE: "إغلاق",
    MENU: "القائمة",
    FOOD_PREFRENCE: "تفضيلات الطعام",
    CANCEL: "إلغاء",
    GET_OFF: "احصل على خصم بقيمة 55$",
    GET_FREE_DELIVERY: "احصل على توصيل مجاني",
    NO_OFFER_AVAILABLE: "لا توجد عروض متاحة",
    APPLY: "تطبيق",
    REMOVE: "إزالة",
    ONLINE_CASH: "إلكتروني\/كاش",
    OFFERS: "العروض",
    INVALID_OTP: "رمز التحقق خاطئ",
    RECEIVE_ORDER_NOTIF: "سيصلك إشعار بالطلب من خلال واتساب",
    NOT_EMPTY: "يجب ألا يكون خانة الاسم فارغاً",
    NAME_ONLY_CHAR: "يجب إدخال الأحرف والأرقام والمسافات فقط في خانة الاسم",
    INVALID_NUMBER: "رقم خاطئ، من فضلك قم بالمحاولة مجدداً",
    PLEASE_ENTER_OTP: "من فضلك قم بإدخال رمز التحقق",
    INVALID_OTP_TRY_AGAIN: "رمز التحقق خاطئ، الرجاء المحاولة مرة أخرى",
    PLEASE_ENTER_NAME: "قم بإدخال الاسم من فضلك",
    OTP_SENT_TO_DEVICE: "سيتم إرسال رمز التحقق إلى جهازك لتأكيد رقمك",
    SENDING: "إرسال",
    VERFIFYING: "جاري التأكيد",
    VERIFY: "تأكيد",
    PAY_NOW: "ادفع الآن",
    NO_OF_PERSON: "عدد الأشخاص",
    FOLLOW_US: "تابعنا",
    DID_YOU_KNOW: "هل تعلم؟",
    CONGRATS: "تهانينا!",
    YOU_SAVED: "قمت بحفظ",
    IF_PLACED_AGG_ORDER: "إذا قمت بطلب نفس العناصر من موقع أو تطبيق يضم قوائم مختلف المطاعم فستكون التكلفة",
    ADD_NEW_CUSTMZ: "أضف تخصيصاً جديداً +",

    //AddressBookConstatns
    WELCOME_TO: "مرحباً بك في \"باسكن روبنز\"",
    FETCHING_STATUS: "جارٍ الحصول على حالة الطلب",
    CONTINUE: "استمرار",
    SELECT_ADDRESS: "اختر نقطة بيع",
    SAVE_NEW_ADDRESS: "حفظ العنوان",
    TEMPORARILY_CLOSED: "مغلق مؤقتاً",
    AVAILABLE_ON: "سيكون متاحاً في ما بعد في",
    CHOOSE_OUTLET: "اختر نقطة بيع بالقرب من موقعك",
    VIEW_MORE: "عرض المزيد",
    SEARCH_OUTLETS: "عرض نقاط البيع المجاورة",
    UH_OH: "أوه!",
    SAVE_AND_PAY: "وفّر وادفع",
    OTHER_DETAILS_HEADING: "تفاصيل أخرى (* يجب ملء الحقول)",
    FULL_NAME_LABEL: "الاسم كاملاً*",
    HOUSE_NO_LABEL: "رقم المنزل\/الشقة*",
    LANDMARK_LABEL: "معلم قريب",
    ENTER_ADDRESS_ERROR: "قم بإدخال عنوانك من فضلك، أو قم باختيار موقعك من الخريطة.",
    ENTER_HOUSE_NO_ERROR: "قم بإدخال رقم منزلك\/شقتك من فضلك.",
    ENTER_NAME_ERROR: "قم بإدخال الاسم كاملاً من فضلك.",
    ADDRESS_TYPE_LABEL: "اسم العنوان*",
    SAVED_ADDRESSES: "العناوين المحفوظة",
    COD: "حدث خطأ في التسليم",
    TAKEAWAY: "الدفع عند الاستلام",
    PAY_IN_CASH: "الدفع لاحقاً",
    //Errors
    OFFER_UNDEFINED: "لم يتم اختيار رمز كوبون",
    OFFER_AMOUNT_INVALID: "إن قيمة الطلب الإجمالية للعناصر التي يسري عليها خصم الكوبون أقل من الحد الأدنى لقيمة الطلب.",
    OFFER_AMOUNT_LESS_THAN_ORDER_VALUE: "قيمة الطلب أقل من الحد الأدنى لقيمة الطلب لهذا الكوبون",
    OFFER_AMOUNT_INVALID_FOR_ITEMS: "لا يمكن استخدام رمز الكوبون للعناصر المستخدمة.",
    ALPHA_NUMERIC: "من فضلك قم بإدخال الأحرف والأرقام فقط",
    OFFER_LENGTH: "لا يمكن أن يكون الكود أكبر من 15 خانة",
    LOCATION_NOT_SERVICEABLE: "الخدمة غير متاحة للموقع الخاص بك. من فضلك قم بالمحاولة مجدداً",
    MIN_ORDER_ADD_MORE: "الحد الأدنى لقيمة الطلب هي .{{minOrderAmountForDelivery}} من فضلك، قم بإضافة المزيد من العناصر إلى السلة.",
    VARIANT_OUT_OF_STOCK: "نفد مخزون هذه التشكيلة",
    ADDON_OUT_OF_STOCK: "نفد مخزون هذه الإضافة",
    REFRESH_MODIFY_CART: "قم بتحديث الصفحة وتعديل السلة من فضلك",
    REFRESH: "تحديث",
    BILL_DETAILS: "تفاصيل الفاتورة",
    ITEM_TOTAL: "إجمالي العناصر",
    DISCOUNT: "الخصم",
    GRAND_TOTAL: "الإجمالي الكلي",
    TABLE_NO: "رقم الطاولة",
    ROOM_NO: "رقم الغرفة",
    COUPON_NOT_APPLICABLE: "لا يمكن استخدام هذا الكوبون",
    SAFTY_PRIORITY_DELIVED: "أمانك هو أولويتنا! إننا نحرص على إعداد وتغليف وتسليم طلباتك بشكل آمن.",
    OFFER_APPLIED_ON_BILL: "تم تطبيق العرض على الفاتورة",
    APPLY_COUPON: "استخدام الكوبون",
    APPLICABLEF_FOR_PAY_NOW_OPTION: "صالح لخيار الدفع الآن",
    APPLICABLEF_FOR_PAY_LATER_OPTION: "صالح لخيار الدفع لاحقاً",
    ADD_ADDRESS_TO_GET_DELIVERY_CHARGES: "قم بإضافة العنوان لمعرفة تكاليف التوصيل",
    AVAILABLE_COUPONS: "الكوبونات المتاحة",
    OFFER_APPLIED_ON_THE_BILL: "تم تطبيق العرض على الفاتورة",
    NO_DELIVERY_CHARGE: "ليس هناك تكلفة توصيل",
    ON_THIS_ORDER: "لهذا الطلب",
    ORDER_FOR: "الطلب ل",
    TO_AVOID_MORE_DELIVERY_CHARGE: "المزيد لتجنب تكاليف التوصيل",
    ADD_NEW_ADDRESS: "أضف عنواناً جديداً",
    PROCESSING_YOUR_ORDER: "جاري إعداد طلبك...",

    NO_DATA_FOUND: "لا توجد بيانات",
    NOT_ACCEPTING_ORDERS_INFO1: "لا يمكننا تلقي طلبات الآن!",
    NOT_ACCEPTING_ORDERS_INFO2: "قم بالتحقق في وقت لاحق.",
    SELECT_ORDER_TYPE: "اختر نوع الطلب",
    SELECT_ORDER_TYPE_TO_CONTINUE: "اختر نوع الطلب للاستمرار",
    OPENS_AT: "يفتح عند",
    FOR: "ل",

    YOUR_ORDER_NO_PLACED: "{{orderId}} تم اعتماد طلبك رقم",
    YOUR_ORDER_NO_CANCELLED: "{orderId} تم إلغاء طلبك رقم",
    CANCEL_ORDER: "إلغاء الطلب",
    PAYMENT_COUDNT_CONFIRMED: "لم يتم تأكيد طلبك، من فضلك قم بمراجعة المطعم أو المحاولة مجدداً.",
    GO_TO_HOME: "الذهاب إلى الصفحة الرئيسة",
    GO_TO_CART: "الذهاب إلى السلة",
    ABOVE_ITEM_WILL_BE_ADDED_TO_TOTAL_BILL: "* سيتم إضافة العناصر أعلاه إلى إجمالي الفاتورة",

    YOU_ARE_LOST: "أوبس! لابدّ أنك تائه.",
    PAGE_NOT_FOUND: "لا يمكن العثور على الصفحة التي تبحث عنها",
    LOOKING_FORR_RESTO_DASHBOARD: "هل تبحث عن لوحة المتابعة الخاصة بالمطعم؟",
    CLICK_HERE: "انقر هنا",

    YOU_NEED_HELP: "أوبس! لا بدّ أنك بحاجة للمساعدة",
    REACH_OUT_SUPPORT_FOR_ACCESS: "من فضلك قم بالتواصل مع الدعم في \"gupshup\" لتتمكن من الوصول إلى لوحة المتابعة",
    GO_BACK_TO_LOGIN: "الرجوع إلى صفحة تسجيل الدخول",
    WE_HAVE_PROBLEM: "لدينا مشكلة يا هيوستن!",
    PAGE_UNAVAILABLE: "الصفحة التي تحاول البحث عنها غير متاحة حالياً.",
    WOULD_LIKE_ORDER_ADVANCE: "هل تريد وضع طلب أولاً؟",

    ADDONS: "الإضافات",
    VARIANTS: "التشكيلات",
    LESS_BUTTON: "... أقل",
    MORE_BUTTON: "... أكثر",
    ADD_LABEL: "أضف",
    CUSTOMIZABLE: "يمكن تخصيصه",
    CUSTOMIZED_COMBO_TYPES: null,
    BASE_PRICE_COMBOS: null,
    BASE_PRICE: "السعر الأساسي",
    CHOOSE_ITEMS: "اختيار عناصر",
    CHOOSE_VARIANTS: "اختيار تشكيلات",
    CHOOSE_ADDONS: "اختيار إضافات",
    PLEASE_SELECT_MESSAGE: "من فضل قم باختيار",
    BACK_BUTTON: "رجوع",
    NEXT_BUTTON: "التالي",
    ADD_BUTTON: "إضافة",
    UPDATE_BUTTON: "تحديث",
    OUT_OF_STOCK_MESSAGE: "نفاد المخزون",
    addonsAtleastUptoText: "من فضلك قم باختيار {{minimum}} إضافات على الأقل و{{maximum}} بأحد أقصى (تم اختيار {{selected}} من {{maximum}})",
    addonsUptoText: "({{selected}} فض {{maximum}} قم بالاختي) من فضلك قم بالاختيار بحد أقصى {{maximum}} إضافات",
    addonsExactText: "من فضلك قم باختيار {{qty}} إضافات (تم اختيار {{selected}})",
    SELECT_MAIN_ITEMS: "اختر العناصر الرئيسة",
    SELECT_FREE_ITEMS: "اختر العناصر المجانية",
    CLOSE_TABLE_PAY: "قم بإغلاق الطاولة والدفع",
    LIKE_TO_ORDER_DIFF_ADDRESS: "هل ترغب في الطلب إلى عنوان مختلف؟",
    UPDATE_LOCATION: "تحديث بيانات الموقع",
    TABLE_CLOSED_FOR_ORDERS: "الطاولة مغلق ولا يمكنك إضافة طلبات",
    SCAN_QR_TO_ORDER: "مسح ﻛﻴﻮ آر ﻛﻮد للطلب",
    NAME_NOT_EMPTY: "لا يمكن أن تكون خانة {{key}} فارغة",
    ITEM_DETAILS: "تفاصيل العنصر",

    RESERVATION_CNFR_FEW_MINT: "سيتم تأكيد حجزك خلال بضع دقائق",
    TAKEAWAY_OPTION: "أريد وجبة جاهزة",
    DELIVERY_OPTION: "أريد طلب توصيل",
    FETCHING_LOCATION: "جارٍ الحصول على بيانات الموقع...",
    SET_LOCATION_TEXT: "انقر هنا للحصول على بيانات الموقع الخاص بك",
    SET_LOCATION_TEXT_TAKEAWAY: "انقر هنا لعرض المسافة إلى المستودع",
    SELECT_STORE: "قم بإعداد الموقع واختيار نقطة بيع من هنا",
    SELECT_STORE_TAKEAWAY: "انقر هنا لاختيار أقرب نقطة بيع",
    SELECT_LOCATION: "اختر الموقع",
    CHANGE_LOCATION: "تغيير الموقع",
    CHANGE: "تغيير",
    COMBO_OFFER: "عروض الخصم \"كومبو\"",
    RESTORENT_LOGO: "شعار المطعم",
    ONLINE: "متصل",
    CASH: "نقداً",
    RESENDING: "إعادة الإرسال",
    RESEND_NOW: "إعادة الإرسال الآن.",
    RESEND_IN: "سيتم إعادة الإرسال خلال {{timer}} ثانية",
    ALMOST_THERE: "شارفنا على الانتهاء!",
    REMOVE_ITM_FRM_CART: "إزالة عنصر من السلة",
    MORE: "المزيد",
    ADDON: "إضافة",
    CURRENTLY_UNAVAILABLE: "غير متاح حالياً",
    EDIT_ADDRESS_BTN: "تعديل زر العنوان",
    ADD_ADDRESS_BTN: "إضافة زر العنوان",
    YOUR_ORDER_DETAILS: "تفاصيل الطلب",
    SUPER_SAVING: "ادخارات كبيرة",
    ORDER_NOW: "قم بالطلب الآن",
    LOCATION_UNSERVICEABLE: "لا يمكن تقديم الخدمة لهذا الموقع",
    DELIVERY_NOT_AVAILABLE: "لا يمكن التوصيل",
    TAKEAWAY_NOT_AVAILABLE: "لا يمكن إعداد طلبات جاهزة",
    SHOP_IS_CLOSED: "المطعم مغلق مؤقتاً",
    PLEASE_TRY_AGAIN: "من فضلك قم بالمحاولة في وقت لاحق",
    DELIVERY_TRY_AFTER_SOMETIME: "من فضلك قم بالمحاولة بعد مدة من أجل التوصيل",
    TAKEAWAY_TRY_AFTER_SOMETIME: "من فضلك قم بالمحاولة بعد مدة من أجل الطلبات الجاهزة",
    /** Payment */
    DEBIT_CREDIT_CARDS: "بطاقة ائتمانية",
    VISA_MASTERCARD_MORE: "فيزا، ماستر كارد، روباي والمزيد",
    PAY_VIA_UPI: "قم بالدفع من خلال واجهة الدفع الموحدة (UPI)",
    WALLETS: "المحافظ",
    PHONEPAY_PAYTM_MORE: "فون بي، باي تي أم، والمزيد",
    NETBANKING: "الخدمات المصرفية عبر الإنترنت",
    ALL_INDIAN_BANKS: "كافة البنوك الهندية",
    METHODS_LOADING_INFO: "الرجاء الانتظار بينما نقوم بتحميل طرق الدفع الخاصة بك...",
    SELECT_PAYMENT_METHOD: "اختر طريقة دفع",
    PREFERRED_PAYMENT_METHODS: "طرق الدفع المفضلة",
    POPULAR_PAYMENT_METHODS: "طرق الدفع المشهورة",
    ALL_PAYMENT_OPTIONS: "كافة خيارات الدفع",
    ALL_PAYMENT_METHODS_PAGE: "صفحة كافة طرق الدفع",
    CARDS_PAGE: "صفحة البطاقات",
    WALLETS_PAGE: "صفحة المحافظ",
    NETBANKING_PAGE: "صفحة الخدمات المصرفية عبر الإنترنت",
    UPI_PAGE: "صفحة واجهة الدفع الموحدة",
    CARDS_PAGE_HEADING: "البطاقات",
    WALLET_PAGE_HEADING: "المحافظ",
    NETBANKING_PAGE_HEADING: "الخدمات المصرفية عبر الإنترنت",
    UPI_PAGE_HEADING: "قم بإدخال معرّف واجهة الدفع الموحدة الخاص بك",
    CARDHOLDER_NAME: "اسم حامل البطاقة",
    CARD_NUMBER: "رقم البطاقة",
    CARD_EXPIRY: "تاريخ الانتهاء (mm\/yy)",
    CARD_CVV: "رمز التحقق من البطاقة الائتمانية (CVV)",
    SAVE_CARD_LABEL: "حفظ تفاصيل البطاقة",
    SAVE_CARD_ADDITIONAL_LABEL: "لن يتم تخزين رمز التحقق من البطاقة الائتمانية (CVV)",
    CARDHOLDER_NAME_ERROR: "قم بإدخال اسم حامل البطاقة من فضلك.",
    CARD_NUMBER_EMPTY_ERROR: "قم بإدخال رقم البطاقة من فضلك.",
    EXPIRY_EMPTY_ERROR: "قم بإدخال تاريخ الانتهاء بصيغة mm\/yy من فضلك.",
    CVV_EMPTY_ERROR: "قم بإدخال رمز التحقق من البطاقة الائتمانية (CVV) من فضلك.",
    getCVVLengthError: "قم باستخدام رمز التحقق الخاص بـ (CVV) والمكون من {{length}} أرقام ",
    CVV_INVALID_ERROR: "قم باستخدام أرقام فقط من فضلك لرمز التحقق (CVV).",
    CARD_NUMBER_ERROR: "رقم البطاقة خاطئ، من فضلك قم بالمحاولة مجدداً.",
    EXPIRY_ERROR: "تاريخ الانتهاء خاطئ، من فضلك قم بالمحاولة مجدداً.",
    UPI_ID: "معرّف واجهة الدفع الموحدة",
    UPI_ID_PLACEHOLDER: "upi@ybl",
    REMEMBER_UPI_ID_LABEL: "تذكر المعرف الخاص بي",
    REMEMBER_UPI_ID_ADITIONAL_LABEL: "سيتم تشفير معرف واجهة الدفع الموحدة الخاص بك",
    VERIFY_AND_PAY: "التأكيد والدفع",
    INVALID_UPI_ERROR: "قم بإدخال معرف واجهة دفع موحدة فعال من فضلك.",
    EMPTY_UPI_ERROR: "قم بإدخال  معرف واجهة الدفع الموحدة.",
    SELECT_OTHER_BANK: "اختر بنكاً آخر",
    SEARCH_BANK: "ابحث عن بنك",
    NO_BANK_FOUND: "لم يتم إيجاد بنك بهذا الاسم!",
    airtelmoney: "Airtel Money",
    freecharge: "Freecharge",
    jiomoney: "Jio Money",
    mobikwik: "Mobikwik",
    olamoney: "Ola Money",
    phonepe: "PhonePe",
    payzapp: "PayZapp",
    TIMER_INFO: "لا تقم بتحديث الصحة أو النقر على زر الرجوع من فضلك",
    TAKING_TIME_INFO: "ممم... سيستغرق هذا الأمر بعض الوقت.",
    PAYMENT_FAILED_INFO: "فشل الدفع. قم بالمحاولة مجدداً من فضلك.",
    PAYMENT_FAILED_FATAL_INFO: "حدث خطأ ما. حاول الدفع مجدداً من خلال صفحة الدفع.",
    UPI_LINK_FAILURE_INFO: "لا يمكننا البدء بطلب الدفع. الرجاء المحاولة مرة أخرى.",
    PROCESSING_INFO: "الرجاء الانتظار بينما نقوم بعملية الدفع.",
    PROCESSING_WAIT_INFO: "الرجاء انتظار المزيد بينما نقوم بالتحقق من حالة الدفع الخاصة بك.",
    SHOW_MORE: "عرض المزيد",
    SHOW_LESS: "عرض أقل",
    APP_NOT_INSTALLED: "لم يتم تثبيت التطبيق أو تم إغلاق التطبيق بسرعة.",
    placeHolder: {
        SEARCH: "بحث...",
        SEARCH_DISH: "البحث عن طبق",
        EX_DONT_ADD_CAPSICUM: "مثلاً: لا تقم بإضافة الفليفلة إلى الساندويش.",
        NUMBER: "رقم*"
    },
    notificationMsg: {
        PLEASE_SELECT_AN_ADDRESS: "الرجاء اختيار العنوان",
        NO_DEATILS_AVAL_FOR_INPUT: "{{name}} :ليس هناك تفاصيل متاحة للإدخال",
        FEATURE_DONT_SUPPORT: "هذه الخاصية غير مدعومة لجهازك",
        CHECK_BROWSER_SETTING_LOCATION: "الرجاء التحقق من إعدادات المتصفح الخاص بك للسماح بالحصول على الموقع الخاص بك",
        NO_OUTLET_LOCATION: "لا توجد نقاط بيع متاحة لهذا الموقع. الرجاء قم باختيار موقعاً مختلفاً",
        SOMETING_WENT_WRONG: "حدث خطأ ما. الرجاء المحاولة مجددا",
        PLEASE_ADD_INSTRUCTIONS: "الرجاء إضافة تعليمات",
        INSTRUCTION_LENGTH_MUST: "لا يمكن أن تكون التعليمات أكثر من 100",
        TABLE_NO_CANT_GRATER: "لا يمكن أن يزيد طول رقم الطاولة عن 3",
        TABLE_NO_SHD_ALP_NUM: "ينبغي أن يكون رقم الطاولة مرتباً بشكل أبجدي رقمي",
        ROOM_NO_LENGTH_CANT_GT: "لا يمكن أن يزيد طول رقم الغرفة عن 6",
        ALP_NUM_ONLY_ROOM_NO: "الرجاء استخدام الأحرف الأبجدية والأرقام فقط لإدخال رقم الغرفة",
        PLZ_ENT_TABLE_NO: "الرجاء إدخال رقم الطاولة",
        PLZ_ENT_ROOM_NO: "الرجاء إدخال رقم الغرفة",
        MIN_ORDER_IS: "الحد الأدنى لقيمة الطلب هو {{minOrderAmount}}",
        TABLE_COLOED_FOR_ORDERS: "الطاولة مغلقة ولا يمكن إضافة المزيد من الطلبات",
        ACTION_NOT_ALLOWED: "لا يمكن القيام بهذا الإجراء",
        COUPON_REMOVE_FROM_ORDER: "تم إزالة الكوبون لهذا الطلب",
        MIN_ORDER_AND_FREE_DEL: "الحد الأدنى لقيمة الطلب هو {{ minimumOrderAmount }} والتوصيل مجاني للطلبات الأكثر من {{ freeDeliveryAmount }}",
        FREE_DEL_ABOVE: "التوصيل مجاني للطلبات الأكثر من {{freeDeliveryAmount}}",
        OFFER_IS_NOT_APPLICABLE: "نأسف، هذا العرض غير متاح لك",
        FEATURE_DOESNT_SUPPORT_FOR_THIS_DEVICE: "هذه الخاصية غير مدعومة لجهازك",
        CHECK_BROWSER_LOCATION_SETTINGS: "الرجاء التحقق من إعدادات المتصفح الخاص بك للسماح بالحصول على الموقع الخاص بك"
    },

    // ------------- New/missing keys

    SEND_OTP_HEADING: "أنت على مسافة خطوة واحدة من تأكيد طلبك",
    NAME_PLACEHOLDER: "الاسم*",
    OTP_SENT_TO: "تم إرسال رمز التحقق إلى",
    CHANGE_NUMBER: "هل تريد تغيير الرقم؟",
    DIDNT_RECEIVE_OTP: "ألم يصلك رمز التحقق؟",
    ENTER_CODE: "قم بإدخال الرمز",
    REPEAT: "إعادة",
    CUSTOMIZE: "تخصيص",
    BROWSE_MENU_INFO: "لا تتردد في تصفح القائمة.",
    INVALID_ORDERTYPE: "نوع الطلب هذا غير مدعوم لهذا المطعم",
    Confirm: "تأكيد",
    CLOSING: "جاري الإغلاق......",
    CUSTOMIZED: "مخصص",
    DELIVERY_TO: "التسليم إلى",
    selectItemComboText: "الرجاء اختيار  {{itemsNeeded}} عنصر\/عناصر. (تم اختيار {{itemsSelected}} \/ {{itemsNeeded}})",
    selectItemText: "تم تحديد {{qty}} عنصرًا {{s}}.",
    itemsAtleastUptoText: "الرجاء اختيار  {{minimum}} عنصر\/عناصر على الأقل وحتى {{maximum}} (تم اختيار {{selected}}\/{{maximum}})",
    itemsAtleastText: "الرجاء اختيار عنصر {{minimum}} (تم اختيار {{selected}})",
    itemsUptoText: "الرجاء اختيار {{maximum}} عنصر\/عناصر بحد أقصى (تم اختيار {{selected}}\/{{maximum}})",
    itemsExactText: "الرجاء اختيار {{qty}} عنصر\/عناصر (تم اختيار {{selected}})",
    addonsAtleastText: 'الرجاء اختيار إضافات عدد {{minimum}} على الأقل (تم اختيار {{selected}})',
    pleaseTryDifferentLocation: "أوه لا! لم نقم بإضافة هذا الموقع إلى نطاق خدماتنا بعد. الرجاء اختيار موقع آخر.",
    byOrderingMsg: "عند الطلب مباشرة من المطعم، فأنت تدفع {{value}} أقل مقارنة بالطلب من خلال منصة تخدم مختلف المطاعم.",
    moreInflatedMsg: "رائع! لا مزيد من الأسعار المرتفعة على القائمة!",
    lesserMenuPricesMsg: "لدينا تحدٍّ لك! لن تجد أسعارًا أقل لقائمة مطعمنا في أي مكان آخر...",
    ThanksMsg: "أنت تجعلنا فخورين بأنفسنا من خلال التعامل معنا مباشرةً! وسوف نحرص على أن تحصل على سعر أقل.",
    dontInflateMsg: "لا! إننا لا نقوم برفع أسعار الوجبات على قائمتنا هنا.",
    NO_BROWSER_LOCATION_SUPPORT: "جهازك لا يدعم خاصية الوصول إلى الموقع.",
    NO_LOCATION_ACCESS: "الرجاء التحقق من إعدادات المتصفح الخاص بك من أجل السماح بالوصول إلى موقعك",
    Takeaway: "الطلبات الخارجية",
    Delivery: "التوصيل",
    OFFICE: "المكتب",
    OTHER: "أخرى",
    YAY: "نعم!",
    FOOD: "الأطعمة",
    BAR: "بار",
    VEG: "نباتي",
    NONVEG: "غير نباتي",
    EGG: "بيض",
    OhNoItemFound: "أوه لا، لم نعثر على عناصر",
    YouHaveNotification: "لديك إشعار جديد",
    DoYouWantjoinTable: "هل تريد الانضمام إلى الطاولة؟",
    YES: "نعم",
    NO: "لا",
    UseCodeGetOffer: "استخدم الرمز للحصول على عرض",
    UseCodeGetOffAbove: "استخدم الرمز CASHONLY واحصل على خصم بنسبة 10% على كافة الطلبات فوق 100$",
    UseCodeGetOffUpto: "استخدم الرمز TESTINFKJKJF للحصول على خصم بنسبة 87% على كافة الطلبات فوق ₹989 وحتى ₹90",
    UseCodeGetFreeDelivery: "استخدم كود CASHONLY لتحصل على توصيل مجاني",
    UseCodeGetFreeDeliveryAbove: "استخدم كود TESTINFKJKJF لتحصل على توصيل مجاني على كافة الطلبات فوق 100$",
    UseCodeGetFreeDeliveryUpto: "استخدم الرمز TESTINFKJKJF لتحصل على توصيل مجاني على كافة الطلبات فوق ₹989 وحتى ₹90",
    ull: null,
    ABOUT_US: "من نحن",
    CONTACT_US: "تواصل معنا",
    PRIVACY_POLICY: "سياسة الخصوصية",
    TERMS_AND_CONDITIONS: "الشروط والأحكام",
    POWERED_BY: "بدعم من",
    ull: null,
    Backend: null,
    COUPON_USAGE_LIMIT_CROSSED: "تم استخدام الكوبون {{ count }} مرة\/مرات ولا يمكن استخدامه مجدداً",
    OFFER_IS_NOT_APPLICABLE_FOR_ORDER: "نأسف، هذا العرض غير ساري على الطلبات {{orderType}}",
    WE_ARE_NOT_ACCEPTING_ORDER_NOW: "نأسف، نحن لا نقبل الطلبات {{orderType}} في الوقت الحالي",
    OR_TEXT: "OR",
    OpensAt: '{{orderType}} يفتح عند {{time}} {{day}}',
    PleaseEnableLocationAccess: 'Location is inaccessible. Please enable location access by changing the settings',
    TOTAL_PAYABLE: "Total Payable",
    Code:'Code',
    Applied: 'applied', 
    ChangeCoupon: 'Change coupon',
    CouponSavings: 'Coupon savings',
    Coupons:'Coupons',
    TOTAL: 'Total',
    POD: "Pay on delivery",
    YOUR_ORDER: "Your Order",

    DELIVERY_CHARGE: 'تكلفة التوصيل',
    SERVICE_CHARGE: 'رسوم الخدمات',
    PACKAGING_CHARGE: 'رسوم التعبئة والتغليف',
    TAXES_AND_CHARGES: 'الضرائب والرسوم',
    SAVED:'saved',
    CHANGE_ADDRESS: 'Change Address'
}
