import styled, { css } from "styled-components";
import styleVals from "../../constants/styles";

const {
  measurements: { spacing10, spacing16, spacing32 },
  colors: { boxShadow, boxShadowHover },
  transitions: { buttonBoxShadow },
} = styleVals;

export const StyledGradientButton = styled.button`
  padding: ${spacing10} ${spacing32} ${spacing10} ${spacing32};
  border: none;
  color: ${(props) => props.buttonTextColor || (props.isRestaurantColorDark ? styleVals.colors.white : styleVals.colors.primaryText)};
  box-shadow: ${boxShadow};
  background: ${(props) =>
    props.noGradient
      ? props.themeColor
      : `linear-gradient(to left, ${props.themeColor}, ${props.gradientColor})`} !important;
  ${(props) =>
    props.loading &&
    css`
      filter: brightness(0.7);
      cursor: not-allowed !important;
    `}
  border-radius: ${spacing16};
  transition: box-shadow ${buttonBoxShadow};
  ${(props) =>
    !(props.loading || props.disabled) &&
    css`
      &:hover {
        box-shadow: ${boxShadowHover};
      }
      &:active {
        box-shadow: ${boxShadowHover};
      }
    `}
`;
