import React from 'react';

export default function usePersistedState(defaultValue,productId, key) {
  const [value, setValue] = React.useState(() => {
    const stickyValue = window.sessionStorage.getItem(productId + "-" + key);
    return stickyValue && stickyValue !== null
      ? JSON.parse(stickyValue)
      : defaultValue;
  });
  React.useEffect(() => {
    if(key){
      window.sessionStorage.setItem(productId + "-" + key, JSON.stringify(value));
    }
  }, [key, value]);
  return [value, setValue];
}

export const usePersistedLocalStorageState = (defaultValue,productId, key) => {
  const [value, setValue] = React.useState(() => {
    const stickyValue = window.localStorage.getItem(productId + "-" + key);
    return stickyValue && stickyValue !== null
      ? JSON.parse(stickyValue)
      : defaultValue;
  });
  React.useEffect(() => {
    if(key){
      window.localStorage.setItem(productId + "-" + key, JSON.stringify(value));
    }
  }, [key, value]);
  return [value, setValue];
}