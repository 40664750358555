import {
    OfferTypes,
} from "../../constants/variables";

const OfferCode = ({ restaurantColor, restaurantDarkenColor, offerCode, offerType }) => {
    const offerCodeArray = offerCode.split(" ");
    const styleOffer = {
        x: offerType === OfferTypes.FREEDELIVERY ? 25 : 40,
        fSize: offerType === OfferTypes.FREEDELIVERY ? 20 : 25,
    }
    return (
        <svg viewBox="3 0 125 145" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_0_97)">
                <path d="M113.219 0C116.019 6.8 123.386 7.5 126.719 7L125.719 11C117.719 15.4 122.385 18.5 125.719 19.5V25C118.519 29 122.719 32.6667 125.719 34V38.5C118.119 42.9 122.552 46.3333 125.719 47.5V53C118.519 56.6 122.719 60.1667 125.719 61.5V66.5C118.919 70.9 122.885 74.6667 125.719 76V80.5C118.119 84.5 122.552 87.8333 125.719 89V95C118.919 98.6 122.885 101.833 125.719 103V108C119.319 111.2 123.052 115.333 125.719 117V121.5C123.719 121.5 122.552 123.167 122.219 124C121.019 127.6 124.052 130.167 125.719 131L125.219 134.5C116.819 132.9 111.385 139.5 109.719 143H108.719C102.552 142.833 84.9187 142.6 63.7188 143C42.5187 143.4 29.5521 140.833 25.7188 139.5C8.11854 129.9 4.71884 110.167 5.21901 101.5C3.88567 84 2.51901 44.1 7.71901 24.5C12.919 4.9 36.5523 0 47.719 0H113.219Z" fill="url(#paint0_linear_0_97)"/>
            </g>
            <g>
                <text x={styleOffer.x} y="70" font-family="Poppins" font-size={styleOffer.fSize} fill="#fff">{offerCodeArray.length > 2 ? `${offerCodeArray[0]} ${offerCodeArray[1]}`: offerCodeArray[0] }</text>
            </g>
            <g>
                <text x={styleOffer.x} y="95" font-family="Poppins" font-size={styleOffer.fSize} fill="#fff">{offerCodeArray.pop()}</text>
            </g>
            <defs>
                <filter id="filter0_d_0_97" x="0" y="0" width="130.719" height="151.042" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="4"/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_97"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_97" result="shape"/>
                </filter>
                <linearGradient id="paint0_linear_0_97" x1="65.3595" y1="0" x2="65.3595" y2="143.042" gradientUnits="userSpaceOnUse">
                    <stop stop-color={restaurantColor}/>
                    <stop offset="1" stop-color={restaurantDarkenColor}/>
                </linearGradient>
            </defs>
        </svg>
    );
  };
  
  export default OfferCode;
  