import styled, { css } from "styled-components";
import styleVals from "../../../constants/styles";
import { darkenColor } from "../../../utils/common";

export const LandingModal = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

LandingModal.Div = styled.div`
  width: 80%;
  max-width: ${styleVals.measurements.spacing420};
  background: ${(props) =>
    `linear-gradient(to bottom, ${darkenColor(props.restaurantColor, 50)}  0%, ${props.restaurantColor} 100%)`} !important;
  padding: ${styleVals.measurements.spacing50} ${styleVals.measurements.spacing20} ${styleVals.measurements.spacing20};
`;

export const ModalHeader = styled.div`
  background: rgba(0, 0, 0, 0.4);
  text-align: center;
  padding: ${styleVals.measurements.spacing52} ${styleVals.measurements.spacing16} ${styleVals.measurements.spacing18};
  margin-bottom: ${styleVals.measurements.spacing10};
  position: relative;
  > div {
    width: ${styleVals.measurements.spacing64};
    height: ${styleVals.measurements.spacing64};
    border-radius: ${styleVals.measurements.spacing8};
    margin: 0 auto;
    position: absolute;
    top: -${styleVals.measurements.spacing30};
    left: 50%;
    transform: translateX(-50%);
    img {
      border-radius: ${styleVals.measurements.spacing10};
      width: 100%;
      width: ${styleVals.measurements.spacing64} !important;
      height: ${styleVals.measurements.spacing64} !important;
    }
  }
`;
export const ModalBody = styled.div`
  background: rgba(0, 0, 0, 0.4);
  text-align: center;
  padding: ${styleVals.measurements.spacing16};
`;

export const Heading = styled.h3`
  font-size: ${styleVals.measurements.spacing18};
  color: ${styleVals.colors.white};
`;

export const Content = styled.p`
  font-size: ${styleVals.measurements.spacing14};
  color: ${props => props.restaurantColor ? darkenColor(props.restaurantColor, 100) : styleVals.colors.white};
  margin: 0;
  ${props => props.withSpacing && css`
    margin-bottom: ${styleVals.measurements.spacing16};
  `}
  ${props => props.firstChild && css`
    margin-top: ${styleVals.measurements.spacing24};
  `}
  ${props => props.lastChild && css`
    margin-bottom: ${styleVals.measurements.spacing24};
  `}
`;

export const ModalBackdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  bottom: 0;
  left: 0;
  right: 0;
`;
