import React from 'react'
import { useRestaurantsInfoContext } from '../../contexts/restaurantInfo'
import {
    RadioLabel,
    SelectionLabel
} from './styles'

const RadioButton = props => {

    const {id, name, label, additionalLabel = '', additionalLabelId = '', value, checked, onChange, variant = 'default', key, disabled, smallerRadio} = props

    const { restaurantColor, isLanguageArab } = useRestaurantsInfoContext()

    const radioProps = {
        labelProps: {
            restaurantColor,
            additionalLabel,
            isDisabled: disabled,
            ...(key && {key})
        },
        inputProps: {
            name,
            id: props.id,
            type: 'radio',
            value,
            checked,
            onChange: disabled ? undefined : onChange,
            disabled
        } 
    }

    return variant === 'itemSelection' ? <SelectionLabel isLanguageArab={isLanguageArab} htmlFor={props.id} {...radioProps.labelProps}>
        <div>
            <div>
                <input {...radioProps.inputProps}/>
                <span aria-hidden></span>
            </div>
            <p id={`${id}-label`}>{label}</p>
        </div>
        {additionalLabel && <p id={additionalLabelId}>{additionalLabel}</p>}
    </SelectionLabel> : <RadioLabel isLanguageArab={isLanguageArab} smallerRadio={smallerRadio} for={props.id} id={`${id}-label`} {...radioProps.labelProps}>
        <input {...radioProps.inputProps} />
        {label}
    </RadioLabel>

}

export default RadioButton