import { get } from '../../utils/common';
import React, { useState, useEffect } from 'react'
import {useTranslation } from 'react-i18next'
import { MenuContainer, MenuItem, MenuCloseButton } from "./styles"
import { useRestaurantsInfoContext } from '../../contexts/restaurantInfo';

export default function Menu({ funMenu, product, menuItem, restaurantColor, comboOffers, selectedCategory, isRestaurantColorDark, bottomGap = '' }) {
    let [menu, setMenu] = useState(null);
    const { isLanguageArab } = useRestaurantsInfoContext();
    const { t }=useTranslation();

    useEffect(() => {
        let filterCategoriesResult = []
        product.forEach(category => {
           if(category.items){
               if(category.items.length > 0){
                   filterCategoriesResult.push({ ...category , items: category.items})
               }
           }
        })
        const comboSectionToDisplay = get(comboOffers, 'length', 0) > 0 ? [{
            ref_id: 'combos',
            title: t('COMBO_OFFER'),
            items: comboOffers
        }] : []
        setMenu([...comboSectionToDisplay, ...filterCategoriesResult])

        var menuItemCss = `.menuItem:hover{ background-color: ${restaurantColor ? restaurantColor : '##ab0012'}; color : ${restaurantColor ? 'white' : 'white'} }`;
        var style = document.createElement('style');

        if (style.styleSheet) {
            style.styleSheet.cssText = menuItemCss;
        } else {
            style.appendChild(document.createTextNode(menuItemCss));
        }
        document.getElementsByTagName('head')[0].appendChild(style);
    },[])
    
    useEffect(() => {
        const menuList = document.getElementById('menu-list-popup')
        const categoryInMenu = document.getElementById(`${selectedCategory.current}-items`)
        if (menu && menuList && categoryInMenu) {
            menuList.scrollTop = categoryInMenu.offsetTop - 16 < 0 ? 0 : categoryInMenu.offsetTop - 16
        }
    }, [menu])

    const getItemCount = (category) => {
        let itemCount = 0;
        if (category.items) {
            for (let i = 0; i < category.items.length; i++) {
                itemCount += (category.items[i].items ? category.items[i].items.length : 1);
            }
        }
        return itemCount;
    }

    const handleCategoryClick = (item) => {
        selectedCategory.current = item.title
        menuItem(item.ref_id, item.title)
    }

     return (
         <MenuContainer onClick={funMenu} isLanguageArab={isLanguageArab}>
             <MenuContainer.Div hasMenu={!!menu} bottomGap={`${(+(bottomGap.replace('px', '')) + 46)}px`}>
                <div id='menu-list-popup'>
                     {get(menu, '[0]')
                        ? menu.map((item) => (
                            <MenuItem
                                isSelected={selectedCategory.current === item.title}
                                key={item.ref_id}
                                onClick={() => handleCategoryClick(item)}
                                noItem={false}
                                isLanguageArab={isLanguageArab}
                            >
                                <p id={`${item.title}-items`}>{item.title}</p> <span>{getItemCount(item)}</span>
                            </MenuItem>))
                        : menu
                            ? <MenuItem id='no-menu-items-found' noItem={false} >{t('NO_ITEM_FOUND')}</MenuItem>
                            : null
                    }
                </div>
             </MenuContainer.Div>
             <MenuCloseButton id='menu-close-button' bottomGap={bottomGap} isRestaurantColorDark={isRestaurantColorDark} onClick={funMenu} restaurantColor={restaurantColor}>{t('CLOSE')}</MenuCloseButton>
         </MenuContainer>
    )
}