import { allPageRoutes, MENUID_DOMAIN } from "../constants/variables";
import { get } from '../utils/common';

// if the subdomain is not one of allowed domains, then control will go into else block
export default function useProductId() {
  if (process.env.REACT_APP_BASE_URLS.indexOf(window.location.href.split('/')[2]) >= 0) {
    const searchParams = new URL(window.location.href);
    let productId = searchParams.pathname.split('/')[1];
    return productId
  } else {
    /*here we will check if the searchParams consists of product id of the outlet or not (in case of chains)
    if product id from chain is not there, we will simply return menuDomain, if present we will return the productId
    of the outlet.
    */
    let searchParams = new URL(window.location.href);
    searchParams = searchParams?.pathname?.split('/')
    if(get(searchParams, 'length', 0) > 2 || !(allPageRoutes.includes(get(searchParams, '[1]')))) {
      return get(searchParams, '[1]')
    }
    return MENUID_DOMAIN;
  }
};