import { ModifyRepititions } from "../../pages/Checkout/styles/Checkout";
import Repititions from "./Repititions";
import {useTranslation } from 'react-i18next'

export default function RepititionsField({
  restaurantColor,
  repititionModal,
  setRepititionModal,
  repititionModalChildren,
  setRepititionsModalChildren,
  textInput,
  productIndex,
  handleRepetitionChange,
}) {
  const {t }=useTranslation()
  return (
    <>
      {repititionModal && (
        <Repititions
          restaurantColor={restaurantColor}
          repititionModal={repititionModal}
          setRepititionModal={setRepititionModal}
          repititionModalChildren={repititionModalChildren}
          setRepititionsModalChildren={setRepititionsModalChildren}
          textInput={textInput}
          productIndex={productIndex}
          handleRepetitionChange={handleRepetitionChange}
        />
      )}
    </>
  );
}
