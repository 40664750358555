import React, { useEffect, useRef, useState } from 'react'
import { useRestaurantsInfoContext } from '../../../contexts/restaurantInfo'
import i18n from '../../../translations/i18n'
import { range } from '../../../utils/common'
import { roundOffToTwoPlaces } from '../ComboCard'

import {
	RightSection,
	AddButtonWrapper,
	CouponsNotApplicableText,
	CustomizableText,
	FoodType,
	FoodTypeIcon,
	ItemAddButton,
	ItemCardWrapper,
	ItemDescription,
	LeftSection,
	ItemImage,
	ItemPrice,
	ItemTitle,
	RestoCurrency,
	PriceText,
	ItemCountButton,
	ItemFirstAddButton,
	ImageWrapper
} from './styles'

export const AddItemButton = ({
	title = '',
	itemImage = '',
	showAddItemButton = true,
	isCustomizable = false,
	itemQuantity = 0,
	handleAddItemClick = () => null,
	disabledPlus = false,
	disabledMinus = false,
	disabledAdd = false,
	buttonLabel = i18n.t('ADD_LABEL'),
	buttonSize = 'md',
	withMargin = false,
	popupButtons = false, // style flag to align the buttons content properly inside popups, checkout and order details page
	invertColors = false // style flag to invert the colors in button
}) => {
	const { restaurantColor, isRestaurantColorDark, isLanguageArab } = useRestaurantsInfoContext()

	const prevCount = useRef(itemQuantity)

	useEffect(() => {
		return () => {
			prevCount.current = itemQuantity
		}
	}, [itemQuantity])

	const itemCount = () => {

		const itemCounter = () => <>{range(0, 3).map(countIndex => {

			const isCountIncreasing = itemQuantity > prevCount.current
			const isCountDecreasing = itemQuantity < prevCount.current
			const isInactive = itemQuantity % 3 === (countIndex+1) % 3
			const isReadyToBeDisplayed = itemQuantity % 3 === (countIndex+2) % 3

			return <ItemCountButton.Count
				key={`${title}-count-${countIndex}`}
				increasing={isCountIncreasing}
				decreasing={isCountDecreasing}
				inactive={isInactive}
				ready={isReadyToBeDisplayed}
				hideNumber={(itemQuantity === 1 && countIndex === 0)}
			>
				{(isCountIncreasing && isInactive) ? itemQuantity-1 : (isCountDecreasing && isReadyToBeDisplayed) ? itemQuantity + 1 : itemQuantity}
			</ItemCountButton.Count>
		})}</>

		return <>
			<ItemCountButton withoutImage={!itemImage} itemQuantity={itemQuantity} popupButtons={popupButtons}>
				<ItemCountButton.Minus isLanguageArab={isLanguageArab} id={`${title}-decrement-button`} role='button' onClick={() => itemQuantity && !disabledMinus && handleAddItemClick('-')}>-</ItemCountButton.Minus>
				{!!itemQuantity && itemCounter()}
				<ItemCountButton.Plus isLanguageArab={isLanguageArab} id={`${title}-increment-button`} role='button' onClick={() => itemQuantity && !disabledPlus && handleAddItemClick('+')}>+</ItemCountButton.Plus>
			</ItemCountButton>
			<ItemFirstAddButton withoutImage={!itemImage} itemQuantity={itemQuantity} popupButtons={popupButtons} id={`${title}-first-add-button`} role='button'>
				{buttonLabel}
			</ItemFirstAddButton>
		</>
	}

	const AddButton = (withImage) => <ItemAddButton
		withImage={withImage}
		restaurantColor={restaurantColor}
		isRestaurantColorDark={isRestaurantColorDark}
		itemQuantity={itemQuantity}
		onClick={() => !itemQuantity && !disabledAdd && handleAddItemClick('')}
		buttonSize={buttonSize}
		withMargin={withMargin}
		popupButtons={popupButtons}
		invertColors={invertColors}
		isLanguageArab={isLanguageArab}
	>
		<ItemAddButton.Content popupButtons={popupButtons} comboButtons={popupButtons} itemQuantity={itemQuantity}>{itemCount()}</ItemAddButton.Content>
	</ItemAddButton>

	return <AddButtonWrapper
	customizableWithImage={showAddItemButton && !!itemImage && isCustomizable}
	withImage={showAddItemButton && !!itemImage && !isCustomizable}
	>
		{!!itemImage && <ImageWrapper><ItemImage src={itemImage} /></ImageWrapper>}
		{showAddItemButton && AddButton(!!itemImage)}
		{showAddItemButton && isCustomizable && <CustomizableText withImage={!!itemImage}>{i18n.t('CUSTOMIZABLE')}</CustomizableText>}
	</AddButtonWrapper>

}

const ItemCard = ({
	title = '',
	itemImage = '',
	itemQuantity = 0,
	foodType,
	price,
	discountedPrice,
	description,
	isRestaurantIndian,
	couponsNotApplicable = false,
	handleAddItemClick,
	isCustomizable = false,
	showAddItemButton
}) => {

	const { restaurantCurrency, isLanguageArab } = useRestaurantsInfoContext();

	const [showMore, setShowMore] = useState(false)

	const FoodTypeLogo = foodType && <FoodType id={`${title}-food-type`} type={foodType}>
		<FoodTypeIcon isRestaurantIndian={isRestaurantIndian} type={foodType} isLanguageArab={isLanguageArab} />
	</FoodType>

	const getPriceWithCurrency = (price, strikethrough) => <PriceText isLanguageArab={isLanguageArab} id={`${title}-${strikethrough ? 'actual-discounted-price' : 'actual-price'}`} strikethrough={strikethrough}>
		<RestoCurrency isLanguageArab={isLanguageArab} id={`${title}-resto-currency`}>{restaurantCurrency || ''}</RestoCurrency>
		<span id={`${title}-${strikethrough ? 'discounted-price' : 'price'}`}>{roundOffToTwoPlaces(price)}</span>
		{couponsNotApplicable && !strikethrough && <CouponsNotApplicableText isLanguageArab={isLanguageArab} id={`${title}-coupons-not-applicable-text`} withPrice>({i18n.t('COUPON_NOT_APPLICABLE')})</CouponsNotApplicableText>}
	</PriceText>

	const itemPrice = !!price 
		? <ItemPrice withDescription={!!description} isLanguageArab={isLanguageArab}>
			{!!discountedPrice
				? <React.Fragment>
					{getPriceWithCurrency(price, true)}
					{getPriceWithCurrency(discountedPrice)}
				</React.Fragment>
				: getPriceWithCurrency(price)}
		</ItemPrice>
		: couponsNotApplicable && <CouponsNotApplicableText id={`${title}-coupons-not-applicable-text`}>({i18n.t('COUPON_NOT_APPLICABLE')})</CouponsNotApplicableText>

	const slicedDescription = () => description.length > 80 ? showMore ? <span>{description} <strong id={`${title}-show-less-button`} onClick={() => setShowMore(!showMore)}>{i18n.t('LESS_BUTTON')}</strong></span> : <span>{description.slice(0, 85)} <strong id={`${title}-show-more-button`} onClick={() => setShowMore(!showMore)}>{i18n.t('MORE_BUTTON')}</strong></span> : description

	const ItemDetails = <LeftSection isLanguageArab={isLanguageArab}>
		{FoodTypeLogo}
		<ItemTitle isLanguageArab={isLanguageArab} id={`${title}-title`}>{title}</ItemTitle>
		{itemPrice}
		{description && <ItemDescription isLanguageArab={isLanguageArab} id={`${title}-description`}>{slicedDescription()}</ItemDescription>}
	</LeftSection>

	const ItemImageWithAddButton = <RightSection isLanguageArab={isLanguageArab}>
		<AddItemButton
			{...({
				title,
				itemImage,
				showAddItemButton,
				isCustomizable,
				itemQuantity,
				handleAddItemClick,
				withMargin: true
			})}
		/>
	</RightSection>

	return <ItemCardWrapper
		withImage={!!itemImage && !isCustomizable && showAddItemButton}
		withImageAndCustomization={!!itemImage && isCustomizable && showAddItemButton}
		withDescription={!!description}
		isLanguageArab={isLanguageArab}
	>
		{ItemDetails}
		{ItemImageWithAddButton}
	</ItemCardWrapper>
}

export default ItemCard