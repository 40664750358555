export const TRANSLATIONS_EN = {
    offer_title: "OFFERS",
    THANK_YOU: "Thank you",
    GO_TO_MAIN_MENU: "Go to main menu",
    THERE_ARENT_ANY_ADDED_ITEM: "There aren't any added items",
    ADD_ITEMS: "Add items",
    ITEMS: "Items",
    CHECKOUT: "Checkout",
    SELECT_OUTLET: 'Please provide your location to select nearby outlets',
    SELECT_LOCATION_MANUALLY: 'Enter location manually',
    DETECT_LOCATION: 'Detect My Location',
    NO_RESULT_FOUND: 'No results found',
    MOVE_MAP_SLOWLY: 'Please move the map slowly.',
    GEOCODER_FAILED: 'Geocoder failed',
    USE_CURRENT_LOCATION: 'Use my current location',
    ENTER_LOCATION: 'Enter a location*',
    NOT_ACCEPTING_ORDER_NOW: 'Sorry, We are not accepting {{orderType}} orders right now.',
    HOME: 'Home',
    VIEW_MENU: 'View Menu',
    INSTRUCTION_ADDED: 'INSTRUCTIONS ADDED',
    CLEAR: 'Clear',
    ADDITIONAL_INSTRUCTIONS: "Additional Instructions (Optional)",
    ADD_INSTRUCTIONS: 'Add Instructions',
    YOUR_PREVIOUS_CUSTOMIZATION: 'Your previous customization',
    REMOVE_COMBO_FROM_CART_POPUP: 'This item has multiple customizations added. You would need to remove the item from the cart.',
    ADD: 'Add',
    NO_ITEM_FOUND: 'NO ITEMS FOUND',
    CLOSE: "Close",
    MENU: "Menu",
    FOOD_PREFRENCE: "Food Preferences",
    CANCEL: "Cancel",

    GET_OFF: "Get {{currency}} {{value}} off",
    GET_FREE_DELIVERY: 'Get free delivery',
    NO_OFFER_AVAILABLE: 'No offers available',
    APPLY: "Apply",
    REMOVE: "Remove",
    ONLINE_CASH: 'online/cash',
    OFFERS: 'Offers',

    INVALID_OTP: 'Invalid OTP',
    RECEIVE_ORDER_NOTIF: 'You will receive notification about order on',
    NOT_EMPTY: "{{key}} field should not be empty.",
    NAME_ONLY_CHAR: "Name field accepts only characters, numbers and spaces",
    INVALID_NUMBER: "Invalid number, Please try again.",
    PLEASE_ENTER_OTP: 'Please enter the OTP',
    INVALID_OTP_TRY_AGAIN: "Invalid OTP, Please try again",
    PLEASE_ENTER_NAME: "Please Enter Name",
    OTP_SENT_TO_DEVICE: 'A 6-digit OTP will be sent to your device to verify your number.',
    SENDING: "Sending",
    VERFIFYING: "Verifying",
    VERIFY: "Verify",

    PAY_NOW: "Pay Now",

    NO_OF_PERSON: "Number of person",
    FOLLOW_US: "Follow Us",
    DID_YOU_KNOW: "Did you know?",
    CONGRATS: "Congratulations!",
    YOU_SAVED: "You have saved",
    IF_PLACED_AGG_ORDER: " if you had placed the same order on food aggregators then it would have cost you ",
    ADD_NEW_CUSTMZ: "+ Add new customization",

    //AddressBookConstatns
    WELCOME_TO: 'Welcome to',
    FETCHING_STATUS: 'Fetching status',
    CONTINUE: 'Continue',
    SELECT_ADDRESS: 'Select an outlet',
    SAVE_NEW_ADDRESS: 'Save new address',
    TEMPORARILY_CLOSED: 'We are temporarily closed.',
    AVAILABLE_ON: 'Will be next available on',
    CHOOSE_OUTLET: 'Select an outlet near your location',
    VIEW_MORE: 'View more',
    SEARCH_OUTLETS: 'See outlets nearby',
    UH_OH: 'Uh oh!',
    SAVE_AND_PAY: 'Save and Pay',
    OTHER_DETAILS_HEADING: 'Other Details (* fields are mandatory)',
    FULL_NAME_LABEL: 'Full Name*',
    HOUSE_NO_LABEL: 'House / Flat No*',
    LANDMARK_LABEL: 'Landmark',
    ENTER_ADDRESS_ERROR: 'Please enter your address or select your location from the map.',
    ENTER_HOUSE_NO_ERROR: 'Please enter your house / flat no.',
    ENTER_NAME_ERROR: 'Please enter your full name.',
    ADDRESS_TYPE_LABEL: 'Address Label*',
    SAVED_ADDRESSES: 'Saved addresses',

    COD: "Cash on Delivery",
    POD: "Pay on delivery",
    TAKEAWAY: "Pay on Pick-up",
    PAY_IN_CASH: "Pay later",

    //Errors
    OFFER_UNDEFINED: "No coupon code is selected",
    OFFER_AMOUNT_INVALID: "Total order value of items eligible for coupon discount is less than minimum order value.",
    OFFER_AMOUNT_LESS_THAN_ORDER_VALUE: "Order value is less than required minimum order value for this coupon",
    OFFER_AMOUNT_INVALID_FOR_ITEMS: "Coupon code not applicable for the selected items.",
    ALPHA_NUMERIC: "Please enter letters and numbers only",
    OFFER_LENGTH: "Offer code length can not be greater than 15",
    LOCATION_NOT_SERVICEABLE: 'This location is currently not serviceable. Please try again later ',
    MIN_ORDER_ADD_MORE: "The minimum order amount is {{minOrderAmountForDelivery}}. Please add more items to your cart.,",
    VARIANT_OUT_OF_STOCK: "Variant out of stock",
    ADDON_OUT_OF_STOCK: "Addon out of stock",
    REFRESH_MODIFY_CART: 'Please refresh the page and modify your cart',
    REFRESH: "Refresh",
    BILL_DETAILS: "Bill Details",
    ITEM_TOTAL: "Item Total",
    DISCOUNT: "Discount",
    GRAND_TOTAL: "Grand Total",
    TOTAL_PAYABLE: "Total Payable",
    TABLE_NO: "Table Number",
    ROOM_NO: "Room Number",
    COUPON_NOT_APPLICABLE: "Coupons not applicable",
    SAFTY_PRIORITY_DELIVED: "Your safety is our priority! We ensure that your orders are safely prepared, safely packed & safely delivered.",
    OFFER_APPLIED_ON_BILL: "OFFER APPLIED ON THE BILL",
    APPLY_COUPON: "Apply Coupon",
    APPLICABLEF_FOR_PAY_NOW_OPTION: "APPLICABLE FOR PAY NOW OPTION",
    APPLICABLEF_FOR_PAY_LATER_OPTION: "APPLICABLE FOR PAY LATER OPTION",
    ADD_ADDRESS_TO_GET_DELIVERY_CHARGES: "Add address to get delivery charges",
    AVAILABLE_COUPONS: "AVAILABLE COUPONS",
    OFFER_APPLIED_ON_THE_BILL: "OFFER APPLIED ON THE BILL",
    NO_DELIVERY_CHARGE: "No delivery charge",
    ON_THIS_ORDER: "on this order",
    ORDER_FOR: 'Order for',
    TO_AVOID_MORE_DELIVERY_CHARGE: "more to avoid delivery charges",
    ADD_NEW_ADDRESS: 'Add new address',
    PROCESSING_YOUR_ORDER: "Processing your Order...",

    NO_DATA_FOUND: 'No data found ',
    NOT_ACCEPTING_ORDERS_INFO1: 'We are not accepting orders right now!',
    NOT_ACCEPTING_ORDERS_INFO2: 'Check back later in some time.',
    SELECT_ORDER_TYPE: 'Select Order Type',
    SELECT_ORDER_TYPE_TO_CONTINUE: 'Select an order type to continue',
    OPENS_AT: 'Opens at',
    FOR: 'for',

    YOUR_ORDER_NO_PLACED: "Your order with no {{orderId}} is successfully placed.",
    YOUR_ORDER_NO_CANCELLED: "Your order with no {orderId} is cancelled.",
    CANCEL_ORDER: "Cancel Order",
    PAYMENT_COUDNT_CONFIRMED: "Your payment could not be confirmed. Please check with restaurant or try again.",
    GO_TO_HOME: "Go to home",
    GO_TO_CART: 'Go to cart',
    ABOVE_ITEM_WILL_BE_ADDED_TO_TOTAL_BILL: "* Above items will be added to your total bill",

    YOU_ARE_LOST: "Oops! You're lost.",
    PAGE_NOT_FOUND: "The page you are looking for was not found.",
    LOOKING_FORR_RESTO_DASHBOARD: "Looking for restaurant dashboard ?",
    CLICK_HERE: "Click here",

    YOU_NEED_HELP: "Oops! You need help",
    REACH_OUT_SUPPORT_FOR_ACCESS: "Please reach out to gupshup support to give you access to the dashboard",
    GO_BACK_TO_LOGIN: "Go back to Login",
    WE_HAVE_PROBLEM: "Houston, we have a problem!",
    PAGE_UNAVAILABLE: "The page you are looking for is temporarily unavailable.",
    WOULD_LIKE_ORDER_ADVANCE: "Would you like to place an order in advance?",

    ADDONS: 'Addons',
    VARIANTS: 'Variants',
    LESS_BUTTON: '...less',
    MORE_BUTTON: '...more',
    ADD_LABEL: 'Add',
    CUSTOMIZABLE: 'Customizable',
    CUSTOMIZED_COMBO_TYPES: ['CUSTOMIZED_COMBO', 'PAY_X_GET_Y', 'BUY_X_GET_Y'],
    BASE_PRICE_COMBOS: ['SIMPLE_COMBO', 'CUSTOMIZED_COMBO'],
    BASE_PRICE: 'Base price',
    CHOOSE_ITEMS: 'Choose Items',
    CHOOSE_VARIANTS: 'Choose Variants',
    CHOOSE_ADDONS: 'Choose Add-ons',
    PLEASE_SELECT_MESSAGE: 'Please select',
    BACK_BUTTON: 'Back',
    NEXT_BUTTON: 'Next',
    ADD_BUTTON: 'Add',
    UPDATE_BUTTON: 'Update',
    OUT_OF_STOCK_MESSAGE: 'Out of stock',
    addonsAtleastUptoText: "Please select atleast {{minimum}} add-on{{s}} upto {{maximum}} ({{selected}}/{{maximum}} selected)",
    addonsAtleastText: "Please select atleast {{minimum}} add-on ({{selected}} selected)",
    addonsUptoText: "Please select upto {{maximum}} add-on{{s}} ({{selected}}/{{maximum}} selected)",
    addonsExactText: "Please select {{qty}} add-on{{s}} ({{selected}} selected)",
    SELECT_MAIN_ITEMS: 'Select main items',
    SELECT_FREE_ITEMS: 'Select free items',
    CLOSE_TABLE_PAY: "Close Table and Pay",
    LIKE_TO_ORDER_DIFF_ADDRESS: "WOULD YOU LIKE TO ORDER AT DIFFERENT ADDRESS",
    UPDATE_LOCATION: "Update Location",
    TABLE_CLOSED_FOR_ORDERS: "Table is closed for orders",
    SCAN_QR_TO_ORDER: "SCAN OTHER QR CODE TO ORDER",
    NAME_NOT_EMPTY: "{{key}} field should not be empty.",
    ITEM_DETAILS: 'ITEM DETAILS',

    RESERVATION_CNFR_FEW_MINT: 'Your reservation will be confirmed in few minutes',
    TAKEAWAY_OPTION: 'I want to takeaway',
    DELIVERY_OPTION: 'I would like delivery',
    FETCHING_LOCATION: 'Fetching location status...',
    SET_LOCATION_TEXT: 'Click here to set your location',
    SET_LOCATION_TEXT_TAKEAWAY: 'Click here to view store distance',
    SELECT_STORE: 'Set location & choose outlet here',
    SELECT_STORE_TAKEAWAY: 'Click here to select nearest outlet',
    SELECT_LOCATION: 'Select location',
    CHANGE_LOCATION: 'Change location',
    CHANGE: 'Change',
    TABLE_NO: 'Table Number',
    ROOM_NO: 'Room Number',
    COMBO_OFFER: 'Combo Offers',
    RESTORENT_LOGO: 'Restaurant Logo',
    ONLINE: 'online',
    CASH: 'cash',
    RESENDING: 'Resending...',
    RESEND_NOW: 'Resend now.',
    RESEND_IN: 'Resend in {{timer}}s.',
    ALMOST_THERE: "ALMOST THERE !",
    REMOVE_ITM_FRM_CART: 'Remove item from cart',
    MORE: "more",
    ADDON: 'Addon',
    CURRENTLY_UNAVAILABLE: 'Currently unavailable',
    EDIT_ADDRESS_BTN: 'Edit address button',
    ADD_ADDRESS_BTN: 'Add address button',
    YOUR_ORDER_DETAILS: 'Your Order Details',
    SUPER_SAVING: 'Super Savings',
    ORDER_NOW: 'Order now',
    CHANGE_LOCATION: 'Change Location',
    TAKEAWAY_OPTION: 'I want to takeaway',
    DELIVERY_OPTION: 'I would like delivery',
    LOCATION_UNSERVICEABLE: "Location Unserviceable",
    DELIVERY_NOT_AVAILABLE: "Delivery not available",
    TAKEAWAY_NOT_AVAILABLE: "Takeaway not available",
    SHOP_IS_CLOSED: "Restaurant temporarily closed",
    PLEASE_TRY_AGAIN: "Please try again after some time",
    DELIVERY_TRY_AFTER_SOMETIME: "For delivery please try after some time",
    TAKEAWAY_TRY_AFTER_SOMETIME: "For takeaway please try after some time",
    SELECT_OUTLET: 'Please provide your location to select nearby outlets',
    SELECT_LOCATION_MANUALLY: 'Enter location manually',
    DETECT_LOCATION: 'Detect My Location',
    USE_CURRENT_LOCATION: 'Use my current location',
    ENTER_LOCATION: 'Enter a location*',
    FETCHING_STATUS: "Fetching status",
    CONTINUE: "Continue",
    VIEW_MENU: "View Menu",
    SELECT_ADDRESS: "Select an outlet",
    SAVE_NEW_ADDRESS: "Save new address",
    TEMPORARILY_CLOSED: "We are temporarily closed.",
    AVAILABLE_ON: "Will be next available on",
    CHOOSE_OUTLET: "Select an outlet near your location",
    VIEW_MORE: "View more",
    SEARCH_OUTLETS: "See outlets nearby",
    UH_OH: "Uh oh!",
    SAVE_AND_PAY: "Save and Pay",
    OTHER_DETAILS_HEADING: "Other Details (* fields are mandatory)",
    FULL_NAME_LABEL: "Full Name*",
    HOUSE_NO_LABEL: "House / Flat No*",
    LANDMARK_LABEL: "Landmark",
    ENTER_ADDRESS_ERROR:
        "Please enter your address or select your location from the map.",
    ENTER_HOUSE_NO_ERROR: "Please enter your house / flat no.",
    ENTER_NAME_ERROR: "Please enter your full name.",
    ADDRESS_TYPE_LABEL: "Address Label*",
    SAVED_ADDRESSES: "Saved addresses",

    /** Payment */
    DEBIT_CREDIT_CARDS: 'Debit / Credit cards',
    VISA_MASTERCARD_MORE: 'Visa, MasterCard, RuPay & more',
    PAY_VIA_UPI: 'Pay via UPI',
    WALLETS: 'Wallets',
    PHONEPAY_PAYTM_MORE: 'PhonePe, PayTM & more',
    NETBANKING: 'Netbanking',
    ALL_INDIAN_BANKS: 'All Indian Banks',
    METHODS_LOADING_INFO: 'Please wait while we load your payment methods...',
    SELECT_PAYMENT_METHOD: 'Select Payment Method',
    PREFERRED_PAYMENT_METHODS: 'PREFERRED PAYMENT METHODS',
    POPULAR_PAYMENT_METHODS: 'POPULAR PAYMENT METHODS',
    ALL_PAYMENT_OPTIONS: 'ALL PAYMENT OPTIONS',
    ALL_PAYMENT_METHODS_PAGE: 'all-payment-methods-page',
    CARDS_PAGE: 'cards-page',
    WALLETS_PAGE: 'wallets-page',
    NETBANKING_PAGE: 'netbanking-page',
    UPI_PAGE: 'upi-page',
    CARDS_PAGE_HEADING: 'Cards',
    WALLET_PAGE_HEADING: 'Wallets',
    NETBANKING_PAGE_HEADING: 'Netbanking',
    UPI_PAGE_HEADING: 'Enter your UPI ID',
    CARDHOLDER_NAME: 'Cardholder\'s name',
    CARD_NUMBER: 'Card number',
    CARD_EXPIRY: 'Expiry (mm/yy)',
    CARD_CVV: 'CVV',
    SAVE_CARD_LABEL: 'Remember card details',
    SAVE_CARD_ADDITIONAL_LABEL: 'Your CVV will never be stored',
    PAY_NOW: 'Pay now',
    CARDHOLDER_NAME_ERROR: 'Please enter the cardholder\'s name.',
    CARD_NUMBER_EMPTY_ERROR: 'Please enter the card number.',
    EXPIRY_EMPTY_ERROR: 'Please enter the expiry date in the format of mm/yy.',
    CVV_EMPTY_ERROR: 'Please enter the CVV.',
    getCVVLengthError: 'Please use {{length}} digits for the CVV.',
    CVV_INVALID_ERROR: 'Please use digits only for the CVV.',
    CARD_NUMBER_ERROR: 'Invalid card number. Please try again.',
    EXPIRY_ERROR: 'Invalid expiry date. Please try again.',
    UPI_ID: 'UPI ID',
    UPI_ID_PLACEHOLDER: 'upi@ybl',
    REMEMBER_UPI_ID_LABEL: 'Remember ID',
    REMEMBER_UPI_ID_ADITIONAL_LABEL: 'Your UPI ID will be encrypted',
    VERIFY_AND_PAY: 'Verify and pay',
    INVALID_UPI_ERROR: 'Please enter a valid UPI ID.',
    EMPTY_UPI_ERROR: 'Please enter the UPI ID.',
    SELECT_OTHER_BANK: 'Select a different bank',
    SEARCH_BANK: 'Search bank',
    NO_BANK_FOUND: 'No such bank found!',
    airtelmoney: 'Airtel Money',
    freecharge: 'Freecharge',
    jiomoney: 'Jio Money',
    mobikwik: 'Mobikwik',
    olamoney: 'Ola Money',
    phonepe: 'PhonePe',
    payzapp: 'PayZapp',
    TIMER_INFO: 'Please do not refresh the page or click the back button.',
    TAKING_TIME_INFO: 'Hmm...this is taking quite some time.',
    PAYMENT_FAILED_INFO: 'Payment failed. Please try again.',
    PAYMENT_FAILED_FATAL_INFO: 'Something went wrong. Please initiate the payment again from checkout page.',
    UPI_LINK_FAILURE_INFO: 'We could not initiate this payment. Please try again.',
    PROCESSING_INFO: 'Please wait while we process your payment.',
    PROCESSING_WAIT_INFO: 'Please wait a few more seconds while we check the status of your payment.',
    SHOW_MORE: 'Show more',
    SHOW_LESS: 'Show less',
    APP_NOT_INSTALLED: 'App is not installed or was closed too soon.',

    placeHolder: {
        SEARCH: 'Search...',
        SEARCH_DISH: 'Search dish',
        EX_DONT_ADD_CAPSICUM: "Eg. Don't add capsicum in the Sandwich.",
        NUMBER: 'Number*'
    },

    notificationMsg: {
        PLEASE_SELECT_AN_ADDRESS: "Please select an address",
        NO_DEATILS_AVAL_FOR_INPUT: "No details available for input: {{name}} ",
        FEATURE_DONT_SUPPORT: 'This feature doesn\'t support for this device',
        CHECK_BROWSER_SETTING_LOCATION: 'Please check your browser settings to allow us get your location',
        NO_OUTLET_LOCATION: 'No outlet available for this location. Please select a different location',
        SOMETING_WENT_WRONG: 'Something went wrong, Please try again',
        PLEASE_ADD_INSTRUCTIONS: "Please Add Instructions",
        INSTRUCTION_LENGTH_MUST: "Instructions length can not be greater than 100",
        TABLE_NO_CANT_GRATER: "Table number length can not be greater than 3",
        TABLE_NO_SHD_ALP_NUM: "Table number should be alpha-numeric",
        ROOM_NO_LENGTH_CANT_GT: "Room number length can not be greater than 6",
        ALP_NUM_ONLY_ROOM_NO: "Please use alphabets & numbers only to enter the room number",
        PLZ_ENT_TABLE_NO: 'Please enter table number',
        PLZ_ENT_ROOM_NO: 'Please enter room number',
        MIN_ORDER_IS: "Minimum order amount is {{minOrderAmount}}",
        TABLE_COLOED_FOR_ORDERS: "Table is closed for orders",
        ACTION_NOT_ALLOWED: 'Action not allowed',
        COUPON_REMOVE_FROM_ORDER: "Coupon removed for this order",
        SCAN_QR_TO_ORDER: "Scan QR code to order",
        MIN_ORDER_AND_FREE_DEL: "Minimum order value is {{minimumOrderAmount}} & free delivery for order above {{freeDeliveryAmount}}",
        FREE_DEL_ABOVE: "Free Delivery for order above {{freeDeliveryAmount}}",
        OFFER_IS_NOT_APPLICABLE: 'Sorry, this offer is not  applicable for you',
        FEATURE_DOESNT_SUPPORT_FOR_THIS_DEVICE: 'This feature doesn\'t support for this device',
        CHECK_BROWSER_LOCATION_SETTINGS: 'Please check your browser settings to allow us get your location',

    },

    // ------------------------- New added keys
    SEND_OTP_HEADING: "You're just a step away from placing your order",
    NAME_PLACEHOLDER: "Name*",
    OTP_SENT_TO: "OTP sent to ",
    CHANGE_NUMBER: "Change number?",
    DIDNT_RECEIVE_OTP: "Didn't receive OTP?",

    ENTER_CODE: "Enter code",
    REPEAT: "REPEAT",
    CUSTOMIZE: "CUSTOMIZE",
    BROWSE_MENU_INFO: "Feel free to browse the menu.",
    INVALID_ORDERTYPE: "This order type is not supported in this restaurant",
    Confirm: "Confirm",
    CLOSING: "Closing.....",
    CUSTOMIZED: "CUSTOMIZED",
    DELIVERY_TO: "Delivery To",
    selectItemComboText: "Please select {{itemsNeeded}} items. ({{itemsSelected}} \/ {{itemsNeeded}} selected)",
    selectItemText: "{{qty}} item{{s}} selected.",
    itemsAtleastUptoText: "Please select atleast {{minimum}} items upto {{maximum}} ({{selected}}\/{{maximum}} selected)",
    itemsAtleastText: "Please select atleast {{minimum}} items ({{selected}} selected)",
    itemsUptoText: "Please select upto {{maximum}} items ({{selected}}\/{{maximum}} selected)",
    itemsExactText: "Please select {{qty}} items ({{selected}} selected)",
    pleaseTryDifferentLocation: "Uh Oh! We haven't got this location on our plate yet. Please try a different location.",
    byOrderingMsg: "By ordering directly from a restaurant you pay {{value}} less compared to ordering from an aggregator's platform.",
    moreInflatedMsg: "Hurray! No more inflated prices on the menu!",
    lesserMenuPricesMsg: "We got you a dare! Show us lesser menu prices for our restaurant anywhere else…",
    ThanksMsg: "Thanks for making us Atma-Nirbhar by ordering from us directly! We will make sure you get a price benefit.",
    dontInflateMsg: "Naah! We don’t inflate item prices on our menu here.",
    NO_BROWSER_LOCATION_SUPPORT: "Your device does not support access to your location.",
    NO_LOCATION_ACCESS: "Please check your browser settings to allow us get your location",
    Takeaway: "Takeaway",
    Delivery: "Delivery",
    OFFICE: "Office",
    OTHER: "Other",
    YAY: "Yay!",
    FOOD: "Food",
    BAR: "Bar",
    VEG: "Veg",
    NONVEG: "Non-Veg",
    EGG: "Egg",
    OhNoItemFound: "Oh ho, No items found",
    YouHaveNotification: "You have new notification",
    DoYouWantjoinTable: "Do you want to join the table?",
    YES: "Yes",
    NO: "No",
    UseCodeGetOffer: "Use code to get offer",
    UseCodeGetOffAbove: "Use code {{code}} and get 10% off on all the orders above $100",
    UseCodeGetOffUpto: "Use code {{code}} and get 87% off on all orders above ₹989 upto ₹90",
    UseCodeGetFreeDelivery: "Use code {{code}} and get free delivery ",
    UseCodeGetFreeDeliveryAbove: "Use code {{code}} and get free delivery on all the orders above $100",
    UseCodeGetFreeDeliveryUpto: "Use code {{code}} and get free delivery on all the orders above $989 upto ₹90",
    ull: null,
    ABOUT_US: "About Us",
    CONTACT_US: "Contact Us",
    PRIVACY_POLICY: "Privacy Policy",
    TERMS_AND_CONDITIONS: "Terms and Conditions",
    POWERED_BY: "Powered By",
    ull: null,
    Backend: null,
    COUPON_USAGE_LIMIT_CROSSED: "The coupon has been used {{ count }} time(s) & cannot be used again",
    OFFER_IS_NOT_APPLICABLE_FOR_ORDER: "Sorry, This offer is not applicable for {{orderType}} orders",
    WE_ARE_NOT_ACCEPTING_ORDER_NOW: "Sorry, We are not accepting {{orderType}} orders right now",
    OR_TEXT: "OR",
    OpensAt: '{{orderType}} Opens at {{time}} {{day}}',
    PleaseEnableLocationAccess: 'Location is inaccessible. Please enable location access by changing the settings',
    Code:'Code',
    Applied: 'applied', 
    ChangeCoupon: 'Change coupon',
    CouponSavings: 'Coupon savings',
    Coupons:'Coupons',
    TOTAL: 'Total',
    YOUR_ORDER: "Your Order",
    DELIVERY_CHARGE: 'Delivery Charge',
    SERVICE_CHARGE: 'Service Charges',
    PACKAGING_CHARGE: 'Packaging Charges',
    TAXES_AND_CHARGES: 'Taxes and charges',
    SAVED:'saved', 
    CHANGE_ADDRESS: 'Change Address'
};
