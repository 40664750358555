import { MENUID_DOMAIN } from "../constants/variables";
import { GET, POST, PUT } from "../lib/axios";
import { get } from "./common";

const getRestaurantDetails = (productId) => {
  return new Promise((resolve, reject) => {
    GET(`ui/v1/${productId}/getMetaData`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

function getOutletDetails(productId, data) {
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${productId}/getOutlet`, data)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

const getMenuDetails = (productId) => {
  return new Promise((resolve, reject) => {
    GET(`ui/v1/menu/${productId}`)
      .then((response) => resolve(response.data))
      .catch((error) => {
        reject(error);
      });
  });
};

const getMenuId = (productId) => {
  return new Promise((resolve, reject) => {
    const customDomainMenuId = sessionStorage.getItem("customDomainMenuId");
    if (productId !== MENUID_DOMAIN || customDomainMenuId) {
      resolve(productId !== MENUID_DOMAIN ? productId : customDomainMenuId)
    } else {
      GET(`ui/v1/${productId}/getMenuId`)
      .then((response) => resolve(get(response, 'data.data')))
      .catch((error) => {
        reject(error);
      });
    }
  })
}

const getAddressList = () => {
  return new Promise((resolve, reject) => {
    GET(`ui/v1/getAllAddress`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

function addNewAddress(address) {
  return new Promise((resolve, reject) => {
    POST(`ui/v1/addAddress`, address)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

function newOrderRequest(productId, order) {
    return new Promise((resolve, reject) => {
      POST(`ui/v1/order/${productId}`, order, null, ['INVALID_ITEM_FULFILLMENT_MODE', 'INVALID_FULFILLMENT_MODE', 'COMBO_NOT_FOUND', 'ITEM_NOT_FOUND', 'ADDON_NOT_FOUND', 'VARIANT_NOT_FOUND', 'ITEM_NOT_AVAILABLE', 'INVALID_ADDONS', 'INVALID_VARIANTS'], true)
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    })
}

function paymentSuccess(orderId, paymentData) {
    return new Promise((resolve, reject) => {
        POST(`ui/v2/order/${orderId}/paymentSuccess`, paymentData)
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    })
}

function newOrderRequestCustom(productId, order) {
    return new Promise((resolve, reject) => {
      POST(`ui/v2/order/${productId}`, order, null, ['INVALID_ITEM_FULFILLMENT_MODE', 'INVALID_FULFILLMENT_MODE', 'COMBO_NOT_FOUND', 'ITEM_NOT_FOUND', 'ADDON_NOT_FOUND', 'VARIANT_NOT_FOUND', 'ITEM_NOT_AVAILABLE', 'INVALID_ADDONS', 'INVALID_VARIANTS'])
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    })
}

function orderByCash(orderId, order) {
  return new Promise((resolve, reject) => {
    PUT(`ui/v1/order${orderId}/paymentStatus`, order)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

function payOnline(productId, orderId) {
  return new Promise((resolve, reject) => {
    const data = {
      callback_url: window.location.origin + productId,
    };
    POST(`ui/v1/order/${orderId}/paymentLink`, data)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

function updateOnlinePaymentStatus(orderId, data) {
  return new Promise((resolve, reject) => {
    POST(`ui/v1/order/${orderId}/paymentStatus`, data)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}
function reserveTable(productId, data) {
  return new Promise((resolve, reject) => {
    POST(`ui/v1/tableRes/${productId}`, data)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}
function getQuoteData(locationData, noToast) {
  return new Promise((resolve, reject) => {
    POST(`ui/v1/checkDeliveryStatus`, locationData, null, (noToast && ['ALL']))
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

function cancelOrderByUser(orderId) {
  return new Promise((resolve, reject) => {
    POST(`ui/v1/cancelOrder/${orderId}`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

function generateOTP({ phoneNumber, recaptchaToken, menu_id }) {
  return new Promise((resolve, reject) => {
    POST(`ui/v1/otp/${menu_id}/send/${phoneNumber}`, { recaptchaToken })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

function validateOTP({ phoneNumber, data }) {
  return new Promise((resolve, reject) => {
    POST(`ui/v1/otp/verify/${phoneNumber}`, data)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

const generateGipLink = (botname) => {
  return new Promise((resolve, reject) => {
    GET(`ui/v1/gipLink/${botname}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};
const getGipUser = () => {
  return new Promise((resolve, reject) => {
    GET(`ui/v1/getGipUser`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};
const markNotificationAsRead = (menu_id) => {
  return new Promise((resolve, reject) => {
    GET(`ui/v1/${menu_id}/markNotificationAsRead`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

async function listActiveOffers(menu_id, fulFillmentMode) {
  return new Promise((resolve, reject) => {
    GET(`ui/v1/${menu_id}/listActiveOffers/${fulFillmentMode}`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

function checkOfferAvailableStatus(menu_id, offer_id, data) {
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${menu_id}/applyOffer/${offer_id}`, data)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}
async function checkTableStatus(menu_id, table_number) {
  return new Promise((resolve, reject) => {
    GET(`ui/v1/${menu_id}/tableStatus/${table_number}`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function blockTable(menu_id, table_number) {
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${menu_id}/blockTable/${table_number}`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function joinTable(menu_id, table_number) {
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${menu_id}/joinTable/${table_number}`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function createSubOrder(menu_id,data) {
    return new Promise((resolve, reject) => {
      POST(`ui/v1/subOrder/${menu_id}`, data, null, ['INVALID_ORDER_AMOUNT', 'INVALID_ITEM_FULFILLMENT_MODE', 'INVALID_FULFILLMENT_MODE', 'ADDON_NOT_FOUND', 'VARIANT_NOT_FOUND', 'INVALID_VARIANTS', 'ITEM_NOT_FOUND', 'COMBO_NOT_FOUND', 'ITEM_NOT_AVAILABLE', 'INVALID_ADDONS'], true)
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    })
}

async function closeDineInOrder(menu_id, table_number, data) {
  return new Promise((resolve, reject) => {
    POST(`ui/v1/order/${menu_id}/${table_number}`, data)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function closeDineInOrderCustom(menu_id,table_number,data) {
  return new Promise((resolve, reject) => {
      POST(`ui/v2/order/${menu_id}/${table_number}`,data)
          .then(res => resolve(res.data))
          .catch(err => reject(err))
  })
}

async function getAllSubOrders(menu_id, table_number, code) {
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${menu_id}/getSuborders/${table_number}`, {
      discount_code: code,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function mapOrdertype(menu_id, data) {
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${menu_id}/mapOrdertype`, data)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}
async function orderEstimation(menu_id,data) {
    return new Promise((resolve, reject) => {
      POST(`ui/v1/orderEstimate/${menu_id}`, data, null, ['INVALID_ITEM_FULFILLMENT_MODE', 'INVALID_FULFILLMENT_MODE', 'LOCATION_NOT_SERVICEABLE', 'ADDON_NOT_FOUND', 'VARIANT_NOT_FOUND', 'INVALID_VARIANTS', 'ITEM_NOT_FOUND', 'COMBO_NOT_FOUND', 'ITEM_NOT_AVAILABLE', 'INVALID_ADDONS'])
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    })
}

async function getUpiLink(order_id,data) {
  return new Promise((resolve, reject) => {
      POST(`ui/v1/${order_id}/getUpiLink`, data)
          .then(res => resolve(res.data))
          .catch(err => reject(err))
  })
}

async function checkUpiPayment(order_id) {
  return new Promise((resolve, reject) => {
      GET(`ui/v1/${order_id}/checkUpiPayment`, null, ['ALL'])
          .then(res => resolve(res.data))
          .catch(err => reject(err))
  })
}

const fetchPreferredPaymentMethods = () => {
  return new Promise((resolve, reject) => {
      GET(`ui/v1/getSavedPaymentMethods`,  null, ['ALL'])
          .then(response => resolve(response.data))
          .catch(error => reject(error));
  })
}

const getMetaData = (productId) => {
  return new Promise((resolve, reject) => {
    GET(`ui/v1/${productId}/getMetaData`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export {
  addNewAddress,
  getMenuDetails,
  getAddressList,
  getOutletDetails,
  newOrderRequest,
  newOrderRequestCustom,
  orderByCash,
  payOnline,
  updateOnlinePaymentStatus,
  getRestaurantDetails,
  reserveTable,
  generateOTP,
  validateOTP,
  getQuoteData,
  cancelOrderByUser,
  generateGipLink,
  getGipUser,
  markNotificationAsRead,
  listActiveOffers,
  checkOfferAvailableStatus,
  checkTableStatus,
  blockTable,
  createSubOrder,
  closeDineInOrder,
  closeDineInOrderCustom,
  getAllSubOrders,
  mapOrdertype,
  joinTable,
  orderEstimation,
  getMetaData,
  paymentSuccess,
  getUpiLink,
  checkUpiPayment,
  fetchPreferredPaymentMethods,
  getMenuId
};
