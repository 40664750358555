import React, { useState, useRef, useEffect } from 'react'
import { get } from '../../utils/common';
import RadioButton from '../RadioButton';
import Checkbox from '../Checkbox';
import { BasePriceWrapper, SectionHeading, SubSection } from '../../Components//Product/ComboCard/styles';
import { AddButton, CloseButton, CountValue, ProductCount, ProductIncreamentButton, ProductTitle, VariantGroupContainer, VariantGroupFooter, VariantGroupHeader, VariantSection } from './styles';
import { AddItemButton } from '../Product/ItemCard';
import { useRestaurantsInfoContext } from '../../contexts/restaurantInfo';
import i18n from '../../translations/i18n'


const AddonsConstants = Object.freeze({
    addonsAtleastUptoText: (minimum, maximum, selected) => i18n.t('addonsAtleastUptoText', { minimum, maximum, selected, s: minimum > 1 ? 's' : '' }),
    addonsAtleastText: (minimum, selected) => i18n.t('addonsAtleastText', { minimum, selected, s: minimum > 1 ? 's' : '' }),
    addonsUptoText: (maximum, selected) => i18n.t('addonsUptoText', { maximum, selected, s: maximum > 1 ? 's' : '' }),
    addonsExactText: (qty, selected) => i18n.t('addonsExactText', { qty, selected, s: qty > 1 ? 's' : '' }),
})


export const focusOnField = id => {
    const field = document.getElementById(id)
    if (field) {
        field.scrollIntoView({ behavior: 'instant', block: 'center' })
        field.focus()
    }
}

const VBasedAddons = ({ group, restaurantColor, restaurantCurrency, AddonLabels, onChange, onSelectedVariantChange, error, index, customizedProduct }) => {
    const [vBasedAddons, setVBasedAddons] = useState(group.variants[0]);
    return (
        <>
            {get(group, "variants", []).map((variant, i) => <div key={variant.ref_id}>
                <RadioButton
                    id={`${get(variant, 'ref_id')}-${group.ref_id}-variant-section-${i}`}
                    key={`${get(variant, 'ref_id')}-${group.ref_id}-variant-section-${i}`}
                    name={`variants-selection-section-${group.ref_id}`}
                    label={get(variant, 'title')}
                    variant="itemSelection"
                    additionalLabel={get(variant, 'in_stock') ? <>{restaurantCurrency}{variant.price}</> : i18n.t('OUT_OF_STOCK_MESSAGE')}
                    value={get(variant, 'ref_id')}
                    checked={get(variant, 'ref_id') === get(customizedProduct, `variant_groups[${index}].selectedVariant.ref_id`)}
                    onChange={(event) => onSelectedVariantChange(event, index, setVBasedAddons, vBasedAddons)}
                    disabled={!get(variant, 'in_stock')}
                />
            </div>)}
            {get(vBasedAddons, "add_on_groups", []).map((group, i) => get(group, 'addons.length') > 0 && <div key={i} id='v-checkboxvariant'>
                <SectionHeading id={`${group.ref_id}-vbasedaddon-group-heading`} restaurantColor={restaurantColor} withSubtext subheading message={error[group.ref_id]}>
                    {group.title}
                    
                    <span id={`${group.ref_id}-vbasedaddon-group-info`}>{AddonLabels(group, get(customizedProduct, `variant_groups[${index}].selectedVAdon.length`, 0))}</span>
                </SectionHeading>
                {group.addons.map((addon, addonIndex) => <div key={addon.ref_id}>
                    <Checkbox
                        id={`${get(addon, 'ref_id')}-${group.ref_id}-v-basedaddon-section-${addonIndex}`}
                        key={`${get(addon, 'ref_id')}--${group.ref_id}-v-basedaddon-addon-section-${addonIndex}`}
                        label={get(addon, 'title')}
                        additionalLabel={get(addon, 'in_stock') ? <>{restaurantCurrency}{addon.price}</> : i18n.t('OUT_OF_STOCK_MESSAGE')}
                        value={get(addon, 'ref_id')}
                        checked={customizedProduct.variant_groups[index].selectedVAdon.filter(a => a.ref_id === addon.ref_id)[0] !== undefined}
                        disabled={!get(addon, 'in_stock')}
                        onChange={(event) => onChange(event, index, vBasedAddons, addon, i)} />
                </div>)}
            </div>)}

        </>
    )
}

export default function VariantGroups({ product, addCartVariant, plyusVarintAddCart, productAllPrice, restaurantColor, restaurantCurrency, roundOffToTwoPlaces }) {
    const { isLanguageArab } = useRestaurantsInfoContext();
    const addPopupRef = useRef(null);
    const [error, setError] = useState({});
    const [shakeText, setShakeText] = useState({
        shake: false,
        colored: false
    });
    useEffect(() => {
        if (get(shakeText, 'shake')) {
            setTimeout(() => {
                setShakeText({
                    ...shakeText,
                    shake: false
                })
            }, 200)
        }
    }, [shakeText]);
    const shakeQtyText = (id) => {
        if (id) {
            setShakeText({
                shake: true,
                colored: true,
                id
            })
        } else {
            setShakeText({
                shake: false,
                colored: false,
                id: ''
            })
        }
    }
    const [customizedProduct, setCustomizedProduct] = useState(() => {
        if (product.variant_groups && product.add_on_groups) {
            return {
                ...product,
                variant_groups: product.variant_groups.map(variantGroup => ({ ...variantGroup, selectedVariant: variantGroup.variants.reduce((prev, curr) => (prev.price < curr.price && prev.in_stock) ? prev : curr.in_stock && curr), variants: undefined, selectedVAdon: [] })),
                add_on_groups: product.add_on_groups.map(addOnGroup => ({ ...addOnGroup, selectedAddOn: [] })),
                cartcount: 1
            }
        }
        if (product.variant_groups) {
            return {
                ...product, variant_groups: product.variant_groups.map(variantGroup => ({ ...variantGroup, selectedVariant: variantGroup.variants.reduce((prev, curr) => (prev.price < curr.price && prev.in_stock) ? prev : curr.in_stock && curr), variants: undefined, selectedVAdon: [] })),
                cartcount: 1

            }
        }
        if (product.add_on_groups) {
            return {
                ...product, add_on_groups: product.add_on_groups.map(addOnGroup => ({ ...addOnGroup, selectedAddOn: [] })),
                cartcount: 1

            }
        }
    })

    useEffect(() => {
        addPopupRef.current.style.bottom = 0;
    }, []);

    const onSelectedVariantChange = (event, variantGroupIndex, setVBasedAddons, vBasedAddons) => {
        const newVariant = product.variant_groups[variantGroupIndex].variants.find(variant => variant.ref_id === event.target.value);
        setVBasedAddons(newVariant);
        setCustomizedProduct(customizedProduct => ({
            ...customizedProduct,
            variant_groups: customizedProduct.variant_groups.map((variantGroup, index) => {
                if (index === variantGroupIndex) {
                    return { ...variantGroup, selectedVariant: newVariant, selectedVAdon: [] }
                } else {
                    return variantGroup
                }

            }),
        }))
    }

    function onChange(event, addOnGroupIndex, vBasedAddons, addon, vbasedIndex) {
        if (vBasedAddons) {
            const newAddOn = addon;
            newAddOn.group_title = vBasedAddons.add_on_groups[vbasedIndex].title;
            setCustomizedProduct(customizedProduct => ({
                ...customizedProduct,
                variant_groups: customizedProduct.variant_groups.map((variantGroup, index) => {
                    if (index === addOnGroupIndex) {
                        if (get(variantGroup, "selectedVAdon", []).find(a => a.ref_id === newAddOn.ref_id)) {
                            let clonerror = { ...error }
                            delete clonerror[vBasedAddons.add_on_groups[vbasedIndex].ref_id];
                            setError(clonerror);
                            if (Object.keys(clonerror).length > 0) focusOnField(`vbasedaddon-${vBasedAddons.add_on_groups[vbasedIndex].ref_id}`)
                            return { ...variantGroup, selectedVAdon: variantGroup.selectedVAdon.filter(a => a.ref_id !== newAddOn.ref_id) }
                        } else {
                            if (get(vBasedAddons, `add_on_groups[${vbasedIndex}].maximum_allowed`, -1) > 0 && get(variantGroup, "selectedVAdon", []).filter(a => a.group_title === newAddOn.group_title).length >= get(vBasedAddons, `add_on_groups[${vbasedIndex}].maximum_allowed`, -1)) {
                                let clonerror = { ...error }
                                clonerror[vBasedAddons.add_on_groups[vbasedIndex].ref_id] = `You can choose up to ${variantGroup[index].maximum_allowed} options`
                                setError(clonerror);
                                if (Object.keys(clonerror).length > 0) focusOnField(`vbasedaddon-${vBasedAddons.add_on_groups[vbasedIndex].ref_id}`)
                                return variantGroup
                            }
                            else {
                                let clonerror = { ...error }
                                delete clonerror[vBasedAddons.add_on_groups[vbasedIndex].ref_id]
                                setError(clonerror);
                                if (Object.keys(clonerror).length > 0) focusOnField(`vbasedaddon-${vBasedAddons.add_on_groups[vbasedIndex].ref_id}`)
                                if (variantGroup.selectedVAdon) {
                                    return { ...variantGroup, selectedVAdon: [...variantGroup?.selectedVAdon, newAddOn] }
                                }
                            }
                        }
                    } else {
                        return variantGroup
                    }
                })
            }))
        } else {
            const newAddOn = product.add_on_groups[addOnGroupIndex].addons.find(addon => addon.ref_id === event.target.value);

            setCustomizedProduct(customizedProduct => ({
                ...customizedProduct,
                add_on_groups: customizedProduct.add_on_groups.map((addOnGroup, index) => {
                    if (index === addOnGroupIndex) {
                        if (addOnGroup.selectedAddOn.find(a => a.ref_id === newAddOn.ref_id)) {
                            let clonerror = { ...error }
                            delete clonerror[addOnGroup.ref_id];
                            if (Object.keys(clonerror).length > 0) focusOnField(`addon-${addOnGroup.ref_id}`)
                            setError(clonerror);
                            return { ...addOnGroup, selectedAddOn: addOnGroup.selectedAddOn.filter(a => a.ref_id !== newAddOn.ref_id) }
                        }
                        else {
                            if (addOnGroup.maximum_allowed > 0 && addOnGroup.selectedAddOn.length >= addOnGroup.maximum_allowed) {
                                let clonerror = { ...error }
                                clonerror[addOnGroup.ref_id] = `You can choose up to ${addOnGroup.maximum_allowed} options`
                                if (Object.keys(clonerror).length > 0) focusOnField(`addon-${addOnGroup.ref_id}`)
                                setError(clonerror)
                                return addOnGroup
                            }
                            else {
                                let clonerror = { ...error }
                                delete clonerror[addOnGroup.ref_id];
                                if (Object.keys(clonerror).length > 0) focusOnField(`addon-${addOnGroup.ref_id}`)
                                setError(clonerror)
                                return { ...addOnGroup, selectedAddOn: [...addOnGroup.selectedAddOn, newAddOn] }
                            }
                        }
                    } else {
                        return addOnGroup
                    }
                }),
            }))
        }

    }

    function errors() {
        let bol = true

        const b = (customizedProduct.variant_groups || []).forEach(variantGroup => {
            get(variantGroup, "selectedVariant.add_on_groups", []).forEach(addonGroup => {
                if (addonGroup.minimum_needed > variantGroup.selectedVAdon.filter(a => a.group_title === addonGroup.title).length) {
                    let clonerror = { ...error };
                    clonerror[addonGroup.ref_id] = `You need to choose minimum ${addonGroup.minimum_needed}`
                    setError(clonerror)
                    bol = false
                }
            })
        }
        )


        let a = (customizedProduct.add_on_groups || []).forEach(group => {
            if (group.minimum_needed > group.selectedAddOn.length) {
                let clonerror = { ...error }
                clonerror[group.ref_id] = `You need to choose minimum ${group.minimum_needed}`
                setError(clonerror)
                bol = false
            }
        })
        if (bol) {
            plyusVarintAddCart(customizedProduct, 'variantgroup')
        }

    }

    const handleAddItemClick = (action) => {
        if (action === '+') {
            setCustomizedProduct(customizedProduct => ({ ...customizedProduct, cartcount: customizedProduct.cartcount + 1 }))
        } else if (action === '-' && customizedProduct.cartcount > 1) {
            setCustomizedProduct(customizedProduct => ({ ...customizedProduct, cartcount: customizedProduct.cartcount - 1 }))
        }
    }

    function AddonLabels(group, addonSelectedQty) {
        let msg;
        if (group.minimum_needed !== 0 && group.maximum_allowed !== -1) {
            msg = AddonsConstants.addonsAtleastUptoText(group.minimum_needed, group.maximum_allowed, addonSelectedQty)
        } else if (group.minimum_needed !== 0 && group.maximum_allowed === -1) {
            msg = AddonsConstants.addonsAtleastText(group.minimum_needed, addonSelectedQty)
        } else if (group.maximum_allowed !== -1 && group.minimum_needed === 0) {
            msg = AddonsConstants.addonsUptoText(group.maximum_allowed, addonSelectedQty)
        } else {
            msg = ""
        }

        return msg;
    }

    return (
        <VariantGroupContainer id="customize-item-modal">
            <VariantGroupContainer.Div id="customize-item-wrapper" ref={addPopupRef} isLanguageArab={isLanguageArab}>
                <VariantGroupHeader>
                    <ProductTitle id='customize-item-heading'>{product.title}</ProductTitle>
                    <div>
                        <CloseButton isLanguageArab={isLanguageArab} id='close-customize-item' onClick={addCartVariant}>X</CloseButton>
                    </div>
                </VariantGroupHeader>

                <VariantSection>
                    <BasePriceWrapper borderBottom>
                        <span>{i18n.t('BASE_PRICE')}</span>
                        <span id='customized-item-base-price'>{product.discounted_price ? <div>{restaurantCurrency}{product.discounted_price}</div> : <div>{restaurantCurrency}{product.price}</div>}</span>
                    </BasePriceWrapper>
                    {product.variant_groups ? product.variant_groups.map((group, index) => <div key={group.ref_id}>
                        <SubSection groupSection firstChild>
                            <SectionHeading id={`${group.ref_id}-variant-group-heading`} restaurantColor={restaurantColor} subheading>
                                {group.title}
                            </SectionHeading>
                        </SubSection>
                        <VBasedAddons group={group}
                            restaurantColor={restaurantColor}
                            restaurantCurrency={restaurantCurrency}
                            AddonLabels={AddonLabels}
                            onChange={onChange}
                            onSelectedVariantChange={onSelectedVariantChange}
                            error={error}
                            index={index}
                            customizedProduct={customizedProduct}
                        />
                    </div>) : ''}
                    {product.add_on_groups ? product.add_on_groups.map((group, index) => get(group, 'addons.length') > 0 && <div key={index}>
                        <SectionHeading id={`${group.ref_id}-addon-group-heading`} restaurantColor={restaurantColor} withSubtext subheading message={error[group.ref_id]}>
                            {group.title}
                            <span id={`${group.ref_id}-addon-group-info`}>{AddonLabels(group, customizedProduct.add_on_groups[index].selectedAddOn.length)}</span>
                        </SectionHeading>
                        {group.addons.map((addon, adnIndex) => <div key={addon.ref_id}>
                            <Checkbox
                                id={`${get(addon, 'ref_id')}-${group.ref_id}-addon-section-${adnIndex}`}
                                key={`${get(addon, 'ref_id')}-${group.ref_id}-addon-section-${adnIndex}`}
                                label={get(addon, 'title')}
                                additionalLabel={get(addon, 'in_stock') ? <>{restaurantCurrency}{get(addon, 'price')}</> : i18n.t('OUT_OF_STOCK_MESSAGE')}
                                value={get(addon, 'ref_id')}
                                checked={customizedProduct.add_on_groups[index].selectedAddOn.filter(a => a.ref_id === addon.ref_id)[0] !== undefined} disabled={!get(addon, 'in_stock')}
                                onChange={(event) => onChange(event, index)} />

                        </div>)}
                    </div>) : ''}

                </VariantSection>

                <VariantGroupFooter>
                    <AddItemButton
                        itemQuantity={customizedProduct.cartcount}
                        handleAddItemClick={(action) => handleAddItemClick(action)}
                        title={`${customizedProduct.title}-customized`}
                        popupButtons
                        invertColors
                    />
                    <AddItemButton
                        handleAddItemClick={() => errors()}
                        buttonLabel={<>{i18n.t('ADD_BUTTON')}&nbsp;-&nbsp;{restaurantCurrency}{roundOffToTwoPlaces(productAllPrice(customizedProduct))}</>}
                        buttonSize='lg'
                        title={`${customizedProduct.title}-customized`}
                        popupButtons
                        invertColors
                    />
                </VariantGroupFooter>
            </VariantGroupContainer.Div>
        </VariantGroupContainer>
    )
}
