import React, { useEffect, useState, useRef } from "react";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { useTranslation } from 'react-i18next'

import { generateOTP, validateOTP } from "../../utils/API";
import { countryFlags, defaultWaitingTimeForResendOTP, reCAPTCHA_site_key } from "../../constants/variables";
import { Notification } from "../../helpers/notification";

import { useRestaurantsInfoContext } from '../../contexts/restaurantInfo'
import { useProductId } from "../../hooks";
import { clevertapEventPush, CT_EVENT_IDS } from "../../helpers/clevertapUtil";
import { get, range } from '../../utils/common';

import TextButton from "../TextButton";
import { WhatsappIcon } from "../Header/Icons";
import TextInput from "../TextInput";
import CustomModal from "../CustomModal/CustomModal";
import GradientButton from '../GradientButton'

import {
  OTPElementWrapper,
  OTPVerificationForm,
  InfoMessage,
  OTPInputWrapper,
  OTPDigitInput,
  InputError
} from "./OTP-Verification.styles";

const OTPInput = ({
  onChange,
  isError,
  setOTPError
}) => {

  const { restaurantColor, isLanguageArab } = useRestaurantsInfoContext()

  const [otpDigits, setOtpDigits] = useState(['', '', '', '', '', ''])

  useEffect(() => {
    onChange(otpDigits.join())
  }, [otpDigits])

  const handleOTPChange = (e, elIndex) => {
    let enteredVal = get(e, 'target.value', '')
    const getDifferentDigit = (originalDigit, enteredDigit) => {
      if(enteredDigit.length > 1) {
        if(enteredDigit[0] !== originalDigit) {
          return enteredDigit[0]
        } else {
          return enteredDigit[1]
        }
      } else {
        return enteredDigit
      }
    }
    const valueToBeTested = isLanguageArab ? enteredVal[1] === enteredVal[0] ? enteredVal[0] : getDifferentDigit(otpDigits[elIndex], enteredVal) : enteredVal[0]
    enteredVal = /^[0-9]{1}$/.test(valueToBeTested) ? valueToBeTested : ''
    const currentOTP = [...otpDigits]
    const currentOTPLength = currentOTP.join('').trim().length
    if(isLanguageArab) {
      if(enteredVal) {
        currentOTPLength && range(currentOTPLength, 0).forEach((index) => {
          currentOTP[index] = currentOTP[index-1]
        })
        currentOTP[0] = enteredVal
      } else {
        currentOTPLength && range(0, currentOTPLength).forEach((index) => {
          currentOTP[index] = currentOTP[index+1]
        })
        currentOTP[currentOTPLength] = ''
      }
    } else {
      currentOTP[elIndex] = enteredVal
    }
    enteredVal ? currentOTPLength < 6 && setOtpDigits(currentOTP) : currentOTPLength <= 6 && setOtpDigits(currentOTP)
    !!isError && setOTPError('')
    if(enteredVal) {
      elIndex !== 5 && document.getElementById(`otp-digit-${elIndex+1}`).focus({preventScroll: true})
    }
  }

  const handleKeyDown = (e, elIndex) => {
    if (e.key === 'Backspace' && !otpDigits[elIndex]) {
      elIndex !== 0 && document.getElementById(`otp-digit-${elIndex-1}`).focus({preventScroll: true})
    }
  }

  return <OTPInputWrapper>
    {range(0, 6).map(elIndex => {
      return <OTPDigitInput
        id={`otp-digit-${elIndex}`}
        key={`otp-digit-${elIndex}`}
        value={otpDigits[elIndex]}
        onChange={(e) => handleOTPChange(e, elIndex)}
        onBlur={() => !!isError && setOTPError('')}
        onKeyDown={e => handleKeyDown(e, elIndex)}
        restaurantColor={restaurantColor}
        type='number'
        isError={isError}
      />
    })}
  </OTPInputWrapper>

}

const ResendOTP = React.memo(({
  getOTP,
  handleSetOTP,
  currentUserAction,
  phoneNumber,
  changeNumber,
  disableChangeNumber,
  handleCloseIcon,
  handleOtpSubmitActions,
  isValidPhoneNumber,
  loading,
  verficationLoading,
  OTPError,
  setOTPError
}) => {

  const [timer, setTimer] = useState(defaultWaitingTimeForResendOTP);
  const [enableResendOTP, setResendOTP] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      timer > 1 ? setTimer(timer - 1) : setResendOTP(true);
    }, 1000);
  }, [timer]);

  useEffect(() => {
    document.getElementById('otp-digit-0').focus({preventScroll: true})
    return () => {
      handleSetOTP({
        target: {
          value: ''
        }
      })
    }
  }, [])

  const handleResendOtp = () => {
    currentUserAction.current = CT_EVENT_IDS.OTP_RESENT
    getOTP({ setTimer, setResendOTP })
  }

  const { t } = useTranslation();

  return <CustomModal Header={t('ALMOST_THERE')} handleCloseIcon={handleCloseIcon}>
    <OTPVerificationForm onSubmit={handleOtpSubmitActions}>
      <OTPElementWrapper>
        <span>{t('OTP_SENT_TO')}<strong>+{phoneNumber}</strong>.</span>
      </OTPElementWrapper>
      <OTPElementWrapper minimalTopGap>
        <TextButton type='button' onClick={changeNumber} disabled={disableChangeNumber}>{t('CHANGE_NUMBER')}</TextButton>
      </OTPElementWrapper>

      <OTPElementWrapper column>
        <OTPInput isError={!!OTPError} setOTPError={setOTPError} onChange={(value) => handleSetOTP({target: {value}})} />
        {!!OTPError && <InputError adjustForOTP>{OTPError}</InputError>}
      </OTPElementWrapper>

      <OTPElementWrapper>
        <span id='resend-otp-section'>{t('DIDNT_RECEIVE_OTP')}
          <TextButton
            id='resend-otp-btn'
            disabled={!enableResendOTP || disableChangeNumber}
            onClick={handleResendOtp}
            type='button'
          >
            {disableChangeNumber ? `${t('RESENDING')}` : enableResendOTP ? t('RESEND_NOW') : t('RESEND_IN', { timer })}
          </TextButton>
        </span>
      </OTPElementWrapper>

      <OTPElementWrapper><InfoMessage id='otp-info-message'>{t('RECEIVE_ORDER_NOTIF')}<WhatsappIcon /></InfoMessage></OTPElementWrapper>

      <OTPElementWrapper bottomGap>
        <GradientButton
          type='submit'
          disabled={(!isValidPhoneNumber && !phoneNumber.length) || loading}
          loading={(!isValidPhoneNumber && !phoneNumber.length) || loading}
        >
          {verficationLoading ? t('VERFIFYING') : t('VERIFY')}
        </GradientButton>
      </OTPElementWrapper>

    </OTPVerificationForm>
  </CustomModal>
})

export default function OTPVerification({ notificationListener, successCallback, showOTPVerification }) {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [number, setNumber] = useState('');
  const [name, setName] = useState('');
  const [isValidPhoneNumber, setISValidPhoneNumber] = useState(false);
  const [loading, setLoading] = useState(false);
  const [verifyOTP, setVerifyOTP] = useState(false);
  const [isInvalidOTP, setIsInvalidOTP] = useState(false);
  const [OTP, setOTP] = useState('');
  const [OTPError, setOTPError] = useState('')
  const [preferedCountry, setPreferedCountry] = useState('');
  const { userPhoneNo, gipUserCookie } = useRestaurantsInfoContext()
  const productId = useProductId();
  const currentUserAction = useRef(CT_EVENT_IDS.SIGN_UP)
  const countryCode = useRef('')
  const [verficationLoading, setVerificationLoading] = useState(false)
  const [nameError, setNameError] = useState('')
  const [numberError, setNumberError] = useState('')
  const { recaptchaScriptAdded, setRecaptchaScriptAdded, isLanguageArab } = useRestaurantsInfoContext();
  const { t } = useTranslation();

  useEffect(() => {
    if (productId) {
      let prefix = productId.split('_')[0];
      setPreferedCountry(countryFlags[prefix] ? countryFlags[prefix] : "in");
    }
  }, [])

  useEffect(() => {
    const phoneField = document.getElementById('user-number')
    const removeNumberError = () => {
      setNumberError('')
    }
    if(phoneField && preferedCountry) {
      phoneField.addEventListener('blur', removeNumberError)
      return () => {
        phoneField.removeEventListener('blur', removeNumberError)
      }
    }
  }, [preferedCountry])

  useEffect(() => {
    if (!recaptchaScriptAdded) {
      const loadScript = src => {
        const tag = document.createElement('script');
        tag.async = true;
        tag.src = src;
        tag.onload = () => {
          setRecaptchaScriptAdded(true)
        }
        const head = document.getElementsByTagName('head')[0];
        head.appendChild(tag);
      }
      loadScript('https://www.google.com/recaptcha/api.js?render=6LfJUWQaAAAAALMPNWVsJ864vEIP42mlZyieSmNj');
    }
  }, [])

  function updatePhoneNumber(isValid, phoneNumber, metaData) {
    if (!(/^[0-9]*$/).test(phoneNumber)) {
      isValid = false;
    }
    setNumber(phoneNumber)
    setPhoneNumber(metaData.dialCode + phoneNumber);
    countryCode.current = metaData.dialCode
    setISValidPhoneNumber(isValid);
    setNumberError('')
  }

  const validatePhoneNumber = () => {
    if (countryCode.current === '91') {
      return get(number, 'length') === 10
    } else if (countryCode.current === '974') {
      return get(number, 'length') === 8
    } else {
      return true
    }
  }

  function getOTP({ setTimer, setResendOTP }) {
    let displayMessage = ''
    let errorField = 'number'
    var letters = /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/;
    if (!name) {
      displayMessage = t('NAME_NOT_EMPTY', { key: "Name" });
      errorField = 'name'
    } else if (!name.match(letters)) {
      displayMessage = t('NAME_ONLY_CHAR');
      errorField = 'name'
    } else if (!isValidPhoneNumber && number) {
      displayMessage = t('INVALID_NUMBER');
    } else if (!number) {
      displayMessage = t('NAME_NOT_EMPTY', { key: "Number" });
    } else if (!validatePhoneNumber()) {
      displayMessage = t('INVALID_NUMBER');
    }
    if (displayMessage) {
      Notification({ displayMessage: displayMessage, dontShowToast: true });
      if (errorField === 'name') {
        setNameError(displayMessage)
        document.getElementById('user-name').focus({preventScroll: true})
      } else {
        setNumberError(displayMessage)
        document.getElementById('user-number').focus({preventScroll: true})
      }
      return;
    } else {
      setNameError('')
      setNumberError('')
    }
    clevertapEventPush({
      eventId: currentUserAction.current,
      eventData: {
        'Name entered': name,
        'Number entered': number,
        'Country code': countryCode.current
      }
    })
    setLoading(true);
    window.grecaptcha.ready(function () {
      window.grecaptcha
        .execute(reCAPTCHA_site_key, { action: "submit" })
        .then((recaptchaToken) =>
          generateOTP({ phoneNumber, recaptchaToken, menu_id: productId })
        )
        .then(() => {
          setVerifyOTP(true);
          setLoading(false);
          if (setTimer) {
            setTimer(defaultWaitingTimeForResendOTP);
            setResendOTP(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    });
  }

  function handleSetOTP(event) {
    const value = event.target.value.replace(/\D/g, "");
    setOTP(isLanguageArab ? value.split('').reverse().join('') : value);
  }

  const clearValue = () => {
    setName('');
    setNumber('');
    setPhoneNumber('');
    setVerifyOTP(false);
    setISValidPhoneNumber(false);
    currentUserAction.current = CT_EVENT_IDS.NUMBER_CHANGED
  }

  function validateUserOTP() {
    let message = ""
    currentUserAction.current = CT_EVENT_IDS.OTP_VERIFICATION
    clevertapEventPush({
      eventId: currentUserAction.current,
      eventData: {
        'Name entered': name,
        'Number entered': number,
        'Country code': countryCode.current
      }
    })
    if (OTP === "") {
      message = t('PLEASE_ENTER_OTP')
    }
    else if (OTP.length < 6) {
      message = t('INVALID_OTP_TRY_AGAIN')
    }
    if (message) {
      Notification({ displayMessage: message, dontShowToast: true });
      setOTPError(message)
      let otpDigitEmptyIndex = OTP.trim().length
      otpDigitEmptyIndex = otpDigitEmptyIndex > 5 ? 5 : otpDigitEmptyIndex
      document.getElementById(`otp-digit-${otpDigitEmptyIndex}`).focus()
      return;
    }
    setVerificationLoading(true)
    let data = { otp: OTP, menu_id: productId, name: name, isOptin: true }
    validateOTP({ phoneNumber, data })
      .then((res) => {
        document.cookie = res;
        userPhoneNo.current = phoneNumber
        clevertapEventPush({
          eventId: CT_EVENT_IDS.PROFILE_PUSH,
          eventData: {
            name,
            phone: phoneNumber
          }
        })
        if (notificationListener) { notificationListener() };
        setVerificationLoading(false)
        gipUserCookie.current = true
        successCallback();
      })
      .catch(e => {
        setVerificationLoading(false)
        setIsInvalidOTP(true);
      })

  }

  function handleCloseIcon() {
    showOTPVerification(false);
  }

  const handleOtpGenerateActions = (e) => {
    e && e.preventDefault();
    getOTP({})
  }

  const handleOtpSubmitActions = (e) => {
    e && e.preventDefault();
    validateUserOTP()
  }

  const handleCountryCodeChange = args => {
    const dialCode = get(args, '1.dialCode')
    countryCode.current = dialCode
    setPhoneNumber(dialCode + number)
    setISValidPhoneNumber(true)
  }
  
  const handleName = (e) => {
    setName(e.target.value)
    setNameError('')
  }

  const createOTPComponent = () => <CustomModal Header={t('SEND_OTP_HEADING')} handleCloseIcon={handleCloseIcon}>
    <OTPVerificationForm onSubmit={handleOtpGenerateActions}>
      <OTPElementWrapper column>
        <TextInput
          id='user-name'
          placeholder={t('NAME_PLACEHOLDER')}
          value={name}
          onChange={handleName}
          onBlur={() => setNameError('')}
          isError={!!nameError}
          errorMessage={nameError}
          borderColor={'#CDCDCD'}
        />
      </OTPElementWrapper>
      {preferedCountry && <OTPElementWrapper error={!!numberError} column adjustNumberInput>
        <IntlTelInput
          containerClassName={`intl-tel-input ${isLanguageArab && "intlTelInput"}`}
          inputClassName={isLanguageArab && 'intlTelInput'}
          fieldId='user-number'
          preferredCountries={[preferedCountry]}
          isInvalid={isValidPhoneNumber}
          placeholder={t('placeHolder.NUMBER')}
          onPhoneNumberChange={(isValid, phoneNumber, metaData) => updatePhoneNumber(isValid, phoneNumber, metaData)}
          onSelectFlag={(...args) => handleCountryCodeChange(args)}
        />
        {!!numberError && <InputError>{numberError}</InputError>}
      </OTPElementWrapper>}
      <OTPElementWrapper smallFont>
        <InfoMessage id="otp-info-message">
          {t('OTP_SENT_TO_DEVICE')}
        </InfoMessage>
      </OTPElementWrapper>
      <OTPElementWrapper bottomGap>
        <GradientButton
          disabled={(!isValidPhoneNumber && !phoneNumber.length && phoneNumber.includes(".")) || !recaptchaScriptAdded || loading}
          loading={(!isValidPhoneNumber && !phoneNumber.length && phoneNumber.includes(".")) || !recaptchaScriptAdded || loading}
          type='submit'
        >
          {loading ? t('SENDING') : t("CONTINUE")}
        </GradientButton>
      </OTPElementWrapper>
    </OTPVerificationForm>
  </CustomModal>

  return verifyOTP ? <ResendOTP
    currentUserAction={currentUserAction}
    phoneNumber={phoneNumber} getOTP={getOTP}
    isInvalidOTP={isInvalidOTP}
    OTP={OTP}
    handleSetOTP={handleSetOTP}
    changeNumber={clearValue}
    disableChangeNumber={loading}
    handleCloseIcon={handleCloseIcon}
    handleOtpSubmitActions={handleOtpSubmitActions}
    isValidPhoneNumber={isValidPhoneNumber}
    loading={loading}
    verficationLoading={verficationLoading}
    OTPError={OTPError}
    setOTPError={setOTPError}
  /> : createOTPComponent();
}