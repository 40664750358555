import React from "react";

import { StyledGradientButton } from "./styles";

import { useRestaurantsInfoContext } from "../../contexts/restaurantInfo";
import { darkenColor } from '../../utils/common';

const GradientButton = props => {

    const { restaurantColor, isRestaurantColorDark } = useRestaurantsInfoContext()

    const { onClick, customThemeColor, buttonTextColor, noGradient, children, type='button' } = props

    const themeColor = customThemeColor || restaurantColor

    const buttonProps = {
        ...props,
        onClick,
        onKeyPress: e => e.code === 'Enter' && onClick(),
        themeColor,
        gradientColor: darkenColor(themeColor),
        isRestaurantColorDark,
        noGradient,
        buttonTextColor,
        type
    }

    return <StyledGradientButton {...buttonProps}>{children}</StyledGradientButton>
}

export default GradientButton

GradientButton.defaultProps = {
  onClick: () => null,
  noGradient: false
};
