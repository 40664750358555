import { get } from '../utils/common';
import { allPageRoutes, MENUID_DOMAIN } from '../constants/variables'
import { getMenuId } from '../utils/API';

export const CT_EVENT_IDS = {
    ESTABLISHMENT_ID: 'Establishment ID',
    CHAIN_ID: 'Chain ID',
    LOGIN_PUSH: 'LOGIN_PUSH',
    PROFILE_PUSH: 'PROFILE_PUSH',
    ORDER_TYPE_SELECTED: 'Order type selected',
    ORDER_TYPE_SWITCHED: 'Order type switched',
    LOCATION_ADDED: 'Location added',
    REQUEST_LOCATION: 'Request location',
    SELECT_DELIVERY_LOCATION: 'Select delivery location',
    IN_APP_NOTIFICATIONS: 'In-App notifications',
    BACK_BUTTONS_CLICKED: 'Back buttons clicked',
    SEARCH_INITIATED: 'Search initiated',
    FILTER_APPLIED: 'Filter applied',
    VISITED_SOCIAL_HANDLES: 'Visited social handles',
    ADDED_TO_CART: 'Added to cart',
    CUSTOMIZATION_SLIDER_OPENED: 'Customization slider opened',
    CUSTOMIZATION_OPENED: 'Customization modal opened',
    MENU_BUTTON_CLICKED: 'Menu button clicked',
    MENU_CATEGORY_SELECTED: 'Menu category selected',
    CHECKOUT_CLICKED: 'Checkout clicked',
    SIGN_UP: 'Sign up',
    NUMBER_CHANGED: 'Number changed',
    OTP_RESENT: 'OTP resent',
    OTP_VERIFICATION: 'OTP verification',
    ADDRESS_FROM_CHECKOUT: 'Address from checkout',
    ADDRESS_ADDED: 'Address added',
    ADDITIONAL_INS_CLICKED: 'Additional instructions click',
    REPITITIONS_MODAL_OPENED: 'Repititions Modal Opened',
    COUPONS_VIEWED: 'Coupons viewed',
    COUPON_APPLIED: 'Coupon applied',
    COUPON_REMOVED: 'Coupon removed',
    ONLINE_PAYMENT_INITIATED: 'Online payment initiated',
    ORDER_PLACED: 'Charged',
    ITEM_MODIFIED: 'Item add button',
    APP_LAUNCHED: 'App launched',
    PAYMENT_METHOD_SELECTED: 'Payment method selected',
}

export const allPages = {
    HOME_PAGE: 'Home Page',
    SET_LOCATION_PAGE: 'Set location page',
    MENU_PAGE: 'Menu page',
    CHECKOUT_PAGE: 'Checkout page',
    ADD_NEW_ADDRESS: 'Add new address page',
    OUTLET_SELECTION_PAGE: 'Outlet selection page',
    TABLE_RESERVATION_PAGE: 'Table reservation page'
}

export const clevertapEventPush = ({eventId = '', eventData = {}}) => {
    const isDevEnv = process.env.REACT_APP_ENVI === 'development'
    const currentUrl = new URL(window.location.href)
    let menuId = currentUrl?.pathname?.split('/')[1]
    menuId = (allPageRoutes.includes(menuId) || menuId === '') ? MENUID_DOMAIN : menuId
    getMenuId(menuId).then(productId => {
        sessionStorage.setItem("customDomainMenuId", productId);
        const cleverTapObj = get(window, 'clevertap')
        const profileObj = {
            "Site": {
                "Name": eventData.name || '',
                "Phone": eventData.phone ? `+${eventData.phone}` : ''
            }
        }
        if (cleverTapObj) {
            if (eventId === CT_EVENT_IDS.LOGIN_PUSH) {
                cleverTapObj.onUserLogin.push(profileObj)
                isDevEnv && console.log('login push', profileObj)
            } else if (eventId === CT_EVENT_IDS.PROFILE_PUSH) {
                cleverTapObj.profile.push(profileObj)
                isDevEnv && console.log('profile push', profileObj)
            } else {
                let chainId = sessionStorage.getItem('chain-origin')
                chainId = chainId.slice(1, chainId.length-1) || 'N/A'
                isDevEnv && console.log(eventId, {
                    [CT_EVENT_IDS.ESTABLISHMENT_ID]: productId,
                    [CT_EVENT_IDS.CHAIN_ID]: chainId,
                    ...eventData
                })
                cleverTapObj.event.push(eventId, {
                    [CT_EVENT_IDS.ESTABLISHMENT_ID]: productId,
                    [CT_EVENT_IDS.CHAIN_ID]: chainId,
                    ...eventData
                })
            }
        }
    })
}