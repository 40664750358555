import { Spinner as Loader } from "react-bootstrap";
import styled, { css } from "styled-components";
import styleVals from "../../constants/styles";


export const StyledCol = styled.div`

@media (max-width: 767px) {
    .col-sm {
        flex:1 0 0%
    }

    .row-cols-sm-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-sm-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-sm-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-sm-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-sm-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-sm-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-sm-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-sm-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-sm-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-sm-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-sm-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-sm-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-sm-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-sm-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-sm-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-sm-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-sm-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-sm-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-sm-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-sm-12 {
        flex: 0 0 auto;
        width: 100%
    }
}
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
`;
export const StyledCard = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
`;
export const StyledCardBody = styled.div`
    flex: 1 1 auto;
    min-height: 1px;
    padding: 0.75rem 0.75rem;
`;

export const CardDescription = styled.p`
    margin-top: 0;
    margin-bottom: 1rem;
    word-wrap: break-word;
`;
export const StyledDropdown = styled.div`
    list-style: none;
    position: absolute;
    top: 110%;
    z-index: 99;
    background: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 0.25rem;
    min-width: 10rem;
    padding: 0.5rem 0;
    ${props => props.isLanguageArab ? css`
        text-align: right;
        padding-right: 4px;
        left: 0;
    ` : css`
        right: 0;
    `}
`;
export const StyledDropdownToggle = styled.div`
     padding: ${styleVals.measurements.spacing0};
    margin: ${styleVals.measurements.spacing0};
    :focus {
        outline: none;
        box-shadow: none;
    }
`;
export const StyledDropdownMenu = styled.div`
       padding-top: ${styleVals.measurements.spacing0};
`;
export const StyledDropdownWrapper = styled.div`
    position: relative;
    > #social-media-button {
        padding: 0;
        padding-right: ${styleVals.measurements.spacing4};
    }
`;
export const StyledCardFooter = styled.div`
    background-color: rgba(0,0,0,.03);
    border-top:  1px solid rgba(0,0,0,.125);
    padding: 0.5rem 1rem;
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
    border-top: 0;
`;

export const StyledInputElement = styled.input`
    color: black;
    border: none;
    width: 100%;
    margin-bottom: ${styleVals.measurements.spacing6};
    padding: ${styleVals.measurements.spacing8} ${styleVals.measurements.spacing12};
    font-size: ${styleVals.measurements.spacing16};
    text-align: ${props => props.alignText};
    &:focus{
      outline: none;
      box-shadow: none;
    }
    border-bottom: 1px solid ${props => props.isError ? '#dc3545' : props.borderColor || '#dfe1e5'};
    ${props => props.showIcon && css`
        padding-right: ${styleVals.measurements.spacing32};
    `};
    ${props => props.enterLocationInput && css`
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right:${styleVals.measurements.spacing70};
    `};
`;
export const StyledContainer = styled.div`
    width: 100%;
    padding-left: ${styleVals.measurements.spacing15};
    padding-right: ${styleVals.measurements.spacing15};
    margin-left: auto;
    margin-right: auto;
    ${props => !props.fluid && css`
        @media (min-width: 576px) {
            max-width: 540px;
        }
        @media (min-width: 768px) {
            max-width: 720px;
        }
        @media (min-width: 992px) {
            max-width: 960px;
        }
        @media (min-width: 1200px) {
            max-width: 1140px;
        }
    `}
`
export const StyledRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-right: -${styleVals.measurements.spacing15};
    margin-left: -${styleVals.measurements.spacing15};
`
export const StyledLoader = styled(Loader)`
`
