import sound from "../assets/audio/sound.mp3";
import {toast} from "react-toastify";
import { clevertapEventPush, CT_EVENT_IDS } from "./clevertapUtil";
import i18n from "../translations/i18n";

const handleClick =(HandleNotificationClick, Clickable) => {
    if(Clickable && HandleNotificationClick ){
        HandleNotificationClick();
    }
}

const toaster = (type, displayMessage, {...toasterOptions}, Clickable,handleNotificationClick) => {
    switch (type) {
        case 'success': {
            toast.success(displayMessage, {onClick: Clickable ? (e)=> handleClick(handleNotificationClick, Clickable): null ,hideProgressBar: true, ...toasterOptions});
            break;
        }
        case 'error': {
            toast.error(displayMessage, {hideProgressBar: true, ...toasterOptions});
            break;
        }
        case 'warn': {
            toast.warn(displayMessage, {hideProgressBar: true, ...toasterOptions});
            break;
        }
        case 'info': {
            toast.info(displayMessage, {hideProgressBar: true, ...toasterOptions});
            break;
        }
        default: {
            toast(displayMessage, {hideProgressBar: true, ...toasterOptions});
            break;
        }
    }
}
export function Notification({ type, displayMessage = i18n.t('YouHaveNotification'), toasterOptions, isAudio, dontShowToast = false }) {
    !dontShowToast && toaster(type, displayMessage, toasterOptions, false);
    displayMessage && clevertapEventPush({
        eventId: CT_EVENT_IDS.IN_APP_NOTIFICATIONS,
        eventData: {
            Errors: displayMessage
        }
    })
    if(isAudio) {
        const audio = new Audio(sound);
        audio.play();
    }
}

export function ClickableNotification({
    type,
    displayMessage,
    toasterOptions,
    handleNotificationClick,
    isAudio,
  }) {
    toaster(type, displayMessage, toasterOptions, true, handleNotificationClick);
    if (isAudio) {
      const audio = new Audio(sound);
      audio.play();
    }
  }