import React, { useEffect, useRef } from 'react';
import {
  ModalContainer,
  ModalHeader,
  CloseIcon,
} from './styles/CustomModal.style';
import { useRestaurantsInfoContext } from '../../contexts/restaurantInfo';

export default function CustomModal({
  children,
  Header,
  handleCloseIcon,
}) {
  const { restaurantColor, isLanguageArab } = useRestaurantsInfoContext();

  const addPopupRef = useRef(null);

  useEffect(() => {
    addPopupRef.current.style.bottom = '50%';
    addPopupRef.current.style.transform = 'translateY(50%)';
  }, []);

  return (
    <ModalContainer>
      <ModalContainer.Div ref={addPopupRef}>
        <CloseIcon id="otp-modal-close-button" onClick={handleCloseIcon} isLanguageArab={isLanguageArab}>x</CloseIcon>
        <ModalHeader color={restaurantColor} id='otp-modal-header'>
          {Header}
        </ModalHeader>
        {children}
      </ModalContainer.Div>
    </ModalContainer>
  );
}
