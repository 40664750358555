import styled, { css } from "styled-components";
import styleVals from '../../constants/styles'

export const FoodType = styled.div`
${props => props.type && css`
position: static;
flex-shrink: 0;
margin-top: 2px;
border: 1px solid
  ${(props) =>
    props.type === 1 ? "#4f9541" : props.type === 2 ? "#A70202" : "#FF6F00"};
margin-right: 10px;
width: 12px;
height: 12px;
display: flex;
align-items: center;
justify-content: center;
top: 10px;
left: 10px;
background: #fff;
${props => props.withMarginBottom && css`
    margin-bottom: 6px;
    margin-top: 0px;
`}
`}
`;

FoodType.foodIcon = styled.div`
${props => props.type && css`
${props => (props.type === 2 && props.isRestaurantIndian) ? css`
    border-left: ${styleVals.measurements.spacing4} solid transparent;
    border-right:${styleVals.measurements.spacing4} solid transparent;
    border-bottom:${styleVals.measurements.spacing4} solid #A70202;
  ` : css`
    background:${props.type === 1 ? "#4f9541" : props.type === 3 ? "#FF6F00" : '#A70202'};
    border-radius:50%;
    width: ${styleVals.measurements.spacing6};
    height: ${styleVals.measurements.spacing6};
  `}
`}
`;
export const PriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  bottom: 20px;
  right: 10px;
  left: 10px;
  padding-top: 5px;
  margin-bottom: 5px;
`;

PriceContainer.Price = styled.div`
  display: block;
  color: #000;
  font-weight: 600;
  font-size: 13px;
`;

PriceContainer.Span = styled.span`
  color: grey;
  margin-right: 5px;
  text-decoration: line-through;
`;

PriceContainer.Div = styled.span`
  color: grey;
  margin-right: 5px;
`;
