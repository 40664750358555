import styled, { css } from "styled-components";
import styleVals from "../../constants/styles"

export const OTPElementWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  ${props => props.column && css`
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
  `}
  padding-top: ${props => props.minimalTopGap ? styleVals.measurements.spacing6 : props.lessTopGap ? styleVals.measurements.spacing10 : styleVals.measurements.spacing20};
  padding-bottom: ${props => props.bottomGap && styleVals.measurements.spacing8};
  color: ${styleVals.colors.primaryText};
  font-size: ${props => props.smallFont ? styleVals.measurements.spacing12 : styleVals.measurements.spacing14};
  #resend-otp-btn {
    padding: 0;
    padding-left: ${styleVals.measurements.spacing4};
  }
  #input-wrapper {
    margin-bottom: 0;
    > p {
      margin-left: 0;
      margin-top: ${styleVals.measurements.spacing2};
    }
  }
  input {
    margin-bottom: 0;
    color: ${styleVals.colors.primaryText};
  }
  ${props => props.adjustNumberInput && css`
    .intl-tel-input {
      width: 100% !important;
    }
    input {
      outline: none;
      border: none;
      border-bottom: 1px solid ${props => props.error ? styleVals.colors.red : styleVals.colors.borderColor};
      width: 100% !important;
      padding-right: 0px;
      font-size: 16px !important;
      text-align: left;
      padding-top: ${styleVals.measurements.spacing8};
      padding-bottom: ${styleVals.measurements.spacing8};
      &:focus {
        box-shadow: none !important;
      }
    }
  `}
`
export const OTPResendButton = styled.div`
  background-color: ${props => props.eResendOTP ? props.resColor : "#a7a5a5" } ;
  position: relative;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-content: center;
  width: 50%;
  font-weight: 400;
  font-size: 0.8rem;
  color: #fff;
  padding: 5px;
  margin: 0;
  > button {
    color: white !important;
    font-size: 0.8rem;
  }
`
export const OTPVerificationForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`
export const InfoMessage = styled.span`
  color: #636f83 !important;
`
export const OTPInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: ${styleVals.measurements.spacing0} ${styleVals.measurements.spacing4};
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
`
export const OTPDigitInput = styled.input`
  height: ${styleVals.measurements.spacing40};
  max-width: ${styleVals.measurements.spacing38};
  text-align: center;
  color: ${styleVals.colors.primaryText};
  font-size: ${styleVals.measurements.spacing20};
  outline: none;
  border: 1px solid ${props => props.isError ? styleVals.colors.red : styleVals.colors.borderColor};
  border-radius: ${styleVals.measurements.spacing6};
  :focus {
    border: 1px solid ${props => props.isError ? styleVals.colors.red : props.restaurantColor};
  }
`
export const InputError = styled.p`
  margin: 0;
  padding: 0;
  color: ${styleVals.colors.red};
  font-size: ${styleVals.measurements.spacing14};
  margin-top: ${styleVals.measurements.spacing2};
  ${props => props.adjustForOTP && css`
    margin-top: ${styleVals.measurements.spacing6};
    margin-left: ${styleVals.measurements.spacing4};
  `}
`