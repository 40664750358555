import React, { useEffect, useState, useRef } from 'react'
import { useRestaurantsInfoContext } from '../../contexts/restaurantInfo'
import {
    CarouselContainer,
    CarouselItem,
    CarouselItemsWrapper,
    ScrollButton
} from './styles'

const Carousel = ({
    items = [],
    scrollButtonId = '',
    itemId = '',
    margin = '',
    hideCarousel = false
}) => {

    const { isLanguageArab } = useRestaurantsInfoContext()
    const [showScrollButton, setShowScrollButton] = useState({
        next: false,
        previous: false
    })
    const activeBannerId = useRef(`${itemId}-1`)

    useEffect(() => {
        const options = {
            root: document.getElementById(`${itemId}-scroll-wrapper`),
            rootMargin: '0px',
            threshold: 0.5
        }
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const idParts = entry.target.id.split('-')
                    activeBannerId.current = Number(idParts[idParts.length - 1])
                }
                setShowScrollButton({
                    next: activeBannerId.current !== items.length,
                    previous: activeBannerId.current !== 1
                })
            })
        }, options)
        let i = 1;
        items.forEach(() => {
            const target = document.getElementById(`${itemId}-${i}`)
            i++;
            observer.observe(target)
        })
        return () => {
            observer.disconnect()
        }
    }, [])

    const showBanner = (previous) => {
        document.getElementById(`${itemId}-scroll-wrapper`)?.scrollTo({
            behavior: 'smooth',
            left: document.getElementById(`${itemId}-${activeBannerId.current + (previous ? -1 : 1)}`).offsetLeft - 22
        })
    }

    return items.length > 0 && <CarouselContainer hideCarousel={hideCarousel} dir={isLanguageArab && 'rtl'} margin={margin}>
        <ScrollButton onClick={() => showBanner()} showButton={showScrollButton.next} isLanguageArab={isLanguageArab} id={scrollButtonId}><span>{'>'}</span></ScrollButton>
        <ScrollButton onClick={() => showBanner(true)} showButton={showScrollButton.previous} isLanguageArab={!isLanguageArab} id={scrollButtonId}><span>{'<'}</span></ScrollButton>
        <CarouselItemsWrapper id={`${itemId}-scroll-wrapper`}>
            {items.map((item, itemKey) => <CarouselItem multipleImages={items.length > 1} id={`${itemId}-${itemKey + 1}`} isLanguageArab={isLanguageArab} firstChild={itemKey === 0} key={itemKey}>{item}</CarouselItem>)}
        </CarouselItemsWrapper>
    </CarouselContainer>
}

export default Carousel