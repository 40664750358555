export const Login = '/';
export const DASHBOARD="/dashboard";

export const HOME = '/:productId/home';
export const PRODUCT="/:productId/menu";
export const TABLERESERVATION="/:productId/tableReservation";
export const VEIWADDRESS="/:productId/viewAddress";
export const CHECKOUT="/:productId/checkout";
export const ORDERDETAILS="/:productId/order";
export const PAYMENT='/:productId/payment'
export const TERM_CONDITIONS = "/:productId/t&c";

export const HOME_D = '/home';
export const PRODUCT_D="/menu";
export const TABLERESERVATION_D="/tableReservation";
export const VEIWADDRESS_D="/viewAddress";
export const CHECKOUT_D="/checkout";
export const ORDERDETAILS_D="/order";
export const PAYMENT_D="/payment";
export const TERM_CONDITIONS_D = "/t&c";

export const ERROR404="/error/404";
export const ERROR500="/error/500";
export const PageRequestAccess="/error/requestaccess";


