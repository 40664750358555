import styled, { css } from "styled-components";
import styleVals from "../../constants/styles";
import { darkenColor } from "../../utils/common";

export const MenuContainer = styled.div`
    position: absolute;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    bottom: ${styleVals.measurements.spacing40};
    z-index: 4;
        text-align:${props => props.isLanguageArab && 'right'}
`;
MenuContainer.Div = styled.div`
    background: linear-gradient(to bottom, #F6F6F6, #E4E3E3);
    border-radius: ${styleVals.measurements.spacing10};
    padding: 10px 0;
    overflow: hidden;
    position: fixed;
    bottom: ${props => props.bottomGap}; 
    > div {
        width: ${styleVals.measurements.spacing250};
        max-height: ${props => props.hasMenu ? styleVals.measurements.spacing250 : styleVals.measurements.spacing0};
        transition: 0.5s all;
        overflow-y: auto;
        padding: ${styleVals.measurements.spacing0} ${styleVals.measurements.spacing5};
        &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: ${styleVals.measurements.spacing6};
        }
        &::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, .3);
            border-radius: ${styleVals.measurements.spacing10};
            border: 1px solid #ffffff;
        }
      }
`;

export const MenuItem = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
  padding: 0.5rem 1rem;
  color:${props => props.noItem ? 'red' : ''};
  ${props => props.isSelected && css`
    font-weight: bold;  
  `}
  > p {
    margin: 0;
    width: 100%;
  }
  > span {
    font-size: 1rem;
    ${props => props.isLanguageArab ? css`margin-right: ${styleVals.measurements.spacing18};` : css`margin-left: ${styleVals.measurements.spacing18};`}
  }
`;

export const MenuCloseButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${styleVals.measurements.spacing100};
    margin: 0 auto;
    color: ${props => props.isRestaurantColorDark ? styleVals.colors.white : styleVals.colors.primaryText};
    font-size:${styleVals.measurements.spacing15};
    font-weight: bold;
    position: fixed;
    bottom: ${props => props.bottomGap};
    left: 0;
    right: 0;
    border-radius: ${styleVals.measurements.spacing17};
    cursor: pointer;
    border: none;
    outline: none;  
    height: ${styleVals.measurements.spacing34};
    background: linear-gradient(to left, ${props => props.restaurantColor}, ${props => darkenColor(props.restaurantColor)}) !important;
    box-shadow: ${styleVals.colors.boxShadow};
`;