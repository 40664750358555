import React from 'react'
import { useRestaurantsInfoContext } from '../../contexts/restaurantInfo'
import {
    CheckboxLabel
} from './styles'

const Checkbox = props => {

    const {id, label, additionalLabel = '', additionalLabelId = '', value, checked, onChange, key, disabled, noBorder = false, bgColor, inline = false, dontShowCheckbox = false, labelPosition = 'left'} = props

    const { restaurantColor, isLanguageArab } = useRestaurantsInfoContext()

    const checkboxProps = {
        labelProps: {
            restaurantColor,
            additionalLabel,
            isDisabled: disabled,
            ...(key && {key}),
            noBorder,
            bgColor
        },
        inputProps: {
            id: props.id,
            type: 'checkbox',
            value,
            checked,
            onChange: disabled ? undefined : onChange,
            disabled
        } 
    }

    return inline ? <CheckboxLabel isLanguageArab={isLanguageArab} htmlFor={props.id} {...checkboxProps.labelProps} inline>
        {labelPosition === 'left' && <p id={`${id}-label`}>{label}</p>}
        {!dontShowCheckbox && <div>
            <input {...checkboxProps.inputProps}/>
            <span aria-hidden></span>
        </div>}
        {labelPosition === 'right' && <p id={`${id}-label`}>{label}</p>}
    </CheckboxLabel> : <CheckboxLabel isLanguageArab={isLanguageArab} htmlFor={props.id} {...checkboxProps.labelProps}>
        <div>
            <div>
                <input {...checkboxProps.inputProps}/>
                <span aria-hidden></span>
            </div>
            <p id={`${id}-label`}>{label}</p>
        </div>
        {additionalLabel && <p id={additionalLabelId}>{additionalLabel}</p>}
    </CheckboxLabel>
}

export default Checkbox