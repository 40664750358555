import React, {useRef, useState, useEffect} from 'react'
import { useRestaurantsInfoContext } from '../../contexts/restaurantInfo';
import {
    SlideUpPopupContainer,
    SlideUpPopupBody,
    SlideUpPopupHeader,
    SlideUpPopupHeading,
    ClosePopupButton,
    SlideUpPopupSection
} from './styles'


const SlideUpPopup = ({
    closePopup,
    showCloseButton,
    heading,
    hasMultipleSections,
    sections = [],
    sectionButtons = [],
    activeSectionIndex,
    children,
    footer
}) => {
    const { isLanguageArab } = useRestaurantsInfoContext();
    const addPopupRef = useRef()

    const [activeIndex, setActiveIndex] = useState(activeSectionIndex)

    useEffect(()=>{
        setActiveIndex(activeSectionIndex)
    }, [activeSectionIndex])

    useEffect(() => {
        addPopupRef.current.style.bottom = 0;
    }, []);

    return <SlideUpPopupContainer id="customize-combo-modal">
        <SlideUpPopupBody id="customize-combo-modal-wrapper" ref={addPopupRef}>
            <SlideUpPopupHeader>
                <SlideUpPopupHeading isLanguageArab={isLanguageArab} >{heading}</SlideUpPopupHeading>
                {showCloseButton && <ClosePopupButton isLanguageArab={isLanguageArab} id="close-customize-combo-modal" onClick={closePopup}><div>X</div></ClosePopupButton>}
            </SlideUpPopupHeader>
                {hasMultipleSections
                    ? sections.map((section, i) => (
                        <SlideUpPopupSection divIndex={i} activeIndex={activeIndex} id={`slide-up-popup-section-${i}`}>
                            {section}
                        </SlideUpPopupSection>))
                    : children}
                {hasMultipleSections
                ? sectionButtons.map((buttons, i) => (
                    <SlideUpPopupSection footerButtons reverse={i === 0} divIndex={i} activeIndex={activeIndex} id={`slide-up-popup-section-buttons-${i}`}>
                        {buttons}
                    </SlideUpPopupSection>))
                : footer}
        </SlideUpPopupBody>
    </SlideUpPopupContainer>
}

SlideUpPopup.defaultProps = {
    showCloseButton: true
}

export default SlideUpPopup