import styled, { css } from "styled-components";
import styleVals from '../../../constants/styles'

export const FoodType = styled.div`
${props => props.type && css`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
border: 1px solid ${props => props.type === 1 ? "#4f9541" : props.type === 2 ? "#A70202" : "#FF6F00"};
width: ${styleVals.measurements.spacing12};
height: ${styleVals.measurements.spacing12};
${props => props.noShrink && css`
    flex-shrink: 0;
`}
${props => props.inlineDiv ? css`
    ${props => props.isLanguageArab ? css`margin-left: ${styleVals.measurements.spacing6}`
        : css`margin-right: ${styleVals.measurements.spacing6}`};
` : css`
    position: absolute;
    left: ${styleVals.measurements.spacing10};
    margin-top: ${styleVals.measurements.spacing4};
`};

`}
`
export const FoodTypeIcon = styled.div`
${props => props.type && css`
${props => (props.type === 2 && props.isRestaurantIndian) ? css`
    border-left: ${styleVals.measurements.spacing4} solid transparent;
    border-right:${styleVals.measurements.spacing4} solid transparent;
    border-bottom:${styleVals.measurements.spacing4} solid #A70202;
  ` : css`
    background:${props.type === 1 ? "#4f9541" : props.type === 3 ? "#FF6F00" : '#A70202'};
    border-radius:50%;
    width: ${styleVals.measurements.spacing6};
    height: ${styleVals.measurements.spacing6};
  `}
`}
`
export const ComboDetailsWrapper = styled.div`
    height: 100%;
    width: 100%;
    overflow: auto;
    padding-bottom: ${styleVals.measurements.spacing250};
    text-align:${props => props.isLanguageArab && 'right'};
`
export const BasePriceWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom : ${props => props.borderBottom && '1px solid #ccc9c9'};
    padding: ${styleVals.measurements.spacing10} ${styleVals.measurements.spacing20};
    @media ${styleVals.breakpoints.smallMobile} {
        padding: ${styleVals.measurements.spacing10} ${styleVals.measurements.spacing10};
    }
    span:first-child {
        display: inline-block;
        padding-right: ${styleVals.measurements.spacing4};
    }
    span:last-child {
        display: inline-block;
    }
`
export const SectionHeading = styled.div`
    color: ${props => props.restaurantColor};
    font-weight: bold;
    padding: ${styleVals.measurements.spacing10} ${styleVals.measurements.spacing20};
    background: ${styleVals.colors.lightGrey};
    ${props => props.subheading && css`
        background: none;
        padding: ${styleVals.measurements.spacing10} ${styleVals.measurements.spacing20} ${styleVals.measurements.spacing0} ${styleVals.measurements.spacing20};
        @media ${styleVals.breakpoints.smallMobile} {
            padding: ${styleVals.measurements.spacing10} ${styleVals.measurements.spacing8} ${styleVals.measurements.spacing0} ${styleVals.measurements.spacing10};
        }
        padding-top: ${props.lessTopGap && styleVals.measurements.spacing4};
        @media ${styleVals.breakpoints.smallMobile} {
            padding-top: ${props.lessTopGap && styleVals.measurements.spacing4};
        }
    `}
    ${props => props.withSubtext && css`
        span:last-child {
            display: block;
            color: ${props => props.message ? 'red' : styleVals.colors.greyText};
            font-size: ${styleVals.measurements.spacing14};
            padding: ${styleVals.measurements.spacing2} 0;
            font-weight: normal;
        }
    `};
`
export const SubSection = styled.div`
    ${props => props.groupSection ? css`
        padding-top: ${props => props.firstChild ? styleVals.measurements.spacing0 : styleVals.measurements.spacing8};
    ` : css`
        padding-top: ${props => props.firstChild ? styleVals.measurements.spacing8 : styleVals.measurements.spacing10};
    `}
    ${props => props.bottomGap && (props.groupSection ? css`
        padding-bottom: ${props => props.firstChild ? styleVals.measurements.spacing0 : styleVals.measurements.spacing8};
    ` : css`
        padding-bottom: ${props => props.firstChild ? styleVals.measurements.spacing8 : styleVals.measurements.spacing10};
    `)}
    
`
export const SelectionLabel = styled.label`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
    width: 100%;
    padding: ${styleVals.measurements.spacing8} ${styleVals.measurements.spacing20};
    min-height: ${styleVals.measurements.spacing50};
    ${props => props.morePaddingRight && css`
        padding-right: ${styleVals.measurements.spacing30};
    `}
    @media ${styleVals.breakpoints.smallMobile} {
        padding: ${styleVals.measurements.spacing8} ${styleVals.measurements.spacing8};
        padding-right: ${props => props.itemLabel ? styleVals.measurements.spacing10 : styleVals.measurements.spacing18};
    }
    border-bottom: 1px solid #CDCDCD;
    span:first-child {
        display: inline-block;
        padding-right: ${styleVals.measurements.spacing4};
        padding-left: ${styleVals.measurements.spacing4};
        max-width: 70%;
        @media ${styleVals.breakpoints.smallMobile} {
            max-width: 60%;
        }
    }
    [id='item-status'] {
        margin: 0;
        padding: 0;
        color: ${styleVals.colors.greyText};
    }
`

const handleQtyTextStyled = (shakeText, id) => {
    if(shakeText && id === shakeText.id) {
        if(shakeText.shake) {
            return css`
                ${shakeText.colored && css`color: ${styleVals.colors.red} !important;`}
                animation: 0.2s shake;
                @keyframes shake {
                    33% {
                    transform: translate(10px, 0px);
                    }
                    66% {
                    transform: translate(-10px, 0px);
                    }
                    100% {
                    transform: translate(0px, 0px);
                    }
                }
            `
        } else if (shakeText.colored) {
            return css`color: ${styleVals.colors.red} !important;`
        }
    }
}

export const QtyText = styled.span`
    ${({shakeText = {}, id}) => handleQtyTextStyled(shakeText, id)}
    transition: all 0.2s;
`
export const AccordionSection = styled.div`
    padding-top: ${styleVals.measurements.spacing10};
`
export const LabelWithFoodType = styled.div`
    display: flex;
    align-items: center;
`