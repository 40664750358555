import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { useHistory } from 'react-router-dom';
import { get } from '../utils/common';
import jsCookie from 'js-cookie';

import { clevertapEventPush, CT_EVENT_IDS } from '../helpers/clevertapUtil'
import usePersistedState, { usePersistedLocalStorageState } from '../helpers/persistent';
import i18n from '../translations/i18n';
import { useProductId, useQuery } from '../hooks';
import { checkIsColorDark, getKeyValue, pushHistory } from '../utils/common';
import { convertMenuToUIFormat } from '../Components/Product/convertMenuToUIFormat';
import { getDescription } from '../Components/OfferList/OfferList';
import { fstore, doc, onSnapshot } from '../lib/Firebase';

import {
  getGipUser,
  getMenuDetails,
  generateGipLink,
  listActiveOffers
} from '../utils/API';

import { ERROR404, ERROR500, PRODUCT, PRODUCT_D, TABLERESERVATION, TABLERESERVATION_D, VEIWADDRESS, VEIWADDRESS_D } from '../constants/routes';
import { Color, MENUID_DOMAIN, OfferTypes, OrderType, RESTAURANT_TYPE } from '../constants/variables';

export const RestaurantsInfo = createContext(null);

export const RestaurantsInfoContext = function ({ children }) {

  const productIdFromRoute = useProductId();
  const history = useHistory();

  const orderTypeQueryParams = useQuery('orderType');
  let table = useQuery('table');
  table = table ? table.split('?')[0] : null;
  let room = useQuery('room');
  room = room ? room.split('?')[0] : null;

  const [productId, setProductId] = useState();
  const [restaurant, setRestaurant] = useState(null);
  const [restaurantColor, setRestaurantColor] = useState(Color.defaultColor);
  const [updateTimestamp, setUpdateTimestamp] = useState();
  const [colorLoading, setColorLoading] = useState(false);
  const [isRestaurantColorDark, setIsRestaurantColorDark] = useState(checkIsColorDark(restaurantColor))
  const [recaptchaScriptAdded, setRecaptchaScriptAdded] = useState(false);
  const [gMapScriptAdded, setGMapScriptAdded] = useState(false);
  const [paymentData, setPaymentData] = useState({
    razorpayOrderId: '',
    gupshupOrderId: '',
    paymentAmount: null,
    amountForPayment: null
  })
  const [userLocationStatusForDelivery, setUserLocationStatusForDelivery] = useState('')
  const [userLocationStatusForTakeaway, setUserLocationStatusForTakeaway] = useState('')
  const [products, setProducts] = useState(null);
  const [restaurantCurrency, setRestaurantCurrency] = useState();
  const [restaurantBot, setRestaurantBot] = useState();
  const [storeName, setStoreName] = useState();
  const [paymentOptions, setPaymentOptions] = useState();
  const [offersArray, setOffersArray] = useState([]);
  const [gipUsr, setGipUsr] = useState("");
  const [configuration, setConfiguration] = useState();
  const [chainId, setChainId] = useState();
  const [selfDelivery, setSelfDelivery] = useState();
  const [repititionModal, setRepititionModal] = useState(false);
  const [repititionModalChildren, setRepititionModalChildren] = useState("");
  const [productIndex, setProductIndex] = useState(0);
  const [serverTime, setServerTime] = useState('');
  const [notifications, setNotifications] = useState();
  const [pwaAppInstallable, setPwaAppInstallable] = useState(false);
  const [isLanguageArab, setIsLanguageArab] = useState(false);
  const [menuLoading, setMenuLoading] = useState(false)

  const [locationAccess, setLocationAccess] = useState(null);

  const [custGIPLink, setCustGIPLink] = useStateWithCallbackLazy();

  const [metaData, setMetaData] = usePersistedLocalStorageState({}, productIdFromRoute, 'metaData');
  const [isGIPOpen, setGIPOpen] = usePersistedLocalStorageState(null, productIdFromRoute, 'gipStatus');
  const [comboInCart, setComboInCart] = usePersistedLocalStorageState(null, productIdFromRoute, "comboInCart");
  const [comboIdentifier, setComboIdentifier] = usePersistedLocalStorageState(null, productIdFromRoute, "comboIdentifier");

  const [restaurantUIStateData, setRestaurantUIStateData] = usePersistedState('', 'ui', 'state');
  const [landingModal, setLandingModal] = usePersistedState(true, productIdFromRoute, 'showLandingModal')
  const [chainOrigin, setChainOrigin] = usePersistedState('', 'chain', 'origin')
  const [defaultOutletShown, setDefaultOutletShown] = usePersistedState(false, 'chain', 'defaultOutletSelected')
  const [defaultOutletSelected, setDefaultOutletSelected] = usePersistedState('', 'chain', 'defaultOutletSelectedName')
  const [chainOriginName, setChainOriginName] = usePersistedState('', 'chain', 'originName')
  const [orderType, setOrderType] = usePersistedState(OrderType.DELIVERY, productIdFromRoute, 'orderType');
  const [offers, setOffers] = usePersistedState({}, productIdFromRoute, "offers");
  const [userLocationData, setUserLocationData] = usePersistedState(null, productIdFromRoute, 'userLocationData');
  const [storeLocationData, setStoreLocationData] = usePersistedState(null, productIdFromRoute, 'storeLocationData');
  const [cart, setCart] = usePersistedState([], productIdFromRoute, "cart");
  const [instructions, setInstructions] = usePersistedState("",productIdFromRoute,"instructions");
  const [dineIndetails,setDineinDetails]  = usePersistedState({},productIdFromRoute, get(metaData, 'table_number'));
  const [totalSavings, setTotalSavings] = usePersistedState(null, productIdFromRoute, "totalSavings");

  const appLaunched = useRef(false);
  const wentToCheckout = useRef(false);
  const userPhoneNo = useRef('')
  const orderInfo = useRef();
  const locationChangedFromTakeaway = useRef(false)
  const orderDetailsJson = useRef()
  const pollTimeout = useRef()
  const customizeExistingFlag = useRef(false);
  const replaceExistingFlag = useRef(false);
  const shouldFilterProductFromCart = useRef(false);
  const locationSelectionModeForSetLocation = useRef('Detect My Location');
  const orderTypeCached = useRef(orderType);
  const isPaymentComplete = useRef(false)
  const previousProductId = useRef(productIdFromRoute)
  const gipUserCookie = useRef(false)
  const pwaDeferredPrompt = useRef()
  const isOrderPlaced = useRef(false)
  const initialLoad = useRef(true)
  const isRestaurantVeg = get(restaurant, 'meta_data.isRestaurantVeg', false);
  
  const handleLocationAccess = (locationStatus) => {
    locationStatus === 'prompt' ? setLocationAccess(null) : setLocationAccess(locationStatus === 'granted');
  }

  useEffect(() => {
    if (navigator?.permissions?.query) {
        navigator.permissions.query({ name: 'geolocation' }).then(result => {
         handleLocationAccess(result.state);
          result.onchange = () => {
          handleLocationAccess(result.state);
        }
      });
    }
  }, [locationAccess])

  const installEvent = (e) => {
    e.preventDefault();
    pwaDeferredPrompt.current = e;
    setPwaAppInstallable(true);

  }

  const setGlobalCurrency = (currency) => {

    return (<span className='global-currency'>{currency}</span>);
  }

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", installEvent);
  }, []);
  const handleGIPUserData = () => {
    if (!!jsCookie.get('gipuserid')) {
      !gipUserCookie.current && getGipUser().then((result) => {
        setGipUsr(result);
        gipUserCookie.current = true
        userPhoneNo.current = get(result, 'phone', '')
        clevertapEventPush({
          eventId: CT_EVENT_IDS.LOGIN_PUSH,
          eventData: result,
        });
      });
    }
  }

  const handleRestoData = (result, order_type = (orderTypeQueryParams || orderType)) => {
    setRestaurant({
      meta_data: get(result, 'meta_data'),
      order_types: get(result, 'order_types')
    });
    const formattedMenu = convertMenuToUIFormat(result);
    setProducts(formattedMenu);
    setPaymentOptions(getKeyValue(formattedMenu, `order_types[${order_type}].paymentOptions`, ''))
    setChainId(formattedMenu.chain_id);
    setConfiguration(getKeyValue(formattedMenu, 'configuration', ''));
    const restaurantLanguage = get(result, 'configuration.language')
    setIsLanguageArab(restaurantLanguage === 'arab')
    i18n.changeLanguage(restaurantLanguage || 'en');
    setSelfDelivery(getKeyValue(formattedMenu, 'self_delivery', ''));
    setServerTime(formattedMenu.loadMenuTime);
  }

  useEffect(() => {
    if (previousProductId.current !== productIdFromRoute) {
      setCart([])
    }
    previousProductId.current = productIdFromRoute
    setProducts(null)
    setRestaurant(null)
  }, [productIdFromRoute])

  useEffect(() => {
    if (productIdFromRoute) {
      initialLoad.current = true
      setColorLoading(true);
      productIdFromRoute !== 'error' && getMenuDetails(productIdFromRoute).then((result) => {
        const alternateUrlType = get(result, 'meta_data.redirectionData.type')
        if(alternateUrlType) {
          const queryParams = new URL(window.location.href).search
          if(alternateUrlType === 'ref_id') {
            const redirectionRefId = get(result, 'meta_data.redirectionData.ref_id', '')
            pushHistory(history, PRODUCT, PRODUCT_D, redirectionRefId, queryParams)
            history.go()
          } else if (alternateUrlType === 'url' && productIdFromRoute !== MENUID_DOMAIN) {
            const redirectionUrl = get(result, 'meta_data.redirectionData.url', '')
            window.location.href = `${redirectionUrl.trim()}/menu${queryParams || ''}`
          } else {
            pushHistory(history, ERROR500, ERROR500, productIdFromRoute);
          }
        } else {
          const restaurantType = get(result, 'meta_data.type')
          if (restaurantType === RESTAURANT_TYPE.OUTLET) {
            initialLoad.current = false
            handleRestoData(result)
            setRestaurantCurrency(setGlobalCurrency(get(result, 'meta_data.currency.symbol', '₹') || '₹'));
            const restaurantThemeColor = get(result, 'meta_data.uiColor');
            restaurantThemeColor && setRestaurantColor(restaurantThemeColor);
            setIsRestaurantColorDark(checkIsColorDark(restaurantThemeColor))
            setColorLoading(false);
            setStoreName(get(result, 'meta_data.name'));
            handleGIPUserData()
            setRestaurantBot(get(result, 'meta_data.botname'));
            const chainId = get(result, 'chain_id')
            if(chainId !== chainOrigin) {
              setChainOrigin('')
              setChainOriginName('')
              setDefaultOutletShown(false)
              setDefaultOutletSelected('')
            } 
          } else {
            const defaultOutletIdFromResp = get(result, 'meta_data.defaultOutletId', '')
            const chainId = get(result, 'meta_data.ref_id', '')
            setChainOrigin(chainId)
            setChainOriginName({
              name: get(result, 'meta_data.name', ''),
              logo: get(result, 'meta_data.logo', '')
            })
            if(defaultOutletIdFromResp) {
              setDefaultOutletShown(true)
              setDefaultOutletSelected(defaultOutletIdFromResp)
              pushHistory(history, PRODUCT, PRODUCT_D, defaultOutletIdFromResp)
            } else {
              setDefaultOutletShown(false)
              pushHistory(history, VEIWADDRESS, VEIWADDRESS_D, productIdFromRoute);
            }
            // i18n.changeLanguage('en');
          }
        }
      }).catch((error) => {
        if (error.response) {
          switch (error.response.status) {
            case 500: {
              pushHistory(history, ERROR500, ERROR500, productIdFromRoute);
              break;
            }
            case 404: {
              pushHistory(history, ERROR404, ERROR404, productIdFromRoute);
              break;
            }
            default:
              pushHistory(history, ERROR500, ERROR500, productIdFromRoute);
          }
        }
      });
      i18n.changeLanguage('en');
    }
  }, [productIdFromRoute, updateTimestamp]);

  const eventPush = useCallback(() => setTimeout(() => {
    clevertapEventPush({
      eventId: CT_EVENT_IDS.APP_LAUNCHED,
      eventData: {
        "Order type": orderTypeCached.current,
      }
    });
    appLaunched.current = true;
  }, 1000), [])

  useEffect(() => {
    !appLaunched.current && eventPush()

    if (orderTypeQueryParams) {
      if (orderType && orderType !== orderTypeQueryParams) {
        setGIPOpen(false);
        setCart([])
      }
      switch (orderTypeQueryParams) {
        case OrderType.DELIVERY: {
          setOrderType(OrderType.DELIVERY);
          break;
        }
        case OrderType.TABLERES: {
          setOrderType(OrderType.TABLERES);
          pushHistory(history, TABLERESERVATION, TABLERESERVATION_D, productIdFromRoute);
          break;
        }
        case OrderType.DINEIN: {
          setOrderType(OrderType.DINEIN);
          break;
        }
        case OrderType.TAKEAWAY: {
          setOrderType(OrderType.TAKEAWAY);
          break;
        }
        case OrderType.INROOMDINEIN: {
          setOrderType(OrderType.INROOMDINEIN);
          break;
        }
        case OrderType.QDINEIN: {
          setOrderType(OrderType.QDINEIN);
          break;
        }
        default: {
          pushHistory(history, PRODUCT, PRODUCT_D, productIdFromRoute);
        }
      }
    } else if (!orderType) {
      pushHistory(history, PRODUCT, PRODUCT_D, productIdFromRoute);
    }

  }, [])

  useEffect(() => {
    orderTypeCached.current = orderType
    if (!gMapScriptAdded) {
      if ([OrderType.DELIVERY, OrderType.TAKEAWAY].includes(orderType)) {
        const loadScript = src => {
          const tag = document.createElement('script');
          tag.async = true;
          tag.src = src;
          tag.onload = () => {
            setGMapScriptAdded(true)
          }
          const head = document.getElementsByTagName('head')[0];
          head.appendChild(tag);
        }
      loadScript(`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&libraries=places`)
      }
    }

    if (orderType === OrderType.DINEIN || orderType === OrderType.QDINEIN) {
      if (table) {
        setMetaData({ table_number: table, edit_disable: true, room_number: metaData.room_number });
      } else if (metaData.table_number) {
        setMetaData({ table_number: metaData.table_number, edit_disable: true, room_number: metaData.room_number });
      }
      if (table && metaData.table_number && table !== metaData.table_number) {
        setGIPOpen(false);
      }
    } else if (orderType === OrderType.INROOMDINEIN) {
      if (room) {
        setMetaData({ room_number: room, edit_disable: true, table_number: metaData.table_number });
      } else if (metaData.room_number) {
        setMetaData({ room_number: metaData.room_number, edit_disable: true, table_number: metaData.table_number });
      }
      if (room && metaData.room_number && room !== metaData.room_number) {
        setGIPOpen(false);
      }
    }

  }, [orderType]);

  useEffect(() => {
    if(!initialLoad.current) {
      setMenuLoading(true)
      productIdFromRoute !== 'error' && getMenuDetails(productIdFromRoute).then((result) => {
        const restaurantType = get(result, 'meta_data.type')
        if (restaurantType === RESTAURANT_TYPE.OUTLET) {
          setMenuLoading(false)
          handleRestoData(result, orderType)
        }
      }).catch(() => {
        pushHistory(history, ERROR500, ERROR500, productIdFromRoute);
      });
    }
  }, [productIdFromRoute, orderType])

  useEffect(() => {
    if (!!restaurantBot && !!jsCookie.get('gipuserid') && !custGIPLink) {
      generateGipLink(restaurantBot).then((result) => {
        setCustGIPLink(result.url);
      }).catch((error) => {
        console.log("error", error);
      });
    }
  }, [restaurantBot, custGIPLink, jsCookie.get('gipuserid')]);

  useEffect(() => {
    if (gipUsr && productIdFromRoute) {
      const notificationDataRef = doc(fstore, `notificationData/${productIdFromRoute}/notifications`, gipUsr.phone)
      onSnapshot(notificationDataRef, (finalDoc) => {
        finalDoc.exists() && setNotifications(finalDoc.data())
      })
    }
  }, [gipUsr, productIdFromRoute]);

  useEffect(() => {
    const order_type = orderTypeCached.current
    if (productIdFromRoute && order_type && metaData && paymentOptions) {
      setOffersArray(null)
      listActiveOffers(productIdFromRoute, order_type).then((res) => {
        let offersArr = [];
        res.forEach((offer) => {
          if (
            offer.is_public &&
            ((offer.discount_type === OfferTypes.FREEDELIVERY &&
              order_type === OrderType.DELIVERY &&
              parseInt(metaData.deliveryCharge) > 0) ||
              offer.discount_type === OfferTypes.PERCENT ||
              offer.discount_type === OfferTypes.FLAT) &&
            ((!!offer.expire_date &&
              offer.expire_date >= new Date().setHours(0, 0, 0, 0)) ||
              !offer.expire_date)
          ) {
            if (!offer.description) {
              offer.description = getDescription(offer, restaurantCurrency);
            }
            if (
              paymentOptions &&
              offer.payment_mode &&
              ((paymentOptions.cash && offer.payment_mode.cash) ||
                (paymentOptions.online && offer.payment_mode.online))
            ) {
              offersArr.push(offer);
            } else if (!paymentOptions || !offer.payment_mode) {
              offersArr.push(offer);
            }
          }
        });
        setOffersArray(offersArr);
      }).catch((e) => {
        console.log(e);
      });
    }
  }, [productIdFromRoute, metaData.deliveryCharge, paymentOptions]);

  return (
    <RestaurantsInfo.Provider
      value={{
        restaurant,
        setRestaurant,
        restaurantColor,
        productId,
        setProductId,
        setUpdateTimestamp,
        colorLoading,
        setColorLoading,
        appLaunched,
        wentToCheckout,
        restaurantUIStateData,
        setRestaurantUIStateData,
        recaptchaScriptAdded,
        setRecaptchaScriptAdded,
        gMapScriptAdded,
        setGMapScriptAdded,
        landingModal,
        setLandingModal,
        chainOrigin,
        setChainOrigin,
        locationChangedFromTakeaway,
        defaultOutletShown,
        setDefaultOutletShown,
        userPhoneNo,
        isRestaurantColorDark,
        chainOriginName,
        defaultOutletSelected,
        setDefaultOutletSelected,
        isPaymentComplete,
        orderType,
        setOrderType,
        metaData,
        setMetaData,
        isGIPOpen,
        setGIPOpen,
        userLocationData,
        setUserLocationData,
        userLocationStatusForDelivery,
        setUserLocationStatusForDelivery,
        userLocationStatusForTakeaway,
        setUserLocationStatusForTakeaway,
        orderInfo,
        storeLocationData,
        setStoreLocationData,
        offers,
        setOffers,
        customizeExistingFlag,
        replaceExistingFlag,
        shouldFilterProductFromCart,
        comboInCart,
        setComboInCart,
        comboIdentifier,
        setComboIdentifier,
        setPaymentData,
        paymentData,
        orderDetailsJson,
        pollTimeout,
        locationSelectionModeForSetLocation,
        cart,
        setCart,
        products,
        gipUsr,
        chainId,
        paymentOptions,
        restaurantCurrency,
        restaurantBot,
        storeName,
        custGIPLink,
        setCustGIPLink,
        offersArray,
        instructions,
        setInstructions,
        dineIndetails,
        setDineinDetails,
        configuration,
        gipUserCookie,
        selfDelivery,
        totalSavings,
        setTotalSavings,
        serverTime,
        repititionModal,
        setRepititionModal,
        repititionModalChildren,
        setRepititionModalChildren,
        productIndex,
        setProductIndex,
        notifications,
        setNotifications,
        pwaAppInstallable,
        setPwaAppInstallable,
        pwaDeferredPrompt,
        isOrderPlaced,
        isLanguageArab,
        menuLoading,
        isRestaurantVeg,
        locationAccess
      }}
    >
      {children}
    </RestaurantsInfo.Provider>
  );
};

export function useRestaurantsInfoContext() {

  const {
    restaurant,
    productId,
    setProductId,
    setRestaurant,
    restaurantColor,
    setUpdateTimestamp,
    colorLoading,
    setColorLoading,
    appLaunched,
    wentToCheckout,
    restaurantUIStateData,
    setRestaurantUIStateData,
    gMapScriptAdded,
    setGMapScriptAdded,
    recaptchaScriptAdded,
    setRecaptchaScriptAdded,
    landingModal,
    setLandingModal,
    chainOrigin,
    setChainOrigin,
    locationChangedFromTakeaway,
    defaultOutletShown,
    setDefaultOutletShown,
    userPhoneNo,
    isRestaurantColorDark,
    chainOriginName,
    defaultOutletSelected,
    setDefaultOutletSelected,
    isPaymentComplete,
    orderType,
    setOrderType,
    metaData,
    setMetaData,
    isGIPOpen,
    setGIPOpen,
    userLocationData,
    setUserLocationData,
    userLocationStatusForDelivery,
    setUserLocationStatusForDelivery,
    userLocationStatusForTakeaway,
    setUserLocationStatusForTakeaway,
    orderInfo,
    storeLocationData,
    setStoreLocationData,
    offers,
    setOffers,
    customizeExistingFlag,
    replaceExistingFlag,
    shouldFilterProductFromCart,
    comboInCart,
    setComboInCart,
    comboIdentifier,
    setComboIdentifier,
    setPaymentData,
    paymentData,
    orderDetailsJson,
    pollTimeout,
    locationSelectionModeForSetLocation,
    cart,
    setCart,
    products,
    gipUsr,
    chainId,
    paymentOptions,
    restaurantCurrency,
    restaurantBot,
    storeName,
    custGIPLink,
    setCustGIPLink,
    offersArray,
    instructions,
    setInstructions,
    dineIndetails,
    setDineinDetails,
    configuration,
    gipUserCookie,
    selfDelivery,
    totalSavings,
    setTotalSavings,
    serverTime,
    repititionModal,
    setRepititionModal,
    repititionModalChildren,
    setRepititionModalChildren,
    productIndex,
    setProductIndex,
    notifications,
    setNotifications,
    pwaAppInstallable, 
    setPwaAppInstallable,
    pwaDeferredPrompt,
    isOrderPlaced,
    isLanguageArab,
    menuLoading,
    isRestaurantVeg,
    locationAccess
  } = useContext(RestaurantsInfo);

  return {
    productId,
    restaurant,
    setProductId,
    setRestaurant,
    restaurantColor,
    setUpdateTimestamp,
    colorLoading,
    setColorLoading,
    appLaunched,
    wentToCheckout,
    restaurantUIStateData,
    setRestaurantUIStateData,
    gMapScriptAdded,
    setGMapScriptAdded,
    recaptchaScriptAdded,
    setRecaptchaScriptAdded,
    landingModal,
    setLandingModal,
    chainOrigin,
    setChainOrigin,
    locationChangedFromTakeaway,
    defaultOutletShown,
    setDefaultOutletShown,
    userPhoneNo,
    isRestaurantColorDark,
    chainOriginName,
    defaultOutletSelected,
    setDefaultOutletSelected,
    isPaymentComplete,
    orderType,
    setOrderType,
    metaData,
    setMetaData,
    isGIPOpen,
    setGIPOpen,
    userLocationData,
    setUserLocationData,
    userLocationStatusForDelivery,
    setUserLocationStatusForDelivery,
    userLocationStatusForTakeaway,
    setUserLocationStatusForTakeaway,
    orderInfo,
    storeLocationData,
    setStoreLocationData,
    offers,
    setOffers,
    customizeExistingFlag,
    replaceExistingFlag,
    shouldFilterProductFromCart,
    comboInCart,
    setComboInCart,
    comboIdentifier,
    setComboIdentifier,
    setPaymentData,
    paymentData,
    orderDetailsJson,
    pollTimeout,
    locationSelectionModeForSetLocation,
    cart,
    setCart,
    products,
    gipUsr,
    chainId,
    paymentOptions,
    restaurantCurrency,
    restaurantBot,
    storeName,
    custGIPLink,
    setCustGIPLink,
    offersArray,
    instructions,
    setInstructions,
    dineIndetails,
    setDineinDetails,
    configuration,
    gipUserCookie,
    selfDelivery,
    totalSavings,
    setTotalSavings,
    serverTime,
    repititionModal,
    setRepititionModal,
    repititionModalChildren,
    setRepititionModalChildren,
    productIndex,
    setProductIndex,
    notifications,
    setNotifications,
    pwaAppInstallable,
    setPwaAppInstallable,
    pwaDeferredPrompt,
    isOrderPlaced,
    isLanguageArab,
    menuLoading,
    isRestaurantVeg,
    locationAccess
  };

}
