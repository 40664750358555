import styled from 'styled-components';
import styleVals from "../../constants/styles";

export const EmptyCart = styled.div`
    position: fixed;
    width: 100%;
    min-height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    bottom: 0;
    left: 0;
    right: 0;
`;
export const EmptyCartNoItem = styled.div`
    position: absolute;
    width: 35vh;
    height: 25%;
    background: ${props => props.restaurantColor ? props.restaurantColor : '#ab0012'};
    bottom: 38vh;
    margin: 0 auto;
    left: 0;
    right: 0;
    border-radius:${styleVals.measurements.spacing4};
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

`;


EmptyCartNoItem.Div = styled.div`
    background: ${props => props.restaurantColor ? props.restaurantColor : '#ab0012'};
    > p {
        color: #ffffff;
        font-size: 1rem;
      }
`
export const AddItemButton = styled.button`
    font-size: ${styleVals.measurements.spacing15};
    padding: 0.5rem 1rem;
    border-radius:${styleVals.measurements.spacing4};
    outline: none;
    color: ${props => props.restaurantColor ? props.restaurantColor : '#ab0012'};;
    border: 2px solid #ffffff;
    background-color: #ffffff;
    cursor: pointer;
`
