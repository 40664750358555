import React, { useEffect, useState } from "react";
import { checkOfferAvailableStatus } from "../../utils/API";
import { Notification } from "../../helpers/notification";
import {
  DiscountTypes,
  OfferTypes,
} from "../../constants/variables";
import { offersMessage, validAlphanumericString } from "../../utils/common";
import { ApplyButtonBlock, CouponCode, CouponDesc, DiscountMessage, MannualOfferCode, NoOfferAvailable, OfferCard, offerCard, OfferInput, OfferListFooter, PaymentText, ReactSpinner, RowPadding, Spinner } from "./styles/OfferList.style";
import { useRestaurantsInfoContext } from "../../contexts/restaurantInfo";
import { MESSAGES } from "../../constants/constants";
import { get } from '../../utils/common';
import { clevertapEventPush, CT_EVENT_IDS } from "../../helpers/clevertapUtil";
import { useTranslation } from 'react-i18next';
import i18n from "../../translations/i18n";
import { CardBody, CardText, Col, Container } from "../BasicComponents";

export const getDescription = (offer, restaurantCurrency) => {
  let desc = offer.description ? offer.description : offersMessage(offer.discount_type, offer.max_discount, offer.min_order_value, offer.code, offer.discount_value, false, restaurantCurrency);
  return desc;
}

export const checkDiscountAvailable = (
  menu_id,
  offer,
  sub_total,
  sub_total_offer,
  metaData,
  orderType,
  free_delivery
) => {
  return new Promise((resolve, reject) => {
    let data = {
      order_subtotal: sub_total,
      order_subtotal_offer: sub_total_offer,
      deliveryCharge: metaData.deliveryCharge ? metaData.deliveryCharge : "",
      fulfillment_mode: orderType,
    };
    checkOfferAvailableStatus(menu_id, offer.offer_id, data)
      .then((res) => {
        if (res.is_offer_applicable) {
          res.offer_id = offer.offer_id;
          res.order_subtotal = data.order_subtotal;
          res.order_subtotal_offer = data.order_subtotal_offer;
          res.min_order_value = offer.min_order_value?offer.min_order_value:0;
          res.type = res.offer.type ? res.offer.type : DiscountTypes.ITEMLVL;
          res.payment_mode = res.offer.payment_mode;
          resolve(res);
        } else {
          Notification({
            type: "error",
            displayMessage: i18n.t('notificationMsg.OFFER_IS_NOT_APPLICABLE'),
          });
          resolve({});
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default function OfferList({
  menu_id,
  restaurantColor,
  restaurantCurrency,
  addOfferCodeandCharges,
  order,
  offers,
  removeOffer,
  funApplyCoupon,
  orderType,
  getPaymentModesApplicable,
  applicableDiscount
}) {
  const [loading, setLoading] = useState(true);
  const { metaData, offersArray, isLanguageArab } = useRestaurantsInfoContext();
  const [discount, setDiscount] = useState({});
  const { t }=useTranslation();

  useEffect(() => {
    setLoading(!offersArray);
  }, [offersArray]);

  const generateDescription = (offer, index) => {
    let description = "";
    switch (offer.discount_type) {
      case OfferTypes.FLAT:
        description = <b id={`flat-discount-message-${index + 1}`}>{t('GET_OFF', { currency: restaurantCurrency, value: offer.discount_value })}</b>;
        break;
      case OfferTypes.PERCENT:
        description = <b id={`discount-message-${index + 1}`}>{t('GET_OFF', { currency: '', value: offer.discount_value + "%" })}</b>;
        break;
      case OfferTypes.FREEDELIVERY:
        description = <b id={`get-free-delivery-message-${index + 1}`}>{t('GET_FREE_DELIVERY')}</b>;
        break;
      default:
        break;
    }
    return <DiscountMessage isLanguageArab={isLanguageArab}>{description}</DiscountMessage>;
  };

  const checkAvailable = (menu_id, offer, order, orderType) => {
    let message = "";
    let sub_total_offer = get(order, 'meta.sub_total_offer')
    let sub_total = get(order, 'meta.sub_total')
    if(!offer.offer_id){
      message = MESSAGES.ERROR.OFFER.OFFER_UNDEFINED
    }

   if(sub_total_offer === 0 ){
      message = MESSAGES.ERROR.OFFER.OFFER_AMOUNT_INVALID_FOR_ITEMS;
    }
    else if (sub_total_offer > 0 && sub_total_offer === sub_total && parseInt(sub_total_offer) < parseInt(offer.min_order_value)){
      message = MESSAGES.ERROR.OFFER.OFFER_AMOUNT_LESS_THAN_ORDER_VALUE;
    }
    else if(sub_total > sub_total_offer && parseInt(sub_total_offer) < parseInt(offer.min_order_value)){
      message = MESSAGES.ERROR.OFFER.OFFER_AMOUNT_INVALID;
    }
    if(message){
      Notification({
        type: "error",
        displayMessage:message,
        toasterOptions: {
          toastId: "coupon-error",
        },
      });
      return;
    }
    else{
      let free_delivery = order?.meta?.free_delivery ? true : false;
      checkDiscountAvailable(
        menu_id,
        offer,
        sub_total,
        sub_total_offer,
        metaData,
        orderType,
        free_delivery
      )
        .then((res) => {
          addOfferCodeandCharges(res);
          if (res.is_offer_applicable) {
            applicableDiscount.current = get(res, 'offer_discount', 0)
            clevertapEventPush({
              eventId: CT_EVENT_IDS.COUPON_APPLIED,
              eventData: {
                'Maximum discount': get(res, 'offer.max_discount', ''),
                'Applicable discount': get(res, 'offer_discount', ''),
                'Payment mode': getPaymentModesApplicable(get(res, 'payment_mode', {})),
                'Order type': orderType
              }
            })
            funApplyCoupon();
          }
        })
        .catch((e) => {
          console.log(e);
        });
      }
  };

  const setOfferCode = (code) => {
    if (code.length > 15) {
      Notification({
        type: "error",
        displayMessage: MESSAGES.ERROR.OFFER.OFFER_LENGTH,
        toasterOptions: {
          toastId: "code-length",
        },
      });
      return;
    }
    if (validAlphanumericString(code) && code.length <= 15) {
      setDiscount({ offer_id: code.toUpperCase() });
    } else {
      Notification({
        type: "error",
        displayMessage: MESSAGES.ERROR.OFFER.ALPHA_NUMERIC,
        toasterOptions: {
          toastId: "offer-text",
        },
      });
    }
  };
  return (
    <>
      <Container fluid>
        <MannualOfferCode id='manually-apply-coupon-section'>
          <OfferInput
            id='coupon-code-input'
            placeholder="Enter code"
            value={discount.offer_id}
            defaultValue={discount.offer_id}
            onChange={(e) => setOfferCode(e.target.value)}
          />

          <div>
            {" "}
            <ApplyButtonBlock isFormControlCss={true} isLanguageArab={isLanguageArab}>
              <ApplyButtonBlock.Button
                onClick={() =>
                  checkAvailable(menu_id, discount, order, orderType)
                }
                restaurantColor={restaurantColor}
                id="manual-coupon-apply-button"
              >
                Apply 
              </ApplyButtonBlock.Button>
            </ApplyButtonBlock>
          </div>
        </MannualOfferCode>
        <RowPadding>
          {loading ? (
            <Spinner>
              <ReactSpinner restaurantColor={restaurantColor} />
            </Spinner>
          ) : null}
          {!loading && offersArray.length === 0 ? (
            <NoOfferAvailable id='no-offers-available-text'>{t('NO_OFFER_AVAILABLE')}</NoOfferAvailable>
          ) : null}
          {offersArray.length > 0 && offersArray.map((offer, index) => {
            return (
              <Col key={index}>
             <OfferCard id={`offer-card-${index}`} isLanguageArab={isLanguageArab}>
                <CardBody id={`card-body-${index}`}>
                    <CardText className='card-text'>
                      <CouponCode restaurantColor={restaurantColor} id={`coupon-code-${index + 1}`}>
                      {offer.code}
                    </CouponCode>
                    {offers.offer_id !== offer.offer_id ? (
                      <ApplyButtonBlock isLanguageArab={isLanguageArab} style={{ float: "right" }}>
                        <ApplyButtonBlock.Button
                          onClick={() =>
                            checkAvailable(menu_id, offer, order, orderType)
                          }
                          restaurantColor={restaurantColor}
                          id={`apply-coupon-button-${index + 1}`}
                        >
                          {t('APPLY')}
                        </ApplyButtonBlock.Button>
                      </ApplyButtonBlock>
                    ) : (
                      <ApplyButtonBlock isLanguageArab={isLanguageArab} style={{ float: "right" }}>
                        <ApplyButtonBlock.Button
                            id={`remove-coupon-button-${index + 1}`}
                          onClick={() => removeOffer(offer, orderType, applicableDiscount.current)}
                          restaurantColor={"red"}
                        >
                          {t('REMOVE')}
                        </ApplyButtonBlock.Button>
                      </ApplyButtonBlock>
                    )}
                  </CardText>
                  {generateDescription(offer, index)}
                </CardBody>
                  <OfferListFooter id="offer-list-footer">
                  <CouponDesc isLanguageArab={isLanguageArab} id={`coupon-description-${index + 1}`}>
                      {offer.description 
                      ? offer.description
                      : getDescription(offer, restaurantCurrency)}
                  </CouponDesc>
                  {offer.payment_mode ? (
                    <CardText>
                      {offer.payment_mode.online &&
                      offer.payment_mode.cash ? (
                          <PaymentText isLanguageArab={isLanguageArab} id={`online-cash-text-${index + 1}`}>{t('ONLINE_CASH')}</PaymentText>
                      ) : (
                          <PaymentText isLanguageArab={isLanguageArab} id={`online-cash-text-${index + 1}`}>
                          {offer.payment_mode.online ? i18n.t('ONLINE') : ""}
                          {offer.payment_mode.cash ? i18n.t('CASH') : ""}
                          </PaymentText>
                      )}
                    </CardText>
                  ) : (
                    <CardText>
                        <PaymentText isLanguageArab={isLanguageArab} id={`online-cash-text-${index + 1}`}>{t('ONLINE_CASH')}</PaymentText>
                    </CardText>
                  )}
                </OfferListFooter>
              </OfferCard>
              </Col>
            );
          })}
        </RowPadding>
      </Container>
    </>
  );
}
