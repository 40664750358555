import styled, { css } from 'styled-components'
import styleVals from '../../../constants/styles'

export const ItemCardWrapper = styled.div`
    position: relative;
    min-height: ${styleVals.measurements.spacing60};
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: white;
    padding: ${styleVals.measurements.spacing16} ${styleVals.measurements.spacing4} ${styleVals.measurements.spacing16};
    ${props => props.withImage && css`
        padding-bottom: ${props.withDescription ? styleVals.measurements.spacing20 : styleVals.measurements.spacing24};
    `}
    ${props => props.withImageAndCustomization && css`
        padding-bottom: ${props.withDescription ? styleVals.measurements.spacing24 : styleVals.measurements.spacing28};
    `}
    @media ${styleVals.breakpoints.smallMobile} {
        padding: ${styleVals.measurements.spacing20} ${styleVals.measurements.spacing4} ${styleVals.measurements.spacing20};
    };
    flex-direction: ${props => props.isLanguageArab && 'row-reverse'} 
`
export const FoodType = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid ${props => props.type === 1 ? "#4f9541" : props.type === 2 ? "#A70202" : "#FF6F00"};
    width: ${styleVals.measurements.spacing14};
    height: ${styleVals.measurements.spacing14};
    margin-bottom: ${styleVals.measurements.spacing6};
`
export const FoodTypeIcon = styled.div`
    ${props => (props.type === 2 && props.isRestaurantIndian) ? css`
        border-left: ${styleVals.measurements.spacing6} solid transparent;
        border-right: ${styleVals.measurements.spacing6} solid transparent;
        border-bottom: ${styleVals.measurements.spacing6} solid #A70202;
    ` : css`
        background: ${props.type === 1 ? "#4f9541" : props.type === 3 ? "#FF6F00" : '#A70202'};
        border-radius: 50%;
        width: ${styleVals.measurements.spacing8};
        height: ${styleVals.measurements.spacing8};
    `};
`
export const LeftSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 70%;
    text-align: ${props => props.isLanguageArab && 'right'};
`
export const ItemTitle = styled.p`
    margin: 0;
    padding: 0;
    font-size: ${styleVals.measurements.spacing16};
    position: relative;
    font-weight: bold;
    margin-bottom: ${styleVals.measurements.spacing2};
`
export const ItemPrice = styled.div`
    display: inline;
    ${props => props.withDescription && css`
        margin-bottom: ${styleVals.measurements.spacing8};
    `}
    ${props => props.isLanguageArab && css`
        margin-bottom: 1px;
    `}
    
`
export const PriceText = styled.p`
    margin: 0;
    padding: 0;
    display: inline-block;
    font-size: ${styleVals.measurements.spacing14};
    font-weight: bold;
    ${props => props.strikethrough && css`
        font-weight: normal;
        color: ${styleVals.colors.greyText};
        text-decoration: line-through;
        ${props =>  props.isLanguageArab ? css`margin-left: ${styleVals.measurements.spacing6};`:css`margin-right: ${styleVals.measurements.spacing6};` }
    `};

`
export const RestoCurrency = styled.span`
    font-family: Roboto;
`
export const ItemDescription = styled.p`
    margin: 0;
    padding: 0;
    font-size: ${styleVals.measurements.spacing14};
`
export const RightSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: ${props => props.isLanguageArab ? styleVals.measurements.spacing11 : styleVals.measurements.spacing12};
`
export const AddButtonWrapper = styled.div`
    position: relative;
    ${props => props.customizableWithImage && css`
        margin-bottom: ${styleVals.measurements.spacing12};
    `}
    ${props => props.withImage && css`
        margin-bottom: ${styleVals.measurements.spacing4};
    `}
`


export const ImageWrapper = styled.div`
    width: 120px !important;
    height: 96px !important;
    overflow: hidden;
	flex-shrink: 0;
    border-radius: 10px;
`
export const ItemImage = styled.img`
    max-width: 100%;
    height: 100%;
    width: 100%;
`
export const ItemAddButton = styled.button`
    flex-shrink: 0;
    padding: 0 ${styleVals.measurements.spacing6};
    min-width: ${props => props.buttonSize === 'xl' ? styleVals.measurements.spacing164 : props.buttonSize === 'lg' ? styleVals.measurements.spacing144 : props.buttonSize === 'sm' ? styleVals.measurements.spacing80 : styleVals.measurements.spacing100};
    height: ${props => props.buttonSize === 'sm' ? styleVals.measurements.spacing28 : styleVals.measurements.spacing34};
    ${props => props.itemQuantity 
    ? props.invertColors 
      ? css`
        color: ${props => props.restaurantColor};
        background: white;
        border: 1px solid ${props => props.restaurantColor};
      ` 
      : css`
        background: ${props => props.restaurantColor};
        color: ${props => props.isRestaurantColorDark ? styleVals.colors.white : styleVals.colors.primaryText};
        border: none;
      ` 
    : props.invertColors 
        ? css`
            background: ${props => props.restaurantColor};
            color: ${props => props.isRestaurantColorDark ? styleVals.colors.white : styleVals.colors.primaryText};
            border: none;
        `
        : css`
            color: ${props => props.restaurantColor};
            background: white;
            border: 1px solid ${props => props.restaurantColor};
    `}
    outline: none;
    overflow: hidden;
    border-radius: ${styleVals.measurements.spacing10};
    box-shadow: ${styleVals.colors.boxShadow};
    ${props => props.popupButtons && css`
        margin-top: ${styleVals.measurements.spacing4};
    `}
    ${props => props.withMargin && (props.withImage ? css`
        position: absolute;
        bottom: -${styleVals.measurements.spacing16};
        left: ${styleVals.measurements.spacing11};
    ` : css`
        margin-right: ${styleVals.measurements.spacing12};
        margin-left: ${styleVals.measurements.spacing20};
    `)}
`
ItemAddButton.Content = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
`
export const ItemFirstAddButton = styled.span`
    position: absolute;
    font-weight: bold;
    display: block;
    text-transform: uppercase;
    top: ${props => props.itemQuantity ? '-100%' : '3%'};
    transition: all 0.2s linear;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`
export const ItemCountButton = styled.div`
    position: absolute;
    font-weight: bold;
    display: block;
    width: 100%;
    height: 100%;
    top: ${props => props.itemQuantity ? '3%' : '100%'};
    transition: all 0.2s linear;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`
ItemCountButton.Minus = styled.span`
    font-size: ${styleVals.measurements.spacing22};
    width: 100%;
    text-align: left;
    padding: 0 !important;
    text-align: ${props => props.isLanguageArab && 'right'}
`
ItemCountButton.Count = styled.span`
    position: absolute;
    left: 25%;
    font-size: ${styleVals.measurements.spacing18};
    width: 50%;
    transform: translateY(${props => props.inactive ? '-120%' : props.ready ? '120%' : '0%'});
    opacity: ${props => ((props.ready && props.increasing) || (props.decreasing && props.inactive) || props.hideNumber) && 0};
    transition: 0.2s transform;
`
ItemCountButton.Plus = styled.span`
    font-size: ${styleVals.measurements.spacing22};
    width: 100%;
    text-align: right;
    padding: 0 !important;
    text-align: ${props => props.isLanguageArab && 'left'}
`
export const CustomizableText = styled.span`
    margin: 0;
    padding: 0;
    font-size: ${styleVals.measurements.spacing12};
    color: ${styleVals.colors.greyText};
    text-align: center;
    position: absolute;
    ${props => props.withImage ? css`
        bottom: -${styleVals.measurements.spacing34};
        left: ${styleVals.measurements.spacing20};
    ` : css`
        bottom: -${styleVals.measurements.spacing14};
        left: ${styleVals.measurements.spacing29};
    `}
`
export const CouponsNotApplicableText = styled.span`
    margin: 0;
    padding: 0;
    color: ${styleVals.colors.greyText};
    font-size: ${styleVals.measurements.spacing12};
    font-weight: normal;
    ${props => props.withPrice ? css`
        margin-left: ${styleVals.measurements.spacing4};
    ` :  css`
        margin-bottom: ${styleVals.measurements.spacing10};
    `}
    width: fit-content;
    margin-right: ${props => props.isLanguageArab && '4px'}
`
