import styled from 'styled-components'
import { Spinner as Loader } from "react-bootstrap";
import styleVals from './../../../constants/styles';

export const Spinner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-content: center;
  background: rgba(255, 255, 255, ${props => props.opacity || '1'});
  z-index: 9;
`;


export const MainLoader = styled(Loader)`
   color: ${props => props.color ? props.color : "#e55353"};
`;
export const MainLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
`;
export const MainLoaderMessage = styled.div` 
   color: ${props => props.color ? props.color : "#e55353"};
   margin: auto ${styleVals.measurements.spacing10};
`;

export const MoneyGifContainer = styled.div`
  display: flex;
  flex-direction : column;
  text-align:center;
  opacity:1;
`;
export const CongratsMessage = styled.span`
  margin: 0 auto;
`;
export const MoneyGifMessage = styled.span`
  width: 80%;
  margin: ${styleVals.measurements.spacing10} auto;
`;
export const RandomMsgDiv = styled.div`
  color: ${props => props.restaurantColor ? props.restaurantColor : "#e55353"};
  padding-bottom: ${styleVals.measurements.spacing20};
  width: 65%;
  margin: 0 auto; 
  opacity: 0.8;
  font-size: ${styleVals.measurements.spacing14};
`;

export const RandomMessageSection = styled.span`
   margin: ${styleVals.measurements.spacing10} auto;
   color: '#e55353';
`;


