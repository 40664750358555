import styled, { css } from "styled-components";
import styleVals from "../../constants/styles";

export const MenuHeader = styled.header`
  position: relative;
  background: ${styleVals.colors.white};
  box-shadow: ${styleVals.measurements.spacing0} ${styleVals.measurements.spacing4} ${styleVals.measurements.spacing4} rgba(0, 0, 0, 0.06);
  min-width: 0;
  padding: ${styleVals.measurements.spacing0} ${styleVals.measurements.spacing16};
  ${props => props.hideHeader ? css`
    max-height: ${styleVals.measurements.spacing0};
    transition: max-height 0.4s linear;
    overflow: hidden;
  ` : css`
    max-height: ${styleVals.measurements.spacing280};
    transition: max-height 0.7s linear;
  `}
`;

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
  padding: ${styleVals.measurements.spacing16} ${styleVals.measurements.spacing0};
  ${props => props.hideHeader ? css`
    max-height: ${styleVals.measurements.spacing0};
    transition: max-height 0.4s linear;
    overflow: hidden;
  ` : css`
    max-height: ${styleVals.measurements.spacing280};
    transition: max-height 0.7s linear;
  `}
`

export const RestaurantLogo = styled.img`
  display: block;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: ${styleVals.measurements.spacing10};
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  width: ${styleVals.measurements.spacing68} !important;
  height: ${styleVals.measurements.spacing68} !important;

  ${props => props.isLanguageArab ? css`
  margin-left: ${styleVals.measurements.spacing12};
    ` : css`
    margin-right: ${styleVals.measurements.spacing12};
    `}
  @media ${styleVals.breakpoints.smallMobile} {
    width: ${styleVals.measurements.spacing48} !important;
    height: ${styleVals.measurements.spacing48} !important;
  }
`
export const NameWithMetaData = styled.div`
  display: flex;
  width: 100%;
  min-width: 0;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  #restaurant-name {
    margin: 0;
    padding: 0;
    font-weight: bold;
    font-size: ${props => props.calculatedRestoFontSize.modernMobile};
    @media ${styleVals.breakpoints.smallMobile} {
      font-size: ${props => props.calculatedRestoFontSize.smallMobile};
    }
    @media (max-width: 319px) {
      font-size: ${props => props.calculatedRestoFontSize.narrowMobile};
    }
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: ${props => props.isLanguageArab && 'right'};
  }
  #ordertype-meta-data {
    margin: 0;
    padding: 0;
    margin-top: ${styleVals.measurements.spacing4};
    color: ${styleVals.colors.greyText};
    font-size: ${styleVals.measurements.spacing16};
    @media ${styleVals.breakpoints.smallMobile} {
      font-size: ${styleVals.measurements.spacing14};
    }
    text-align: ${props => props.isLanguageArab && 'right'};
  }
`

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  svg {
    ${props => props.isLanguageArab ? css`
      margin-right: ${styleVals.measurements.spacing8};
    ` : css`
      margin-left: ${styleVals.measurements.spacing8};
    `}
  }
  #open-gip-button {
    svg {
      ${props => props.isLanguageArab && css`
        margin-right: ${styleVals.measurements.spacing16};
      `
    }
  }

`
export const AddressAndEstimationDetails = styled.div`
  box-shadow: ${styleVals.measurements.spacing0} ${styleVals.measurements.spacing4} ${styleVals.measurements.spacing4} rgba(0, 0, 0, 0.06);
  background: ${styleVals.colors.white};
  overflow: hidden;
  ${props => props.hideHeader ? css`
    max-height:${styleVals.measurements.spacing0};
    transition: max-height 0.4s linear;
    overflow: hidden;
  ` : css`
    max-height: ${styleVals.measurements.spacing280};
    transition: max-height 0.7s linear;
  `}
  background: ${styleVals.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 0;
  font-size: ${styleVals.measurements.spacing14};
  padding: ${styleVals.measurements.spacing0} ${styleVals.measurements.spacing16};
`
export const LogoAndDetails = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  min-width: 0;
  align-items: center;
  ${props => props.firstSection && css`
    padding-top: ${styleVals.measurements.spacing18};
    padding-bottom: ${styleVals.measurements.spacing14};
    border-bottom: 1px solid ${styleVals.colors.borderColor};
  `}
  ${props => props.lastSection && css`
    padding-top: ${styleVals.measurements.spacing14};
    padding-bottom: ${styleVals.measurements.spacing14};
  `}
  svg {
    flex-shrink: 0;
    display: inline-block;
    ${props => props.isLanguageArab ? css`
  margin-left: ${styleVals.measurements.spacing6};
    ` : css`
    margin-right: ${styleVals.measurements.spacing6};
    `}
  }
`
export const DetailsWrapper = styled.div`
  display: flex;
  ${props => props.alignRight && css`
    flex-direction: row-reverse;
  `}
  justify-content: space-between;
  width: 100%;
  min-width: 0;
  p {
    line-height: ${styleVals.measurements.spacing18};
  }
  #location-text {
    margin: 0;
    width: 100%;
    ${props => props.isLanguageArab ? css`
    margin-left: ${styleVals.measurements.spacing12};
  `: css`
    margin-right: ${styleVals.measurements.spacing12};
  `}

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  #estimation-text {
    margin: 0;
    margin-right: ${styleVals.measurements.spacing12};
  }
  #distance-text {
    margin: 0;
    margin-right: ${styleVals.measurements.spacing12};
  }
  #order-type-button {
    margin: 0;
    padding: 0;
    text-align: right;
  }
  #fetching-location {
    margin: 0;
    padding: 0;
  }
  #set-location {
    margin: 0;
    padding: 0;
    color: ${styleVals.colors.red};
    font-size: ${styleVals.measurements.spacing16};
    text-align: left;
  }
  #set-location-takeaway {
    margin: 0;
    padding: 0;
    font-size: ${styleVals.measurements.spacing16};
    text-align: left;
  }
  #location-change-button {
    margin: 0;
    padding: 0;
  }
`

export const Search = styled.div`
  padding: ${styleVals.measurements.spacing20} ${styleVals.measurements.spacing16} 0;
  position: relative;
  display: flex;
  align-items: center;
  [id='search-input-wrapper'] {
    ${props => props.isLanguageArab ? css`
    margin-left: ${styleVals.measurements.spacing14}` : css`
    margin-right: ${styleVals.measurements.spacing14}
    `};
  }
  > button {
    background: ${styleVals.colors.white};
    border: 1px solid #c4c4c4;
    border-radius: ${styleVals.measurements.spacing16};
    width: ${styleVals.measurements.spacing44};
    height: ${styleVals.measurements.spacing40};
    margin-left: ${styleVals.measurements.spacing20};
    min-width: ${styleVals.measurements.spacing44};
  }
`;

Search.Div = styled.div`
  width: 100%;
  position: relative;
  button {
    border: 0;
    padding: 0;
    position: absolute;
    left: ${styleVals.measurements.spacing10};
    top: 11px;
    background: ${styleVals.colors.white};
  }
  input {
    background: ${styleVals.colors.white};
    outline: none;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: ${styleVals.measurements.spacing10};
    width: 100%;
    height: ${styleVals.measurements.spacing40};
    padding: 11px 15px 11px 34px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: ${styleVals.measurements.spacing16};
    line-height: ${styleVals.measurements.spacing18};
    color: rgba(0, 0, 0, 0.62);
  }
`;

export const ChatbotIconWrapper = styled.button`
  position: relative;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  [id='notification-indicator'] {
    width: ${styleVals.measurements.spacing12};
    height: ${styleVals.measurements.spacing12};
    background: red;
    position: absolute;
    right: ${props => props.isLanguageArab ? styleVals.measurements.spacing16 : 0};
    top: -${styleVals.measurements.spacing2};
    border-radius: 50%;
  }
  [id='notification-indicator-blink'] {
    width: ${styleVals.measurements.spacing12};
    height: ${styleVals.measurements.spacing12};
    background: red;
    position: absolute;
    right: ${props => props.isLanguageArab ? styleVals.measurements.spacing16 : 0};
    top: -${styleVals.measurements.spacing2};
    border-radius: 50%;
    animation: blink 2s steps(5, start) infinite;
    -webkit-animation: blink 1s steps(5, start) infinite;
    @keyframes blink {
      to {
        visibility: hidden;
      }
    }
    @-webkit-keyframes blink {
      to {
        visibility: hidden;
      }
    }
  }
`
export const LogoAndCloseIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${styleVals.measurements.spacing60};
  padding: ${styleVals.measurements.spacing12} ${styleVals.measurements.spacing6};
  ${props => props.isGIPOpen && css`
    background: white;
    box-shadow: ${styleVals.measurements.spacing0} ${styleVals.measurements.spacing4} ${styleVals.measurements.spacing4} rgba(0, 0, 0, 0.06);
  `}
  img {
    width: ${styleVals.measurements.spacing48} !important;
    height: ${styleVals.measurements.spacing48} !important;
  }
  [id='close-gip-button'] {
    border: ${styleVals.measurements.spacing2} solid #d8d8d8;
    width: ${styleVals.measurements.spacing40} !important;
    height: ${styleVals.measurements.spacing40} !important;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    svg {
      flex-shrink: 0;
    }
  }
`
export const OrderTypeRadioWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: ${styleVals.measurements.spacing16};
  padding-top: ${styleVals.measurements.spacing12};
  padding-bottom: ${styleVals.measurements.spacing12};
  padding-left: ${styleVals.measurements.spacing4};
  #delivery-selection-button-label {
    margin: 0;
    flex-shrink: 0;
    font-weight: bold;
    ${props => props.isLanguageArab ? css`
    padding: ${styleVals.measurements.spacing0} ${styleVals.measurements.spacing0} ${styleVals.measurements.spacing0} ${styleVals.measurements.spacing8};
    ` : css`
    padding: ${styleVals.measurements.spacing0} ${styleVals.measurements.spacing8} ${styleVals.measurements.spacing0} ${styleVals.measurements.spacing0};
    `}
  }
  #takeaway-selection-button-label {
    margin: 0;
    flex-shrink: 0;
    font-weight: bold;
    ${props => props.isLanguageArab ? css`
    padding: ${styleVals.measurements.spacing0} ${styleVals.measurements.spacing8} ${styleVals.measurements.spacing0} ${styleVals.measurements.spacing0};
    ` : css`
    padding: ${styleVals.measurements.spacing0} ${styleVals.measurements.spacing0} ${styleVals.measurements.spacing0} ${styleVals.measurements.spacing8};
    `}
  }
`;

export const PureVegWrapper = styled.div`
   display: flex;
   margin-top: ${styleVals.measurements.spacing6};
   img[id='pure-veg-image'] {
      width: ${styleVals.measurements.spacing15};
      height: ${styleVals.measurements.spacing15};
      ${props => props.isLanguageArab ? css`margin-left: ${styleVals.measurements.spacing6};` : css` margin-right: ${styleVals.measurements.spacing6};`}
   };
   span[id='pure-veg-text'] {
     font-size: ${styleVals.measurements.spacing12};
     color: #000000;
   }
`;
