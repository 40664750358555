import styled, { css } from 'styled-components'
import styleVals from '../../constants/styles'

export const SlideUpPopupContainer = styled.div`
    position: fixed;
    width: 100%;
    max-width: ${styleVals.measurements.spacing500};
    min-height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    bottom: 0;
    overflow: hidden;
`
export const SlideUpPopupBody = styled.div`
    position: relative;
    width: 100%;
    background: #f3f3f3;
    border-top-left-radius: ${styleVals.measurements.spacing20};
    border-top-right-radius: ${styleVals.measurements.spacing20};
    max-height: 85vh;
    height: 85vh;
    bottom: -80%;
    transition: 1s;
`
export const SlideUpPopupHeader = styled.div`
    padding: ${styleVals.measurements.spacing10} ${styleVals.measurements.spacing10};
    padding-left: ${styleVals.measurements.spacing20};
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #9a9a9a;
`
export const SlideUpPopupHeading = styled.span`
    font-weight: 600;
    font-size: ${styleVals.measurements.spacing18};
    width: 85%;

    ${props => props.isLanguageArab && css`
        text-align: right;
        padding-right: 20px;
    `};

`
export const ClosePopupButton = styled.div`
    display: flex;
    height: 100%;
    justify-content: space-around;
    align-items: center;

    > div {
        width: ${styleVals.measurements.spacing30};
        height: ${styleVals.measurements.spacing30};
        font-size: ${styleVals.measurements.spacing22};
        font-weight: 600;
        border: 1px solid #000000;
        cursor: pointer;
        background-color: #ffffff;
        border-radius: ${styleVals.measurements.spacing16};
        text-align: center;
        line-height: 1.4;
        position: absolute;
        top: -${styleVals.measurements.spacing16};
        ${props => props.isLanguageArab ? css`
        left: ${styleVals.measurements.spacing16};
        `: css`
        right: ${styleVals.measurements.spacing16};
        `}
    }
`
export const SlideUpPopupSection = styled.div`
    width: 100%;
    height: 100%;
    max-width: ${styleVals.measurements.spacing500};
    position: absolute;
    ${props => props.footerButtons && css`
        display: flex;
        flex-direction: ${props.reverse ? 'row-reverse' : 'row'};
        justify-content: space-between;
        align-items: center;
        position: fixed;
        overflow: hidden;
        left: 1;
        bottom: 0;
        height: ${styleVals.measurements.spacing52};
        padding: ${styleVals.measurements.spacing0} ${styleVals.measurements.spacing12};
        background: #e7e7e7;
    `}
    transform: ${props => {
        if(props.divIndex < props.activeIndex) {
            return 'translateX(-100%)'
        } else if(props.divIndex > props.activeIndex) {
            return 'translateX(100%)'
        } else {
            return 'translateX(0%)'
        }
    }};
    @media (min-width: 501px) {
        opacity: ${props => {
            if(props.divIndex < props.activeIndex) {
                return '0'
            } else if(props.divIndex > props.activeIndex) {
                return '0'
            } else {
                return '1'
            }
        }};
    }
    transition: all 0.2s ease-in-out;
`