const {
  OfferTypes,
  OrderType,
  OrderTypeLabel,
  MENUID_DOMAIN,
} = require("../constants/variables");
const { default: i18n } = require("../translations/i18n");


//lodash exports
exports.get = require('lodash/get')
exports.debounce = require('lodash/debounce')
exports.matchesProperty = require('lodash/matchesProperty')
exports.find = require('lodash/find')
exports.range = require('lodash/range')
exports.set = require('lodash/set')
exports.isEqual = require('lodash/isEqual')
exports.reduce = require('lodash/reduce')
exports.cloneDeep = require('lodash/cloneDeep')

exports.pushHistory = (
  history,
  routeWithMenuId,
  routeWithoutMenuId,
  menuId,
  searchParam,
  state
) => {
  if (menuId === MENUID_DOMAIN) {
    history.push({
      pathname: routeWithoutMenuId,
      search: searchParam ? searchParam : "",
      state,
    });
  } else {
    history.push({
      pathname: routeWithMenuId.replace(":productId", menuId),
      search: searchParam ? searchParam : "",
      state,
    });
  }
};

exports.validateResponse = (response) => {
  if (response.status === 204) {
    return;
  }
  if (!response.ok) {
    throw response;
  }
  return response.json();
};
exports.validAlphanumericString = (value) => {
  if (!value) {
    return true;
  } else {
    var regex = /^[0-9a-zA-Z]+$/;
    if (value.match(regex)) {
      return true;
    } else {
      return false;
    }
  }
};
exports.offersMessage = (
  discount_type,
  max_discount,
  min_order_value,
  code,
  discount_value,
  custom,
  restaurantCurrency
) => {
  if (discount_type && code && !custom) {
    let description = "";
    switch (discount_type) {
      case OfferTypes.FLAT:
        if (discount_value) {
          description = `Use code ${code} and get ${restaurantCurrency}${discount_value} off ${min_order_value !== "" && min_order_value >= 0 ? `on all the orders above ${restaurantCurrency}${min_order_value}` : ``} `;
        }
        break;
      case OfferTypes.PERCENT:
        if (discount_value) {
          if (max_discount) {
            description = `Use code ${code} and get ${discount_value}% off ${min_order_value !== "" && min_order_value >= 0 ? `on all the orders above ${restaurantCurrency}${min_order_value}` : ``} upto ${restaurantCurrency}${max_discount} `;
          } else {
            description = `Use code ${code} and get ${discount_value}% off ${min_order_value >= 0 ? `on all the orders above ${restaurantCurrency}${min_order_value}` : ``}`;
          }
        }
        break;
      case OfferTypes.FREEDELIVERY:
        if (max_discount) {
          description = `Use code ${code} and get free delivery ${min_order_value !== "" && min_order_value >= 0 ? `on all the orders above ${restaurantCurrency}${min_order_value}` : ``} upto ${restaurantCurrency}${max_discount} `;
        }
        else {
          description = `Use code ${code} and get free delivery ${min_order_value !== "" && min_order_value >= 0 ? `on all the orders above ${restaurantCurrency}${min_order_value}` : ``}`;
        }
        break;
      default:
        description = i18n.t('UseCodeGetOffer');
        break;
    }
    return description;
  }
};

exports.getFormattedDate = (value) => {
  var today = new Date(value);
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  today = yyyy + "-" + mm + "-" + dd;
  return today;
};

exports.getDDMMYYYY = (value) => {
  var today = new Date(value);
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  today = dd + "-" + mm + "-" + yyyy;
  return today;
};

exports.getFulFillmentLabel = (mode) => {
  var fulfillment_mode = "";
  switch (mode) {
    case OrderType.DINEIN:
      fulfillment_mode = OrderTypeLabel.DINEIN;
      break;
    case OrderType.DELIVERY:
      fulfillment_mode = OrderTypeLabel.DELIVERY;
      break;
    case OrderType.TAKEAWAY:
      fulfillment_mode = OrderTypeLabel.TAKEAWAY;
      break;
    case OrderType.TABLERES:
      fulfillment_mode = OrderTypeLabel.TABLERES;
      break;
    case OrderType.INROOMDINEIN:
      fulfillment_mode = OrderTypeLabel.INROOMDINEIN;
      break;
    case OrderType.QDINEIN:
      fulfillment_mode = OrderTypeLabel.QDINEIN;
      break;
    default:
      fulfillment_mode = "";
  }
  return fulfillment_mode;
};

exports.getTime = (millisec) => {
  var date = new Date(millisec);
  return date.toLocaleTimeString();
};

exports.getCookie = (cookieString, name) => {
  // Split cookie string and get all individual name=value pairs in an array
  var cookieArr = cookieString.split(";");

  // Loop through the array elements
  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split("=");

    /* Removing whitespace at the beginning of the cookie name
    and compare it with the given string */
    if (name === cookiePair[0].trim()) {
      // Decode the cookie value and return
      return decodeURIComponent(cookiePair[1].replace(/['"]+/g, ""));
    }
  }

  // Return null if not found
  return null;
};

exports.onlyNumbers = (event) => {
  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
  }
};

exports.roundOffToTwoPlaces = (number) => Math.round(number * 100) / 100;
exports.onlyAlphaNumeric = (event) => {
  if (!/^[0-9a-zA-Z]+$/.test(event.key)) {
    event.preventDefault();
  }
};

exports.roundOffToTwoPlaces = (number) => Math.round(number * 100) / 100;

exports.trimmedValue = (val) => {
  if (val) {
    return val.trim();
  }
  return "";
};

exports.darkenColor = (col, gradientDegree = 40) => {

  col = col.trim()

  let usePound = false;
  if (col[0] === "#") {
      col = col.slice(1);
      usePound = true;
  }
  const num = parseInt(col, 16);
  let r = (num >> 16) + gradientDegree;
  if (r > 255) r = 255;
  else if (r < 0) r = 0;
  let b = ((num >> 8) & 0x00FF) + gradientDegree;
  if (b > 255) b = 255;
  else if (b < 0) b = 0;
  let g = (num & 0x0000FF) + gradientDegree;
  if (g > 255) g = 255;
  else if (g < 0) g = 0;
  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
}

exports.focusOnField = (id, isFocused = true) => {
  const field = document.getElementById(id)
  if (field) {
      field.scrollIntoView({ behavior: 'instant', block: 'center' })
      isFocused && field.focus()
  }
}

exports.trimmedValue = (val) =>{
  if(val){
      return val.trim()
  }
  return ''
}

exports.getDistance = function(user_coordinates, restaurant_coordinates) {

  const R = 6371e3; // metres

  const user_lat_rad = user_coordinates.lat * Math.PI/180; // in radians
  const rest_lat_rad = restaurant_coordinates.lat * Math.PI/180;
  const lat_diff_rad = (restaurant_coordinates.lat - user_coordinates.lat) * Math.PI/180;
  const long_diff_rad = (restaurant_coordinates.long - user_coordinates.long) * Math.PI/180;

  // Haversine formula
  const a = Math.sin(lat_diff_rad/2) * Math.sin(lat_diff_rad/2) +
          Math.cos(user_lat_rad) * Math.cos(rest_lat_rad) *
          Math.sin(long_diff_rad/2) * Math.sin(long_diff_rad/2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

  return (R * c)/1000; // in metres
}

exports.checkIsColorDark = (color) => {
  let r, g, b
  if (color.match(/^rgb/)) {
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
    r = color[1];
    g = color[2];
    b = color[3];
  }
  else {
    color = +("0x" + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));
    r = color >> 16;
    g = color >> 8 & 255;
    b = color & 255;
  }
  const hsp = Math.sqrt(
    0.299 * (r * r) +
    0.587 * (g * g) +
    0.114 * (b * b)
  );
  return hsp <= 160
}

exports.isWindowOnFocus = () => {
  // Standard browsers:
  var hidden = "hidden",
    visibilityChange = "visibilitychange";
  if (typeof document?.hidden !== "undefined") {
    hidden = "hidden";
    visibilityChange = "visibilitychange";
  } else if (typeof document?.mozHidden !== "undefined") {
    hidden = "mozHidden";
    visibilityChange = "mozvisibilitychange";
  } else if (typeof document?.msHidden !== "undefined") {
    hidden = "msHidden";
    visibilityChange = "msvisibilitychange";
  } else if (typeof document?.webkitHidden !== "undefined") {
    hidden = "webkitHidden";
    visibilityChange = "webkitvisibilitychange";
  } else if ("onfocusin" in document)
    document.onfocusin = document.onfocusout = onchange;
  //IOS devices and others
  else
    window.onpageshow =
      window.onpagehide =
      window.onfocus =
      window.onblur =
        onchange;
  var isInFocus = false;
  var onchange = function (evt) {
    var v = "visible",
      h = "hidden",
      evtMap = {
        focus: v,
        focusin: v,
        pageshow: v,
        blur: h,
        focusout: h,
        pagehide: h,
      };
    evt = evt || window.event;
    if (evt.type in evtMap)
      h === evtMap[evt.type] ? (isInFocus = false) : (isInFocus = true);
    document.removeEventListener(visibilityChange, onchange, false);
  };
  document.addEventListener(visibilityChange, onchange, false);
  // set the initial state (but only if browser supports the Page Visibility API)
  if (document[hidden] !== undefined)
    onchange({ type: document[hidden] ? "blur" : "focus" });
  return isInFocus;
};

exports.detectOS = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows";
  }
  if (/android/i.test(userAgent)) {
    return "Android";
  }
  // iOS detection
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }
  return "unknown";
};

exports.getKeyValue = (obj, objPath, defaultValue) => {
  const resolvedValue = exports.get(obj, objPath);
  if (!!resolvedValue) {
    return resolvedValue;
  }
  else {
    return defaultValue || resolvedValue;
  }
};

exports.setEnterListener = (input) => {
  const _addEventListener = input.addEventListener;

  const addEventListenerWrapper = (type, listener) => {
    if (type === 'keydown') {
      const _listener = listener
      listener = (event) => {
        const suggestionSelected = document.getElementsByClassName('pac-item-selected').length > 0;

        if (event.key === 'Enter' && !suggestionSelected) {
          const simulate_enter = new KeyboardEvent('keydown', {
            key: 'ArrowDown',
            code: 'ArrowDown',
            keyCode: 40,
          })
          _listener.apply(input, [simulate_enter]);
        }

        if (event.key === 'ArrowDown' && !suggestionSelected) {
          const simulate_down = new KeyboardEvent('keydown', {
            key: 'ArrowDown',
            code: 'ArrowDown',
            keyCode: 40,
          })
          _listener.apply(input, [simulate_down])
        }
        _listener.apply(input, [event])
      }
    }
    _addEventListener.apply(input, [type, listener])
  }
  input.addEventListener = addEventListenerWrapper;
}
