import styled, { css } from 'styled-components'
import styleVals from '../../constants/styles'

export const AccordingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`
export const AccordionHeader = styled.div`
    ${props => props.isBillDetail ?  
         css`
        display: inline-block;
        min-height: auto; 
        padding: 6px 0;
        font-weight: 600;
        position: relative;
        color:#3F3F3F;
        div[id='total-tax']{
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            padding-left: 20px;
        }`
        : css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding : 8px 20px;
        min-height: ${styleVals.measurements.spacing50};
        `};

    color: ${props => props.disabled && styleVals.colors.greyText};
    ${props => props.increaseHeadingFont && css`
        font-size: ${styleVals.measurements.spacing18};
        @media ${styleVals.breakpoints.smallMobile} {
            font-size: ${styleVals.measurements.spacing14};
        }
    `}
    @media ${styleVals.breakpoints.smallMobile} {
        padding: ${props => !props.isBillDetail && `${styleVals.measurements.spacing8} ${styleVals.measurements.spacing10}`}
    }
    ${props => props.headingBackground && css`
        background: ${props.headingBackground};
    `}
    ${props => props.lessSidePadding && css`
        padding: ${styleVals.measurements.spacing8} ${styleVals.measurements.spacing8};
    `}
    ${props => props.headingBorder && css`
        border-top: ${props.firstChild && `1px solid ${props.headingBorder}`};
        border-bottom: 1px solid ${props.headingBorder};
    `}
`
export const AccordionHeading = styled.span`
    display: inline-block;
    max-width: ${props => props.actionLabel ? '70%' : '100%'};
    @media ${styleVals.breakpoints.smallMobile} {
        max-width: ${props => props.actionLabel ? '50%' : '100%'};
    }
    @media ${styleVals.breakpoints.narrowMobile} {
        max-width: ${props => props.actionLabel ? '45%' : '100%'};
    }
`
export const AccordionActionWrapper = styled.div`
    display: inline-flex;
    align-items: center;
    ${props => props.actionLabel && css`
        span:first-child {
            padding: 0;
            margin: 0;
            display: inline-block;
            line-height: 1px;
        }
    `}
`
export const AccordionContent = styled.div`
    ${props => props.isAccordionOpen ? css`
        max-height: ${`${props.expandedHeight}px`};
        overflow: hidden;
    ` : css`
        max-height: 0px;
        overflow: hidden;
    `}
    display: flex;
    flex-direction: column;
    transition: all 0.2s ease;
`