const getUIOptions = (opArr, options, catalogue) => {
    let optionsArr = [];
    for(let k in opArr) {
        let key = opArr[k];
        let validOp = {...options[key]};
        validOp.ref_id = key;
        if(catalogue && options[key].add_on_groups){
            options[key].add_on_groups.forEach(addon => {
                validOp.add_on_groups= getUIAddonGroups(options[key].add_on_groups, catalogue);

            })
        }
        optionsArr.push(validOp);
    }
    return optionsArr;
}

const getUIVariantGroups = (vgArr, catalogue) => {
    let variantGroups = [];
    for (let k in vgArr) {
        let key = vgArr[k];
        let validVG = {...catalogue.variant_groups[key]};
        validVG.ref_id = key;
        validVG.variants = getUIOptions(catalogue.variant_groups[key].variants,catalogue.variants, catalogue);
        if(validVG)
        variantGroups.push(validVG);
    }
    if (variantGroups.length > 0) {
        return variantGroups;
    } else {
        return null;
    }
}

const getUIAddonGroups = (agArr, catalogue) => {
    let addonGroups = [];
    for (let k in agArr) {
        let key = agArr[k];
        let validAG = {...catalogue.add_on_groups[key]};
        validAG.ref_id = key;
        validAG.addons = getUIOptions(catalogue.add_on_groups[key].addons,catalogue.addons);
        addonGroups.push(validAG);
    }
    if (addonGroups.length >0) {
        return addonGroups;
    } else {
        return null;
    }
}


const getUIItems = (itemArr, catalogue) => {
    let items = [];
    for(let k in itemArr) {
        let key = itemArr[k];
        let validItem = {...catalogue.items[key]};
        validItem.ref_id = key;
        validItem.variant_groups = getUIVariantGroups(catalogue.items[key].variant_groups,catalogue);
        validItem.add_on_groups = getUIAddonGroups(catalogue.items[key].add_on_groups,catalogue);
        if (!validItem.bill_components) {
            validItem.bill_components = {
                charges:[],
                taxes:[]
            }
        }
        items.push(validItem);
    }
    return items;
}

const getUISubcategory = (subCatObj, catalogue) => {
    let subCatArr = [];
    for(let key in subCatObj) {
        let validSubcat = {...subCatObj[key]};
        validSubcat.ref_id = key;
        validSubcat.items = getUIItems(subCatObj[key].items,catalogue);
        subCatArr.push(validSubcat);
    }
    subCatArr.sort(sortFun);
    return subCatArr;
}

function sortFun(a,b) {
    return a.sort_order > b.sort_order ? 1 : -1;
}

const getUICategories = (catalogue)=> {
    let catArr = [];
    for (let key in catalogue.categories) {
        let validCat = {...catalogue.categories[key]};
        const items = getUIItems(catalogue.categories[key].items, catalogue);
        const subcategories = getUISubcategory(catalogue.categories[key].subcategories, catalogue);

        validCat.items = [...items, ...subcategories];
        validCat.items.sort(sortFun);
        validCat.ref_id = key;
        catArr.push(validCat);
    }
    catArr.sort(sortFun);
    return catArr;
}

const getBillComponentArr = (billComps) => {
    let validArr = [];
    for (let key in billComps) {
        let validComp = {...billComps[key]};
        validComp.ref_id = key;
        validArr.push(validComp);
    }
    return validArr;
}

const getUIBillComponents = (bill_components) => {
    let validBillComponents = {
        charges:[],
        taxes: [],
    }
    if (bill_components) {
        validBillComponents.charges = getBillComponentArr(bill_components.charges);
        validBillComponents.taxes = getBillComponentArr(bill_components.taxes);
    }
    return validBillComponents;
}

const convertMenuToUIFormat = (catalogue) => {
    const data = { ...catalogue };
    data.menu = getUICategories(catalogue);
    data.bill_components = getUIBillComponents(catalogue.bill_components);
    return data;
}

const convertItems = (catelogue) => {
    const data = getUIItems(Object.keys(catelogue.items),catelogue);
    return data;
}

export {
    convertMenuToUIFormat,
    convertItems
}