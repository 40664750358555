import styled, { css } from "styled-components";
import styleVals from '../../../constants/styles'
import { Card, CardText, Loader, Row ,Input, CardFooter} from "../../BasicComponents";

export const MannualOfferCode = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem;
    cursor: pointer;  
`;

export const RowPadding = styled(Row)`
    padding-bottom: ${styleVals.measurements.spacing100};
    height: 65vh;
    overflow: auto;
`;
export const Spinner = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: ${styleVals.measurements.spacing50};
`;
export const ReactSpinner = styled(Loader)`
  color: ${props => props.restaurantColor};
`;

export const NoOfferAvailable = styled.div`
  padding: ${styleVals.measurements.spacing10};
    margin: ${styleVals.measurements.spacing10};
    background: white;
    cursor: default;
    font-weight: bold;
    color:red;
`;

export const OfferCard = styled(Card)`
    margin: ${styleVals.measurements.spacing12} 0;
   border-radius: ${styleVals.measurements.spacing10};
    overflow: hidden;
   >div {
      flex: 1 1 auto;
      min-height: 1px;
      padding: 0.75rem;
    }
    ${props => props.isLanguageArab && css`
      .card-text {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    `}
`;
export const OfferListFooter = styled(CardFooter)`
      border: none;
      border-radius: ${styleVals.measurements.spacing10};
      padding: 0 0.75rem !important;
`;
export const CouponCode = styled.span`
    border-radius: ${styleVals.measurements.spacing5};
    padding: ${styleVals.measurements.spacing5} ${styleVals.measurements.spacing10};
    ${props => props.restaurantColor ? css`
    border: ${props.restaurantColor} solid ${styleVals.measurements.spacing2}; 
    color:${props.restaurantColor};` : css`
    border: '#ab0012';
    color: '#ab0012';
  `}
`;

export const CouponDesc = styled(CardText)`
    font-size: ${styleVals.measurements.spacing12};
    font-style: italic;
    display: flex;
    align-items: center;
    margin-bottom: ${styleVals.measurements.spacing10};
    padding-top: ${styleVals.measurements.spacing10};
    ${props => props.isLanguageArab && css`
      text-align: right;
    `}
`;

export const PaymentText = styled.span`
    padding-right: 3px;
    font-size: ${styleVals.measurements.spacing14};
    color: green;
    display: inline-block;
    width: 100%;
    ${props => props.isLanguageArab && css`
      text-align: right;
    `}
`;


export const ApplyButtonBlock = styled.div`
  position: relative;
  text-align: center;
  ${props => props.isFormControlCss && (props.isLanguageArab ? css`
    margin-right: ${styleVals.measurements.spacing12};
  ` : css`
    margin-left: ${styleVals.measurements.spacing12};
  `)}
`;

ApplyButtonBlock.Span = styled.span`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: ${styleVals.measurements.spacing10};
  color: #727272 !important;
  float:right;
`;
ApplyButtonBlock.Button = styled.button`
  background-color: ${props => props.disabled ? '#878787' : (props.restaurantColor || '#ab0012')};
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${styleVals.measurements.spacing30};
  text-transform: uppercase;
  border-radius:  ${styleVals.measurements.spacing18};
  min-width: 0;
  font-size: ${styleVals.measurements.spacing13};
  font-weight: 700 !important;
  cursor: pointer;
  border: 1px solid ${props => props.restaurantColor || '#a2a2a2'};
  padding:${styleVals.measurements.spacing7};

  &:hover {
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  }
`

export const OfferInput = styled(Input)`
    border:none;
    border-bottom: 1px solid black;
    width: 75%;
    border-radius: ${styleVals.measurements.spacing0};
    font-size:  ${styleVals.measurements.spacing16};
    border: 1px solid #d8dbe0;
    border-radius: 0.25rem;
    width:85%;
    text-transform: uppercase;
`;

export const DiscountMessage = styled(CardText)`
    margin-bottom: 0;
    ${props => props.isLanguageArab && css`
      text-align: right;
    `}
`;
