import React, { useEffect, useState } from 'react';
import { debounce, get } from '../../utils/common';

import { MarkerIcon, ChatBot, SearchIcon } from './Icons/index';
import { useCallback } from 'react';
import FilterDropDown from '../FilterDropdown';
import SocialMediaDropdown from '../SocialMediaDropdown/SocialMediaDropdown';
import { OrderType } from '../../constants/variables';
import TextButton from '../TextButton';
import CallWaiter from '../../assets/icons/CallWaiter';
import RadioButton from '../RadioButton';

import styleVals from '../../constants/styles';
import PureVegIcon from "../../assets/images/pure_veg.png";

import {
  HeaderRight,
  MenuHeader,
  Search,
  ChatbotIconWrapper,
  LogoAndCloseIcon,
  HeaderContent,
  RestaurantLogo,
  NameWithMetaData,
  AddressAndEstimationDetails,
  LogoAndDetails,
  DetailsWrapper,
  OrderTypeRadioWrapper,
  PureVegWrapper
} from './styles';
import i18n from '../../translations/i18n';
import { useRestaurantsInfoContext } from '../../contexts/restaurantInfo';

const Header = ({
  logo,
  restaurantColor,
  searchValue,
  search,
  setSearchField,
  barMenu,
  veganFunc,
  eggFunc,
  dropDownTypes,
  nonVegFunc,
  foodFunc,
  barItemFunc,
  socialHandles,
  toggleGIPWindow,
  isGIPOpen,
  notifyIndicator,
  orderType,
  setOrderType,
  setGIPOpen,
  userLocationStatusForDelivery,
  userLocationStatusForTakeaway,
  userLocationToShow,
  goToLocationPage,
  defaultOutletShown,
  roomNumber,
  tableNumber,
  triggerClevertapOrderTypeSwitchEvent,
  restaurant,
  chainOriginName
}) => {

  const HeaderConstants = {
    TAKEAWAY_OPTION: i18n.t("TAKEAWAY_OPTION"),
    DELIVERY_OPTION: i18n.t("DELIVERY_OPTION"),
    FETCHING_LOCATION: i18n.t("FETCHING_LOCATION"),
    SET_LOCATION_TEXT: i18n.t("SET_LOCATION_TEXT"),
    SET_LOCATION_TEXT_TAKEAWAY: i18n.t("SET_LOCATION_TEXT_TAKEAWAY"),
    SELECT_STORE: i18n.t("SELECT_STORE"),
    SELECT_STORE_TAKEAWAY: i18n.t("SELECT_STORE_TAKEAWAY"),
    SELECT_LOCATION: i18n.t("SELECT_LOCATION"),
    CHANGE_LOCATION: i18n.t("CHANGE_LOCATION"),
    CHANGE: i18n.t("CHANGE"),
    TABLE_NO: i18n.t("TABLE_NO"),
    ROOM_NO: i18n.t("ROOM_NO"),
    PURE_VEG: i18n.t("Pure Veg"),
  };

  const { pwaDeferredPrompt, setPwaAppInstallable, isLanguageArab, menuLoading, isRestaurantVeg } = useRestaurantsInfoContext();
  const [localSearchValue, setLocalSearchValue] = useState(searchValue);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      pwaDeferredPrompt.current = e;
      // // Update UI notify the user they can install the PWA
      setPwaAppInstallable(true);
    });

    window.addEventListener('appinstalled', () => {
      // Log install to analytics
      console.log('INSTALL: Success');
    });
  }, []);


  const handleSearch = useCallback(
    debounce((value) => {
      search(value);
      setSearchField(!!value);
    }, 200),
    []
  );

  useEffect(() => {
    handleSearch(localSearchValue);
  }, [localSearchValue]);

  const handleSearchBar = (value) => {
    setLocalSearchValue(value);
  };

  const handleOrderTypeSelection = (orderType) => {
    triggerClevertapOrderTypeSwitchEvent(orderType)
    setOrderType(orderType);
    setGIPOpen(false);
  };

  const getAddressAndEstimation = () => {
    const locationToShow = userLocationToShow
    const locationText = get(locationToShow, 'address', '')

    const getOrderTypeRadios = () => {
      return <OrderTypeRadioWrapper isLanguageArab={isLanguageArab}>
        <RadioButton
          id='delivery-selection-button'
          name='ordertype-selection-radio'
          label={i18n.t(get(restaurant, `order_types.[${OrderType.DELIVERY}].label`, 'Delivery'))}
          value={OrderType.DELIVERY}
          checked={orderType === OrderType.DELIVERY}
          onChange={() => handleOrderTypeSelection(OrderType.DELIVERY)}
          smallerRadio
        />
        <RadioButton
          id='takeaway-selection-button'
          name='ordertype-selection-radio'
          label={i18n.t(get(restaurant, `order_types.[${OrderType.TAKEAWAY}].label`, 'Takeaway'))}
          value={OrderType.TAKEAWAY}
          checked={orderType === OrderType.TAKEAWAY}
          onChange={() => handleOrderTypeSelection(OrderType.TAKEAWAY)}
          smallerRadio
        />
      </OrderTypeRadioWrapper>
    }

    const getAddressAndEstimateDetails = () => {
      if (defaultOutletShown) {
        if (orderType === OrderType.DELIVERY) {
          return {
            address: <TextButton id='set-location' onClick={() => goToLocationPage(HeaderConstants.SELECT_LOCATION)}>{i18n.t('SELECT_STORE')}</TextButton>,
            addressChangeButton: null
          }
        } else if (orderType === OrderType.TAKEAWAY) {
          return {
            address: <TextButton id='set-location' onClick={() => goToLocationPage(HeaderConstants.SELECT_LOCATION)}>{i18n.t('SELECT_STORE_TAKEAWAY')}</TextButton>,
            addressChangeButton: null
          }
        }
      } else {
        if (orderType === OrderType.DELIVERY) {
          if (!userLocationStatusForDelivery) {
            return {
              address: <p id='fetching-location'>{HeaderConstants.FETCHING_LOCATION}</p>,
              addressChangeButton: <TextButton onClick={() => goToLocationPage(HeaderConstants.CHANGE_LOCATION)} id='location-change-button'>{HeaderConstants.CHANGE}</TextButton>
            }
          } else if (userLocationStatusForDelivery === 'error') {
            if (!!get(locationToShow, 'lat')) {
              return {
                address: <p id='location-text'>{locationText}</p>,
                addressChangeButton: <TextButton onClick={() => goToLocationPage(HeaderConstants.CHANGE_LOCATION)} id='location-change-button'>{HeaderConstants.CHANGE}</TextButton>
              }
            }
            return {
              address: <TextButton id='set-location' onClick={() => goToLocationPage(HeaderConstants.SELECT_LOCATION)}>{HeaderConstants.SET_LOCATION_TEXT}</TextButton>,
              addressChangeButton: null
            }
          } else if (userLocationStatusForDelivery === 'success') {
            return {
              address: <p id='location-text'>{locationText}</p>,
              addressChangeButton: <TextButton onClick={() => goToLocationPage(HeaderConstants.CHANGE_LOCATION)} id='location-change-button'>{HeaderConstants.CHANGE}</TextButton>
            }
          }
        } else if (orderType === OrderType.TAKEAWAY) {
          if (!userLocationStatusForTakeaway) {
            return {
              address: <p id='fetching-location'>{HeaderConstants.FETCHING_LOCATION}</p>,
              addressChangeButton: <TextButton onClick={() => goToLocationPage(HeaderConstants.CHANGE_LOCATION)} id='location-change-button'>{HeaderConstants.CHANGE}</TextButton>
            }
          } else if (userLocationStatusForTakeaway === 'error') {
            return {
              address: locationText ? <p id='location-text'>{locationText}</p> : <TextButton id='set-location-takeaway' onClick={() => goToLocationPage(HeaderConstants.SELECT_LOCATION)}>{i18n.t('SET_LOCATION_TEXT_TAKEAWAY')}</TextButton>,
              addressChangeButton: !!locationText && <TextButton onClick={() => goToLocationPage(HeaderConstants.CHANGE_LOCATION)} id='location-change-button'>{HeaderConstants.CHANGE}</TextButton>
            }
          } else if (userLocationStatusForTakeaway === 'success') {
            return {
              address: <p id='location-text'>{locationText}</p>,
              addressChangeButton: <TextButton onClick={() => goToLocationPage(HeaderConstants.CHANGE_LOCATION)} id='location-change-button'>{HeaderConstants.CHANGE}</TextButton>
            }
          }
        }
      }
    }

    const allDetails = getAddressAndEstimateDetails()

    return <AddressAndEstimationDetails hideHeader={isGIPOpen}>
      <LogoAndDetails id='address-details-wrapper' firstSection isLanguageArab={isLanguageArab}>
        <MarkerIcon restaurantColor={restaurantColor} />
        <DetailsWrapper isLanguageArab={isLanguageArab}>
          {allDetails.address}
          {allDetails.addressChangeButton}
        </DetailsWrapper>
      </LogoAndDetails>
      {getOrderTypeRadios()}
    </AddressAndEstimationDetails>
  }

  const SocialAndChatbot = (
    <HeaderRight isLanguageArab={isLanguageArab}>
      {!isGIPOpen && get(socialHandles, 'enabled', false) && (
        <SocialMediaDropdown
          socialHandles={socialHandles}
          restaurantColor={styleVals.colors.iconGrey}
          isLanguageArab={isLanguageArab}
        />
      )}
      <ChatbotIconWrapper isLanguageArab={isLanguageArab} id='open-gip-button' onClick={toggleGIPWindow}>
        {orderType === OrderType.DINEIN ? <CallWaiter restaurantColor={styleVals.colors.iconGrey} /> : <ChatBot restaurantColor={styleVals.colors.iconGrey} />}
        {!isGIPOpen && notifyIndicator && <span id={orderType === OrderType.DINEIN ? 'notification-indicator' : 'notification-indicator-blink'} />}
      </ChatbotIconWrapper>
    </HeaderRight>
  );

  const isSwitchableOrderType = [OrderType.DELIVERY, OrderType.TAKEAWAY].includes(orderType)

  const headerMetaData = {
    [OrderType.DINEIN]: `${HeaderConstants.TABLE_NO}-${tableNumber}`,
    [OrderType.QDINEIN]: `${HeaderConstants.TABLE_NO}-${tableNumber}`,
    [OrderType.INROOMDINEIN]: `${HeaderConstants.ROOM_NO}-${roomNumber}`
  }

  const calculateFontSize = (text) => {
    const textLength = get(text, 'length', 0)
    if (textLength >= 15) {
      return {
        narrowMobile: '16px',
        smallMobile: '18px',
        modernMobile: '20px'
      }
    } else if (textLength >= 10) {
      return {
        narrowMobile: '17px',
        smallMobile: '20px',
        modernMobile: '22px'
      }
    } else if (textLength > 5) {
      return {
        narrowMobile: '22px',
        smallMobile: '24px',
        modernMobile: '24px'
      }
    } else {
      return {
        narrowMobile: '24px',
        smallMobile: '24px',
        modernMobile: '24px'
      }
    }
  }

  const SearchAndFilter = (
    <Search id='search-and-filter' isLanguageArab={isLanguageArab}>
      <Search.Div id='search-input-wrapper'>
        <button>
          <SearchIcon />
        </button>
        <input
          placeholder={i18n.t('placeHolder.SEARCH_DISH')}
          autoComplete='false'
          value={localSearchValue}
          id='search-input'
          type='text'
          onChange={(e) => handleSearchBar(e.target.value)}
        />
      </Search.Div>
      {
        (!isRestaurantVeg || barMenu) && <FilterDropDown
          barMenu={barMenu}
          dropDownTypes={dropDownTypes}
          veganFunc={veganFunc}
          eggFunc={eggFunc}
          nonVegFunc={nonVegFunc}
          foodFunc={foodFunc}
          barItemFunc={barItemFunc}
          restaurantColor={restaurantColor}
          isLanguageArab={isLanguageArab}
          isRestaurantVeg={isRestaurantVeg}
        />
      }
    </Search>
  );



  const GIPHeader = (
    <LogoAndCloseIcon isGIPOpen={isGIPOpen}>
      <img id='restaurant-header-logo' src={logo} onClick={toggleGIPWindow} role='button' />
      <button id='close-gip-button' onClick={toggleGIPWindow}>
        <svg
          width='12'
          height='12'
          viewBox='0 0 12 12'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M11.6758 9.76094L7.91235 5.99964L11.6729 2.23618C12.1066 1.80393 12.1066 1.10872 11.6744 0.675031L11.325 0.324908C11.1197 0.114546 10.8387 0 10.5433 0C10.5419 0 10.5419 0 10.5419 0C10.2465 0 9.96914 0.115987 9.76166 0.324188L5.99964 4.08909L2.23618 0.32851C1.80465 -0.10374 1.11016 -0.105902 0.676473 0.326349L0.326349 0.67287C0.115267 0.87963 0 1.16059 0 1.45524C0 1.75134 0.115987 2.03014 0.324188 2.23762L4.08909 5.99964L0.32779 9.7631C-0.105901 10.1954 -0.105901 10.8906 0.326349 11.3257L0.675752 11.6751C0.882512 11.8855 1.16275 12 1.45813 12C1.75206 12 2.03302 11.8826 2.23978 11.6758L6.00108 7.91163L9.7631 11.6715C9.97635 11.8869 10.2602 11.9957 10.5448 11.9957C10.8264 11.9957 11.1088 11.8883 11.3243 11.6737L11.6737 11.325C11.8855 11.1197 12 10.8387 12 10.5433C12.0014 10.2479 11.8855 9.96914 11.6773 9.76094H11.6758Z'
            fill={restaurantColor ? restaurantColor : '#EE2F37'}
          />
        </svg>
      </button>
    </LogoAndCloseIcon>
  );

  const restaurantName = get(restaurant, 'meta_data.name', '')

  const nameToShow = defaultOutletShown ? get(chainOriginName, 'name') : restaurantName
  const logoToShow = defaultOutletShown ? get(chainOriginName, 'logo') : logo

  const PureVeg = () => (<PureVegWrapper isLanguageArab={isLanguageArab}>
    <img src={PureVegIcon} alt="Pure veg" id="pure-veg-image" />
    <span id="pure-veg-text">{HeaderConstants.PURE_VEG}</span>
  </PureVegWrapper>)

  const MainHeader = <MenuHeader id='menu-header' hideHeader={isGIPOpen}>
    <HeaderContent hideHeader={isGIPOpen}>
      <RestaurantLogo id='restaurant-header-logo' src={logoToShow} isLanguageArab={isLanguageArab} />
      <NameWithMetaData calculatedRestoFontSize={calculateFontSize(nameToShow)} isLanguageArab={isLanguageArab}>
        <h1 id='restaurant-name'>{nameToShow}</h1>
        {!isSwitchableOrderType && <h2 id='ordertype-meta-data'>{headerMetaData[orderType]}</h2>}
        {isRestaurantVeg && PureVeg()}
      </NameWithMetaData>
      {SocialAndChatbot}
    </HeaderContent>
  </MenuHeader>

  return <React.Fragment>
    {MainHeader}
    {isSwitchableOrderType && getAddressAndEstimation()}
    {isGIPOpen ? GIPHeader : !menuLoading && SearchAndFilter}
  </React.Fragment>
};

export default Header;
