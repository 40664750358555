import React from 'react';
import { useRestaurantsInfoContext } from '../../contexts/restaurantInfo'

import { TextStyleButton } from './styles'

const TextButton = ({
    id = '',
    children,
    handleChange,
    type = 'button',
    linkColor='false',
    smallFontSize='false',
    ...props
}) => {

    const { restaurantColor } = useRestaurantsInfoContext()

    return <TextStyleButton 
        id={id}
        onClick={handleChange}
        restaurantColor={restaurantColor}
        type={type}
        linkColor={linkColor}
        smallFontSize={smallFontSize}
        {...props}
    >
        {children}
    </TextStyleButton>

}

export default TextButton