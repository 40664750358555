import React from "react";
import { useHistory } from "react-router-dom";
import { get } from '../../utils/common';
import {useTranslation } from 'react-i18next';
import { useRestaurantsInfoContext } from "../../contexts/restaurantInfo";
import { CHECKOUT, CHECKOUT_D } from "../../constants/routes";
import { pushHistory } from "../../utils/common";
import { useProductId } from "../../hooks";
import { AddItemButton } from '../Product/ItemCard/index'
import i18n from "../../translations/i18n";
import {
  RepititionsContainer,
  RepititionsDiv,
  RepititionsTitle,
  RepititionsSubTitle,
  RepititionsModalClose,
  RepititionModalContent,
  RepititionsModalButtonContainer,
} from "./style";

const Repititions = ({
  restaurantColor,
  repititionModal,
  setRepititionModal,
  repititionModalChildren,
  productIndex,
  handleRepetitionChange
}) => {
  const { isLanguageArab } = useRestaurantsInfoContext();
  let history = useHistory();
  const productId = useProductId();
  const { t } = useTranslation();

  const { products } = useRestaurantsInfoContext();

  const generateRepitiionModalChildren = (product) => {
    let isComboItem = get(product, 'isComboItem', false);
    let productDetails = [];
    let comboArray = [];
    let addonsArray = [];
    let variantsArray = [];
    if (isComboItem) {
      get(product,'sections',[]).forEach(function(section) {
        get(section,'items',[]).length > 0 && section?.items.forEach(function(item) {
          productDetails.push(<span><strong>{get(products, `items[${item.ref_id}].title`)}</strong></span>);
          if (get(section,'variants',[]).length > 0) {
            section.variants.map(function(variant) {
              variantsArray.push(get(products, `variants[${variant.ref_id}].title`));
            });
            comboArray.push(<div style={{padding: "0 10px"}}><span style={{textDecoration: "underline"}}>{t('VARIANTS')}</span></div>);
            comboArray.push(<span style={{padding: "0 10px"}}>{variantsArray.map(v => v.charAt(0).toUpperCase() + v.substr(1)).join(', ')}</span>);
            variantsArray = [];
          } else if (get(item,'variants',[]).length > 0) {
              item.variants.map(function(variant) {
                variantsArray.push(get(products, `variants[${variant.ref_id}].title`));
              });
              comboArray.push(<div style={{padding: "0 10px"}}><span style={{textDecoration: "underline"}}>{t('VARIANTS')}</span></div>);
              comboArray.push(<span style={{padding: "0 10px"}}>{variantsArray.map(v => v.charAt(0).toUpperCase() + v.substr(1)).join(', ')}</span>);
              variantsArray = [];
          }
          if (get(section,'addons',[]).length > 0) {
              section.addons.map(function(addon) {
                addonsArray.push(get(products, `addons[${addon.ref_id}].title`));
              });
              comboArray.push(<div style={{padding: "0 10px"}}><span style={{textDecoration: "underline"}}>{t('ADDONS')}</span></div>);
              comboArray.push(<span style={{padding: "0 10px"}}>{addonsArray.map(a => a.charAt(0).toUpperCase() + a.substr(1)).join(', ')}</span>);
              addonsArray = [];
          } else if (get(item,'addons',[]).length > 0) {
              item.addons.map(function(addon) {
                addonsArray.push(get(products, `addons[${addon.ref_id}].title`));
              });
              comboArray.push(<div style={{padding: "0 10px"}}><span style={{textDecoration: "underline"}}>{t('ADDONS')}</span></div>);
              comboArray.push(<span style={{padding: "0 10px"}}>{addonsArray.map(a => a.charAt(0).toUpperCase() + a.substr(1)).join(', ')}</span>);
              addonsArray = [];
          }
          productDetails.push(comboArray);
          productDetails.push(<hr />);
          comboArray = [];
        });
      });
    } else {
      if (get(product, 'add_on_groups') !== null && get(product, 'add_on_groups', []).length > 0) {
        get(product, 'add_on_groups', []).forEach(addon => {
          get(addon, 'selectedAddOn').forEach(selectedAddon => {
            productDetails.push(<p>{get(addon, `title`)}: <span>{get(selectedAddon, `title`)}</span></p>);
          })
        })
      }
      
      if (get(product, 'variant_groups', []) !== null && get(product, 'variant_groups', []).length > 0) {
        get(product, 'variant_groups', []).forEach(variant => {
          let selectedVariant = get(variant, 'selectedVariant');
          productDetails.push(<p>{get(variant, `title`)}: <span>{get(selectedVariant, `title`)}</span></p>);
        })
      }
    }
    return productDetails;
  }

  const generateRemovalChildren = () => {
    return <p>{t('REMOVE_COMBO_FROM_CART_POPUP')}</p>
  }
  
  const goToCheckout = () => {
    setRepititionModal(!repititionModal);
    pushHistory(history, CHECKOUT, CHECKOUT_D,productId);
  }

  return (
    <RepititionsContainer id='repitition-container' isLanguageArab={isLanguageArab}>
      <RepititionsDiv removal={repititionModalChildren !== "removal" ? false : true}>
        <RepititionsTitle>{repititionModalChildren !== "removal" ? get(repititionModalChildren, 'title') : i18n.t('REMOVE_ITM_FRM_CART')}</RepititionsTitle>
        <RepititionsModalClose isLanguageArab={isLanguageArab} onClick={() => setRepititionModal(!repititionModal)}>X</RepititionsModalClose>
        {repititionModalChildren !== "removal" && <RepititionsSubTitle isLanguageArab={isLanguageArab} restaurantColor={restaurantColor}>{t('YOUR_PREVIOUS_CUSTOMIZATION')}</RepititionsSubTitle>}
        <RepititionModalContent removal={repititionModalChildren !== "removal" ? false : true}>
          {repititionModalChildren !== "removal" ?
            generateRepitiionModalChildren(repititionModalChildren) :
            generateRemovalChildren()
          }
        </RepititionModalContent>
        {repititionModalChildren !== "removal" ? <RepititionsModalButtonContainer>
          <AddItemButton
            buttonLabel={t('CUSTOMIZE')}
            handleAddItemClick={() => {
              setRepititionModal(false);
              handleRepetitionChange(get(repititionModalChildren, 'ref_id'), '', productIndex)
            }}
            buttonSize='lg'
            title={'customize-combo'}
            invertColors
          />
          <AddItemButton
            buttonLabel={t('REPEAT')}
            handleAddItemClick={() => {
              setRepititionModal(false);
              handleRepetitionChange(get(repititionModalChildren, 'ref_id'), '+', productIndex)
            }}
            title={'repeat-combo'}
            invertColors
          />
        </RepititionsModalButtonContainer> : 
        <RepititionsModalButtonContainer>
            <AddItemButton
              buttonLabel={t('GO_TO_CART')}
              handleAddItemClick={() => { setRepititionModal(false); goToCheckout() }}
              buttonSize='lg'
              invertColors
            />
            <AddItemButton
              buttonLabel={t('CANCEL')}
              handleAddItemClick={() => setRepititionModal(false)}
              invertColors
            />
        </RepititionsModalButtonContainer>}
      </RepititionsDiv>
    </RepititionsContainer>
  );
};

export default Repititions;
