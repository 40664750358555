const CallWaiter = ({ restaurantColor }) => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      fill={restaurantColor ? restaurantColor : "#EE2F37"}
      width="29"
      height="29"
      viewBox="0 0 40.625 40.625"
    >
      <g>
        <g>
          <path
            d="M32.097,24.868l-3.785-0.946c1.566-1.391,2.832-3.305,3.676-5.416c1.254-0.227,2.43-1.223,2.619-3.511
			c0.182-2.211-0.545-3.123-1.479-3.488c-0.025-0.707-0.115-1.363-0.152-1.983c-0.422-6.985-3.933-10.057-9.609-9.449
			c-0.744,0.08-1.472,0.126-2.211,0.006c-2.964-0.487-6.058,1.789-7.565,4.162c-1.951,3.072-1.206,7.197-1.211,7.332
			c-0.855,0.405-1.492,1.334-1.32,3.42c0.183,2.214,1.289,3.219,2.497,3.486c0.862,2.118,2.152,4.051,3.733,5.454l-3.754,0.936
			c-5.124,1.283-8.714,5.865-8.714,11.146v6.32c0,1.794,1.484,3.288,3.278,3.288h29.44c1.794,0,3.265-1.494,3.265-3.287v-6.32
			C40.804,30.735,37.22,26.151,32.097,24.868z M11.048,43.625H8.099c-0.691,0-1.279-0.596-1.279-1.287v-6.32
			c0-3.24,1.656-6.162,4.228-7.886V43.625z M14.358,12.65c0-0.294,0.044-0.583,0.13-0.865c0.232-0.752,0.53-1.394,0.825-1.916
			c0.511-0.903,1.339-1.585,2.323-1.913c0.03-0.01,0.06-0.02,0.089-0.03c0.346-0.119,0.727,0.013,0.926,0.319
			c0.199,0.306,0.164,0.709-0.085,0.976c-0.047,0.05-0.094,0.1-0.142,0.148c-0.15,0.155-0.184,0.389-0.084,0.58
			c0.1,0.191,0.312,0.296,0.524,0.261c2.074-0.342,5.708-1.252,8.441-3.609c0.725-0.624,1.812-0.562,2.461,0.141
			c0.615,0.668,1.013,1.497,1.149,2.395c0.153,1.003,0.234,2.166,0.234,3.513c0,5.233-3.745,11.554-8.397,11.554
			C18.103,24.204,14.358,17.884,14.358,12.65z M23.555,43.625h-1.446l-6.883-17.1l4.299-1.082c1.012,0.486,2.096,0.759,3.229,0.759
			c1.174,0,2.29-0.279,3.325-0.776l4.372,1.108L23.555,43.625z M38.806,42.338c0,0.691-0.576,1.287-1.267,1.287h-2.886v-15.45
			c2.535,1.729,4.152,4.628,4.152,7.842V42.338z"
          />
          <circle cx="22.8" cy="34.085" r="1.142" />
          <circle cx="22.8" cy="38.159" r="1.142" />
          <path
            d="M26.197,27.144L24.14,27.74c-0.032,0.008-0.063,0.016-0.096,0.021c-0.333-0.292-0.765-0.486-1.24-0.486
			s-0.907,0.193-1.24,0.486c-0.032-0.005-0.064-0.013-0.095-0.021l-2.05-0.597c-0.614-0.177-1.197,0.286-1.197,0.925V30.4
			c0,0.641,0.584,1.102,1.2,0.924l2.141-0.624c0.333,0.296,0.765,0.48,1.242,0.494c0.478-0.014,0.911-0.198,1.245-0.494l2.146,0.624
			c0.614,0.178,1.208-0.283,1.208-0.924v-2.332C27.403,27.428,26.812,26.967,26.197,27.144z"
          />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};

export default CallWaiter;
