import styled, { css } from 'styled-components'
import styleVals from '../../constants/styles'

export const TextStyleButton = styled.button`
    display: inline-block;
    background: none;
    outline: none;
    border: none;
    color: ${props => props.disabled ? styleVals.colors.greyText : props.linkColor ? styleVals.colors.linkColor : props.restaurantColor};
    font-size:${props=> props.smallFontSize ? '12px':  'inherit'};
    ${props => !props.disabled && css`
        :hover {
            text-decoration: underline;
        }
        :focus {
            text-decoration: underline;
        }
    `}

`