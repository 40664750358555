import React from "react";
import { useHistory } from "react-router-dom";
import leftArrowFlat from '../../assets/icons/arrow-left-flat.svg';
import leftArrow from '../../assets/icons/arrow-left.svg';
import { Header, HeaderWrapper, IconWrapper, LeftArrowIcon } from "./styles";
import { allPages, clevertapEventPush, CT_EVENT_IDS } from "../../helpers/clevertapUtil";
import { HOME, HOME_D } from "../../constants/routes";
import { pushHistory } from "../../utils/common";
import { useProductId } from "../../hooks";
import i18n  from '../../translations/i18n'
import { useRestaurantsInfoContext } from "../../contexts/restaurantInfo"

export default function NavigationHeader({ title, logo, backClickEvent, headerContent, heightSwitch, smallHeader, ...props }) {
    const logoImg = logo ? <img width="40" height="40" alt={i18n.t('RESTORENT_LOGO')} src={logo}/> : null;
    const { isLanguageArab } = useRestaurantsInfoContext();
    const  productId  = useProductId();
    const history = useHistory();

    const onBackClickEvent = () => {
        if (backClickEvent) {
            backClickEvent();
            return;
        }
        const pageId = history.location.pathname.split('/')[2]
        const pageNameForUrl = ({
            'viewAddress': allPages.SET_LOCATION_PAGE,
            'tableReservation': allPages.TABLE_RESERVATION_PAGE
        })[pageId]
        clevertapEventPush({
            eventId: CT_EVENT_IDS.BACK_BUTTONS_CLICKED,
            eventData: {
                Location: pageNameForUrl
            }
        })
        pushHistory(history, HOME, HOME_D,productId);
    }

    const Icon = <React.Fragment>
        <LeftArrowIcon isLanguageArab={isLanguageArab} onClick={onBackClickEvent} id='back-icon'>
            <img width='30px' alt="Back button" src={(headerContent || smallHeader) ? leftArrowFlat : leftArrow} />
        </LeftArrowIcon>
        { logoImg }
        {headerContent ? <span id='title-text'>{title}</span> : <span id='save-new-address-text'>{title}</span>}
    </React.Fragment>

    const headerHeight = heightSwitch ? '60px' : '174px';

    const contentOpacity1 = heightSwitch ? 1 : 0
    const contentOpacity2 = 1 - contentOpacity1

    const IconAndLogo = <HeaderWrapper id='header-wrapper'>
        <IconWrapper isLanguageArab={isLanguageArab}>{Icon}</IconWrapper>
        {headerContent}
    </HeaderWrapper>

    const headerProps = smallHeader ? {
        styles: {
            headerHeight: '60px',
            smallHeader
        },
        content: Icon
    } : {
        styles: {
            contentOpacity1,
            contentOpacity2,
            headerHeight
        },
        content: IconAndLogo
    }

    return (
        (headerContent || smallHeader) && <Header {...headerProps.styles} {...props} isLanguageArab={isLanguageArab}>
            {headerProps.content}
        </Header>
    )
}