import {
    OfferTypes,
} from "../../constants/variables";

const OfferCodeReverted = ({ restaurantColor, restaurantDarkenColor, offerCode, offerType }) => {
    const offerCodeArray = offerCode.split(" ");
    const styleOffer = {
        x: offerType === OfferTypes.FREEDELIVERY ? 120 : 90,
        fSize: offerType === OfferTypes.FREEDELIVERY ? 20 : 25,
    }
    return (
        <svg viewBox="3 0 125 145" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_1_2)">
                <path d="M17.5 143.042C14.7 136.242 7.33334 135.542 4 136.042L5.00026 132.042C13.0003 127.642 8.3336 124.542 5.00026 123.542V118.042C12.2003 114.042 8.00026 110.375 5.00026 109.042V104.542C12.6003 100.142 8.16692 96.7085 5.00026 95.5418V90.0418C12.2003 86.4418 8.00026 82.8752 5.00026 81.5418V76.5418C11.8003 72.1418 7.8336 68.3752 5.00026 67.0418V62.5418C12.6003 58.5418 8.16692 55.2085 5.00026 54.0418V48.0418C11.8003 44.4418 7.8336 41.2085 5.00026 40.0418V35.0418C11.4003 31.8418 7.66692 27.7085 5.00026 26.0418V21.5418C7.00026 21.5418 8.16692 19.8752 8.50026 19.0418C9.70026 15.4418 6.66692 12.8752 5.00026 12.0418L5.50026 8.54184C13.9003 10.1418 19.3336 3.54184 21.0003 0.0418396H22.0003C28.1669 0.208511 45.8003 0.441833 67.0003 0.0418396C88.2003 -0.358154 101.167 2.20851 105 3.54184C122.6 13.1418 126 32.8752 125.5 41.5418C126.833 59.0418 128.2 98.9418 123 118.542C117.8 138.142 94.1667 143.042 83 143.042H17.5Z" fill="url(#paint0_linear_1_2)"/>
            </g>
            <g>
                <text x={styleOffer.x} y="70" font-family="Poppins" font-size={styleOffer.fSize} fill="#fff">{offerCodeArray.length > 2 ? `${offerCodeArray[0]} ${offerCodeArray[1]}`: offerCodeArray[0] }</text>
            </g>
            <g>
                <text x={styleOffer.x} y="95" font-family="Poppins" font-size={styleOffer.fSize} fill="#fff">{offerCodeArray.pop()}</text>
            </g>
            <defs>
                <filter id="filter0_d_1_2" x="0" y="0" width="130.719" height="151.042" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="4"/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_2"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_2" result="shape"/>
                </filter>
                <linearGradient id="paint0_linear_1_2" x1="65.3595" y1="143.042" x2="65.3595" y2="0" gradientUnits="userSpaceOnUse">
                    <stop stop-color={restaurantColor}/>
                    <stop offset="1" stop-color={restaurantDarkenColor}/>
                </linearGradient>
            </defs>
        </svg>
    );
  };
  
  export default OfferCodeReverted;
  