import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  *, *:before, *:after {
    box-sizing: border-box;
  }
  html, body {
    height: 100%;
    font-family: 'Poppins', 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #FFFFFF;
    color: #000000;
    font-size: 16px;
}
  .gmnoprint {
    display: none !important;
  }
  .Toastify__toast-container {
    padding: 10px;
  }
  .grecaptcha-badge {
    position: fixed !important;
    left: -1000px !important;
    z-index: -9;
  }
  .form-control:invalid, .form-control.is-invalid {
    background-image: none !important;
  }
  .global-currency{
    font-family: Roboto;
    padding-right: 0px !important;
  }
/*  .app {
    background-color: #f6f6f6;
  }
  
  @media( min-width: 500px ) {
    .App {
      box-shadow: 0 0 38px 7px rgba(0, 0, 0, 0.12);
    }
  }*/`;
