import styled, { css } from 'styled-components'
import styleVals from '../../constants/styles'

export const HeaderWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;

`
export const IconWrapper = styled.div`
    top: ${styleVals.measurements.spacing16};
    position: absolute;
    display: flex;
    align-items: center;
    ${props => props.isLanguageArab ? css`
    right: ${styleVals.measurements.spacing4};
    flex-direction: row-reverse;`
    : css`left: ${styleVals.measurements.spacing4}`}
`
export const Header = styled.header`
  width: 100%;
  ${props => css`
    height: ${props.headerHeight};
    ${!props.smallHeader && css`
      transition: height 0.2s linear;
      img[id="outlet-logo"], p[id="welcome-text"], p[id="rest-name-text"] {
        transition: all 0.1s linear;
        opacity: ${props.contentOpacity2};
      }
      span[id="title-text"] {
        transition: all 0.1s linear;
        opacity: ${props.contentOpacity1};
      }
    `}
  `}
  box-shadow: none;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  max-height: ${styleVals.measurements.spacing174};
  background-color: #ffffff;
  font-size:  ${styleVals.measurements.spacing16};
  line-height: ${styleVals.measurements.spacing19};
  display: flex;
  align-items: center;
  overflow: hidden;
  ${props => props.isLanguageArab && css`flex-direction: row-reverse;`};
`

export const LeftArrowIcon = styled.a`
   width: auto;
    max-height:  ${styleVals.measurements.spacing40};
    min-height:  ${styleVals.measurements.spacing30};
    margin: auto ${styleVals.measurements.spacing5};
    float: left;
     ${props => props.isLanguageArab && css` transform: rotate(180deg)`};
`;