import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { useRestaurantsInfoContext } from '../../contexts/restaurantInfo'
import { useProductId } from '../../hooks'
import { get } from '../../utils/common'
import {
    SectionWrapper,
    CategoryText,
    FooterWrapper,
    RestoName
} from './styles'
import MenuGupshupLogo from "../../assets/images/menu-gupshup-logo.png";

const FooterLinks = [
    {
        id: 'aboutUs',
        label: 'About Us',
        link: 'https://www.gupshup.io/developer/about'
    },
    {
        id: 'privacyPolicy',
        label: 'Privacy policy',
        link: 'https://www.gupshup.io/developer/privacy'
    },
    {
        id: 'termsConditions',
        label: 'Terms and Conditions',
        link: false
    },
    {
        id: 'refundPolicy',
        label: 'Refund Policy',
        link: false
    },
]

const Footer = ({
    menuCategories = [],
    menuCategoryClick = () => null
}) => {
    const productId = useProductId();
    const history = useHistory();
    const { restaurant, isLanguageArab } = useRestaurantsInfoContext()

    const restaurantName = get(restaurant, 'meta_data.name');

    const handleRedirection = (link, id) => {
        if (!link) {
            history.push(`/${productId}/t&c#${id}`);
        } else {
            window.open(link, '_blank');
        }
    }

    return <FooterWrapper>
        <RestoName isLanguageArab={isLanguageArab} id='footer-restaurant-name'>{restaurantName}</RestoName>
        <SectionWrapper withNoMarginTop>
            {menuCategories.map((category, index) => {
                const {title, ref_id} = category
                return <React.Fragment key={index}>
                    {index !== 0 ? <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span> : ''}
                    <CategoryText onClick={() => menuCategoryClick(ref_id, title, true)}>{title}</CategoryText>
                </React.Fragment>
            })}
        </SectionWrapper>
        <SectionWrapper withLinks>
            {FooterLinks.map((linkText, linkKey) => <a onClick={() => handleRedirection(linkText.link, linkText.id)} key={linkKey} target={'_blank'}>{linkKey !== 0 && linkKey !== (FooterLinks).length - 1 && <sup>&nbsp;&nbsp;.&nbsp;&nbsp;</sup >}{linkText.label}{linkKey === (FooterLinks).length - 2 && <sup>&nbsp;&nbsp;.&nbsp;&nbsp;</sup>}</a >)}
        </SectionWrapper>
        <SectionWrapper withImage>
            <span>{'Gupshup © 2022'}&nbsp;&nbsp;&nbsp;</span>
            <span>{'Powered by'}&nbsp;<img src={MenuGupshupLogo} alt='menu-gupshup-logo' /></span>
        </SectionWrapper>
    </FooterWrapper>
}

export default Footer;