import styled, { css } from "styled-components";
import styleVals from './../../constants/styles';
export const RepititionsContainer = styled.div`
    position: fixed;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    min-height: 100vh;
    height: -webkit-fill-available;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    z-index: 4;
    max-width: ${styleVals.measurements.spacing500};
    text-align:${props => props.isLanguageArab && 'right'};
`;

export const RepititionsDiv = styled.div`
    width: 100%;
    height: ${props => props?.removal === true ? '180px' : '300px'};
    transition: height 0.0005s ease;
    background-color: #ffffff;
    border: none;
    position: fixed;
    bottom: ${styleVals.measurements.spacing0};
    border-radius: ${styleVals.measurements.spacing20} ${styleVals.measurements.spacing20} ${styleVals.measurements.spacing0} ${styleVals.measurements.spacing0};
    max-width: ${styleVals.measurements.spacing500};
`;

export const RepititionsTitle = styled.div`
    margin-top: 0;
    display: flex;
    align-items: center;
    font-weight: bold;
    padding: 0.8rem;
    border-bottom: 1px solid #cccccc;
    cursor: pointer;
    text-transform: uppercase;
    font-size: ${styleVals.measurements.spacing18};
`;

export const RepititionsSubTitle = styled.div`
    color: ${props => props?.restaurantColor || '#3c4b64'};
    padding:  ${styleVals.measurements.spacing5} ${styleVals.measurements.spacing20};
    font-weight: bold;
    text-align: left;
    font-size: ${styleVals.measurements.spacing16};
    text-align:${props => props.isLanguageArab && 'right'};
`;

export const RepititionsModalClose = styled.div`
    width: ${styleVals.measurements.spacing30};
    height: ${styleVals.measurements.spacing30};
    cursor: pointer;
    border: 1px solid #000000;
    background-color: #ffffff;
    border-radius: ${styleVals.measurements.spacing15};
    text-align: center;
    font-size: ${styleVals.measurements.spacing21};
    line-height: 1.42857;
    position: absolute;
    top: -${styleVals.measurements.spacing13};
    font-weight: 600;
    ${props => props.isLanguageArab ? css`left: ${styleVals.measurements.spacing10};` : css`right: ${styleVals.measurements.spacing10};`}
`;

export const RepititionModalContent = styled.div`
    max-height: ${props => props?.removal ? '100px' : '150px'};
    padding: ${styleVals.measurements.spacing5} ${styleVals.measurements.spacing20};
    overflow-y: auto;
`;

export const RepititionsModalButtonContainer = styled.div`
    display: flex;
    position: absolute;
    bottom: ${styleVals.measurements.spacing15};
    left: ${styleVals.measurements.spacing12};
    right: ${styleVals.measurements.spacing12};
    width: auto;
    justify-content:space-between;
`;

export const RepititionsModalButton = styled.div`
    background-color: ${props => props?.restaurantColor || '#D2202E'};
    cursor: pointer;
    margin: 0 auto;
    padding: 0 ${styleVals.measurements.spacing20};
    bottom: ${styleVals.measurements.spacing30};
    left: 40%;
    border-radius: ${styleVals.measurements.spacing18};
    color: #FFFFFF;
    display: flex;
    align-items: center;
    height: ${styleVals.measurements.spacing36};
    justify-content: center;
    text-transform: uppercase;
    min-width: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 35px;
`;