import React from 'react'
import { useRestaurantsInfoContext } from '../../contexts/restaurantInfo';
import { Input } from '../BasicComponents';

import {
    InputWrapper,
    ErrorText
} from './styles'

const TextInput = props => {
    const { isLanguageArab } = useRestaurantsInfoContext();
    const textInputProps = {
        id: props.id,
        placeholder: props.placeholder,
        value: props.value,
        onChange: props.onChange,
        onBlur: props.onBlur,
        alignText: isLanguageArab && !props.alignText ? 'right' : (props.alignText || 'left'),
        type: props.type || 'text',
        isError: !!props.errorMessage,
        onFocus: props.onFocus,
        borderColor: props.borderColor,
        showIcon: props.showIcon,
        maxLength: props.maxLength,
        minLength: props.minLength,
        min: props.min,
        enterLocationInput: props.enterLocationInput || false
    }

    return (
        <InputWrapper id={'input-wrapper'} showIcon={props.showIcon} smallIcon={props.smallIcon} iconExists={!!props.iconToShow} noBottomGap={props.noBottomGap} ref={props.ref} enterLocationInput={props.enterLocationInput}>
            <Input {...textInputProps} />
            {!!props.errorMessage && <ErrorText alignText={props.alignText}>{props.errorMessage}</ErrorText>}
            {props.showIcon && <div id='textinput-icon-wrapper'>
                {<img id='textinput-icon' src={props.iconToShow} alt='.' />}
            </div>}
        </InputWrapper>
    )
}

export default TextInput
