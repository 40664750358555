import React from "react";
import { CongratsMessage, MainLoader, MainLoaderContainer, MainLoaderMessage, MoneyGifContainer, MoneyGifMessage, RandomMessageSection, RandomMsgDiv, Spinner } from "./styles/spinner";
import {useRestaurantsInfoContext} from "../../contexts/restaurantInfo";
import {useTranslation } from 'react-i18next';


export default function SpinnerComponent({ ...props }) {
  const {restaurantColor} = useRestaurantsInfoContext();
  const { t }=useTranslation();

  const DisplayMessageConstants = Object.freeze({
    byOrderingMsg: (value) => t('byOrderingMsg', { value })
  })

  let displayMessage = [
    DisplayMessageConstants.byOrderingMsg('20%'),
    t('moreInflatedMsg'),
    t('lesserMenuPricesMsg'),
    t('ThanksMsg'),
    t('dontInflateMsg')]

  const RandomMsg = () => {
    let integer = Math.floor(Math.random() * 5);
    let imgSrc;
    if(integer === 0){
        imgSrc = "/assets/images/thinking_face.png";
    }else if(integer === 1){
        imgSrc = "/assets/images/confetti.png";
    }else if( integer === 3 ){
        imgSrc = "/assets/images/open_hand.png";
    }else{
        imgSrc = "/assets/images/handover_face.png";
    }
    return (
      <>
          <img src={imgSrc} alt="" width="50" height="50"  style={{margin: "20px auto"}}/>
        {integer === 0 && <RandomMessageSection>{t('DID_YOU_KNOW')} </RandomMessageSection>}
        <RandomMsgDiv restaurantColor={restaurantColor}>
            <b>{displayMessage[integer]}</b>
        </RandomMsgDiv>
      </>
    )
  }

  const imgGif = <img src="/assets/images/money_gif.gif" alt="" width="250" height="auto" style={{ margin: "10px auto" }} />
  
         
  const MoneyGif = (price, currency) => {
    return(
      <MoneyGifContainer>
           {imgGif}
        <CongratsMessage>{t('CONGRATS')}</CongratsMessage>
        <MoneyGifMessage>{t('YOU_SAVED')} <b style={{ color: "red" }}>{currency} {price}</b>. {t('IF_PLACED_AGG_ORDER')} <b style={{ color: "red" }}>{currency} {price}</b> {t('MORE')}</MoneyGifMessage>
      </MoneyGifContainer>
    )

  }
  return (
    <Spinner {...props}>
      <>
        {(props.text) ? (RandomMsg()) : ("")}
      </>
      {props.price && props.gifLoader ? MoneyGif(props.price, props.currency) : null}
      <MainLoaderContainer>
        <MainLoader color={props.color} animation="border" />
        {props.message ? (
          <MainLoaderMessage color={props.color}>
          <b>{props.message}</b>
          </MainLoaderMessage>) : ("")}
      </MainLoaderContainer>
    </Spinner>
  );
}
