import React from "react";
import { useTranslation } from "react-i18next";
import { AddItemButton, EmptyCart, EmptyCartNoItem } from "./styles";

export default function EmptyCartModel({ handleClick, restaurantColor }) {

    const { t } = useTranslation();
    return (
        <EmptyCart id='empty-cart-modal-wrapper'>
            <EmptyCartNoItem id='empty-cart-modal' restaurantColor={restaurantColor}>
                <EmptyCartNoItem.Div restaurantColor={restaurantColor}>
                    <p id='no-item-message'>{t('THERE_ARENT_ANY_ADDED_ITEM')}</p>
                    <AddItemButton id='add-items-to-cart' onClick={handleClick} restaurantColor={restaurantColor}>{t('ADD_ITEMS')}</AddItemButton>
                </EmptyCartNoItem.Div>
            </EmptyCartNoItem>
        </EmptyCart>
    )
}
