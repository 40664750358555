import React, { useEffect, useState, useRef, useCallback } from "react";
import jsCookie from "js-cookie";
import { useHistory } from "react-router-dom";
import { debounce, get, isEqual, reduce, cloneDeep } from '../../utils/common';

import Header from "../../Components/Header/Header";

import styleVals from '../../constants/styles'
import {
  CHECKOUT,
  ORDERDETAILS,
  CHECKOUT_D,
  ORDERDETAILS_D,
  VEIWADDRESS,
  VEIWADDRESS_D
} from "../../constants/routes";
import {
  errorCodes,
  OrderStatus,
  OrderType,
  PaymentMode,
  paymentStatus,
  RESTAURANT_TYPE,
} from "../../constants/variables";
import {
  notifocationsInRealTime,
  markNotificationAsRead,
  checkOfferAvailable,
  mapOrderTypeInbot,
  returnRefreshedCart,
  productAllPrice,
  productPrice,
  getFreedeliveryAmount,
  isItemAvailableNow,
  getOffers,
  checkIsItemCustomizable
} from "../../Components/Product/ProductController";
import { useProductId, useQuery } from "../../hooks";
import {
  generateGipLink,
  getAllSubOrders,
  getQuoteData,
} from "../../utils/API";
import { useRestaurantsInfoContext } from "../../contexts/restaurantInfo";
import {
  CartBag,
  CategoriesWrapper,
  EmptyCart,
  GIPBotIFrame,
  MenuButton,
  MenuItemContainer,
  ProductContainer,
  ProductFooter,
  SubCategoryBlock,
  TotalItemCounts,
  CartWithAmountWrapper,
  ComboOffersWrapper,
  CategoryHeading,
  CategoryWrapper,
  ConfirmationToJoinTable,
  JoinTableText,
  JoinTableButtonSection,
  JoinTableButton,
  MinOrderAmount,
  FooterMessage,
  FooterMessageForDinein,
  MenuLoader,
  MenuLoaderWrapper,
  BannerImage
} from "./styles/Product.style";
import Menu from "../../Components/Menu/Menu";
import VariantGroups from "../../Components/VariantGroups/VariantGroups";
import VariantGroupPlyus from "../../Components/VariantGroupPlyus/VariantGroupPlyus";
import { OTPVerification, Spinner } from "../../Components";
import ShoppingCart from "../../assets/icons/shopping-cart.svg";
import OfferSlider from "../../Components/OfferSlider/OfferSlider";
import EmptyCartModal from "../../Components/EmptyCartModal/EmptyCartModal";
import {
  checkTableStatus,
  blockTable,
  joinTable as joinTableAPI,
} from "../../utils/API";
import "./ProductV2.scss";
import { Notification } from "../../helpers/notification";
import { usePersistedLocalStorageState } from "../../helpers/persistent";
import { allPages, clevertapEventPush, CT_EVENT_IDS } from "../../helpers/clevertapUtil";
import { ComboCard, CombosPopup } from "./../../Components/Product/ComboCard";
import { getDistance, getKeyValue, pushHistory } from "../../utils/common";
import LandingCardModal from "./LandingCardModal/index";
import { useTranslation } from "react-i18next";
import i18n from "../../translations/i18n";
import OrderTypeServiceable from "./OrderTypeServiceable";
import RepititionsField from "../../Components/Repetitions/RepititionsField";
import ItemCard from "../../Components/Product/ItemCard";
import Footer from "../../Components/Footer";
import { Loader } from "../../Components/BasicComponents";
import Carousel from "../../Components/Carousel";


const MenuPageConstants = {
  COMBO_OFFERS_HEADING: 'Combo Offers',
  SIMPLE_COMBO: 'SIMPLE_COMBO',
  CUSTOMIZED_COMBO: 'CUSTOMIZED_COMBO',
  PAYX_GETY: 'PAY_X_GET_Y',
  BUYX_GETY: 'BUY_X_GET_Y',
  ELIGIBLE_COMBOS: ['SIMPLE_COMBO', 'CUSTOMIZED_COMBO', 'PAY_X_GET_Y', 'BUY_X_GET_Y'],
  CUSTOMIZED_COMBO_TYPES: ['CUSTOMIZED_COMBO', 'PAY_X_GET_Y', 'BUY_X_GET_Y'],
  BASE_PRICE_COMBOS: ['SIMPLE_COMBO', 'CUSTOMIZED_COMBO'],
  NO_BROWSER_LOCATION_SUPPORT: 'Your device does not support access to your location.',
  NO_LOCATION_ACCESS: 'Please check your browser settings to allow us get your location',
  BROWSE_MENU_INFO: 'Feel free to browse the menu.',
  SELECT_LOCATION: 'Select location',
  CHANGE_LOCATION: 'Change location',
  ADD_BUTTON: 'Add button',
  INVALID_ORDERTYPE: 'This order type is not supported in this restaurant'
};
export default function ProductV2({ ...props }) {
  const { isRestaurantVeg, restaurant, setProductId, restaurantColor, colorLoading, wentToCheckout, restaurantUIStateData, setRestaurantUIStateData, landingModal, setLandingModal, locationChangedFromTakeaway, defaultOutletShown, isRestaurantColorDark, chainOriginName, orderType, metaData, setMetaData, isGIPOpen, setGIPOpen, orderInfo, offers, setOffers, setOrderType, userLocationData, setUserLocationData, userLocationStatusForDelivery, setUserLocationStatusForDelivery, userLocationStatusForTakeaway, setUserLocationStatusForTakeaway, storeLocationData, setStoreLocationData, customizeExistingFlag, replaceExistingFlag, shouldFilterProductFromCart, comboInCart, setComboInCart, comboIdentifier, setComboIdentifier, notifications, cart, setCart, products, gipUsr, restaurantBot, storeName, custGIPLink, paymentOptions, restaurantCurrency, dineIndetails, setDineinDetails, setCustGIPLink, totalSavings, repititionModal, setRepititionModal, repititionModalChildren, setRepititionModalChildren, productIndex, isOrderPlaced, pwaDeferredPrompt, setPwaAppInstallable, pwaAppInstallable, isLanguageArab, menuLoading } = useRestaurantsInfoContext();
  const restaurantCurrencyValue = get(restaurant, 'meta_data.currency.symbol', '₹') || '₹';
  const { t } = useTranslation();
  const productId = useProductId();
  const orderState = useQuery("state");
  const isBotOpen = useQuery("bot");
  const [allFilteredProducts, setAllFilteredProducts] = useState([]);
  const [filteredComboOffers, setFilteredComboOffers] = useState([]);
  const [variantsProduct, setVariantsProduct] = useState({});
  const [variantsAllProduct, setVariantsAllProduct] = useState([]);
  const [showOffers, setShowOffers] = useState(false);
  const [classmenu, setClassmenu] = useState(false);
  const [variantPopup, setvariantPopup] = useState(false);
  const [variantGroupPlyusPopup, setVariantGroupPlyusPopup] = useState(false);
  const [loading, setLoading] = useState(true);
  const [botLoader, setBotLoader] = useState(false);
  const [loadingLocal, setLoadingLocal] = useState(false);
  const [dropDownTypes, setDropDownTypes] = useState({ vegan: isRestaurantVeg, egg: false, nonVeg: false, barItem: false, foodItem: false })
  const [searchValue, setSearchValue] = useState('');
  const [isOTPVerificationRequire, setIsOTPVerificationRequire] = useState(false);
  const [isOTPVerificationForGIP, setIsOTPVerificationForGIP] = useState(false);
  const [isOccupied, setIsOccupied] = useState();
  const [showModal, setShowModal] = useState(false);
  const [notifyIndicator, setNotifyIndicator] = useState(false);
  const [ordrState, setOrderState] = useState(orderState);
  const [realtimeListenerStarted, setRealtimeListenerStarted] = useState(false);
  const history = useHistory();
  const [searchField, setSearchField] = useState(false);
  const scrollRef = useRef();

  const MenuPageConstants = {
    COMBO_OFFERS_HEADING: 'Combo Offers',
    SIMPLE_COMBO: 'SIMPLE_COMBO',
    CUSTOMIZED_COMBO: 'CUSTOMIZED_COMBO',
    PAYX_GETY: 'PAY_X_GET_Y',
    BUYX_GETY: 'BUY_X_GET_Y',
    ELIGIBLE_COMBOS: ['SIMPLE_COMBO', 'CUSTOMIZED_COMBO', 'PAY_X_GET_Y', 'BUY_X_GET_Y'],
    CUSTOMIZED_COMBO_TYPES: ['CUSTOMIZED_COMBO', 'PAY_X_GET_Y', 'BUY_X_GET_Y'],
    BASE_PRICE_COMBOS: ['SIMPLE_COMBO', 'CUSTOMIZED_COMBO'],
    NO_BROWSER_LOCATION_SUPPORT: t('NO_BROWSER_LOCATION_SUPPORT'),
    NO_LOCATION_ACCESS: t('CHECK_BROWSER_SETTING_LOCATION'),
    BROWSE_MENU_INFO: t('BROWSE_MENU_INFO'),
    SELECT_LOCATION: t('SELECT_LOCATION'),
    CHANGE_LOCATION: t('CHANGE_LOCATION'),
    ADD_BUTTON: 'Add button',
    INVALID_ORDERTYPE: t('INVALID_ORDERTYPE')
  };
  useEffect(() => {
    setDropDownTypes({ ...dropDownTypes, vegan: isRestaurantVeg })
  }, [isRestaurantVeg])

  let spinnerText = false;
  if (!spinnerText && history?.location?.state?.text) {
    spinnerText = history?.location?.state?.text;
  }
  const filterDataLength = useRef(0)

  
  let customizableProductID = get(history, "location.state.ref_id", null);
  let addNewCustomizationProductID = get(history, "location.state.ref_id", null);
  if (comboIdentifier && customizableProductID && cart) {
    setComboInCart(cart.find(item => get(item, 'ref_id') === customizableProductID && get(item, 'comboIdentifier') === comboIdentifier));
  }
  if (cart.length <= 0) {
    setComboInCart(null);
    setComboIdentifier(null);
  }
  let comboVariations = 0, comboItemCount = 0;

  const [isCartEmpty, setIsCartEmpty] = useState(false);
  const [isServiceable, setIsServiceable] = useState(true);
  const [serviceability, setServiceability] = useState();


  usePersistedLocalStorageState(orderType, productId, "orderType");

  const [comboOffers, setComboOffers] = useState([])
  const [showCombosPopup, setShowCombosPopup] = useState(false)
  const [selectedCombo, setSelectedCombo] = useState({})

  const cartLength = useRef(0)
  const selectedCategory = useRef('')

  const [menuFooterHeight, setMenuFooterHeight] = useState('100px')
  const [domReady, setDomReady] = useState(false)

  const restaurantType = get(restaurant, 'meta_data.type')
  const isRestaurantIndian = get(restaurant, 'meta_data.address.country', '').toLowerCase() === 'India'.toLowerCase();

  const previousScrollTop = useRef(0)
  const placeholderTableNumber = useRef('')
  const elementToScrollTo = useRef(null)
  const positionToScrollTo = useRef(null);


  useEffect(() => {
    setOffers({});
    if (orderType === OrderType.DELIVERY) {
      setMetaData({ ...metaData, createNewAddress: false });
    }
  }, []);

  useEffect(() => {
    const tableNumber = get(metaData, 'table_number')
    if (tableNumber) {
      placeholderTableNumber.current = tableNumber
    }
  }, [get(metaData, 'table_number')])

  useEffect(() => {
    setTimeout(() => {
      const footer = document.getElementById('menu-footer')
      if (footer) {
        setMenuFooterHeight(`${footer.offsetHeight + 14}px`)
      }
    }, 100)
  }, [isGIPOpen, searchField, domReady, landingModal])

  const handleMenuScroll = () => {
    const menuItemsWrapper = document.getElementById("menu-items-wrapper");
    if (window.scrollY < (get(menuItemsWrapper, "offsetTop", 0)) && window.scrollY <= previousScrollTop.current) {
      document.getElementById('search-and-filter')?.classList.remove('search-sticky')
    } else if (window.scrollY > (get(menuItemsWrapper, "offsetTop", 0) - 60) && window.scrollY > previousScrollTop.current) {
      document.getElementById('search-and-filter')?.classList.add('search-sticky')
    }
    previousScrollTop.current = window.scrollY
    if (window.scrollY !== 0) {
      positionToScrollTo.current = window.scrollY
    }
  };

  useEffect(() => {
    setTimeout(() => {
      const menuItems = document.getElementById("menu-items-wrapper");
      if (menuItems) {
        window.addEventListener("scroll", handleMenuScroll);
        return () => {
          window.removeEventListener("scroll", handleMenuScroll);
        }
      } else {
        if (!domReady) {
          setDomReady(true)
        }
      }
    }, 200)
  }, [isGIPOpen, restaurant, orderType])

  useEffect(() => {
    setTimeout(() => {
      const menuItems = document.getElementById("menu-items-wrapper");
      if (menuItems) {
        window.addEventListener("scroll", handleMenuScroll);
        return () => {
          window.removeEventListener("scroll", handleMenuScroll);
        };
      }
    }, 200)
  }, [domReady, landingModal]);

  useEffect(() => {
    if (addNewCustomizationProductID && get(comboOffers, 'length', 0) > 0) {
      const comboInCartRefId = cart.find(product => product.ref_id === addNewCustomizationProductID && customizeExistingFlag.current)?.ref_id;
      const combo = comboOffers.find(product => product.code === comboInCartRefId);
      if (combo) {
        history.replace();
        addComboToCart(combo, combo.combo_type, "");
      }
    }
  }, [comboOffers]);

  const currentFilterTypes = useRef(dropDownTypes);
  const minimumOrderAmount = !get(products, 'configuration.gs_free_delivery.enabled') && get(products, 'self_delivery.enabled') ? get(products, 'self_delivery.minimum_order_amount', 0) : 0
  const freeDeliveryAmount = getFreedeliveryAmount(products);

  const serverTime = get(products, 'loadMenuTime')

  useEffect(() => {
    if (cart && cart.length === 0) {
      setOffers({});
    }
  }, [cart]);


  const clevertapDebounce = debounce(value => value && clevertapEventPush({
    eventId: CT_EVENT_IDS.SEARCH_INITIATED,
    eventData: {
      keyword: value
    }
  }), 1000)

  const triggerClevertapSearchEvent = useCallback(clevertapDebounce, [])

  useEffect(() => {
    triggerClevertapSearchEvent(searchValue)
  }, [searchValue]);

  const triggerClevertapOrderTypeSwitchEvent = (orderType) => {
    clevertapEventPush({
      eventId: CT_EVENT_IDS.ORDER_TYPE_SWITCHED,
      eventData: {
        'Switched to': orderType
      }
    })
  }

  useEffect(() => {
    const selectedItemFilter = reduce(dropDownTypes, function (result, value, key) {
      return isEqual(value, currentFilterTypes.current[key]) ?
        result : key;
    }, '')
    currentFilterTypes.current = dropDownTypes
    const filterTypeMap = {
      foodItem: 'Food',
      barItem: 'Bar',
      vegan: 'Vegan',
      nonVeg: 'Non-Veg',
      egg: 'Egg'
    }
    dropDownTypes[selectedItemFilter] && clevertapEventPush({
      eventId: CT_EVENT_IDS.FILTER_APPLIED,
      eventData: {
        option: filterTypeMap[selectedItemFilter]
      }
    })
  }, [dropDownTypes])

  useEffect(() => {
    if (orderType && get(restaurant, `order_types[${orderType}]enabled`) && !get(restaurantUIStateData, 'viewMenu')) {
      setRestaurantUIStateData({ ...restaurantUIStateData, orderTypeDisabled: false });
    }
    get(restaurantUIStateData, 'viewMenu') && setRestaurantUIStateData({ ...restaurantUIStateData, viewMenu: false })
  }, [orderType, restaurant]);

  useEffect(() => {
    if (restaurant?.order_types && orderType) {
      const serviceabilityFromResp = get(restaurant, `order_types[${orderType}].serviceability`)
      setServiceability(serviceabilityFromResp);
      if (!(!!serviceabilityFromResp)) {
        Notification({
          type: "error",
          displayMessage: MenuPageConstants.INVALID_ORDERTYPE,
          toasterOptions: {
            toastId: 'invalid-ordertype-message',
          },
        });
      }
    }
    if (orderType === OrderType.DELIVERY) {
      setIsServiceable(!(!!get(metaData, 'notServiceable')))
    } else {
      setIsServiceable(get(serviceability, 'isServiceable'))
    }
  }, [metaData?.notServiceable, orderType, restaurant, serviceability]);

  const goToLocationPage = (eventLocation) => {
    wentToCheckout.current = true;
    triggerClevertapEventForChangeLocation(eventLocation)
    pushHistory(history, VEIWADDRESS, VEIWADDRESS_D, productId);
  };

  const handleNoUserLocationForDelivery = (errorMessage) => {
    if (restaurantType === RESTAURANT_TYPE.OUTLET) {
      setStoreLocationData({
        ...storeLocationData,
        address: get(restaurant, "meta_data.address.address"),
        fullAddress: get(restaurant, 'meta_data.address'),
        lat: +get(restaurant, "meta_data.address.lat"),
        long: +get(restaurant, "meta_data.address.long"),
        restaurantName: get(restaurant, 'meta_data.name')
      })
      setUserLocationStatusForDelivery('error')
      setMetaData({ ...metaData, notServiceable: true })
      errorMessage && Notification({
        type: "error",
        displayMessage: errorMessage,
        toasterOptions: {
          toastId: `${errorMessage}-message`,
        },
      });
    }
  }

  const handleStoreLocationData = (userPosition, userLocationExists) => {
    const latPath = userLocationExists ? 'lat' : 'coords.latitude'
    const longPath = userLocationExists ? 'long' : 'coords.longitude'
    if (restaurantType === RESTAURANT_TYPE.OUTLET) {
      const userLatAndLong = {
        lat: get(userPosition, latPath),
        long: get(userPosition, longPath)
      };
      const storeLatAndLong = {
        lat: get(restaurant, "meta_data.address.lat"),
        long: get(restaurant, "meta_data.address.long"),
      };
      const storeDistanceToUser = getDistance(userLatAndLong, storeLatAndLong).toFixed(2)
      setStoreLocationData({
        ...storeLocationData,
        address: get(restaurant, "meta_data.address.address"),
        fullAddress: get(restaurant, 'meta_data.address'),
        lat: +get(restaurant, "meta_data.address.lat"),
        long: +get(restaurant, "meta_data.address.long"),
        restaurantName: get(restaurant, 'meta_data.name'),
        storeDistanceToUser
      });
      const allUserAddressData = userLocationExists ? userPosition : {}
      setUserLocationData({
        ...allUserAddressData,
        lat: get(userPosition, latPath),
        long: get(userPosition, longPath)
      });
      setUserLocationStatusForTakeaway('success')
    }
  };

  const handleNoUserLocationForTakeaway = (errorMessage) => {
    if (restaurantType === RESTAURANT_TYPE.OUTLET) {
      setStoreLocationData({
        ...storeLocationData,
        address: get(restaurant, "meta_data.address.address"),
        fullAddress: get(restaurant, 'meta_data.address'),
        lat: +get(restaurant, "meta_data.address.lat"),
        long: +get(restaurant, "meta_data.address.long"),
        restaurantName: get(restaurant, 'meta_data.name')
      })
      setUserLocationStatusForTakeaway('error')
      errorMessage && Notification({
        type: "error",
        displayMessage: errorMessage,
        toasterOptions: {
          toastId: `${errorMessage}-message`,
        },
      });
    }
  }

  const fetchUserLocationForTakeaway = (userLocation) => {
    if (userLocation) {
      handleStoreLocationData(userLocation, !!userLocation);
    } else {
      handleNoUserLocationForTakeaway()
    }
  };

  const generateAddressDataFromResponse = (locationData, res) => {
    return {
      ...locationData,
      addressReference: get(locationData, 'address_id', ''),
      deliveryCharge: get(res, 'estimated_price', null),
      delivery_partner: get(res, 'delivery_partner', ''),
      estimated_delivery_time: get(res, 'estimated_delivery_time', ''),
      minOrderAmount: get(res, 'min_order_amt', null),
      freeDeliveryAmount: get(res, 'free_delivery_amt', null)
    }
  }

  useEffect(() => {
    if (get(restaurant, 'meta_data') && orderType === OrderType.DELIVERY && !userLocationStatusForDelivery) {
      if (locationChangedFromTakeaway.current || userLocationData) {
        locationChangedFromTakeaway.current = false
        const addressValidationPayload = {
          restaurantData: {
            lat: get(restaurant, "meta_data.address.lat"),
            long: get(restaurant, "meta_data.address.long"),
            pincode: get(restaurant, "meta_data.address.pincode"),
          },
          address_data: {
            lat: get(userLocationData, "lat"),
            long: get(userLocationData, "long"),
            pincode: get(userLocationData, "pincode"),
          },
          restaurant_id: productId
        };
        getQuoteData(addressValidationPayload, true).then((res) => {
          if (get(res, "isServiceable", false)) {
            setUserLocationData({
              ...userLocationData,
              estimated_delivery_time: get(res, 'estimated_delivery_time', '')
            });
            setUserLocationStatusForDelivery('success')
            setUserLocationStatusForTakeaway('success')
            setMetaData({ ...metaData, ...generateAddressDataFromResponse(userLocationData, res), notServiceable: false });
          } else {
            setUserLocationStatusForDelivery('error')
            setMetaData({ ...metaData, notServiceable: true });
          }
        }).catch(() => {
          setUserLocationStatusForDelivery('error')
          setMetaData({ ...metaData, notServiceable: true });
        });
      } else {
        handleNoUserLocationForDelivery()
      }
    }
  }, [orderType, get(restaurant, 'meta_data'), userLocationStatusForDelivery]);

  useEffect(() => {
    if (get(restaurant, 'meta_data') && orderType === OrderType.TAKEAWAY && !userLocationStatusForTakeaway) {
      fetchUserLocationForTakeaway(userLocationData || null);
    }
  }, [orderType, get(restaurant, 'meta_data'), userLocationStatusForTakeaway]);

  useEffect(() => {
    if (orderType === OrderType.DINEIN && metaData.table_number && !dineIndetails.isBlocked && isServiceable) {
      checkTableStatus(productId, metaData.table_number).then(res => {
        if (res.response === "success" && res.is_occupied) {
          if (res && res.order_id && res.payment && ((res.payment.status === paymentStatus.COMPLETED && res.payment.mode === PaymentMode.ONLINE) ||
            (res.payment.status === paymentStatus.PENDING && res.payment.mode === PaymentMode.CASH))) {
            setIsServiceable(false);
            setIsOccupied(true);
            setDineinDetails({ ...dineIndetails, isBlocked: true, isPaid: true });
            setShowModal(false);
          } else {
            //Join table popup commented
            /*setIsOccupied(true);
            setShowModal(true);*/
            blockTableforUser(joinTableAPI);
            getSuborders();
          }
        }
        else {
          blockTableforUser(blockTable);
          getSuborders();
        }
      }).catch(e => {
        setMetaData({});
        setDineinDetails({})
        console.log(e);
      })
    }
  }, [metaData.table_number]);

  const triggerClevertapEventForChangeLocation = (eventLocation) => {
    clevertapEventPush({
      eventId: CT_EVENT_IDS.REQUEST_LOCATION,
      eventData: {
        Button: eventLocation,
      },
    });
  }

  const getSuborders = () => {
    if (orderType === OrderType.DINEIN && metaData.table_number) {
      checkTableStatus(productId, metaData.table_number).then(res => {
        if (res && res.response === "success" && res.is_occupied) {
          getSubOrderData(res);
        }
      }).catch(e => {
        console.log(e);
      })
    } else {
      setDineinDetails({});
    }
  }

  const getSubOrderData = (table_data) => {
    let offer = getOffers(productId);
    offer = offer ? JSON.parse(offer) : offer;
    let offer_id = offers.offer_id ? offers.offer_id : offer && offer.offer_id ? offer.offer_id : "";
    getAllSubOrders(productId, metaData.table_number, offer_id).then(result => {
      if (result.status === "success") {
        if (offer_id && get(result, 'bill_summary.sub_total_offer') <= 0) {
          setOffers({})
        }
        if (table_data && table_data.order_id && table_data.payment && ((table_data.payment.status === paymentStatus.COMPLETED && table_data.payment.mode === PaymentMode.ONLINE) ||
          (table_data.payment.status === paymentStatus.PENDING && table_data.payment.mode === PaymentMode.CASH))) {
          setIsServiceable(false);
          setIsOccupied(true);
          setDineinDetails({ ...dineIndetails, subOrders: result, isBlocked: true, isPaid: true });
        }
        else {
          setDineinDetails({ ...dineIndetails, subOrders: result, isBlocked: true });
        }

      }
    }).catch(e => {
      let errorCode = get(e, 'response.data.error[0].code', '');
      if (errorCode === errorCodes.INVALID_DISCOUNT_CODE) {
        setOffers({});
        Notification({
          type: "error",
          displayMessage: i18n.t("notificationMsg.COUPON_REMOVE_FROM_ORDER"),
          toasterOptions: {
            toastId: "offer-remove",
          },
        })
      }
    })
  }
  const blockTableforUser = (API) => {
    API(productId, metaData.table_number).then(res => {
      if (res.response.status === "success" && res.response.is_occupied) {
        setIsOccupied(true);
        setDineinDetails({ ...dineIndetails, isBlocked: true })
        setShowModal(false);
      }
      else {
        //setIsOccupied(isOccupied);
      }
    }).catch(e => {
      console.log(e);
    })
  }
  useEffect(() => {
    if (cart.length > 0 && products) {
      let refreshedCart = returnRefreshedCart(products, cart)
      setCart(refreshedCart);
    }
  }, [products]);


  // blocks history back
  useEffect(() => {
    const OptHistoryBack = history.block((location, action) => {
      if (action === "POP") {
        if (isGIPOpen) {
          setGIPOpen(false);
        } else if (variantPopup) {
          setvariantPopup(false);
        } else if (variantGroupPlyusPopup) {
          setVariantGroupPlyusPopup(false);
        } else if (classmenu) {
          setClassmenu(false);
        } else if (searchValue) {
          setSearchValue("");
        } else if (variantGroupPlyusPopup) {
          setVariantGroupPlyusPopup(false);
        } else if (isOTPVerificationRequire) {
          setIsOTPVerificationRequire(false);
        } else if (isOTPVerificationForGIP) {
          setIsOTPVerificationForGIP(false);
        } else if (showCombosPopup) {
          setShowCombosPopup(false);
        } else if (landingModal) {
          setLandingModal(false);
        } else {
          clevertapEventPush({
            eventId: CT_EVENT_IDS.BACK_BUTTONS_CLICKED,
            eventData: {
              Location: allPages.MENU_PAGE,
            },
          });
          return true;
        }
        return false;
      }
    });

    return () => { OptHistoryBack(); }
  }, [variantPopup, isGIPOpen, variantGroupPlyusPopup, classmenu, searchValue, isOTPVerificationRequire, isOTPVerificationForGIP, showCombosPopup, landingModal]);

  const checkIsAnyModalOpen = () => (landingModal && !defaultOutletShown) || showCombosPopup || isOTPVerificationForGIP || isOTPVerificationRequire || variantGroupPlyusPopup || variantPopup || classmenu || showModal || isCartEmpty || repititionModal

  useEffect(() => {
    if (checkIsAnyModalOpen()) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }
  }, [landingModal, defaultOutletShown, showCombosPopup, isOTPVerificationForGIP, isOTPVerificationRequire, variantGroupPlyusPopup, variantPopup, classmenu, showModal, isCartEmpty, repititionModal])

  useEffect(() => {
    if (!showCombosPopup && positionToScrollTo.current) {
      window.scroll(0, positionToScrollTo.current)
    }
  }, [showCombosPopup])

  useEffect(() => {
    if (!classmenu) {
      elementToScrollTo.current && window.scrollTo({
        behavior: 'smooth',
        top: get(elementToScrollTo, 'current.offsetTop')
      })
      elementToScrollTo.current = null
    }
  }, [classmenu])

  useEffect(() => {
    if (products && serverTime) {
      const filteredData = filterProductList(products.menu);
      setAllFilteredProducts([...filteredData]);
      filterDataLength.current = get(filteredData, 'length', 0)
    }
  }, [searchValue, dropDownTypes, serverTime, products]);

  useEffect(() => {
    if (products && serverTime) {
      const filteredOutCombos = filterComboOffers()
      setFilteredComboOffers(filteredOutCombos)
      filterDataLength.current = filterDataLength.current || get(filteredOutCombos, 'length', 0)
    }
  }, [searchValue, dropDownTypes, serverTime, comboOffers, searchField])

  useEffect(() => {
    if (scrollRef.current && filterDataLength.current > 0) {
      window.scrollTo({
        behavior: 'smooth',
        top: scrollRef.current.offsetTop
      })
    }
  }, [dropDownTypes]);

  const checkAllVariantsOutOfStock = (item, listType = 'item') => {
    if (listType === 'item') {
      item = get(item, 'items[0]', item)
      const allVariantGroupsOfItem = get(item, 'variant_groups')
      if (allVariantGroupsOfItem) {
        for (let i = 0; i < allVariantGroupsOfItem.length; i++) {
          const variantsForGroup = get(allVariantGroupsOfItem, `[${i}]variants`, [])
          if (get(variantsForGroup, 'length', 0) === 0) {
            return true
          }
          const inStockStatusForGroup = variantsForGroup.reduce((netStatus, variant) => {
            const variantStatus = get(variant, 'in_stock', false)
            return netStatus || variantStatus
          }, false)
          if (inStockStatusForGroup) {
            continue
          } else {
            return true
          }
        }
      }
      return false
    } else if (listType === 'combo') {
      const allVariantGroupsOfItem = get(item, 'variant_groups', [])
      if (allVariantGroupsOfItem.length > 0) {
        for (let i = 0; i < allVariantGroupsOfItem.length; i++) {
          const variantsForGroup = get(products, `variant_groups[${allVariantGroupsOfItem[i]}]variants`, [])
          if (get(variantsForGroup, 'length', 0) === 0) {
            return true
          }
          const variantsForGroupDetails = variantsForGroup.map(variantId => {
            return get(products, `variants[${variantId}]`, {})
          })
          const inStockStatusForGroup = variantsForGroupDetails.reduce((netStatus, variant) => {
            const variantStatus = get(variant, 'in_stock', false)
            return netStatus || variantStatus
          }, false)
          if (inStockStatusForGroup) {
            continue
          } else {
            return true
          }
        }
      }
      return false
    }
  }

  const checkAllNecessaryAddonsOutOfStock = (item, listType = 'item') => {
    if (listType === 'item') {
      item = get(item, 'items[0]', item)
      const addonGroupsFromVariants = (get(item, 'variant_groups', []) || []).reduce((aGrps, vGrp) => {
        return [...aGrps, ...(get(vGrp, 'variants', []) || []).reduce((aGrpsFromVts, vDetails) => {
          return [...aGrpsFromVts, ...(get(vDetails, 'add_on_groups', []) || [])]
        }, [])]
      }, [])
      const allAddonGroupsOfItem = [...(get(item, 'add_on_groups', []) || []), ...addonGroupsFromVariants]
      if (allAddonGroupsOfItem) {
        for (let i = 0; i < allAddonGroupsOfItem.length; i++) {
          const addonsForGroup = get(allAddonGroupsOfItem, `[${i}]addons`, [])
          const minimumReqd = get(allAddonGroupsOfItem, `[${i}]minimum_needed`, 0)
          if (minimumReqd > 0 && get(addonsForGroup, 'length', 0) < minimumReqd) {
            return true
          }
          if (minimumReqd > 0) {
            const inStockCountForGroup = addonsForGroup.reduce((netCount, addon) => {
              return get(addon, 'in_stock', false) ? netCount + 1 : netCount
            }, 0)
            if (inStockCountForGroup >= minimumReqd) {
              continue
            } else {
              return true
            }
          } else {
            continue
          }
        }
      }
      return false
    } else if (listType === 'combo') {
      const allAddonGroupsOfItem = get(item, 'add_on_groups')
      if (allAddonGroupsOfItem) {
        for (let i = 0; i < allAddonGroupsOfItem.length; i++) {
          const addonsForGroup = get(products, `add_on_groups[${allAddonGroupsOfItem[i]}]addons`, [])
          const addonsForGroupDetails = addonsForGroup.map(addonId => {
            return get(products, `addons[${addonId}]`, {})
          })
          const minimumReqd = get(products, `add_on_groups[${allAddonGroupsOfItem[i]}]minimum_needed`, 0)
          if (minimumReqd > 0 && get(addonsForGroup, 'length', 0) < minimumReqd) {
            return true
          }
          if (minimumReqd > 0) {
            const inStockCountForGroup = addonsForGroupDetails.reduce((netCount, addon) => {
              return get(addon, 'in_stock', false) ? netCount + 1 : netCount
            }, 0)
            if (inStockCountForGroup >= minimumReqd) {
              continue
            } else {
              return true
            }
          } else {
            continue
          }
        }
      }
      return false
    }
  }

  const checkIfAnyItemOutOfStock = (listOfItems = []) => {
    const itemOutOfStock = !!listOfItems.find(item => !get(products, `items[${item}].in_stock`))
    if (itemOutOfStock) {
      return true
    } else {
      return !!listOfItems.find(itemId => {
        const item = get(products, `items[${itemId}]`, {})
        if (get(item, 'item_offer_time', []).length > 0) {
          return !isItemAvailableNow(get(item, 'item_offer_time', []), serverTime)
        }
        return false
      })
    }
  }

  const checkAllItemsInSectionOutOfStock = combo => {
    const allSections = get(combo, 'sections', [])
    for (let i = 0; i < allSections.length; i++) {
      const itemsInSection = get(allSections[i], 'items', [])
      const outOfStockFlagForItems = itemsInSection.reduce((reducedFlag, item) => {
        const itemDtls = get(products, `items[${item}]`)
        const notInStockFlag = !get(itemDtls, 'in_stock', false)
        if (notInStockFlag) {
          return notInStockFlag && reducedFlag
        } else {
          if (get(itemDtls, 'item_offer_time', []).length > 0) {
            return !isItemAvailableNow(get(itemDtls, 'item_offer_time', []), serverTime) && reducedFlag
          }
          return false && reducedFlag
        }
      }, true)
      if (outOfStockFlagForItems) {
        return true
      }
      continue
    }
    return false
  }

  const checkIfComboItemOutOfStock = (itemId) => {
    const itemDetails = get(products, `items[${itemId}]`)
    const notInStockFlag = !get(itemDetails, 'in_stock', false)
    if (notInStockFlag) {
      return notInStockFlag
    } else {
      if (get(itemDetails, 'item_offer_time', []).length > 0) {
        return !isItemAvailableNow(get(itemDetails, 'item_offer_time', []), serverTime)
      }
      return false
    }
  }

  const checkIfComboOutOfStock = (combo) => {

    const itemsInCombos = get(combo, 'sections', []).reduce((allItems, section) => {
      const itemsInSection = get(section, 'items', [])
      return [...allItems, ...itemsInSection]
    }, [])
    const comboType = get(combo, 'combo_type');
    if (comboType === MenuPageConstants.SIMPLE_COMBO && checkIfAnyItemOutOfStock(itemsInCombos)) {
      return true
    } else if (checkAllItemsInSectionOutOfStock(combo)) {
      return true
    } else {
      if (comboType === MenuPageConstants.CUSTOMIZED_COMBO) {
        const sectionsInCustCombo = get(combo, 'sections', [])
        for (let i = 0; i < sectionsInCustCombo.length; i++) {
          const addItemOnce = get(sectionsInCustCombo, `[${i}].addItemOnce`, false)
          if (addItemOnce) {
            const itemsInSection = get(sectionsInCustCombo, `[${i}].items`, [])
            const itemsNeeded = get(sectionsInCustCombo, `[${i}].items_needed`, 0)
            let itemsOutOfStockCounter = 0
            itemsInSection.forEach(itemInSection => {
              if (checkIfComboItemOutOfStock(itemInSection)) {
                itemsOutOfStockCounter += 1
              }
            })
            if ((itemsInSection.length - itemsOutOfStockCounter) < itemsNeeded) {
              return true
            }
          }
          const variantGroupsForThisSection = get(sectionsInCustCombo[i], 'variant_groups', [])
          if (variantGroupsForThisSection.length > 0) {
            if (checkAllVariantsOutOfStock({ variant_groups: variantGroupsForThisSection }, 'combo')) {
              return true
            } else {
              const addonGroupsInSection = get(sectionsInCustCombo[i], 'add_on_groups', [])
              if (addonGroupsInSection.length > 0) {
                if (checkAllNecessaryAddonsOutOfStock({ add_on_groups: addonGroupsInSection }, 'combo')) {
                  return true
                }
                continue
              }
              continue
            }
          }
          continue
        }
      } else if (comboType === MenuPageConstants.SIMPLE_COMBO) {
        return checkOutofStockSimpleCombo(combo);
      } else { // for buy x and pay x combos
        const sectionsInCombo = get(combo, 'sections', [])
        for (let i = 0; i < sectionsInCombo.length; i++) {
          const itemsInSection = get(sectionsInCombo, `[${i}].items`, [])
          const addItemOnce = get(sectionsInCombo, `[${i}].addItemOnce`, false)
          const itemsNeeded = get(sectionsInCombo, `[${i}].items_needed`, 0) + get(sectionsInCombo, `[${i}].items_free`, 0)
          let allItemsOutOfStockCounter = 0
          for (let j = 0; j < itemsInSection.length; j++) {
            if (addItemOnce && checkIfComboItemOutOfStock(itemsInSection[j])) {
              allItemsOutOfStockCounter += 1
            } else {
              const variantGroupsForItem = get(products, `items[${itemsInSection[j]}].variant_groups`, [])
              if (variantGroupsForItem.length > 0) {
                if (checkAllVariantsOutOfStock({ variant_groups: variantGroupsForItem }, 'combo')) {
                  allItemsOutOfStockCounter += 1
                } else {
                  const addonGroupsForItem = get(products, `items[${itemsInSection[j]}].add_on_groups`, [])
                  const allAddonGroupsFromVariants = variantGroupsForItem.reduce((reducedAddonGroups, vGrp) => {
                    const variantsForGrp = get(products, `variant_groups[${vGrp}].variants`, [])
                    const addonGroupsFromVariants = variantsForGrp.reduce((reducedAddonGroupsFromVts, vrt) => {
                      const addonGroups = get(products, `variants[${vrt}].add_on_groups`, [])
                      return [...reducedAddonGroupsFromVts, addonGroups]
                    })
                    return [...reducedAddonGroups, addonGroupsFromVariants]
                  }, [])
                  const allAddonGrps = [...addonGroupsForItem, allAddonGroupsFromVariants]
                  if (checkAllNecessaryAddonsOutOfStock({ add_on_groups: allAddonGrps }, 'combo')) {
                    allItemsOutOfStockCounter += 1
                  }
                }
              } else {
                const addonGroupsForItem = get(products, `items[${itemsInSection[j]}].add_on_groups`, [])
                if (addonGroupsForItem.length > 0) {
                  if (checkAllNecessaryAddonsOutOfStock({ add_on_groups: addonGroupsForItem }, 'combo')) {
                    allItemsOutOfStockCounter += 1
                  }
                }
              }
            }
          }
          if (addItemOnce ? (itemsInSection.length - allItemsOutOfStockCounter) < itemsNeeded : allItemsOutOfStockCounter === itemsInSection.length) {
            return true
          }
        }

      }
    }
  }

  const checkOutofStockSimpleCombo = (combo) => {
    if (products && combo) {
      let addonStatus = false, variantStatus = false;
      const itemsDetails = get(combo, 'sections', []).reduce((allAGrps, section) => {
        const item_details = get(section, 'items_details', [])
        return [...allAGrps, ...item_details]
      }, []);
      let addonArray = [];
      let variantArray = [];
      if (itemsDetails.length > 0) {
        itemsDetails.map((data, it) => {
          const addon_details = get(data, 'addons', []);
          const variant_details = get(data, 'variants', []);

          Object.keys(addon_details).map((key) => {
            let array = addon_details[key];
            addonArray = addonArray.concat(array);
          })
          Object.keys(variant_details).map((key) => {
            let array = variant_details[key];
            variantArray = variantArray.concat(array);
          })

        });
      }
      for (var i = 0; i < addonArray.length; i++) {
        let addons = products.addons[addonArray[i]];
        addonStatus = get(addons, 'in_stock', false);
        if (addonStatus) {
          continue
        } else {
          return true
        };
      }
      for (var j = 0; j < variantArray.length; j++) {
        let variants = products.variants[variantArray[j]];
        variantStatus = get(variants, 'in_stock', false);
        if (variantStatus) {
          continue
        } else {
          return true
        };
      }
      return false;
    } else {
      return false;
    }
  }

  const segregateCombos = (eligibleCombos) => {
    const combosWithImages = []
    const combosWithoutImages = []
    for(const eligibleCombo of eligibleCombos) {
      if(!!get(eligibleCombo, 'image_url')) {
        combosWithImages.push(eligibleCombo)
      } else {
        combosWithoutImages.push(eligibleCombo)
      }
    }
    setComboOffers([...combosWithImages, ...combosWithoutImages])
  }

  const processCombos = (allCombos) => {
    const eligibleCombos = Object.keys(allCombos)
    .filter(comboId => {
      const currentCombo = get(allCombos, `[${comboId}]`)
      return get(currentCombo, 'is_active', false) && !checkIfComboOutOfStock(currentCombo) && get(currentCombo, 'fulfillment_modes', []).includes(orderType) && MenuPageConstants.ELIGIBLE_COMBOS.includes(get(currentCombo, 'combo_type', ''))
    })
    .reduce((combosArr, currentItem) => [...combosArr, allCombos[currentItem]], [])
    segregateCombos(eligibleCombos) 
  }

  useEffect(() => {
    // Skip un-necessary API Calls in Route is not valid
    if (!orderType) {
      return;
    }
    setProductId(productId);
    if (products && restaurantColor) {
      if (products.configuration && products.configuration.showOffers) {
        setShowOffers(products.configuration.showOffers);
      }
      const allCombos = get(products, 'combos', {})
      processCombos(allCombos)
      setLoading(false);
    }

  }, [orderType, products, restaurantColor]);


  const handleInstallClick = () => {
    setPwaAppInstallable(false);
    pwaDeferredPrompt.current.prompt();

  };
  useEffect(() => {
    if (ordrState === OrderStatus.PAYMENT_CONFIRMED || ordrState === OrderStatus.PAYMENT_CONFIRMED_CASH) {
        orderInfo.current && clevertapEventPush(orderInfo.current)
        setCart([]);
        setOffers({});
        setBotLoader(true);
      isOrderPlaced.current = true;
        setLoadingLocal(true);
      if (orderType === OrderType.DINEIN && orderState === OrderStatus.PAYMENT_CONFIRMED) {
        getSuborders();
        }
        setOrderState("");
        window.history.replaceState({}, document.title, window.location.href.split('?')[0]);
            //Removing loader and showing bot after 10 seconds in case loader gets stuck due to connectivity issue
      setTimeout(function () {
        setIsOTPVerificationForGIP(false);
        setGIPOpen(true);
        setBotLoader(false);
        setLoadingLocal(false);
        if (!isGIPOpen) {
                setGIPOpen(true);
                markNotificationAsRead(productId, setNotifyIndicator);
        }
        }, 10000);
    }

      
  }, [orderType, products]);

  useEffect(() => {
    if (isBotOpen) {
      setGIPOpen(true);
      window.history.replaceState({}, document.title, window.location.href.split('?')[0]);
    }
  }, [orderType, products]);
  useEffect(() => {
    if (dineIndetails && dineIndetails.isBlocked && !dineIndetails.isPaid && orderType === OrderType.DINEIN && metaData.table_number) {
      getSuborders();
    }
    //Checking payment status to disable placing orders
  }, [orderType, metaData.table_number]);

  useEffect(() => {
    if (dineIndetails.isPaid) {
      setIsServiceable(false);
    }
  }, [dineIndetails]);

  useEffect(() => {
    if (jsCookie.get('gipuserid') && !!restaurantBot) {
      if (!realtimeListenerStarted) {
        setRealtimeListenerStarted(true);
      }
      realtimeListenToNotifications();
    }
  }, [gipUsr, restaurantBot, realtimeListenerStarted, notifications]);

  useEffect(() => {
    if (restaurantBot && orderType && jsCookie.get('gipuserid') && !!custGIPLink) {
      mapOrderTypeInbot(productId, { store_name: storeName, botname: restaurantBot, ordertype: orderType, table_no: orderType === OrderType.DINEIN && metaData.table_number ? metaData.table_number : null });
    }
  }, [orderType, restaurantBot, metaData.table_number, custGIPLink])

  const filterItem = (listItem) => {
    const vegan = dropDownTypes.vegan;
    const egg = dropDownTypes.egg;
    const nonVeg = dropDownTypes.nonVeg;
    const barItem = dropDownTypes.barItem;
    const foodItem = dropDownTypes.foodItem;

    return listItem.items.filter(item => {
      if ((!item.in_stock && !item.items) || get(item, 'hidden')) {
        return false;
      }
      if (item.price === 0 && (!item.add_on_groups && !item.variant_groups)) {
        return false;
      }
      if (!item.items && get(item, "item_offer_time") && !isItemAvailableNow(get(item, "item_offer_time"), serverTime)) {
        return false;
      }

      if (item.fulfillment_modes && !item.fulfillment_modes.includes(orderType)) {
        return false;
      }
      if (!get(item, 'items.length') && (checkAllVariantsOutOfStock(item) || checkAllNecessaryAddonsOutOfStock(item))) {
        return false
      }
      //Filtering on the basis of food_type
      let dropdownFilter = (vegan || egg || nonVeg) ?
        ((vegan && item.food_type === 1) || (egg && item.food_type === 3) || (nonVeg && item.food_type === 2)) : true;

      //Filtering on the basis of bar or food item
      dropdownFilter = dropdownFilter && ((barItem || foodItem) ?
        ((barItem && item.is_bar_item) || (foodItem && !item.is_bar_item)) : true);

      if (item?.items?.length) {
        item.items = item.items.filter(subcategoryItem => {
          if (!subcategoryItem.in_stock || subcategoryItem.hidden) {
            return false;
          }
          if (subcategoryItem.price === 0 && (!subcategoryItem.add_on_groups && !subcategoryItem.variant_groups)) {
            return false;
          }
          if (get(subcategoryItem, "item_offer_time") && !isItemAvailableNow(get(subcategoryItem, "item_offer_time"), serverTime)) {
            return false;
          }
          // for subcategory items fulfi9llment_mode
          if (subcategoryItem.fulfillment_modes && !subcategoryItem.fulfillment_modes.includes(orderType)) {
            return false;
          }

          if (checkAllVariantsOutOfStock(subcategoryItem) || checkAllNecessaryAddonsOutOfStock(subcategoryItem)) {
            return false
          }
          let subDropdownFilter = (vegan || egg || nonVeg) ?
            ((vegan && subcategoryItem.food_type === 1) || (egg && subcategoryItem.food_type === 3) || (nonVeg && subcategoryItem.food_type === 2)) : true;

          //Filtering on the basis of bar or food item
          subDropdownFilter = subDropdownFilter && ((barItem || foodItem) ?
            ((barItem && subcategoryItem.is_bar_item) || (foodItem && !subcategoryItem.is_bar_item)) : true);

          return subcategoryItem.title.match(new RegExp(escapeRegExp(searchValue), 'gi')) !== null && subDropdownFilter;
        })
      }
      return !item.items ? item.title.match(new RegExp(escapeRegExp(searchValue), 'gi')) !== null && dropdownFilter : item.items.length;
    });
  }
  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }
  const offersDisplay = React.useMemo(() => {
    return (
      <OfferSlider
        menu_id={productId}
        restaurantColor={restaurantColor}
        orderType={orderType}
        metaData={metaData}
        paymentOptions={paymentOptions}
        showOffers={showOffers}
      />
    );
  }, [productId, showOffers]);

  const filterProductList = (menu) => {
    const listItems = cloneDeep(menu);
    const result = [];

    listItems.forEach((listItem) => {
      const filteredData = filterItem(listItem);

      if (filteredData.length) {
        listItem.items = filteredData;
        result.push(listItem);
      }
    });
    return result;
  };

  const clearDineInData = () => {
    if (orderType === OrderType.DINEIN) {
      setDineinDetails({});
      setMetaData({});
      markNotificationAsRead(productId, setNotifyIndicator);
    }
  };

  const openGIPWindow = () => {
    const cookie = jsCookie.get("gipuserid");
    if (cookie) {
      if (!!custGIPLink) {
        setIsOTPVerificationForGIP(false);
        setGIPOpen(true);
        setBotLoader(false);
        setLoadingLocal(false);
        markNotificationAsRead(productId, setNotifyIndicator);
      } else if (restaurantBot) {
        generateGipLink(restaurantBot)
          .then((result) => {
            setCustGIPLink(result.url, () => {
              setIsOTPVerificationForGIP(false);
              setGIPOpen(true);
              setBotLoader(false);
              setLoadingLocal(false);
              markNotificationAsRead(productId, setNotifyIndicator);
              mapOrderTypeInbot(productId, {
                store_name: storeName,
                botname: restaurantBot,
                ordertype: orderType,
                table_no:
                  orderType === OrderType.DINEIN && metaData.table_number
                    ? metaData.table_number
                    : null,
              });
            });
          })
          .catch((error) => {
            console.log("error", error);
          });
      } else {
        setCustGIPLink(null);
      }
      setIsOTPVerificationForGIP(false);
    } else {
      setIsOTPVerificationForGIP(true);
    }
  };

  const closeGIPWindow = () => {
    setGIPOpen(false);
    setBotLoader(false);
    markNotificationAsRead(productId, setNotifyIndicator);
  };

  const toggleGIPWindow = () => {
    if (!isGIPOpen) {
      openGIPWindow();
    } else {
      if (isOrderPlaced.current && pwaAppInstallable) {
        handleInstallClick();
      }
      isOrderPlaced.current = false;
      closeGIPWindow();
    }
  };

  const realtimeListenToNotifications = async () => {
    if (gipUsr) {
      await notifocationsInRealTime(productId, gipUsr, isGIPOpen, setNotifyIndicator, openGIPWindow, closeGIPWindow, getSuborders, clearDineInData, notifications);
    }
  };

  // menu
  function menu() {
    setClassmenu(!classmenu);
  }

  function menuItem(id, catName, footerClick) {
    clevertapEventPush({
      eventId: CT_EVENT_IDS.MENU_CATEGORY_SELECTED,
      eventData: {
        "Category selected": catName,
      },
    });
    const element = document.getElementById(`${id}-category-heading`);
    if(footerClick) {
      window.scrollTo({
        behavior: 'smooth',
        top: get(element, 'offsetTop')
      })
    } else {
      setClassmenu(!classmenu);
      elementToScrollTo.current = element
    }
  }


  function closeAndPay() {
    const cookie = jsCookie.get("gipuserid");
    if (!cookie) {
      setIsOTPVerificationRequire(true);
      return;
    }
    setIsOTPVerificationRequire(false);
    pushHistory(history, CHECKOUT, CHECKOUT_D, productId);
  }

  const triggerClevertapCheckoutEvent = () => {
    wentToCheckout.current = true;
    clevertapEventPush({
      eventId: CT_EVENT_IDS.CHECKOUT_CLICKED,
      eventData: {
        "No of items in cart": cart.length
          ? +cart.reduce((a, b) => a + b.cartcount, 0)
          : 0,
        "Amount of items in the cart": getTotalCartAmount(),
      },
    });
  };

  // cart
  function checkout() {
    const cookie = jsCookie.get('gipuserid');
    if (!cookie) {
      setIsOTPVerificationRequire(true);
      return;
    } else {
      setIsOTPVerificationRequire(false);
    }
    if (getTotalCartAmount() === 0) {
      setCart([]);
      setIsCartEmpty(true);
      return;
    }
    if (!cart.length) {
      setIsCartEmpty(true);
      return;
    }
    if (orderType === OrderType.DELIVERY && get(products, 'self_delivery.enabled')) {
      triggerClevertapCheckoutEvent();
      if (get(products, 'configuration.gs_free_delivery.enabled')) {
        pushHistory(history, CHECKOUT, CHECKOUT_D, productId);
      }
      else if (getTotalCartAmount() >= minimumOrderAmount) {
        pushHistory(history, CHECKOUT, CHECKOUT_D, productId);
      } else {
        Notification({
          type: "error",
          displayMessage: i18n.t('notificationMsg.MIN_ORDER_IS', { minOrderAmount: `${restaurantCurrencyValue}${minimumOrderAmount}` }),
          toasterOptions: {
            toastId: "minimum-amount-error"
          }
        })
        return
      }
    }
    if (orderType === OrderType.DINEIN) {
      triggerClevertapCheckoutEvent()
      if (metaData.table_number) {
        pushHistory(history, ORDERDETAILS, ORDERDETAILS_D, productId);
      }
      else {

        Notification({
          type: "error",
          displayMessage: i18n.t('SCAN_QR_TO_ORDER'),
          toasterOptions: {
            toastId: "table-error"
          }
        })
      }
    }
    else {
      const otherOrderTypesCheck = !!((orderType === OrderType.DELIVERY && get(products, 'self_delivery.enabled')) || (orderType === OrderType.DINEIN))
      !otherOrderTypesCheck && triggerClevertapCheckoutEvent()
      pushHistory(history, CHECKOUT, CHECKOUT_D, productId);
    }
  }

  const getVariantDesc = variant => {
    const getVAdonsForVariant = vAdons => vAdons.reduce((desc, val, i) => {
      return desc + (i === 0 ? get(val, 'title') : `, ${get(val, 'title')}`)
    }, '')
    return `${get(variant, 'title')} - ${get(variant, 'selectedVariant.title')}${get(variant, 'selectedVAdon', []).length > 0 ? ` with ${getVAdonsForVariant(get(variant, 'selectedVAdon'))}` : ''}`
  }

  const getAddonDesc = addOn => {
    const getAdons = Adons => Adons.reduce((desc, val, i) => {
      return desc + (i === 0 ? get(val, 'title') : `, ${get(val, 'title')}`)
    }, '')
    return `${get(addOn, 'title')}${get(addOn, 'selectedAddOn', []).length > 0 ? ` with ${getAdons(get(addOn, 'selectedAddOn', []))}` : ''}`
  }

  const getCurrentItemAmount = product => {
    const actualProductPrice = get(product, 'discounted_price', 0) || get(product, 'price', 0)
    const productVariantGroups = get(product, 'variant_groups')
    const productAddOns = get(product, 'add_on_groups')
    const cartCount = get(product, 'cartcount', 0)
    let price = actualProductPrice;
    if (productVariantGroups || productAddOns) {
      productVariantGroups && productVariantGroups.forEach(
        (item) => {
          price += get(item, 'selectedVariant.price', 0)
          if (item?.selectedVAdon) {
            item.selectedVAdon.forEach(
              it => {
                price += it.price
              }
            )
          }
        }
      );
      productAddOns && productAddOns.forEach(addOn => {
        addOn.selectedAddOn?.forEach(
          (item) => (price += item.price)
        );
      })
      return (price * cartCount);
    } else {
      return (actualProductPrice * cartCount);
    }
  }

  const getDesc = (groups, descFunc) => {
    const fullDesc = groups.reduce((desc, currentGroup, i) => {
      return desc + descFunc(currentGroup) + (i < groups.length ? ', ' : '')
    }, '')
    return fullDesc.substring(0, fullDesc.length - 2)
  }

  const cleverTapTrackAddToCart = (product, mappingFlag, source) => {
    const cartMappings = mappingFlag === 'firstTimeAdd' ? {
      'Product id': product['ref_id'],
      'Product name': product.title,
      'Price of product': getCurrentItemAmount(product),
      'quantity': get(product, 'cartcount', 1),
      'Variant selected': product.variant_groups ? getDesc(product.variant_groups, getVariantDesc) : null,
      'Addons selected': product.add_on_groups ? getDesc(product.add_on_groups, getAddonDesc) : null
    } : mappingFlag === 'customization' ? {
      'Product id': product['ref_id'],
      'Product name': product.title,
      'Source': source
    } : {
      'Product id': product['ref_id'],
      'Product name': product.title
    }
    const cartEventMappings = {
      firstTimeAdd: CT_EVENT_IDS.ADDED_TO_CART,
      customization: CT_EVENT_IDS.CUSTOMIZATION_SLIDER_OPENED,
      'customization-variant': CT_EVENT_IDS.CUSTOMIZATION_OPENED
    }
    if (mappingFlag === 'firstTimeAdd' && wentToCheckout.current) {
      wentToCheckout.current = false
    } else {
      clevertapEventPush({
        eventId: cartEventMappings[mappingFlag],
        eventData: cartMappings
      })
    }

  }

  useEffect(() => {
    if (![OrderStatus.PAYMENT_CONFIRMED, OrderStatus.PAYMENT_CONFIRMED_CASH].includes(ordrState)) {
      const currentCartLength = get(cart, 'length', 0)
      const previousCartLength = get(cartLength, 'current', 0)
      if (currentCartLength > 0) {
        if (previousCartLength < currentCartLength) {
          cleverTapTrackAddToCart(cart[currentCartLength - 1], 'firstTimeAdd')
        }
        cartLength.current = currentCartLength
      } else {
        cartLength.current = 0
        wentToCheckout.current = false
      }
    }
  }, [get(cart, 'length')])

  useEffect(() => {
    classmenu && clevertapEventPush({
      eventId: CT_EVENT_IDS.MENU_BUTTON_CLICKED
    })
  }, [classmenu])

  async function addCart(product) {
    const cloncart = [...cart];
    cloncart.push({ ...product, cartcount: 1 });
    let offersData = await checkOfferAvailable(productId, cloncart, offers, products, metaData, orderType);
    setOffers(offersData);
    let refreshedCart = returnRefreshedCart(products, cloncart)
    return setCart(refreshedCart);
  }

  const trackModifyCart = (itemAdded) => {
    clevertapEventPush({
      eventId: CT_EVENT_IDS.ITEM_MODIFIED,
      eventData: {
        'Product Id': get(itemAdded, 'itemId', ''),
        'Product name': get(itemAdded, 'itemName', ''),
        'Action': get(itemAdded, 'itemAction', ''),
        'quantity': get(itemAdded, 'itemCount', '')
      }
    })
  }

  async function minusMenuCount(id) {
    let itemRemoved = {}
    let cloncart = [...cart];
    cloncart.forEach((item, i) => {
      if (item.ref_id === id) {
        item.cartcount--;
        itemRemoved = {
          itemId: get(item, 'ref_id'),
          itemName: get(item, 'title'),
          itemCount: get(item, 'cartcount', 0),
          itemAction: '-'
        }
        if (item.cartcount < 1) {
          return cloncart.splice(i, 1);
        }
      }
    });
    trackModifyCart(itemRemoved)
    let offersData = await checkOfferAvailable(productId, cloncart, offers, products, metaData, orderType);
    setOffers(offersData);
    let refreshedCart = returnRefreshedCart(products, cloncart)
    setCart(refreshedCart);
  }

  async function plyusMenuCount(id) {
    let itemAdded = {}
    let cloncart = [...cart];
    cloncart.forEach((item) => {
      if (item.ref_id === id) {
        item.cartcount++;
        itemAdded = {
          itemId: get(item, 'ref_id'),
          itemName: get(item, 'title'),
          itemCount: item.cartcount,
          itemAction: '+'
        }
      }
    });
    trackModifyCart(itemAdded)
    let offersData = await checkOfferAvailable(productId, cloncart, offers, products, metaData, orderType);
    setOffers(offersData);
    let refreshedCart = returnRefreshedCart(products, cloncart)
    setCart(refreshedCart);
  }

  function variantPrice(item, is_discount) {
    let price = is_discount ? item.discounted_price ? item.discounted_price : 0 : item.price;
    if (get(item, "variant_groups")) {
      for (let i = 0; i < item.variant_groups.length; i++) {
        let defaultVar = item.variant_groups[i].variants.reduce((prev, curr) =>
          prev.price < curr.price && prev.in_stock
            ? prev
            : curr.in_stock && curr
        );

        if (defaultVar) {
          price += defaultVar.price;
        }
      }
    }

    return parseFloat(price.toFixed(2));
  }


  const handleScroll = () => {
    let element = document.getElementsByClassName("show");
    if (element.length) {
      element[0].classList.remove("show");
    }
  }

  // variant_group
  function addCartVariant(product, source = 'Menu') {
    if (variantPopup === false) {
      cleverTapTrackAddToCart(product, 'customization', source)
      setVariantsProduct(product);
      setvariantPopup(true);
      setVariantGroupPlyusPopup(false);
    } else {
      setvariantPopup(false);
    }
  }

  function plyusVariantGroup(product) {
    if (variantGroupPlyusPopup === false) {
      setVariantsAllProduct(
        cart.filter((item) => item.ref_id === product.ref_id)
      );
      setVariantsProduct(product);
      setVariantGroupPlyusPopup(true);
      cleverTapTrackAddToCart(product, 'customization-variant')
    } else {
      setVariantGroupPlyusPopup(false);
    }
  }



  async function plyusVarintAddCart(product, variant) {
    let itemAdded = {}
    let cloncart = [...cart];
    let variantbol = false;
    let addonbol = false;
    let addcart = true;
    for (let i = 0; i < cloncart.length; i++) {
      if (product.ref_id === cloncart[i].ref_id) {
        let countvariant = 0;
        if (product.variant_groups) {
          for (let j = 0; j < product.variant_groups.length; j++) {
            if (
              product.variant_groups[j].selectedVariant.ref_id ===
              cloncart[i].variant_groups[j].selectedVariant.ref_id
            ) {
              if (product.variant_groups[j].selectedVAdon) {
                if (product.variant_groups[j].selectedVAdon.length === cloncart[i].variant_groups[j].selectedVAdon.length) {
                  let countVaddOn = 0;
                  product.variant_groups[j].selectedVAdon.forEach(group => {
                    if (cloncart[i].variant_groups[j].selectedVAdon.find(a =>
                      a.ref_id === group.ref_id
                    )) {
                      countVaddOn++
                    }
                  })
                  if (countVaddOn === product.variant_groups[j].selectedVAdon.length) {
                    countvariant++;
                  }
                }

              } else {
                countvariant++;
              }
            }
          }
          if (countvariant === product.variant_groups.length) {
            variantbol = true;
          }
        }
        if (product.add_on_groups) {
          let countaddongroups = 0;
          for (let j = 0; j < product.add_on_groups.length; j++) {
            if (
              product.add_on_groups[j].selectedAddOn.length ===
              cloncart[i].add_on_groups[j].selectedAddOn.length
            ) {
              let countSelectedAddon = 0;
              product.add_on_groups[j].selectedAddOn.forEach((group) => {
                if (
                  cloncart[i].add_on_groups[j].selectedAddOn.find(
                    (a) => a.ref_id === group.ref_id
                  )
                ) {
                  countSelectedAddon++;
                }
              });
              if (
                countSelectedAddon ===
                product.add_on_groups[j].selectedAddOn.length
              ) {
                countaddongroups++;
              }
            }
            if (countaddongroups === product.add_on_groups.length) {
              addonbol = true;
            }
          }
        }
        if (
          (product.variant_groups && !product.add_on_groups && variantbol) ||
          (product.variant_groups &&
            product.add_on_groups &&
            variantbol &&
            addonbol) ||
          (!product.variant_groups && product.add_on_groups && addonbol)
        ) {
          if (variant === "variantgroup") {
            cloncart[i].cartcount += product.cartcount;
          } else {
            cloncart[i].cartcount++;
          }
          itemAdded = {
            itemId: get(cloncart[i], 'ref_id'),
            itemName: get(cloncart[i], 'title'),
            itemCount: cloncart[i].cartcount,
            itemAction: '+'
          }
          trackModifyCart(itemAdded);
          setVariantsAllProduct(
            cloncart.filter((a) => a.ref_id === product.ref_id)
          );
          let refreshedCart = returnRefreshedCart(products, cloncart);
          setCart(refreshedCart);
          let offersData = await checkOfferAvailable(productId, refreshedCart, offers, products, metaData, orderType);
          setOffers(offersData);
          setvariantPopup(false);
          addcart = false;
          return;
        }
      }
      variantbol = false;
      addonbol = false;
    }
    if (addcart) {
      cloncart.push(product);
      setVariantsAllProduct(
        cloncart.filter((a) => a.ref_id === product.ref_id)
      );
      let refreshedCart = returnRefreshedCart(products, cloncart)
      setCart(refreshedCart);
      let offersData = await checkOfferAvailable(productId, refreshedCart, offers, products, metaData, orderType);
      setOffers(offersData);
      setvariantPopup(false);
    }
  }

  async function minusVarintAddCart(product) {
    let itemRemoved = {};
    let cloncart = [...cart];
    let variantbol = false;
    let addonbol = false;
    for (let i = 0; i < cloncart.length; i++) {
      if (product.ref_id === cloncart[i].ref_id) {
        let countvariant = 0;
        if (product.variant_groups) {
          for (let j = 0; j < product.variant_groups.length; j++) {
            if (
              product.variant_groups[j].selectedVariant.ref_id ===
              cloncart[i].variant_groups[j].selectedVariant.ref_id
            ) {
              countvariant++;
            }
          }
          if (countvariant === product.variant_groups.length) {
            variantbol = true;
          }
        }
        if (product.add_on_groups) {
          let countaddongroups = 0;
          for (let j = 0; j < product.add_on_groups.length; j++) {
            if (
              product.add_on_groups[j].selectedAddOn.length ===
              cloncart[i].add_on_groups[j].selectedAddOn.length
            ) {
              let countSelectedAddon = 0;
              product.add_on_groups[j].selectedAddOn.forEach((group) => {
                if (
                  cloncart[i].add_on_groups[j].selectedAddOn.find(
                    (a) => a.ref_id === group.ref_id
                  )
                ) {
                  countSelectedAddon++;
                }
              });
              if (
                countSelectedAddon ===
                product.add_on_groups[j].selectedAddOn.length
              ) {
                countaddongroups++;
              }
            }
            if (countaddongroups === product.add_on_groups.length) {
              addonbol = true;
            }
          }
        }

        if (
          (product.variant_groups && !product.add_on_groups && variantbol) ||
          (product.variant_groups &&
            product.add_on_groups &&
            variantbol &&
            addonbol) ||
          (!product.variant_groups && product.add_on_groups && addonbol)
        ) {
          cloncart[i].cartcount--;
          itemRemoved = {
            itemId: get(cloncart[i], 'ref_id', get(product, 'ref_id')),
            itemName: get(cloncart[i], 'title', get(product, 'title')),
            itemCount: get(cloncart, `[${i}].cartcount`, 0),
            itemAction: '-'
          }
          get(itemRemoved, 'itemId') && trackModifyCart(itemRemoved);
          if (cloncart[i].cartcount < 1) {
            cloncart.splice(i, 1);
          }
          setVariantsAllProduct(
            cloncart.filter((a) => a.ref_id === product.ref_id)
          );
          let a = cloncart.filter((a) => a.ref_id === product.ref_id).length
            ? ""
            : setVariantGroupPlyusPopup(false);
          let refreshedCart = returnRefreshedCart(products, cloncart);
          setCart(refreshedCart);
          let offersData = await checkOfferAvailable(productId, refreshedCart, offers, products, metaData, orderType);
          setOffers(offersData);
          setvariantPopup(false);
          return;
        }
      }
      variantbol = false;
      addonbol = false;
    }
  }

  const closeTableStatus = () => {
    if (orderType === OrderType.DINEIN) {
      setIsOccupied(true);
      setShowModal(false);
      setMetaData({ notServiceable: true });
      setIsServiceable(false);
    }
  };
  const joinTable = () => {
    blockTableforUser(joinTableAPI);
    setIsOccupied(true);
    getSuborders();
    setShowModal(false);
  };

    const tableStatus = (
        <>
        {restaurant && isServiceable && <ConfirmationToJoinTable onClick={joinTable}>
          <ConfirmationToJoinTable.Div>
            <JoinTableText>
              <p>{t('DoYouWantjoinTable')}</p>
            </JoinTableText>
            <JoinTableButtonSection>
              <JoinTableButton restaurantColor={restaurantColor} onClick={() => closeTableStatus()}>
                {t('NO')}
              </JoinTableButton>
              <JoinTableButton restaurantColor={restaurantColor} onClick={joinTable}>
                {t('YES')}
              </JoinTableButton>
            </JoinTableButtonSection>
          </ConfirmationToJoinTable.Div>
        </ConfirmationToJoinTable>}
      </>
    );

  const buttonClickToAddressPage = (orderType === OrderType.DELIVERY && (!userLocationStatusForDelivery || get(metaData, 'notServiceable'))) || defaultOutletShown

  const getCartCount = (product, itemCustomizable) => {
    const productInCart = cart.filter(
      (cartProduct) => cartProduct.ref_id === product.ref_id
    );
    let count = 0;
    if (productInCart[0]) {
      if (itemCustomizable) {
        for (let i = 0; i < productInCart.length; i++) {
          count += productInCart[i].cartcount;
        }
      } else {
        count = productInCart[0].cartcount;
      }
    }
    return {
      productInCart,
      count
    }
  }

  const handleAddItemClick = (item, productInCart, action) => {
    if(buttonClickToAddressPage) {
      goToLocationPage(MenuPageConstants.ADD_BUTTON)
    } else {
      if(checkIsItemCustomizable(item)) {
        if (productInCart[0]) {
          plyusVariantGroup(item)
        } else {
          addCartVariant(item)
        }
      } else {
        if(action) {
          action === '+' ? plyusMenuCount(item.ref_id) : minusMenuCount(item.ref_id)
        } else {
          addCart(item)
        }
      }
    }
  }

  const showAddItemButton = orderType === OrderType.DINEIN && (dineIndetails.isPaid || !metaData.table_number) ? false : ((get(serviceability, 'isServiceable') && !get(restaurantUIStateData, 'orderTypeDisabled')) || defaultOutletShown)

  const renderProduct = (item = {}, itemIndex) => {
    const {
      title,
      image_url,
      food_type,
      discounted_price = 0,
      price,
      description,
    } = item


    const itemPrice = variantPrice(item, false);
    const itemDiscountedPrice = !!item.discounted_price && variantPrice(item, true);

    const isItemCustomizable = checkIsItemCustomizable(item)
    const itemPropsInCart = getCartCount(item, isItemCustomizable)

    if (customizableProductID && customizableProductID === item.ref_id && replaceExistingFlag.current) {
      history.replace();
      replaceExistingFlag.current = false;
      plyusVariantGroup(item);
    }

    if (addNewCustomizationProductID && addNewCustomizationProductID === item.ref_id && customizeExistingFlag.current) {
      history.replace();
      customizeExistingFlag.current = false;
      addCartVariant(item);
    }


    return <ItemCard
      key={itemIndex}
      itemImage={image_url}
      title={title}
      description={description}
      foodType={food_type}
      price={itemPrice}
      discountedPrice={itemDiscountedPrice}
      isRestaurantIndian={isRestaurantIndian}
      isCustomizable={isItemCustomizable}
      itemQuantity={itemPropsInCart.count}
      couponsNotApplicable={'discount_mode' in item && !get(item, 'discount_mode')}
      handleAddItemClick={(action) => handleAddItemClick(item, itemPropsInCart.productInCart, action)}
      showAddItemButton={showAddItemButton}
      isRestaurantColorDark={isRestaurantColorDark}
      isLanguageArab={isLanguageArab}
    />
  }

  const renderMenuItem = (item, itemIndex, categoryTitle) => {
    return get(item, 'items')
      ? <SubCategoryBlock id={`${categoryTitle}-${item.title}-subcategory-wrapper`} key={itemIndex} searchValue={searchValue}>
        {!searchValue ? <SubCategoryBlock.h2 id={`${categoryTitle}-${item.title}-subcategory-heading`} restaurantColor={restaurantColor}>{`${item.title}${getNoOfItemsToDisplay(get(item, 'items'))}`}</SubCategoryBlock.h2> : ""}
        {item.items.map((subItem, subItemIndex) => renderProduct(subItem, subItemIndex))}
      </SubCategoryBlock>
      : renderProduct(item, itemIndex)
  }

  const processSimpleCombo = (combo) => {
    const simpleComboPayload = {
      title: get(combo, 'title'),
      comboDescription: get(combo, 'description'),
      ref_id: get(combo, 'code'),
      code: get(combo, 'code'),
      food_type: get(combo, 'food_type'),
      price: get(combo, 'price'),
      sections: get(combo, 'sections', []).map(section => ({
        items: get(section, 'items', []).map(item => ({
          ref_id: item
        }))
      })),
      bill_components: get(combo, 'bill_components')
    }
    return simpleComboPayload
  }

  const simpleComboHandler = (combo, action, isSimpleCombo) => {
    const comboForCart = isSimpleCombo ? processSimpleCombo(combo) : combo
    if (action) {
      const cartModified = [...cart]
      cartModified[isSimpleCombo] = isSimpleCombo;
      let comboItem = cartModified.find(product => product.ref_id === combo.code);
      cartModified.forEach(function (product) {
        if (product.ref_id === combo.code && !isSimpleCombo) {
          comboItemCount += product.cartcount;
          comboVariations++;
        }
      })
      let clevertapDataForCombo = {
        itemId: combo.code,
        itemName: combo.title,
        itemAction: action
      }
      if (isSimpleCombo && action === '+') {
        comboItem.cartcount = comboItem.cartcount + 1;
        let updatedCart = cartModified.filter(product => product.ref_id !== combo.code);
        setCart([...updatedCart, comboItem]);
        trackModifyCart({
          ...clevertapDataForCombo,
          itemCount: comboItem.cartcount
        })
      } else if (isSimpleCombo && action === '-' && comboItem.cartcount > 1) {
        comboItem.cartcount = comboItem.cartcount - 1;
        let updatedCart = cartModified.filter(product => product.ref_id !== combo.code);
        setCart([...updatedCart, comboItem]);
        trackModifyCart({
          ...clevertapDataForCombo,
          itemCount: comboItem.cartcount
        })
      } else if (isSimpleCombo && action === '-' && comboItem.cartcount === 1) {
        let updatedCart = cartModified.filter(product => product.ref_id !== combo.code)
        if (updatedCart.length === 0) {
          setCart([]);
          setComboInCart(null);
        }
        else {
          setCart(updatedCart);
          setComboInCart(updatedCart);
        }
        trackModifyCart({
          ...clevertapDataForCombo,
          itemCount: 0
        })
      }
      else if (action === '-') {
        if (comboVariations <= 1 && comboItemCount > 1) {
          comboItem.cartcount = comboItem.cartcount - 1;
          let updatedCart = cartModified.filter(product => product.ref_id !== combo.code);
          setCart([...updatedCart, comboItem]);
          trackModifyCart({
            ...clevertapDataForCombo,
            itemCount: comboItem.cartcount
          })
        }
        if (comboVariations > 1 && comboItemCount > 1) {
          setRepititionModal(!repititionModal);
          setRepititionModalChildren("removal");
        }
        else if (comboItemCount === 1) {
          let updatedCart = cartModified.filter(product => product.ref_id !== combo.code);
          setCart(updatedCart);
          trackModifyCart({
            ...clevertapDataForCombo,
            itemCount: 0
          })
        }
      } else {
        setRepititionModal(!repititionModal);
        setRepititionModalChildren(comboItem);
      }
    } else {
      let otherItemsInCart = !shouldFilterProductFromCart.current ? cart : cart.filter(item => !(item.ref_id === combo.code && comboIdentifier === item.comboIdentifier));
      setCart([...otherItemsInCart, {
        ...comboForCart,
        cartcount: 1,
        isSimpleCombo: isSimpleCombo !== undefined || isSimpleCombo ? isSimpleCombo : false,
        quantity: 1,
        isComboItem: true,
        discount_mode: get(combo, "discount_mode"),
        comboIdentifier: cart.filter(item => item.ref_id === combo.code).length + 1
      }])
      setRepititionModal(false);
      setComboInCart(comboForCart);
    }
  }

  const addCustomizedCombo = (selectedComboDetails) => {
    selectedComboDetails["isSimpleCombo"] = false;
    setShowCombosPopup(false)
    setSelectedCombo({});
    simpleComboHandler(selectedComboDetails)
  }

  const customizedComboHandler = (combo, action) => {
    if (action) {
      setSelectedCombo(combo)
      simpleComboHandler(combo, action)
    } else {
      setShowCombosPopup(true)
      clevertapEventPush({
        eventId: CT_EVENT_IDS.CUSTOMIZATION_SLIDER_OPENED,
        eventData: {
          'Product id': combo['code'],
          'Product name': combo.title,
          'Source': 'Menu'
        }
      })
      setSelectedCombo(combo);
    }

  }

  const addComboToCart = (combo, comboType, action) => {
    const comboModHandlers = {
      [MenuPageConstants.SIMPLE_COMBO]: simpleComboHandler,
      [MenuPageConstants.CUSTOMIZED_COMBO]: customizedComboHandler,
      [MenuPageConstants.BUYX_GETY]: customizedComboHandler,
      [MenuPageConstants.PAYX_GETY]: customizedComboHandler,
    }
    comboModHandlers[comboType](combo, action, comboType === MenuPageConstants.SIMPLE_COMBO)
  }

  const filterComboByFoodFilter = (combo) => {
    const foodTypefilterMap = {
      vegan: 1,
      nonVeg: 2,
      egg: 3
    }
    const selectedFoodTypes = Object.keys(dropDownTypes).filter(fType => !!dropDownTypes[fType])
    if (selectedFoodTypes.length > 0) {
      const selectedFoodPrefs = selectedFoodTypes.reduce((allFTypes, fType) => {
        return foodTypefilterMap[fType] ? [...allFTypes, foodTypefilterMap[fType]] : allFTypes
      }, [])
      if (selectedFoodPrefs.length > 0) {
        if (selectedFoodPrefs.includes(get(combo, 'food_type'))) {
          const foodOrBarCombo = get(combo, 'is_bar_item') ? 'barItem' : 'foodItem'
          const foodOrBarSelected = selectedFoodTypes.includes('foodItem') || selectedFoodTypes.includes('barItem')
          if (foodOrBarSelected) {
            return selectedFoodTypes.includes(foodOrBarCombo)
          } else {
            return true
          }
        } else {
          return false
        }
      } else {
        const foodOrBarCombo = get(combo, 'is_bar_item') ? 'barItem' : 'foodItem'
        const foodOrBarSelected = selectedFoodTypes.includes('foodItem') || selectedFoodTypes.includes('barItem')
        if (foodOrBarSelected) {
          return selectedFoodTypes.includes(foodOrBarCombo)
        } else {
          return true
        }
      }
    } else {
      return true
    }
  }

  const filterComboBySearchValue = (combo) => {
    const searchVal = searchValue.toLowerCase()
    const comboTitle = get(combo, 'title', '').toLowerCase()
    const comboDescription = get(combo, 'description', '').toLowerCase()
    let allItemTitlesInsideCombo = []
    get(combo, 'sections', []).forEach(section => {
      const allItemsInsideSection = get(section, 'items', [])
      allItemTitlesInsideCombo = [...allItemTitlesInsideCombo, ...allItemsInsideSection.map(item => {
        return get(products, `items[${item}].title`, '').toLowerCase()
      })]
    })
    return comboTitle.includes(searchVal) || comboDescription.includes(searchVal) || !!allItemTitlesInsideCombo.find(title => title.includes(searchVal))
  }

  const filterComboOffers = () => {
    if (searchField) {
      if (searchValue) {
        return (comboOffers).filter(combo => filterComboBySearchValue(combo))
      } else {
        return []
      }
    } else {
      return (comboOffers || []).filter(combo => filterComboByFoodFilter(combo))
    }
  }

  const ComboOffersSection = () => {
    return filteredComboOffers.length > 0 && <ComboOffersWrapper id='combos-section'>
      <CategoryHeading isLanguageArab={isLanguageArab} id='combos-category-heading'>{`${t('COMBO_OFFER')}${getNoOfItemsToDisplay(filteredComboOffers)}`}</CategoryHeading>
      {filteredComboOffers.map(combo => {
        const comboType = get(combo, 'combo_type', '')
        const foodType = get(combo, 'food_type', '')
        const comboCardProps = {
          itemImage: get(combo, 'image_url', ''),
          title: get(combo, 'title', ''),
          description: get(combo, 'description', ''),
          foodType,
          price: MenuPageConstants.BASE_PRICE_COMBOS.includes(comboType) && get(combo, 'price', ''),
          isRestaurantIndian,
          isComboCustomizable: MenuPageConstants.CUSTOMIZED_COMBO_TYPES.includes(comboType),
          couponsNotApplicable: !get(combo, 'discount_mode', false),
          onClick: (action) => buttonClickToAddressPage ? goToLocationPage(MenuPageConstants.ADD_BUTTON) : addComboToCart(combo, comboType, action),
          showAddItemButton,
          comboInCart,
          comboRefId: get(combo, 'code'),
          comboVariations,
          cart,
          comboItemCount
        }
        if (customizableProductID && customizableProductID === combo.code && combo.combo_type !== "SIMPLE_COMBO" && comboInCart && replaceExistingFlag.current) {
          history.replace();
          addComboToCart(combo, combo.combo_type, "");
        }
        return <ComboCard key={get(combo, 'code')} {...comboCardProps} />
      })}
    </ComboOffersWrapper>
  }

  const displayMenuButton = () => {
    return !isGIPOpen && allFilteredProducts.length === 0 ? false : !menuLoading;
  };


  const minimumOrderMessage = () => {
    if (restaurantCurrency) {
      let minOrderMessage = "";
      if (minimumOrderAmount && freeDeliveryAmount) {
        minOrderMessage = i18n.t('notificationMsg.MIN_ORDER_AND_FREE_DEL', { minimumOrderAmount: `${restaurantCurrencyValue}${minimumOrderAmount}`, freeDeliveryAmount: `${restaurantCurrencyValue}${freeDeliveryAmount}` });
      } else if (minimumOrderAmount) {
        minOrderMessage = i18n.t('notificationMsg.MIN_ORDER_IS', { minOrderAmount: `${restaurantCurrencyValue}${minimumOrderAmount}` });
      } else if (freeDeliveryAmount) {
        minOrderMessage = i18n.t('notificationMsg.FREE_DEL_ABOVE', { freeDeliveryAmount: `${restaurantCurrencyValue}${freeDeliveryAmount}` });
      }
      return minOrderMessage;
    }
  }

  const getBottomGapForMenuBtn = () => {
    const footer = document.getElementById('menu-footer')
    return footer && `${footer.offsetHeight + 14}px`
  }

  const roundOffToTwoPlaces = number => String(number).split('.')[1] ? number.toFixed(2) : number

  const getTotalCartAmount = () => {
    return cart?.length > 0 ? cart.reduce((totalAmount, currentItem) => totalAmount + getCurrentItemAmount(currentItem), 0) : 0
  }

  const getCartDetails = () => <span id='cart-amount'>{restaurantCurrency}{roundOffToTwoPlaces(getTotalCartAmount())}</span>

  const comboRepititionPopup = () => {
    return <RepititionsField
      restaurantColor={restaurantColor}
      repititionModal={repititionModal}
      setRepititionModal={setRepititionModal}
      repititionModalChildren={repititionModalChildren}
      setRepititionModalChildren={setRepititionModalChildren}
      productIndex={productIndex}
      handleRepetitionChange={handleRepetitionChange} />
  }

  async function handleRepetitionChange(productRefId, action, index) {
    // if (repititionModal)
    //   setRepititionModal(!repititionModal);
    if (action !== '') {
      let itemAdded = {}
      let cloncart = [...cart];
      cloncart.forEach((item, i) => {
        if (item.ref_id === productRefId) {
          item.cartcount++;
          itemAdded = {
            itemId: get(item, 'ref_id'),
            itemName: get(item, 'title'),
            itemCount: item.cartcount,
            itemAction: '+'
          }
        }
      });
      trackModifyCart(itemAdded);
      let refreshedCart = returnRefreshedCart(products, cloncart)
      setCart(refreshedCart);
      setRepititionModalChildren("");
      setRepititionModal(!repititionModal);
    } else {
      setRepititionModal(!repititionModal);
      setRepititionModalChildren("");
      addNewCustomizationProductID = productRefId;
      customizeExistingFlag.current = true;
      shouldFilterProductFromCart.current = false;
      if (addNewCustomizationProductID && get(comboOffers, 'length', 0) > 0) {
        const comboInCartRefId = cart.find(product => product.ref_id === addNewCustomizationProductID && customizeExistingFlag.current)?.ref_id;
        const combo = comboOffers.find(product => product.code === comboInCartRefId);
        if (combo) {
          history.replace();
          addComboToCart(combo, combo.combo_type, "");
        }
      }
    }
  }

  const getNoOfItemsToDisplay = (items) => get(items, 'length') ? ` (${get(items, 'length', 0)})` : ''


  const AllMenuItems = () => (!searchField || searchValue) && allFilteredProducts.map((category, categoryIndex) => {
    let itemDetail = [...get(category, 'items', [])]
    let categoryDetail = { ...category }

    itemDetail.map(data => {
      if (get(category, 'subcategories')) {
        let subCategoryItem = [...data.items];
        subCategoryItem.map(subCatItem => {
          subCatItem["categoryId"] = get(category, 'ref_id');
          let subcategoryId = Object.keys(get(category, 'subcategories', null)).find(id => id === data.ref_id);
          subCatItem["subcategoryId"] = subcategoryId;
        })
        data['items'] = subCategoryItem;
      } else {
        data["categoryId"] = get(category, 'ref_id');
        data["subcategoryId"] = null
      }
    })
    categoryDetail = { items: itemDetail };

    return (<CategoryWrapper id={`${category.title}-category-wrapper`} firstChild={categoryIndex === 0} key={categoryIndex} isLanguageArab={isLanguageArab}>
      <CategoryHeading isLanguageArab={isLanguageArab} id={`${category.ref_id}-category-heading`}>{category.title}{getNoOfItemsToDisplay(get(categoryDetail, 'items'))}</CategoryHeading>
      <MenuItemContainer id={`${category.title}-category-items`} searchValue={searchValue}>
        {getKeyValue(categoryDetail, 'items', []).map((item, itemIndex) => renderMenuItem(item, itemIndex, category.title))}
      </MenuItemContainer>
    </CategoryWrapper>)
  })

  const MenuHeader = () => !!restaurant && (
    <Header
      roomNumber={get(metaData, 'room_number')}
      tableNumber={get(metaData, 'table_number') || placeholderTableNumber.current}
      defaultOutletShown={defaultOutletShown}
      userLocationToShow={userLocationData}
      storeLocationToShow={storeLocationData}
      userLocationStatusForDelivery={userLocationStatusForDelivery}
      userLocationStatusForTakeaway={userLocationStatusForTakeaway}
      goToLocationPage={goToLocationPage}
      orderType={orderType}
      setOrderType={setOrderType}
      logo={get(products, "meta_data.logo")}
      restaurantColor={restaurantColor}
      search={(value) => setSearchValue(value)}
      searchValue={searchValue}
      searchField={searchField}
      setSearchField={setSearchField}
      veganFunc={() =>
        setDropDownTypes({ ...dropDownTypes, vegan: !dropDownTypes.vegan })
      }
      dropDownTypes={dropDownTypes}
      eggFunc={() =>
        setDropDownTypes({ ...dropDownTypes, egg: !dropDownTypes.egg })
      }
      nonVegFunc={() =>
        setDropDownTypes({
          ...dropDownTypes,
          nonVeg: !dropDownTypes.nonVeg,
        })
      }
      foodFunc={() =>
        setDropDownTypes({
          ...dropDownTypes,
          foodItem: !dropDownTypes.foodItem,
        })
      }
      barItemFunc={() =>
        setDropDownTypes({
          ...dropDownTypes,
          barItem: !dropDownTypes.barItem,
        })
      }
      name={products?.meta_data?.name || ""}
      barMenu={products?.meta_data?.hasBarMenu || ""}
      notifyIndicator={notifyIndicator}
      isGIPOpen={isGIPOpen}
      toggleGIPWindow={toggleGIPWindow}
      productId={productId}
      setNotifyIndicator={setNotifyIndicator}
      socialHandles={get(products, "configuration.socialHandles")}
      setGIPOpen={setGIPOpen}
      setIsOTPVerificationForGIP={setIsOTPVerificationForGIP}
      botLoader={botLoader}
      triggerClevertapOrderTypeSwitchEvent={triggerClevertapOrderTypeSwitchEvent}
      isRestaurantColorDark={isRestaurantColorDark}
      restaurant={restaurant}
      chainOriginName={chainOriginName}
    />
  )

  const getBannerCarousel = () => {
    const bannerImages = get(restaurant, 'meta_data.banners', [])
    return <Carousel
      margin='24px 0 0 0'
      scrollButtonId='banner-scroll-button'
      itemId={'banner-image'}
      items={bannerImages.map(bannerImage => <BannerImage src={get(bannerImage, 'url', '')} />)}
      hideCarousel={!!searchValue}
    />
  }

  const extractCategories = () => [{ref_id: 'combos', title: t('COMBO_OFFER')}, ...allFilteredProducts].map(cat => ({ref_id: get(cat, 'ref_id'), title: get(cat, 'title')}))

  const MenuBody = () => (!isGIPOpen && !!restaurant) && <CategoriesWrapper id='menu-items-wrapper' onScroll={handleScroll} ref={scrollRef}>
    {!defaultOutletShown && <OrderTypeServiceable
      restaurant={restaurant}
      userLocationStatusForDelivery={userLocationStatusForDelivery}
      userLocationStatusForTakeaway={userLocationStatusForTakeaway}
      goToLocationPage={() => goToLocationPage(MenuPageConstants.SELECT_LOCATION)}
      triggerClevertapOrderTypeSwitchEvent={triggerClevertapOrderTypeSwitchEvent}
    />}
    {getBannerCarousel()}
    {!searchField ? offersDisplay : ""}
    {searchValue && (!!allFilteredProducts.length || !!filteredComboOffers.length) && <CategoryHeading isLanguageArab={isLanguageArab} resultsHeading>Results</CategoryHeading>}
    {ComboOffersSection()}
    {AllMenuItems()}
    {(!allFilteredProducts?.length && !filteredComboOffers?.length) ? <EmptyCart id="no-items-found-wrapper"><div><EmptyCart.img src={ShoppingCart} /><EmptyCart.span id='no-items-found-message'>{t('OhNoItemFound')}</EmptyCart.span></div></EmptyCart> : null}
    {!searchValue && <Footer menuCategories={extractCategories()} menuCategoryClick={menuItem} />}
  </CategoriesWrapper>

  const MenuModals = () => <React.Fragment>
    {landingModal && !defaultOutletShown && (
      <LandingCardModal
        handleClick={() => setLandingModal(false)}
        productId={productId}
      />
    )}
    {!!custGIPLink && isGIPOpen && <GIPBotIFrame frameborder="0" allowfullscreen src={custGIPLink} display={isGIPOpen} allow="location" />}
    {displayMenuButton() && !!restaurant && !isGIPOpen && !searchField && !searchValue &&
      <MenuButton id='menu-button' isRestaurantColorDark={isRestaurantColorDark} bottomGap={getBottomGapForMenuBtn() || menuFooterHeight} onClick={menu} restaurantColor={restaurantColor} style={classmenu ? { visibility: "hidden" } : null}>{t('MENU')}</MenuButton>}
    {classmenu && <Menu bottomGap={getBottomGapForMenuBtn() || menuFooterHeight} isRestaurantColorDark={isRestaurantColorDark} selectedCategory={selectedCategory} funMenu={menu} menuItem={menuItem} product={allFilteredProducts} restaurantColor={restaurantColor} comboOffers={filteredComboOffers} />}
    {variantPopup && <div>
      <VariantGroups
        product={variantsProduct}
        addCartVariant={product => addCartVariant(product, 'Customization Modal')}
        plyusVarintAddCart={plyusVarintAddCart}
        productAllPrice={productAllPrice}
        roundOffToTwoPlaces={roundOffToTwoPlaces}
        restaurantColor={restaurantColor}
        restaurantCurrency={restaurantCurrency}
      />
    </div>
    }
    {variantGroupPlyusPopup && <VariantGroupPlyus
      product={variantsProduct}
      restaurantCurrency={restaurantCurrency}
      variantsAllProduct={variantsAllProduct}
      plyusVariantGroup={plyusVariantGroup}
      productAllPrice={productAllPrice}
      productPrice={productPrice}
      addCartVariant={product => addCartVariant(product, 'Customization Modal')}
      plyusVarintAddCart={plyusVarintAddCart}
      minusVarintAddCart={minusVarintAddCart}
      roundOffToTwoPlaces={roundOffToTwoPlaces}
      restaurantColor={restaurantColor}
      isRestaurantIndian={isRestaurantIndian} />
    }
    {showCombosPopup && <CombosPopup
      closePopup={() => setShowCombosPopup(false)}
      selectedCombo={selectedCombo}
      addCombo={addCustomizedCombo}
      products={products}
      restaurantColor={restaurantColor}
      restaurantCurrency={restaurantCurrency}
      customizeExistingFlag={customizeExistingFlag}
      replaceExistingFlag={replaceExistingFlag}
      shouldFilterProductFromCart={shouldFilterProductFromCart}
      comboInCart={replaceExistingFlag.current && comboInCart}
      isRestaurantIndian={isRestaurantIndian}
    />}
    {comboRepititionPopup()}
    {isCartEmpty && <EmptyCartModal handleClick={() => setIsCartEmpty(false)} restaurantColor={restaurantColor} />}
    {isOTPVerificationRequire && <OTPVerification notificationListener={realtimeListenToNotifications} successCallback={checkout} showOTPVerification={setIsOTPVerificationRequire} />}
    {isOTPVerificationForGIP && <OTPVerification notificationListener={realtimeListenToNotifications} successCallback={() => openGIPWindow()} showOTPVerification={setIsOTPVerificationForGIP} />}
    {showModal && !dineIndetails.isBlocked && !isGIPOpen && tableStatus}
  </React.Fragment>

  const footerElementsColor = isRestaurantColorDark ? styleVals.colors.white : styleVals.colors.primaryText

  const MenuFooter = () => !isGIPOpen && restaurant && ((searchField && cart.length > 0) || !searchField) && (
    defaultOutletShown
      ? <ProductFooter id='menu-footer'>
        <ProductFooter.Container restaurantColor={restaurantColor}>
          <ProductFooter.cartItem footerColor={footerElementsColor}>
            <CartWithAmountWrapper isLanguageArab={isLanguageArab} footerColor={footerElementsColor}>
              <CartBag footerColor={footerElementsColor}>
                <CartBag.icon footerColor={footerElementsColor} className='fa fa-shopping-cart' />
                <TotalItemCounts id='cart-items-count'>{'0'}</TotalItemCounts>
                <span id='cart-items-label'>{t('ITEMS')}</span>
              </CartBag>
              <span id='cart-amount'>{!!restaurantCurrency && <>{restaurantCurrency}0</>}</span>
            </CartWithAmountWrapper>
            <ProductFooter.Button id='checkout-button' footerColor={footerElementsColor} restaurantColor={restaurantColor} onClick={() => goToLocationPage(MenuPageConstants.ADD_BUTTON)} >
              <span>{t('CHECKOUT')}</span>
            </ProductFooter.Button>
          </ProductFooter.cartItem>
        </ProductFooter.Container>
      </ProductFooter>
      : serviceability && !serviceability.isServiceable && !get(restaurantUIStateData, 'orderTypeDisabled')
        ? <ProductFooter id='menu-footer'>
          <ProductFooter.Container footerColor={footerElementsColor} restaurantColor={restaurantColor} >
            <FooterMessage id='footer-message'>
              {MenuPageConstants.BROWSE_MENU_INFO}
            </FooterMessage>
          </ProductFooter.Container>
        </ProductFooter>
        : <ProductFooter id='menu-footer'>
          {orderType === OrderType.DELIVERY && !!minimumOrderMessage() && <MinOrderAmount><span id='footer-info-message' className="global-currency">{minimumOrderMessage()}</span></MinOrderAmount>}
          <ProductFooter.Container restaurantColor={restaurantColor}>
            {orderType === OrderType.DINEIN && !cart.length && dineIndetails &&
              dineIndetails.subOrders &&
              dineIndetails.subOrders.suborders_list &&
              dineIndetails.subOrders.bill_summary.sub_total > 0 &&
              dineIndetails.subOrders.suborders_list.length > 0 && !dineIndetails.isPaid
              ? <ProductFooter.noItems>
                <ProductFooter.Button id='close-table-pay-button' footerColor={footerElementsColor} restaurantColor={restaurantColor} onClick={closeAndPay} >
                  <span>{t('CLOSE_TABLE_PAY')}</span>
                </ProductFooter.Button>
              </ProductFooter.noItems>
              : isServiceable && !get(restaurantUIStateData, 'orderTypeDisabled') && (orderType === OrderType.DINEIN ? metaData.table_number : true) 
                ? <ProductFooter.cartItem footerColor={footerElementsColor}>
                  <CartWithAmountWrapper isLanguageArab={isLanguageArab} footerColor={footerElementsColor}>
                    <CartBag footerColor={footerElementsColor}>
                      <CartBag.icon footerColor={footerElementsColor} className='fa fa-shopping-cart' />
                      <TotalItemCounts isLanguageArab={isLanguageArab} id='cart-items-count'>{cart.length ? + cart.reduce((a, b) => a + b.cartcount, 0) : '0'}</TotalItemCounts>
                      <span id='cart-items-label'>{t('ITEMS')}</span>
                    </CartBag>
                    {!!restaurantCurrency && getCartDetails()}
                  </CartWithAmountWrapper>
                  <ProductFooter.Button id='checkout-button' footerColor={footerElementsColor} restaurantColor={restaurantColor} onClick={checkout} >
                    <span>{t('CHECKOUT')}</span>
                  </ProductFooter.Button>
                </ProductFooter.cartItem>
                : orderType === OrderType.DELIVERY && !get(restaurantUIStateData, 'orderTypeDisabled')
                  ? <ProductFooter.cartItem footerColor={footerElementsColor}>
                    <FooterMessage id='footer-message'>{MenuPageConstants.BROWSE_MENU_INFO}</FooterMessage>
                  </ProductFooter.cartItem>
                  : orderType === OrderType.DINEIN && !get(restaurantUIStateData, 'orderTypeDisabled')
                    ? <ProductFooter.cartItem footerColor={footerElementsColor}>
                      {dineIndetails.isPaid ? <FooterMessageForDinein id='footer-message'>{t('TABLE_CLOSED_FOR_ORDERS')}</FooterMessageForDinein> : <FooterMessageForDinein id='footer-message'>{t('SCAN_QR_TO_ORDER')}</FooterMessageForDinein>}
                    </ProductFooter.cartItem>
                    : <ProductFooter.cartItem footerColor={footerElementsColor} >
                      <FooterMessageForDinein id='footer-message'>{MenuPageConstants.BROWSE_MENU_INFO}</FooterMessageForDinein>
                    </ProductFooter.cartItem>}
          </ProductFooter.Container>
        </ProductFooter>)

  if (loading || loadingLocal || colorLoading || !restaurantCurrency) {
    if (loadingLocal && [OrderType.DELIVERY, OrderType.TAKEAWAY].includes(orderType)) {
      return <Spinner color={restaurantColor} gifLoader={true} currency={restaurantCurrency} price={totalSavings} message="Processing your Order..." />
    } else if (loadingLocal) {
      return <Spinner color={restaurantColor} message="Processing your Order..." />
    } else {
      return <Spinner text={spinnerText} />;
    }
  } else {
    return <ProductContainer withBg={!!allFilteredProducts.length || !!filteredComboOffers.length} dir={isLanguageArab ? "rtl" : ''}>
      {MenuHeader()}
      {menuLoading
        ? <MenuLoaderWrapper>
            <MenuLoader animation='border' restaurantColor={restaurantColor} />
          </MenuLoaderWrapper>
        : <React.Fragment>
            {MenuBody()}
            {MenuFooter()}
            {MenuModals()}
          </React.Fragment>
      }
    </ProductContainer>
  }
}
