import React, { useEffect, useState } from "react";
import Flickity from "react-flickity-component";
import "../../assets/scss/flickity.scss";
import { useRestaurantsInfoContext } from "../../contexts/restaurantInfo";
import { useTranslation } from 'react-i18next'
import { OffersCardContainer, OffersCardLeft, OffersCardRight, OfferDescription, OffersCode, OffersHeader, OffersWrapper } from "./styles";
import OfferCode from '../../assets/icons/offerCode';
import OfferCodeReverted from '../../assets/icons/offerCodeReverted';
import {
  OfferTypes,
} from "../../constants/variables";
import { darkenColor, get } from '../../utils/common';

const flickityOptions = {
  initialIndex: 0,
  autoPlay: 2000,
  wrapAround: '>1',
  lazyLoad: true,
  draggable: '>1',
  pageDots: false,
  prevNextButtons: false,
  freeScroll: true,
  contain: true,
  groupCells: 1,
};
export default function OfferSlider({
  showOffers
}) {
  const [loading, setLoading] = useState(false);
  const { restaurantColor, offersArray, isLanguageArab, restaurantCurrency } = useRestaurantsInfoContext()
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(!offersArray);
  }, [offersArray]);

  const generateDescription = (offer, index) => {
    return ({
      [OfferTypes.FLAT]: t(`${get(restaurantCurrency, 'props.children')}${offer.discount_value} OFF`),
      [OfferTypes.PERCENT]: t(`${offer.discount_value}% OFF`),
      [OfferTypes.FREEDELIVERY]: t('FREE DELIVERY')
      })[get(offer, 'discount_type') || '']
  };

  return (
    <>
      {showOffers && !loading && offersArray?.length ? (
        <OffersWrapper isLanguageArab={isLanguageArab}>
          <OffersHeader id='offers-heading'>{t('OFFERS')} </OffersHeader>
          <Flickity
            className={"offerCarousel"} // default ''
            elementType={"div"} // default 'div'
            options={flickityOptions} // takes flickity options {}
            disableImagesLoaded={false} // default false
            reloadOnUpdate // default false
          >

            {offersArray.length ?
              offersArray.map((element, index) => {
                return (
                  <>
                    <OffersCardContainer key={index} isLanguageArab={isLanguageArab}>
                      <OffersCardLeft key={index} isLanguageArab={isLanguageArab} restaurantColor={restaurantColor}>
                        {isLanguageArab ?
                          <OfferCodeReverted restaurantColor={restaurantColor} restaurantDarkenColor={darkenColor(restaurantColor)} offerCode={generateDescription(element, index)} offerType={element.discount_type}></OfferCodeReverted>
                          : <OfferCode restaurantColor={restaurantColor} restaurantDarkenColor={darkenColor(restaurantColor)} offerCode={generateDescription(element, index)} offerType={element.discount_type}></OfferCode>
                        }
                      </OffersCardLeft>
                      <OffersCardRight key={index} isLanguageArab={isLanguageArab}>
                        <OfferDescription id={`${element.code}-coupon-description`}>
                          {element.description}  
                        </OfferDescription>
                        <OffersCode restaurantColor={restaurantColor}>{element.code}</OffersCode>
                      </OffersCardRight>
                    </OffersCardContainer>
                  </>
                );
              }) : <OffersHeader id='no-offers-available-heading' style={{ color: 'red' }}>{t('NO_OFFER_AVAILABLE')}</OffersHeader>}
          </Flickity>
        </OffersWrapper>
      ) : (
        ""
      )}
    </>
  );
}



