import styled, { css } from 'styled-components'
import styleVals from '../../constants/styles'

export const VariantGroupPlyusContainer = styled.div`
    position: fixed;
    width: 100%;
    min-height: 100vh;
    margin-top: 0;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    bottom: 0;
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: ${styleVals.measurements.spacing500};
    > div {
        position: absolute;
        width: 90%;
        background: #ffffff;
        border-radius:  ${styleVals.measurements.spacing20};
        max-height: 69%;
        hr[id='title-separator'] {
            margin: 8px 0 4px;
            padding: 0;
        }
        }
        text-align:${props => props.isLanguageArab && 'right'};
`
export const VariantGroupPlyusHeader = styled.div`
    height: auto;
    padding: 0 ${styleVals.measurements.spacing10};
    display: flex;
    justify-content: space-between;
    padding-top: ${styleVals.measurements.spacing10};
    align-items: center;
`

export const ModelcloseIcon = styled.span`
     font-size: ${styleVals.measurements.spacing21};
     margin-right: 2%;
     cursor: pointer;
     width:  ${styleVals.measurements.spacing30};
     height:  ${styleVals.measurements.spacing30};
     border: 1px solid #000;
     background-color: #fff;
     border-radius:  ${styleVals.measurements.spacing15};
     text-align: center;
     font-size: ${styleVals.measurements.spacing21};
     line-height: 1.42857;
     position: absolute;
     top: -${styleVals.measurements.spacing13};
     font-weight: 600;
     ${props => props.isLanguageArab ? css`left: 1px` : css` right: 1px;`}
`;

export const VariantGroupPlyusName = styled.span`
   display: flex;
   align-items: center;
   font-weight: 600;
   font-size: ${styleVals.measurements.spacing17};
   width: 87%;
`

export const VariantGroupPlyusProducts = styled.div`
    max-height: ${styleVals.measurements.spacing300};
            overflow-y: auto;
            overflow-x: hidden;
            > div {
                display: flex;
            }
`
export const ProductsLeftSection = styled.div`
        width: 70%;
        padding: ${styleVals.measurements.spacing4};
        margin-top: ${styleVals.measurements.spacing5};
        margin-bottom: ${styleVals.measurements.spacing5};
        background: white;
        border-radius: ${styleVals.measurements.spacing5};
        margin: ${styleVals.measurements.spacing6};
        > span{
            font-size: ${styleVals.measurements.spacing15};
        }
`
export const ProductsTitle = styled.div`
    font-size: ${styleVals.measurements.spacing15};
`
export const VariantAndAddonSection = styled.div`
        font-size: ${styleVals.measurements.spacing13};
        color: #666666;
        padding-bottom: 1px;
`

export const ProductsRightSection = styled.div`
    padding: ${styleVals.measurements.spacing4};
    background: white;
    margin: ${styleVals.measurements.spacing6};
    margin-top: ${styleVals.measurements.spacing22};
    margin-right: ${styleVals.measurements.spacing8};
`
export const ProductPrice = styled.div`
    font-size: ${styleVals.measurements.spacing15};
    padding: ${styleVals.measurements.spacing5};
    font-weight: 600;
    text-align: end;
`
export const AddCustomization = styled.div`
     height: 8vh;
     display: flex;
     align-items: center;
     padding: 0 ${styleVals.measurements.spacing10};
     border-top: 1px solid #9d9d9d;

     > div {
         cursor: pointer;
         color: #b60000;
         font-weight: 500;
         color:${props => props.restaurantColor ? props.restaurantColor : '#ab0012'}
    }
`
