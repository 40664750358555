import { Spinner } from "react-bootstrap";
import styled, { css } from "styled-components";
import styleVals from "../../../constants/styles";
import { darkenColor } from "../../../utils/common";

export const ProductContainer = styled.div`
  position: relative;
  max-width: 500px;
  margin: 0 auto;
  min-height: 100vh;
  ${props => props.withBg && css`
    background-color: #f7f7f7;
  `}
`;

export const CategoriesWrapper = styled.div`
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const CategoryWrapper = styled.div`
  margin: 0;
  min-height: 0;
  background: white;
  margin-top: ${styleVals.measurements.spacing24};
  padding: ${styleVals.measurements.spacing24} ${styleVals.measurements.spacing10} ${styleVals.measurements.spacing12};
  text-align: ${props => props.isLanguageArab && 'right'};
`;

export const CategoryHeading = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-weight: 700;
  color: #000;
  ${props => props.resultsHeading ? css`
    margin: ${styleVals.measurements.spacing24} ${styleVals.measurements.spacing10} ${styleVals.measurements.spacing12};
  ` : css`
    margin: ${styleVals.measurements.spacing0} ${styleVals.measurements.spacing0} ${styleVals.measurements.spacing12};
  `};
  ${props => props.isLanguageArab && css`
    text-align: right;
  `}
`;

export const SubCategoryBlock = styled.div`
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 5px;
  width: 100%;
  ${props => props.searchValue && css`
      padding: 0 5px;
      margin-top: 0px;
  `}
`;
SubCategoryBlock.div = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

SubCategoryBlock.h2 = styled.h2`
  color: ${(props) => props?.restaurantColor || "#3c4b64"};
  font-size: 1rem;
  font-weight: 600;
  margin: 0 0 5px;
  text-transform: uppercase;
  word-break: break-all;
  display: inline-block;
  padding-bottom: 2px;
`;

SubCategoryBlock.div = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const ProductFooter = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 500px;
  background: #fff;
  box-shadow: 0 2px 3px rgb(0 0 0 / 16%);
  z-index: 1;
`;

ProductFooter.Container = styled.div`
  padding: 10px 16px;
  background: ${(props) => props?.restaurantColor || "#444444"};
  color: ${props => props.footerColor};
  display: block;
  width: 100%;
  font-weight: 600;
`;

ProductFooter.cartItem = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  color: ${props => props.footerColor};
`;
ProductFooter.noItems = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;
export const CartBag = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80px;
  #cart-items-label {
    color: ${props => props.footerColor};
  }
`;

CartBag.icon = styled.i`
  margin-right: 16px;
  font-size: 22px;
  color: ${props => props.footerColor};
`;
export const TotalItemCounts = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #d91b12;
  text-align: center;
  position: absolute;
  top: -6px;
  font-size: 12px;
  line-height: 22px;
  font-weight: bold;
  color: #fff;
  ${props => props.isLanguageArab ? css`
  right: 0;
  ` : css`
  left: 12px;
  `  }
`;

ProductFooter.Button = styled.button`
  background: ${(props) =>
    props.disabled ? "#878787" : props.restaurantColor || "#ab0012"};
  display: flex;
  align-items: center;
  padding: 9px 16px;
  font-weight: 600;
  border: 1px solid ${props => props.footerColor};
  border-radius: 6px;
  color: ${props => props.footerColor};
  &:hover {
    background: ${(props) =>
      props.disabled ? "#878787" : props.restaurantColor || "#ab0012"};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    opacity: 1;
  }
`;

export const MenuItemContainer = styled.div`
  display: flex !important;
  flex-wrap: wrap;
  justify-content: space-between;
  grid-row-gap: 5px;
  padding-left: 0;

  ${props => props.searchValue && css`
    /* margin-bottom: 0px; */
    grid-gap: 0px;
  `}
`;

export const MenuButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  margin: 0 auto;
  background: linear-gradient(to left, ${props => props.restaurantColor}, ${props => darkenColor(props.restaurantColor)}) !important;
  color: ${props => props.isRestaurantColorDark ? styleVals.colors.white : styleVals.colors.primaryText};
  font-size: 15px;
  font-weight: bold;
  position: fixed;
  bottom: ${(props) => props.bottomGap};
  left: 0;
  right: 0;
  border-radius: 17px;
  cursor: pointer;
  border: none;
  outline: none;
  box-shadow: ${styleVals.colors.boxShadow};
  height: ${styleVals.measurements.spacing34};
`;

export const GIPBotIFrame = styled.iframe`
  width: 100%;
  max-width: 500px;
  margin: 60px auto auto;
  height: calc(100% - 60px);
  border: none;
  display: ${(props) => (props.display ? "flex" : "none")};
  position: fixed;
  z-index: 9;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const CountContainer = styled.div`
  position: relative;
  ${props => props.withLabelBefore && css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    span[id='item-price'] {
      padding: 0;
      margin: 0;
      display: inline-block;
      ${props => props.adjustPriceLabel && css`
        margin-right: ${styleVals.measurements.spacing12};
      `}
    }
  `}
`;

CountContainer.Div = styled.div`
  display: flex;
  width: 75px;
  height: 30px;
  border: 1px solid #a2a2a2;
  border-radius: 18px;
  position: relative;
`;

export const CountMinus = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 700 !important;
  width: 22px;
  border-radius: 18px 0 0 18px;
  cursor: pointer;
  color: ${(props) => props.disabledButton && "grey"};
`;

export const CountPlus = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 700 !important;
  color: ${(props) => props.restaurantColor || "#60b246"};
  width: 22px;
  border-radius: 0 18px 18px 0;
  cursor: pointer;
  color: ${(props) => props.disabledButton && "grey"};
  ${(props) =>
    props.biggerFont &&
    css`
      font-size: 20px;
      font-weight: 100 !important;
      width: 34px;
      color: #6a6a6a;
      padding-top: 2px;
    `};
  ${(props) =>
    props.onlyButton &&
    css`
      i {
        color: ${props.disabled
          ? styleVals.colors.greyText
          : props.restaurantColor};
      }
    `}
`;

export const CountBlock = styled.span`
  flex: 1 1;
  word-wrap: normal;
  border-radius: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 700 !important;
`;

export const AddButtonBlock = styled.div`
  position: relative;
  text-align: center;
`;

AddButtonBlock.Span = styled.span`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 10px;
  color: #727272 !important;
`;
AddButtonBlock.Button = styled.button`
  background-color: ${(props) =>
    props.disabled ? "#878787" : props.restaurantColor || "#ab0012"};
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${(props) =>
    props.withPrice ? styleVals.measurements.spacing136 : "75px"};
  ${(props) => props.withPrice && css`
    padding: 0 ${styleVals.measurements.spacing8};
  `};
  height: 30px;
  text-transform: uppercase;
  border-radius: 18px;
  font-size: ${(props) =>
    props.maintainSize ? styleVals.measurements.spacing14 : "13px"};
  font-weight: ${(props) => (props.maintainSize ? "200" : "700")};
  cursor: pointer;
  border: 1px solid ${(props) => props.restaurantColor || "#a2a2a2"};

  &:hover {
    background-color: ${(props) =>
      props.disabled ? "#878787" : props.restaurantColor || "#ab0012"};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  }

  ${(props) =>
    !props.maintainSize &&
    css`
      @media (max-width: 375px) {
        width: 60px;
        height: 20px;
      }
    `}
`;
export const EmptyCart = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background: white;
  margin-top: ${styleVals.measurements.spacing80};
`;

EmptyCart.img = styled.img`
  display: block;
  width: 80px;
  height: 80px;
  opacity: 0.5;
  margin: auto;
`;
EmptyCart.span = styled.span`
  font-size: 12px;
  color: #888888;
  text-align: center;
`;
export const CartWithAmountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  span[id="cart-amount"] {
    font-size: ${styleVals.measurements.spacing14};
    color: ${props => props.footerColor};
    ${props => props.isLanguageArab && css`
      text-align: right;
    `}
  }
`;
export const ComboOffersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: white;
  margin-top: ${styleVals.measurements.spacing24};
  padding: ${styleVals.measurements.spacing24} ${styleVals.measurements.spacing10} ${styleVals.measurements.spacing12};
`;

export const ConfirmationToJoinTable = styled.div`
   position: fixed;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    height: 100vh;
    height: -webkit-fill-available;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    z-index: 2;
    max-width: 500px;
`;

ConfirmationToJoinTable.Div = styled.div`
      width: 100%;
      height: 165px;
      transition: height 0.0005s ease;
      background-color: #ffffff;
      border: none;
      position: fixed;
      bottom: 0px;
      border-radius: 20px 20px 0px 0px;
      padding: 50px 0px;
      max-width: 500px;
`
export const JoinTableText = styled.div`
        display: flex;
        justify-content: center;
`;
export const JoinTableButtonSection = styled.div`
    display: flex;
    justify-content: space-around;
`;
export const JoinTableButton = styled.button`
    margin: 0 auto;
    bottom: 30px;
    left: 40%;
    border-radius: 18px;
    color: #ffffff;
    display: flex;
    align-items: center;
    width: 75px;
    height: 30px;
    justify-content: center;
    text-transform: uppercase;
    min-width: 0;
    font-weight: 700;
    font-size: 13px;
    background-color:${props => props.restaurantColor};
    border: 1px solid ${props => props.restaurantColor};
`;
export const FooterMessage = styled.div`
    text-align: center;
    width: 80%;
    margin: auto;

`;

export const MinOrderAmount = styled.div`
    background-color: #D3D5D6;
    color: black;
    padding: 2px;
    text-align: center;
    span {
      font-size: 12px;
    }
`;

export const FooterMessageForDinein = styled.span`
  margin: 0px auto;
`;
export const MenuLoaderWrapper = styled.div`
    margin-top: ${styleVals.measurements.spacing36};
    display: flex;
    width: 100%;
    justify-content: center;
`
export const MenuLoader = styled(Spinner)`
  color: ${props => props.restaurantColor};
`
export const BannerImage = styled.img`
  height: 100% !important;
  width: 100% !important;
`