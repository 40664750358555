import { get } from '../../../utils/common';
import {
  LandingModal,
  Content,
  ModalHeader,
  ModalBody,
  Heading,
  ModalBackdrop,
} from './styles';
import GradientButton from '../../../Components/GradientButton/index';
import { useRestaurantsInfoContext } from '../../../contexts/restaurantInfo';
import i18n from '../../../translations/i18n';


export default function LandingCardModal({ handleClick }) {
  const { restaurant, restaurantColor } = useRestaurantsInfoContext()

  const LandingCardModalConstants = {
    WELCOME_TO: i18n.t('WELCOME_TO'),
    SUPER_SAVING: i18n.t('SUPER_SAVING'),
    ORDER_NOW: i18n.t('ORDER_NOW'),
  };

  const closeModal = (event) => {
    const parent = document.getElementById('landing-modal-backdrop');
    const modal = parent.childNodes[0];
    if (event.target === modal) {
      handleClick();
    }
  };

  const offerMessagesEnabled = get(restaurant, 'meta_data.offer_messages_enabled', false)

  return (
    <ModalBackdrop id='landing-modal-backdrop' onClick={closeModal}>
      <LandingModal id='landing-modal-wrapper' restaurantColor={restaurantColor}>
        <LandingModal.Div
          restaurantColor={restaurantColor}
        >
          <ModalHeader restaurantColor={restaurantColor}>
            <div><img id='landing-modal-logo' src={get(restaurant, 'meta_data.logo')} /></div>
            <Heading id='landing-modal-heading'>
              {`${LandingCardModalConstants.WELCOME_TO} ${get(restaurant, 'meta_data.name', '')}`}
            </Heading>
            <Content id='landing-modal-message' withSpacing={!offerMessagesEnabled} restaurantColor={restaurantColor}>{get(restaurant, 'meta_data.welcome_message', '')}</Content>
            {!offerMessagesEnabled && <GradientButton id='landing-modal-button' onClick={handleClick}>
              {LandingCardModalConstants.ORDER_NOW}
            </GradientButton>}
          </ModalHeader>
          {offerMessagesEnabled &&
            get(restaurant, 'meta_data.offer_message', []).length > 0 && (
              <ModalBody>
                <Heading id='landing-modal-offer-heading'>{LandingCardModalConstants.SUPER_SAVING}</Heading>
              {get(restaurant, 'meta_data.offer_message', []).map((ofr_msg, i) => (
                  <Content
                    id={`landing-modal-offer-${i+1}`}
                    key={i}
                    firstChild={i === 0}
                    lastChild={i === get(restaurant, 'meta_data.offer_message.length') - 1}
                    withSpacing
                  >
                    {get(ofr_msg, 'message')}
                  </Content>
                ))}
              {offerMessagesEnabled && <GradientButton id='landing-modal-button' onClick={handleClick}>
                  {LandingCardModalConstants.ORDER_NOW}
                </GradientButton>}
              </ModalBody>
            )}
        </LandingModal.Div>
      </LandingModal>
    </ModalBackdrop>
  );
}
