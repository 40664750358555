import styled, { css } from "styled-components";
import { Loader } from '../../Components/BasicComponents';
import styleVals from '../../constants/styles'

export const OffersWrapper = styled.div`
    padding: ${styleVals.measurements.spacing8} ${styleVals.measurements.spacing10} ${styleVals.measurements.spacing0};
    text-align: ${props => props.isLanguageArab && 'right'};
`

export const OffersHeader = styled.h2`
  margin: 0;
  padding: 0;
  font-size: ${styleVals.measurements.spacing20};
  font-weight: 700;
  color: #000;
  margin: ${styleVals.measurements.spacing24} 0 ${styleVals.measurements.spacing12};
`;

export const Spinner = styled(Loader)`
  color: ${props => props.restaurantColor};
`;

export const OffersCard = styled.div`
       border-radius:${styleVals.measurements.spacing7};
       margin:${styleVals.measurements.spacing10};
       max-width:${styleVals.measurements.spacing320};
       width:fit-content;
       min-width:${styleVals.measurements.spacing270};
       height:${styleVals.measurements.spacing125};
       display:flex;
       justify-content:center;
       align-items:center;
       background: #fff 0 0 no-repeat padding-box;
       box-shadow:${styleVals.measurements.spacing5} ${styleVals.measurements.spacing1} ${styleVals.measurements.spacing3} rgb(0 0 0 / 6%);
       opacity: 1;
       border:${styleVals.measurements.spacing1} solid #e5e5e5;
       border-radius: ${styleVals.measurements.spacing45};
`;

export const OffersCardContainer = styled.div`
      max-width:${styleVals.measurements.spacing320};
      min-width:${styleVals.measurements.spacing270};
      height:${styleVals.measurements.spacing110};
      opacity: 1;
      display:flex;
      margin:${styleVals.measurements.spacing10};
      width:${styleVals.measurements.spacing320};
`;

export const OffersCardLeft = styled.div`
      width: 30%; 
      display:flex;
      justify-content:center;
      align-items:center;
      background: #fff 0 0 no-repeat padding-box;
      opacity: 1;
      border-top-left-radius: ${styleVals.measurements.spacing40} ${styleVals.measurements.spacing40};
      border-bottom-left-radius: ${styleVals.measurements.spacing40} ${styleVals.measurements.spacing40};
      border-top-right-radius: ${styleVals.measurements.spacing40} ${styleVals.measurements.spacing25};
      border-bottom-right-radius: ${styleVals.measurements.spacing40} ${styleVals.measurements.spacing30};
      color: #fff;
      font-family: "Poppins";
`;

export const OffersCardRight = styled.div`
       width: 70%;
       display:flex;
       justify-content:center;
       align-items:center;
       background: #FFFFFF 0 0 no-repeat padding-box;
       opacity: 1;
       border-radius: ${styleVals.measurements.spacing10};
       flex-direction: column;
       box-shadow: ${styleVals.measurements.spacing2} ${styleVals.measurements.spacing2} #00000014;
       ${props => props.isLanguageArab ? css`border-top-left-radius: ${styleVals.measurements.spacing35} ${styleVals.measurements.spacing35};` : css`border-top-right-radius: ${styleVals.measurements.spacing35} ${styleVals.measurements.spacing35};`}
       ${props => props.isLanguageArab ? css`border-bottom-left-radius: ${styleVals.measurements.spacing35} ${styleVals.measurements.spacing35};` : css`border-bottom-right-radius: ${styleVals.measurements.spacing35} ${styleVals.measurements.spacing35};`}
`;

export const OfferDescription = styled.span`
    font-size: ${styleVals.measurements.spacing12};
    width: 100%;
    float: left;
    width: 80%;
    height:${styleVals.measurements.spacing140};
    max-height: 85%;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    :-webkit-scrollbar {
    display: none;
    }
    max-width:${styleVals.measurements.spacing150};
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    line-height: ${styleVals.measurements.spacing13};
    text-align: center;
    padding-top: ${styleVals.measurements.spacing15};    
`;

export const OffersCode = styled.h5`
    color: ${props => props.restaurantColor ? props.restaurantColor : 'red'};
    font-family: 'Poppins';
    font-weight: 600;
    line-height: ${styleVals.measurements.spacing30};
`;
