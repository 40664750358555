import i18n from "../translations/i18n";

export const MESSAGES = Object.freeze({
  ERROR: {
    OFFER: {
      OFFER_UNDEFINED: i18n.t('OFFER_UNDEFINED'),
      OFFER_AMOUNT_INVALID: i18n.t('OFFER_AMOUNT_INVALID'),
      OFFER_AMOUNT_INVALID_FOR_ITEMS: i18n.t('OFFER_AMOUNT_INVALID_FOR_ITEMS'),
      ALPHA_NUMERIC : i18n.t('ALPHA_NUMERIC'),
      OFFER_LENGTH : i18n.t('OFFER_LENGTH'),
      OFFER_AMOUNT_LESS_THAN_ORDER_VALUE : i18n.t('OFFER_AMOUNT_LESS_THAN_ORDER_VALUE')

    },
  },
});
