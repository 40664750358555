import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Checkbox from '../../Components/Checkbox'
import { FilterIcon } from '../../Components/Header/Icons';
import {
  CheckboxWrapper,
  FilterDivider,
  FilterHeading,
  FilterOnIndicator
} from './styles';
import { DropdownWrapper, Dropdown, DropdownMenu, DropdownToggle } from '../BasicComponents';
import { useComponentVisible } from '../../hooks'

const FilterDropDown = ({
  barMenu,
  dropDownTypes,
  veganFunc,
  nonVegFunc,
  foodFunc,
  barItemFunc,
  eggFunc,
  restaurantColor,
  isLanguageArab, isRestaurantVeg
}) => {
  const { t } = useTranslation();
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible();

  const toggleDropdown = () => setIsComponentVisible(!isComponentVisible)

  let anyFoodTypeSelected = !!Object.keys(dropDownTypes).find(fType => (isRestaurantVeg ? ['barItem', 'foodItem'].includes(fType) : true) && !!dropDownTypes[fType]);

  const filterType = Object.freeze({
    FOOD: t('FOOD'),
    BAR: t('BAR'),
    VEG: t('VEG'),
    NONVEG: t('NONVEG'),
    EGG: t('EGG')
  });
  const MenuTypeSelection = () => <div>
    <FilterHeading firstChild>{t('MENU')}</FilterHeading>
    <CheckboxWrapper>
      <Checkbox
        id='filter-food-selection'
        onChange={foodFunc}
        checked={dropDownTypes.foodItem}
        value={filterType.FOOD}
        label={filterType.FOOD}
        labelPosition = 'right'
        bgColor={'white'}
        inline
      />
    </CheckboxWrapper>
    <CheckboxWrapper>
      <Checkbox
        id='filter-bar-selection'
        onChange={barItemFunc}
        checked={dropDownTypes.barItem}
        value={filterType.BAR}
        label={filterType.BAR}
        labelPosition = 'right'
        bgColor={'white'}
        inline
      />
    </CheckboxWrapper>
    <FilterDivider isRestaurantVeg={isRestaurantVeg} />
  </div>

  const FoodTypeSelection = () => <React.Fragment>
    <FilterHeading withTopMargin={!!barMenu} firstChild={!barMenu}>{t('FOOD_PREFRENCE')}</FilterHeading>
    <CheckboxWrapper>
      <Checkbox
        id='filter-veg-selection'
        onChange={veganFunc}
        checked={dropDownTypes.vegan}
        value={filterType.VEG}
        label={filterType.VEG}
        labelPosition = 'right'
        bgColor={'white'}
        inline
      />
    </CheckboxWrapper>
    <CheckboxWrapper>
      <Checkbox
        id='filter-nonveg-selection'
        onChange={nonVegFunc}
        checked={dropDownTypes.nonVeg}
        value={filterType.NONVEG}
        label={filterType.NONVEG}
        labelPosition = 'right'
        bgColor={'white'}
        inline
      />
    </CheckboxWrapper>
    <CheckboxWrapper>
      <Checkbox
        id='filter-egg-selection'
        onChange={eggFunc}
        checked={dropDownTypes.egg}
        value={filterType.EGG}
        label={filterType.EGG}
        labelPosition = 'right'
        bgColor={'white'}
        inline
      />
    </CheckboxWrapper>
  </React.Fragment>
  
  return (
    <DropdownWrapper>
      <div ref={ref} id='filter-toggle-dropdown-wrapper'>
        <div onClick={toggleDropdown} id='filter-toggle-wrapper'>
          <DropdownToggle id='filter-toggle'>
            {anyFoodTypeSelected && <FilterOnIndicator id='filter-applied-indicator' />}
            <FilterIcon restaurantColor={restaurantColor} />
          </DropdownToggle>
        </div>
        {
          isComponentVisible && <Dropdown isLanguageArab={isLanguageArab} id='filter-dropdown-view'>
            <DropdownMenu id='filter-menu' placement='bottom-end'>
              {!!barMenu && MenuTypeSelection()}
              {!isRestaurantVeg && FoodTypeSelection()}
            </DropdownMenu>
          </Dropdown>
        }
      </div>
    </DropdownWrapper>
  );
};

export default FilterDropDown;
