import axios from "axios";
import jsCookie from "js-cookie";
import { Notification } from "../helpers/notification";
import { get } from '../utils/common';
import  i18n  from '../translations/i18n';
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.headers = {
    'Content-Type': 'application/json',
    'gipuserid': jsCookie.get('gipuserid')
}

const handleError = (error, multipleErrors) => {
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        let displayMsg = null;
        if (error.response.message) {
            displayMsg = error.response.message;
        } else if (error.response.data) {
            if (error.response.data.message) {
                displayMsg = error.response.data.message;
            } else if (error.response.data.errors && error.response.data.errors[0]) {
                displayMsg = error.response.data.errors[0].error;
                if(multipleErrors) {
                    get(error, 'response.data.errors', []).forEach((err) => {
                        Notification({
                            type: 'error',
                            displayMessage: get(err, 'error'),
                            toasterOptions: {
                                toastId: get(err, 'error')
                            }
                        })
                    })
                    return
                }
            }
        } else if (error.response.data.error) {
            displayMsg = error.response.data.error;
        } else if (error.response.statusText) {
            displayMsg = error.response.statusText;
        }
        Notification({
            type: 'error',
            displayMessage: displayMsg,
            toasterOptions: {
                toastId: displayMsg
            }
        })
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        Notification({
            type: 'error',
            displayMessage: i18n.t('notificationMsg.SOMETING_WENT_WRONG'),
            toasterOptions: {
                toastId: 'connectionError'
            }

        })
    } else {
        // Something happened in setting up the request that triggered an Error
        Notification({
            type: 'error',
            displayMessage: error.message,
            toasterOptions: {
                toastId: error.message
            }
        })
        console.log('Error', error.message);
    }
    console.log(error.config);
}

function GET(url, headers = null, noToastErrorCodes=[]) {
    axios.defaults.headers['gipuserid'] = jsCookie.get('gipuserid');
    axios.defaults.headers['Accept-Language'] = i18n.language;
    if (headers && headers.Authorization) {
        axios.defaults.headers['Authorization'] = headers.Authorization;
    }
    return new Promise((resolve, reject) => {
        axios.get(url)
            .then(response => resolve(response))
            .catch(function (error) {
                if(noToastErrorCodes.length === 0) {
                    handleError(error);
                    reject(error);
                } else if (noToastErrorCodes.includes('ALL')) {
                    reject(error);
                } else {
                    const errorCode = get(error, 'response.data.errors[0].code');
                    if(noToastErrorCodes.includes(errorCode)) {
                        reject(error);
                    } else {
                        handleError(error);
                        reject(error)
                    }
                }
            });
    })
}
function POST(url, data = {}, headers = null, noToastErrorCodes=[], multipleErrors = false) {
    return new Promise((resolve, reject) => {
        // convert to string if it's object
        try {
            if (typeof data !== 'string') {
                data = JSON.stringify(data);
            }
        } catch (error) {
            reject(error);
        }

        if (headers && headers.Authorization) {
            axios.defaults.headers['Authorization'] = headers.Authorization;
        }
        axios.defaults.headers['Accept-Language'] = i18n.language;
        axios.defaults.headers['gipuserid'] = jsCookie.get('gipuserid');
        axios.post(url, data, headers)
            .then(response => resolve(response))
            .catch((error) => {
                const errorCode = get(error, 'response.data.errors[0].code')
                if (noToastErrorCodes.includes('ALL') || noToastErrorCodes.includes(errorCode)) {
                    reject(error);
                } else {
                    handleError(error, multipleErrors);
                    reject(error);
                }
            })
    })
}
function DELETE(url, headers = null) {
    axios.defaults.headers['gipuserid'] = jsCookie.get('gipuserid');
    axios.defaults.headers['Accept-Language'] = i18n.language;
    if (headers && headers.Authorization) {
        axios.defaults.headers['Authorization'] = headers.Authorization;
    }
    return new Promise((resolve, reject) => {
        axios.delete(url)
            .then(response => resolve(response))
            .catch((error) => {
                handleError(error);
                reject(error);
            })
    })
}
function PUT(url, data, headers = null) {
    return new Promise((resolve, reject) => {
        // convert to string if it's object
        try {
            if (typeof data !== 'string') {
                data = JSON.stringify(data);
            }
        } catch (error) {
            reject(error);
        }

        if (headers && headers.Authorization) {
            axios.defaults.headers['Authorization'] = headers.Authorization;
        }
        axios.defaults.headers['Accept-Language'] = i18n.language;
        axios.defaults.headers['gipuserid'] = jsCookie.get('gipuserid');
        axios.put(url, data, headers)
            .then(response => resolve(response))
            .catch((error) => {
                handleError(error);
                reject(error);
            })
    })
}
export { GET, POST, DELETE, PUT };