import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import * as ROUTES from "./constants/routes";
import { Spinner } from "./Components";
import "bootstrap/scss/bootstrap.scss";
import { RestaurantsInfoContext } from "./contexts/restaurantInfo";
import { ToastContainer } from "react-toastify";
import jsCookie from "js-cookie";
import { useClearCache } from 'react-clear-cache'

import Product from "./pages/ProductV2/ProductV2"
// const TableReservation = React.lazy(() =>
//   import("./pages/TableReservation/TableReservation")
// );
const Page404 = React.lazy(() => import("./pages/ErrorPages/Page404"));
const Page500 = React.lazy(() => import("./pages/ErrorPages/Page500"));
const SetLocation = React.lazy(() =>
  import("./pages/SetLocation/SetLocation")
);
const Checkout = React.lazy(() => import("./pages/Checkout/Checkout.js"));
const OrderDetails = React.lazy(() =>
  import("./pages/OrderDetails/OrderDetails")
);
const Payment = React.lazy(() => import('./pages/Payment'));
const TermAndCondition = React.lazy(() => import('./pages/TermAndCondition/TermAndCondition'));

export default function App() {
  // Set default Cookie || For development purpose only
  if (process.env.REACT_APP_GIP_USERID_COOKIE) {
    jsCookie.set("gipuserid", process.env.REACT_APP_GIP_USERID_COOKIE);
  }

  const {isLatestVersion, emptyCacheStorage} = useClearCache()

  useEffect(() => {
    if (!isLatestVersion) {
      emptyCacheStorage()
    }
  }, [])

  //table reservation to be enabled in future

  return (
    <BrowserRouter>
        <RestaurantsInfoContext>
          <Switch>
            {/* <Route exact path={[ROUTES.TABLERESERVATION, ROUTES.TABLERESERVATION]}>
              <Suspense fallback={<Spinner />}>
                <TableReservation />
              </Suspense>
            </Route> */}
            <Route exact path={[ROUTES.PRODUCT, ROUTES.PRODUCT_D]}>
                <Product />
            </Route>
            <Route exact path={[ROUTES.ORDERDETAILS, ROUTES.ORDERDETAILS_D]}>
              <Suspense fallback={<Spinner />}>
                <OrderDetails />
              </Suspense>
            </Route>
            <Route exact path={[ROUTES.CHECKOUT, ROUTES.CHECKOUT_D]}>
              <Suspense fallback={<Spinner />}>
                <Checkout />
              </Suspense>
            </Route>
            <Route path={[ROUTES.PAYMENT, ROUTES.PAYMENT_D]}>
              <Suspense fallback={<Spinner />}>
                <Payment />
              </Suspense>
            </Route>
            <Route exact path={[ROUTES.VEIWADDRESS, ROUTES.VEIWADDRESS_D]}>
              <Suspense fallback={<Spinner />}>
                <SetLocation />
              </Suspense>
            </Route>
            <Route path={ROUTES.DASHBOARD}>
              <Suspense fallback={<Spinner />}>
                <Redirect to={process.env.REACT_APP_dashboard_url} />
              </Suspense>
            </Route>
            <Route exact path={ROUTES.ERROR500} name="Page 500">
              <Suspense fallback={<Spinner />}>
                <Page500 />
              </Suspense>
            </Route>
            <Route path={ROUTES.ERROR404} name="Page 404">
              <Suspense fallback={<Spinner />}>
                <Page404 />
              </Suspense>
            </Route>
          <Route exact path={[ROUTES.TERM_CONDITIONS, ROUTES.TERM_CONDITIONS_D]}>
            <Suspense fallback={<Spinner />}>
              <TermAndCondition />
            </Suspense>
          </Route>
            <Redirect from={ROUTES.HOME} to={ROUTES.PRODUCT} />
            <Redirect from={ROUTES.HOME_D} to={ROUTES.PRODUCT_D} />
            <Redirect from="/:productId" to={ROUTES.PRODUCT} />
            <Redirect from='/' to={ROUTES.PRODUCT_D} />
          </Switch>
        </RestaurantsInfoContext>
        <ToastContainer />
    </BrowserRouter>
  );
}
