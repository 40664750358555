import styled, { css } from 'styled-components'
import styleVals from '../../constants/styles'

export const FilterOnIndicator = styled.span`
    width: ${styleVals.measurements.spacing14};
    height: ${styleVals.measurements.spacing14};
    background: red;
    position: absolute;
    right: 0;
    border-radius: 50%;
`

export const FilterHeading = styled.div`
    text-transform: uppercase;
    padding: ${props => props.firstChild ? styleVals.measurements.spacing0 : styleVals.measurements.spacing6} ${styleVals.measurements.spacing10} ${styleVals.measurements.spacing6};
    font-weight: bold;
    font-size: ${styleVals.measurements.spacing14};
    text-transform: uppercase;
    color: #404040;
    ${props => props.withTopMargin && css`
        margin-top: 1px;
    `}
`
export const CheckboxWrapper = styled.div`
    width: fit-content;
    label {
        p {
            font-size: ${styleVals.measurements.spacing16};
            margin-left: ${styleVals.measurements.spacing6}; 
        }
        margin-bottom: ${styleVals.measurements.spacing10};
    }

`
export const FilterDivider = styled.div`
  ${props => !props.isRestaurantVeg && css`
        border-bottom: ${styleVals.measurements.spacing2} solid ${styleVals.colors.borderColor};
        padding-top: ${styleVals.measurements.spacing2};
    `}
`