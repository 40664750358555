import styled, { css } from 'styled-components'
import styleVals from '../../constants/styles'

export const InputWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-bottom: ${props => !props.noBottomGap && styleVals.measurements.spacing24};
    ${props => props.enterLocationInput && css`min-width : 0`};
    ${props => props.showIcon && css`
        position: relative;
        div[id='textinput-icon-wrapper'] {
            position: absolute;
            right: 0;
            top: ${styleVals.measurements.spacing0};
            ${props => props.smallIcon ? css`
                padding-top: ${styleVals.measurements.spacing4};
                height: ${styleVals.measurements.spacing16};
                img {
                    height: ${styleVals.measurements.spacing16} !important;
                }
            ` : css`
                padding-top: ${styleVals.measurements.spacing0};
                height: ${styleVals.measurements.spacing32} !important;
                img {
                    height: ${styleVals.measurements.spacing32} !important;
                }
            `}
            img[id='textinput-icon'] {
                width: ${styleVals.measurements.spacing46} !important;
                opacity: ${props.iconExists ? 1 : 0};
                transition: 0.3s all;
            }
            ${!props.iconExists && css`img[alt]:after {  
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #fff;
                color: #fff;
                font-family: 'Helvetica';
                font-weight: 300;
                line-height: 2;  
                text-align: center;
                content: attr(alt);
            }`}
        }
    `}
`

export const ErrorText = styled.p`
    font-size: ${styleVals.measurements.spacing14};
    margin: 0;
    padding: 0;
    color: ${styleVals.colors.red};
    margin-left: ${styleVals.measurements.spacing12};
    text-align: ${props => props.alignText};
`
