import { get } from '../../utils/common';
import React, { useEffect, useRef, useState } from 'react'
import { AccordingWrapper, AccordionActionWrapper, AccordionContent, AccordionHeader, AccordionHeading } from './styles'

const Accordion = ({
    heading,
    accordionAction,
    children,
    headingBackground,
    headingBorder,
    firstChild,
    accordionOpen,
    actionLabel = '',
    actionLabelId = '',
    accordionDisabled = false,
    headerClick = () => null,
    lessSideHeaderPadding = false,
    increaseHeadingFont = false,
    isBillDetail =  false
}) => {

    const contentHeightRef = useRef()

    const [isAccordionOpen, setIsAccordionOpen] = useState(accordionOpen)
    const [accordionHeight, setAccordionHeight] = useState(get(contentHeightRef, 'current.scrollHeight', 0))

    useEffect(() => {
        setAccordionHeight(get(contentHeightRef, 'current.scrollHeight', 0))
    }, [children])

    useEffect(() => {
        setIsAccordionOpen(accordionOpen)
        // window.scroll(0, get(contentHeightRef, 'current.offsetTop'))
    }, [accordionOpen])

    return <AccordingWrapper>
        <AccordionHeader isBillDetail={isBillDetail} increaseHeadingFont={increaseHeadingFont} lessSidePadding={lessSideHeaderPadding} isAccordionOpen={isAccordionOpen} onClick={headerClick} disabled={accordionDisabled} headingBorder={headingBorder} firstChild={firstChild} headingBackground={headingBackground}>
            <AccordionHeading id actionLabel={!!actionLabel}>{heading}</AccordionHeading>
            <AccordionActionWrapper actionLabel={!!actionLabel}>{!!actionLabel && <span id={actionLabelId}>{actionLabel}</span>}{!accordionDisabled && accordionAction}</AccordionActionWrapper>
        </AccordionHeader>
        <AccordionContent ref={contentHeightRef} isAccordionOpen={isAccordionOpen} expandedHeight={accordionHeight}>
            {children}
        </AccordionContent>
    </AccordingWrapper>
}

export default Accordion