import { get } from '../../../utils/common';
import {
    ServiceabilityWrapper,
    TextStyleButton,
    ServiceableInfo,
    ServiceableHeading,
    ServiceableText
} from './styles';
import { useRestaurantsInfoContext } from '../../../contexts/restaurantInfo';
import { ClockNumber, Days, MeridiemInfo, OrderType } from '../../../constants/variables';
import serviceableImg from "../../../assets/images/serviceable.jpg";
import i18n from '../../../translations/i18n';

const ServiceableMsgConstants = {
    CHANGE_LOCATION: i18n.t('CHANGE_LOCATION'),
    TAKEAWAY_OPTION:i18n.t('TAKEAWAY_OPTION'),
    DELIVERY_OPTION:i18n.t('DELIVERY_OPTION'),
    LOCATION_UNSERVICEABLE:i18n.t('LOCATION_UNSERVICEABLE'),
    DELIVERY_NOT_AVAILABLE:i18n.t('DELIVERY_NOT_AVAILABLE'),
    TAKEAWAY_NOT_AVAILABLE:i18n.t('TAKEAWAY_NOT_AVAILABLE'),
    SHOP_IS_CLOSED:i18n.t('SHOP_IS_CLOSED'),
    PLEASE_TRY_AGAIN:i18n.t('PLEASE_TRY_AGAIN'),
    DELIVERY_TRY_AFTER_SOMETIME:i18n.t('DELIVERY_TRY_AFTER_SOMETIME'),
    TAKEAWAY_TRY_AFTER_SOMETIME:i18n.t('TAKEAWAY_TRY_AFTER_SOMETIME'),
};

const TextButton = ({
    id = '',
    children,
    handleClick,
    type = 'button',
    ...props
}) => {
    const { restaurantColor } = useRestaurantsInfoContext()
    return <TextStyleButton
        id={id}
        onClick={handleClick}
        restaurantColor={restaurantColor}
        type={type}
        {...props}
    >
        {children}
    </TextStyleButton>
}


export default function OrderTypeServiceable({ restaurant, userLocationStatusForDelivery, userLocationStatusForTakeaway, goToLocationPage, triggerClevertapOrderTypeSwitchEvent }) {

    const { restaurantColor, orderType, setOrderType, userLocationData, isLanguageArab } = useRestaurantsInfoContext()

    const handleOrderTypeSelection = (text) => {
        if(text === ServiceableMsgConstants.TAKEAWAY_OPTION) {
            triggerClevertapOrderTypeSwitchEvent(OrderType.TAKEAWAY)
            setOrderType(OrderType.TAKEAWAY)
        } else if (text === ServiceableMsgConstants.DELIVERY_OPTION) {
            triggerClevertapOrderTypeSwitchEvent(OrderType.DELIVERY)
            setOrderType(OrderType.DELIVERY)
        } else if (text === ServiceableMsgConstants.CHANGE_LOCATION) {
            goToLocationPage()
        }
    };
    const checkEnabledAndServicability = (orderTypes, type) => get(orderTypes, `[${type}].enabled`) && get(orderTypes, `[${type}].serviceability.isServiceable`)

    const checkOrdertypesAvailability = (orderTypes, type) => get(orderTypes, `[${type}]`)?.hasOwnProperty('authorized') ? get(orderTypes, `[${type}].authorized`) && checkEnabledAndServicability(orderTypes, type) : checkEnabledAndServicability(orderTypes, type)


    const getOpensAtText = (orderType, nextBusinessHours) => {
        const startTime = get(nextBusinessHours, 'startTime', '');
        const day = get(nextBusinessHours, 'day', '');

        let orderTypeDetail = i18n.t(orderType), startTimeDetail = `${ClockNumber[startTime.split(" ")[0]]} ${MeridiemInfo[startTime.split(" ")[1]]}`, dayDetail = Days[day];
            if (startTime || day) {
                if (isLanguageArab) {
                    return i18n.t('OpensAt', { orderType: orderTypeDetail, time: startTimeDetail, day: dayDetail });
                } else {
                    return i18n.t('OpensAt', { orderType, time: startTime, day });
                }
            }
    }

    const serviceableMessage = () => {
        if (!checkOrdertypesAvailability(get(restaurant, 'order_types'), OrderType.DELIVERY) && !checkOrdertypesAvailability(get(restaurant, 'order_types'), OrderType.TAKEAWAY)) {
            const deliveryTiming = get(restaurant, `order_types[${OrderType.DELIVERY}].enabled`) && getOpensAtText(get(restaurant, `order_types[${OrderType.DELIVERY}].label`), get(restaurant, `order_types[${OrderType.DELIVERY}].serviceability.nextServiceableBusinessHours`))
            const takeawayTiming = get(restaurant, `order_types[${OrderType.TAKEAWAY}].enabled`) && getOpensAtText(get(restaurant, `order_types[${OrderType.TAKEAWAY}].label`), get(restaurant, `order_types[${OrderType.TAKEAWAY}].serviceability.nextServiceableBusinessHours`))

            if (!deliveryTiming && !takeawayTiming) {
                return {
                    titleText: ServiceableMsgConstants.SHOP_IS_CLOSED,
                    subComps: [ServiceableMsgConstants.PLEASE_TRY_AGAIN],
                    subCompType: 'text'
                }

            } else if (deliveryTiming && !takeawayTiming) {
                return {
                    titleText: ServiceableMsgConstants.SHOP_IS_CLOSED,
                    subComps: [deliveryTiming, ServiceableMsgConstants.TAKEAWAY_TRY_AFTER_SOMETIME],
                    subCompType: 'text'
                }
            } else if (!deliveryTiming && takeawayTiming) {
                return {
                    titleText: ServiceableMsgConstants.SHOP_IS_CLOSED,
                    subComps: [ServiceableMsgConstants.DELIVERY_TRY_AFTER_SOMETIME, takeawayTiming],
                    subCompType: 'text'
                }
            } else {
                return {
                    titleText: ServiceableMsgConstants.SHOP_IS_CLOSED,
                    subComps: [deliveryTiming, takeawayTiming],
                    subCompType: 'text'
                }
            }
        } else if (orderType === OrderType.DELIVERY && !checkOrdertypesAvailability(get(restaurant, 'order_types'), OrderType.DELIVERY)) {
            const deliveryTiming = get(restaurant, `order_types[${OrderType.DELIVERY}].enabled`) && getOpensAtText(get(restaurant, `order_types[${OrderType.DELIVERY}].label`), get(restaurant, `order_types[${OrderType.DELIVERY}].serviceability.nextServiceableBusinessHours`))
            if (deliveryTiming) {
                return {
                    titleText: deliveryTiming,
                    subComps: [ServiceableMsgConstants.TAKEAWAY_OPTION],
                    subCompType: 'button'
                }
            } else {
                return {
                    titleText: ServiceableMsgConstants.DELIVERY_NOT_AVAILABLE,
                    subComps: [ServiceableMsgConstants.TAKEAWAY_OPTION],
                    subCompType: 'button'
                }
            }
        } else if (orderType === OrderType.TAKEAWAY && !checkOrdertypesAvailability(get(restaurant, 'order_types'), OrderType.TAKEAWAY)) {
            const takeawayTiming = get(restaurant, `order_types[${OrderType.TAKEAWAY}].enabled`) && getOpensAtText(get(restaurant, `order_types[${OrderType.TAKEAWAY}].label`), get(restaurant, `order_types[${OrderType.TAKEAWAY}].serviceability.nextServiceableBusinessHours`))

            if (takeawayTiming) {
                return {
                    titleText: takeawayTiming,
                    subComps: [ServiceableMsgConstants.DELIVERY_OPTION],
                    subCompType: 'button'
                }
            } else {
                return {
                    titleText: ServiceableMsgConstants.TAKEAWAY_NOT_AVAILABLE,
                    subComps: [ServiceableMsgConstants.DELIVERY_OPTION],
                    subCompType: 'button'
                }
            }
        } else if (orderType === OrderType.DELIVERY && !!get(userLocationData, 'lat') && userLocationStatusForDelivery === 'error') {
            return {
                titleText: ServiceableMsgConstants.LOCATION_UNSERVICEABLE,
                subComps: [ServiceableMsgConstants.CHANGE_LOCATION, ServiceableMsgConstants.TAKEAWAY_OPTION],
                subCompType: 'button'
            }
        } else {
            return null
        }
    }

    const serviceableData = serviceableMessage()

    return [OrderType.DELIVERY, OrderType.TAKEAWAY].includes(orderType) && serviceableData && <ServiceabilityWrapper>
        <ServiceableInfo id='ordertype-serviceability-wrapper' backgroundImg={serviceableImg} restaurantColor={restaurantColor}>
            <div id='ordertype-serviceability-content'>
                <ServiceableHeading id='ordertype-status-heading'>
                    {get(serviceableData, `titleText`, "")}
                </ServiceableHeading>
                {
                    get(serviceableData, `subComps`, []).length > 0 && get(serviceableData, `subComps`, []).map((text, textIndex) => (
                        get(serviceableData, `subCompType`, '') == 'button' ? <TextButton
                            id={`ordertype-action-${textIndex+1}`}
                            handleClick={() => handleOrderTypeSelection(text)} key={text}>
                            {text}
                        </TextButton> : <ServiceableText id={`ordertype-status-${textIndex+1}`} key={text}>{text}</ServiceableText>
                    ))
                }
            </div>
        </ServiceableInfo>
    </ServiceabilityWrapper>
}
