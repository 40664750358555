import styled, { css } from 'styled-components'
import styleVals from '../../constants/styles'

export const AddOnTitle = styled.div`
    @media ${styleVals.breakpoints.smallMobile} {
        padding-left: ${styleVals.measurements.spacing8};
    }
`

export const VariantGroupContainer = styled.div`
        position: fixed;
        width: 100%;
        max-width: ${styleVals.measurements.spacing500};
        min-height: 100vh;
        background: rgba(0, 0, 0, 0.5);
        top: 0;
        z-index: 4;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        overflow: hidden;
        bottom: 0;
`
VariantGroupContainer.Div = styled.div`
    position: absolute;
    width: 100%;
    background: #f3f3f3;
    border-top-left-radius: ${styleVals.measurements.spacing20};
    border-top-right-radius: ${styleVals.measurements.spacing20};
    max-height: 90vh;
    height: 90vh;
    bottom: -80%;
    transition: 1s;
    text-align:${props => props.isLanguageArab && 'right'}
`


export const VariantGroupHeader = styled.div`
      padding: ${styleVals.measurements.spacing10} ${styleVals.measurements.spacing10};
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #9a9a9a;
      > div {
        display: flex;
        height: 100%;
        align-items: center;
        }
`

export const ProductTitle = styled.div`
          display: flex;
        align-items: center;
        font-weight: 600;
        font-size: ${styleVals.measurements.spacing17};
        width: 85%;
`

export const VariantSection = styled.div`
      padding-bottom: ${styleVals.measurements.spacing100};
      max-height: calc(90vh - 110px);
      overflow: auto;
      height: calc(90vh - 110px);
      background-color: #f3f3f3;
`

export const CloseButton = styled.div`
          width: ${styleVals.measurements.spacing30};
          height: ${styleVals.measurements.spacing30};
          font-size: ${styleVals.measurements.spacing21};
          font-weight: 600;
          border: 1px solid #000000;
          cursor: pointer;
          background-color: #ffffff;
          border-radius: ${styleVals.measurements.spacing15};
          text-align: center;
          line-height: 1.42857;
          position: absolute;
          top: -${styleVals.measurements.spacing18};
          ${props => props.isLanguageArab ? css` left: ${styleVals.measurements.spacing10};` : css` right: ${styleVals.measurements.spacing10};`}
         
`

export const VariantGroupFooter = styled.div`
      border-top: 1px solid #9a9a9a;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem;
      background: #fff;
      position: fixed;
      bottom: 0;
      width: 100%;
      max-width: ${styleVals.measurements.spacing500};
`

export const ProductCount = styled.div`
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #a2a2a2;
        height:  ${styleVals.measurements.spacing35};
        width:  ${styleVals.measurements.spacing90};
        max-height:  ${styleVals.measurements.spacing35};
        position: relative;
        border-radius: ${styleVals.measurements.spacing18};
    `;
export const CountValue = styled.div`
    padding: 0 0.5rem;
    height:  ${styleVals.measurements.spacing35};
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1;
    font-size:  ${styleVals.measurements.spacing13};
    font-weight: 600;
    `;

export const ProductIncreamentButton = styled.button`
          position: relative;
          cursor: pointer;
          background: no-repeat;
          border: transparent;
          display: flex;
          justify-content: center;
          align-items: center;
          height:  ${styleVals.measurements.spacing35};
          width:  ${styleVals.measurements.spacing35};
          font-weight: 400;
          position: absolute;
          padding: 0.5rem;
          font-size: ${styleVals.measurements.spacing18};
          line-height: ${styleVals.measurements.spacing20};

    left: ${(props) => props.isMinusButton && 0};
    right: ${(props) => !props.isMinusButton && 0};

    color: ${(props) => (!props.isMinusButton && props.restaurantColor) ? props.restaurantColor : `#bebfc5`};
`;

export const AddButton = styled.button`
        font-size:  ${styleVals.measurements.spacing15};
        color: white;
        background: ${props => props.restaurantColor ? props.restaurantColor : ' #ab0012'};
        border-radius: ${styleVals.measurements.spacing18};
        outline: none;
        cursor: pointer;
        display: block;
        border: none;
        padding: ${styleVals.measurements.spacing9} ${styleVals.measurements.spacing25};
        > span {
          font-size: ${styleVals.measurements.spacing13};
          margin-left: ${styleVals.measurements.spacing2};
        }
`;



