import styled, { css } from "styled-components";
import styleVals from "../../constants/styles";

const getCheckboxStyles = (props) => {
  return css`
    input ~ span::before {
      display: block;
      position: absolute;
      right:${props => props.isLanguageArab ? ' -15px' : '-6px'};
      top: -12px;
      width: ${styleVals.measurements.spacing24};
      height: ${styleVals.measurements.spacing24};
      content: "";
      border: 2px solid ${props.restaurantColor};
      background: ${props.bgColor || '#f3f3f3'};
      padding-left: ${styleVals.measurements.spacing16}
    }
    input:disabled ~ span::before {
      display: block;
      position: absolute;
      right:${props => props.isLanguageArab ? ' -15px' : '-6px'};
      top: -12px;
      width: ${styleVals.measurements.spacing24};
      height: ${styleVals.measurements.spacing24};
      content: "";
      border: 2px solid ${styleVals.colors.disabledGrey};
      background: ${props.bgColor || '#f3f3f3'};
      padding-left: ${styleVals.measurements.spacing16}
    }
    input:checked ~ span::before {
      display: block;
      position: absolute;
      right:${props => props.isLanguageArab ? ' -15px' : '-6px'};
      top: -12px;
      width: ${styleVals.measurements.spacing24};
      height: ${styleVals.measurements.spacing24};
      content: "";
      border: 2px solid ${props.restaurantColor};
      background: ${props.restaurantColor};
      padding-left: ${styleVals.measurements.spacing16}
    }
    input:checked ~ span::after {
      display: block;
      position: absolute;
      content: "";
      right:${props => props.isLanguageArab ? ' -6px' : '3px'};
      top: -9px;
      width: 6px;
      height: 15px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(43deg);
    }
  `
}

export const CheckboxLabel = styled.label`
  ${props => props.inline ? css`
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 0;
      div {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        padding-right: ${styleVals.measurements.spacing6};
        padding-left: ${styleVals.measurements.spacing16};
        @media ${styleVals.breakpoints.smallMobile} {
          padding-left: ${styleVals.measurements.spacing14};
        }
        span {
          position: relative;
        }
        ${props => getCheckboxStyles(props)}
      }
      p {
        padding: 0;
        margin: 0;
        display: inline-block;
        color: ${props.isDisabled && styleVals.colors.greyText};
      }
    ` : css`
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 0;
      padding: 0;
      width: 100%;
      ${props => props.isLanguageArab ? css`
        padding: ${styleVals.measurements.spacing10} ${styleVals.measurements.spacing20};
        padding-right: ${styleVals.measurements.spacing26};
        @media ${styleVals.breakpoints.smallMobile} {
          padding: ${styleVals.measurements.spacing10} ${styleVals.measurements.spacing8};
          padding-right: ${styleVals.measurements.spacing16};
        }
      ` : css`
        padding: ${styleVals.measurements.spacing10} ${styleVals.measurements.spacing20};
        padding-left: ${styleVals.measurements.spacing26};
        @media ${styleVals.breakpoints.smallMobile} {
          padding: ${styleVals.measurements.spacing10} ${styleVals.measurements.spacing8};
          padding-left: ${styleVals.measurements.spacing16};
        }
      `}
      ${!props.noBorder && css`border-bottom: 1px solid #CDCDCD;`}
      div {
        display: flex;
        flex-direction: row;
        max-width: ${(props.additionalLabel ? "75%" : "100%")};
        @media ${styleVals.breakpoints.smallMobile} {
          max-width: ${(props.additionalLabel ? "65%" : "100%")};
        }
        div {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          span {
            position: relative;
          }
          ${props => getCheckboxStyles(props)}
        }
        p {
          padding: 0;
          margin: 0;
          display: inline-block;
          ${props => props.isLanguageArab ? css`
            padding-right: ${styleVals.measurements.spacing16};
            @media ${styleVals.breakpoints.smallMobile} {
              padding-right: ${styleVals.measurements.spacing14};
            }
          ` : css`
            padding-left: ${styleVals.measurements.spacing16};
            @media ${styleVals.breakpoints.smallMobile} {
              padding-left: ${styleVals.measurements.spacing14};
            }
          `}
        }
      }
      p {
        margin: 0;
        padding: 0;
        color: ${props.isDisabled && styleVals.colors.greyText};
      }
    `
  }
`;

