import { get, set, range } from '../../../utils/common';
import React, { useState, useEffect } from "react"
import Accordion from "../../Accordion"
import Checkbox from "../../Checkbox"
import RadioButton from "../../RadioButton"
import SlideUpPopup from '../../SlideUpPopupModal';
import i18n from "../../../translations/i18n"
import { CountContainer, CountPlus } from "../../../pages/ProductV2/styles/Product.style"
import ItemCard, { AddItemButton } from "../ItemCard"
import {
    FoodType,
    FoodTypeIcon,
    ComboDetailsWrapper,
    BasePriceWrapper,
    SectionHeading,
    SelectionLabel,
    SubSection,
    QtyText,
    AccordionSection,
    LabelWithFoodType
} from "./styles";
import { useRestaurantsInfoContext } from '../../../contexts/restaurantInfo';

const ComboCardConstants = Object.freeze({
    SIMPLE_COMBO: 'SIMPLE_COMBO',
    CUSTOMIZED_COMBO: 'CUSTOMIZED_COMBO',
    PAY_X_GET_Y: 'PAY_X_GET_Y',
    BUY_X_GET_Y: 'BUY_X_GET_Y',
    addonsAtleastUptoText: (minimum, maximum, selected) => i18n.t('addonsAtleastUptoText', { minimum, maximum, selected , s: minimum > 1 ? 's' : '' }),
    addonsAtleastText: (minimum, selected) => i18n.t('addonsAtleastText', { minimum, selected, s: minimum > 1 ? 's' : '' }),
    addonsUptoText: (maximum, selected) => i18n.t('addonsUptoText', { maximum, selected, s: maximum > 1 ? 's' : '' }),
    addonsExactText: (qty, selected) => i18n.t('addonsExactText', { qty, selected, s: qty > 1 ? 's' : '' }),
    SELECT_MAIN_ITEMS: i18n.t('SELECT_MAIN_ITEMS'),
    SELECT_FREE_ITEMS: i18n.t('SELECT_FREE_ITEMS'),
    
    itemsUptoText:(maximum, selected) => i18n.t('itemsUptoText', { maximum, selected, s: maximum > 1 ? 's' : '' }),
    itemsAtleastText:(minimum, selected) => i18n.t('itemsAtleastText', { minimum, selected, s: minimum > 1 ? 's' : '' }),
    itemsAtleastUptoText: (minimum, maximum, selected) => i18n.t('itemsAtleastUptoText', { minimum, maximum, selected, s: minimum > 1 ? 's' : '' }),
    itemsExactText:(qty, selected) => i18n.t('itemsExactText', { qty, selected, s: qty > 1 ? 's' : '' }),
    selectItemText:(qty) => i18n.t('selectItemText', { qty , s: qty > 1 ? 's' : ''}),
    selectItemComboText:(itemsNeeded, itemsSelected) => i18n.t('selectItemComboText', { itemsNeeded , itemsSelected}),
})

const getAddonsSelected = (groupItems) => Object.values(groupItems).reduce((qty, currentItem) => {
    return get(currentItem, 'checked') ? qty + 1 : qty
}, 0)

const getFirstInStockItem = (itemKeys, itemObj) => {
    const foundItem = itemKeys.find(itemKey => !get(itemObj, `[${itemKey}].outOfStock`))
    return foundItem
}

const focusOnField = (id, sectionIndex, customAction) => {
    const field = document.getElementById(id)
    if (field) {
        const divToBeScrolled = document.getElementById(`combo-items-list-${sectionIndex}`)
        if (divToBeScrolled) {
            const scrollTopCalc = field.offsetTop - 60
            divToBeScrolled.scrollTop = scrollTopCalc < 0 ? 0 : scrollTopCalc
        }
        setTimeout(() => {
            customAction(id)
        }, 100)
    }
}

export const roundOffToTwoPlaces = number => String(number).split('.')[1] ? number.toFixed(2) : number

export const sortOneArrayAlongWithAnother = (arrC1, arrC2) => {
    const arr1 = [...arrC1]
    const arr2 = [...arrC2]
    for (let i = 0; i < arr1.length; i++) {
        for (let j = 0; j < (arr1.length - i - 1); j++) {
            if (arr1[j] < arr1[j + 1]) {
                const temp1 = arr1[j]
                arr1[j] = arr1[j + 1]
                arr1[j + 1] = temp1
                const temp2 = arr2[j]
                arr2[j] = arr2[j + 1]
                arr2[j + 1] = temp2
            }
        }
    }
    return {
        arr1, arr2
    }
}

const getItemLabelWithFoodType = (title, foodType, isRestaurantIndian, sectionIndex, itemId, isLanguageArab) => {
    return <LabelWithFoodType>
        <FoodType id={`section-${sectionIndex}-${itemId}-food-type`} inlineDiv type={foodType} noShrink isLanguageArab={isLanguageArab}>
            <FoodTypeIcon type={foodType} isRestaurantIndian={isRestaurantIndian} />
        </FoodType>
        <span id={`section-${sectionIndex}-${itemId}-item`}>{title}</span>
    </LabelWithFoodType>
}

const checkAllAddonGroupsHaveAddons = (groupsObj) => {
    const allAddonGroups = Object.values(groupsObj)
    if (allAddonGroups.length > 0) {
        for (let addonGroupIndex = 0; addonGroupIndex < allAddonGroups.length; addonGroupIndex++) {
            const lengthOfAddonsInside = Object.values(get(allAddonGroups, `[${addonGroupIndex}].items`, {})).length
            if (lengthOfAddonsInside > 0) {
                continue
            } else {
                return false
            }
        }
        return true
    }
    return false
}

export const ComboCard = ({
    key,
    title,
    description,
    price,
    itemImage,
    foodType,
    onClick,
    comboInCart,
    couponsNotApplicable,
    comboItemCount,
    comboVariations,
    comboRefId,
    cart,
    isRestaurantIndian,
    isRestaurantColorDark,
    isComboCustomizable,
    showAddItemButton
}) => {

    if (comboInCart && comboItemCount === 0) {
        cart.forEach(function (product) {
            if (product.ref_id === comboRefId) {
                comboItemCount += product.cartcount;
                comboVariations++;
            }
        })
    }

    return <ItemCard
        key={key}
        itemImage={itemImage}
        title={title}
        description={description}
        foodType={foodType}
        price={price}
        isRestaurantIndian={isRestaurantIndian}
        isCustomizable={isComboCustomizable}
        itemQuantity={comboItemCount}
        couponsNotApplicable={couponsNotApplicable}
        handleAddItemClick={(action) => onClick(action)}
        showAddItemButton={showAddItemButton}
        isRestaurantColorDark={isRestaurantColorDark}
    />
}

const CustomizableCombosPopup = ({ closePopup, selectedCombo, addCombo, restaurantColor, restaurantCurrency, products, comboInCart, shouldFilterProductFromCart, isRestaurantIndian }) => {
    const { isLanguageArab } = useRestaurantsInfoContext();
    const totalSections = get(selectedCombo, 'sections', []).length - 1

    const getPriceWithCurrency = price => <>{restaurantCurrency || ''}{roundOffToTwoPlaces(price)}</>

    const itemMapper = (itemId, type, sectionIndex) => {
        const itemDetails = get(products, `${type}[${itemId}]`)
        let comboItemTypeInCart = get(comboInCart, `sections[${sectionIndex}].${type}`, []);
        const getQuantity = (itemId) => {
            let quantity = 0;
            comboItemTypeInCart.forEach((item) => {
                if (item.ref_id === itemId) {
                    quantity = item.quantity;
                }
            })
            return quantity;
        }
        const getChecked = (itemId) => {
            let checked = false;
            comboItemTypeInCart.forEach((item) => {
                if (item.ref_id === itemId) {
                    checked = true;
                }
            })
            return checked;
        }
        const mappedObj = () => ({
            [itemId]: {
                itemId,
                title: get(itemDetails, 'title'),
                ...({
                    'items': {
                        quantity: getQuantity(itemId),
                        outOfStock: !get(itemDetails, 'in_stock'),
                        price: get(itemDetails, 'price', 0),
                        foodType: get(itemDetails, 'food_type')
                    },
                    'variants': {
                        price: get(itemDetails, 'price'),
                        outOfStock: !get(itemDetails, 'in_stock')
                    },
                    'addons': {
                        checked: getChecked(itemId),
                        price: get(itemDetails, 'price'),
                        outOfStock: !get(itemDetails, 'in_stock')
                    }
                })[type]
            }
        })
        return mappedObj()
    }

    const mappedItems = (items, itemType, sectionIndex) => items.reduce((itemsObj, itemId) => ({ ...itemsObj, ...itemMapper(itemId, itemType, sectionIndex) }), {})

    const getItemsForSection = (section, groupField, itemType, getDefaultItem, sectionIndex) => {
        if (itemType === 'items') {
            return mappedItems(get(section, itemType, []), itemType, sectionIndex)
        } else {
            const groups = get(section, groupField, [])
            const itemsForGroups = (groups).reduce((itemObj, groupId) => {
                const itemsForEachGroup = get(products, `${groupField}[${groupId}].${itemType}`, [])
                const groupTitle = get(products, `${groupField}[${groupId}].title`, '')
                const addOnConstraints = itemType === 'addons' ? {
                    minimumNeeded: get(products, `${groupField}[${groupId}].minimum_needed`, 0),
                    maximumAllowed: get(products, `${groupField}[${groupId}].maximum_allowed`, -1),
                } : {}
                return {
                    ...itemObj, [groupId]: {
                        groupId,
                        title: groupTitle,
                        items: mappedItems(itemsForEachGroup, itemType, sectionIndex),
                        ...addOnConstraints
                    }
                }
            }, {})

            return getDefaultItem ? Object.keys(itemsForGroups).reduce((groupObj, groupId) => {
                const itemObj = get(itemsForGroups, `[${groupId}].items`, {})
                return { ...groupObj, [groupId]: getFirstInStockItem(Object.keys(itemObj), itemObj) }
            }, {}) : itemsForGroups
        }
    }

    const [activeComboSection, setActiveComboSection] = useState(0)

    const [selectedComboDetails, setSelectedComboDetails] = useState({
        ...selectedCombo,
        sections: get(selectedCombo, 'sections', []).map((section, index) => {
            const allItemsForSection = getItemsForSection(section, '', 'items', false, index)
            const allVariantsForSection = getItemsForSection(section, 'variant_groups', 'variants', false, index)
            const allAddonsForSection = getItemsForSection(section, 'add_on_groups', 'addons', false, index)
            return {
                sectionTitle: section.title,
                items: allItemsForSection,
                variant_groups: allVariantsForSection,
                add_on_groups: allAddonsForSection,
                itemsNeeded: get(section, 'items_needed', 0),
                priceToBeAdded: get(section, 'priceToBeAdded', false),
                addItemOnce: get(section, 'addItemOnce', false),
                maxItems: get(section, 'max_items', 0),
                minItems: get(section, 'min_items', 0)
            }
        })
    })

    const generateVariantsInitialStateFromCart = () => get(comboInCart, 'sections', []).map(section => get(section, 'variants', []).reduce((selectedVariantsObj, variant) => {
        return {
            ...selectedVariantsObj,
            [get(variant, 'variant_group')]: get(variant, 'ref_id')
        }
    }, {}))

    const [selectedVariants, setSelectedVariants] = useState(
        comboInCart ? generateVariantsInitialStateFromCart() : get(selectedCombo, 'sections', []).map((section, index) => getItemsForSection(section, 'variant_groups', 'variants', true, index))
    )

    const getItemsQty = (allItemsObj) => Object.values(allItemsObj).reduce((count, item) => {
        return count + item.quantity
    }, 0)

    const getTotalPrice = () => {
        const basePrice = get(selectedComboDetails, 'price', 0)
        const splitSections = get(selectedComboDetails, 'sections', []).slice(0, activeComboSection + 1)
        const totalItemsPrice = splitSections.reduce((totalPrice, section) => {
            if (get(section, 'priceToBeAdded', false)) {
                const itemsPriceInSection = Object.values(get(section, 'items', []))
                    .filter(item => get(item, 'quantity', 0) > 0)
                    .reduce((totalPriceInSection, item) => {
                        return totalPriceInSection + (get(item, 'price', 0) * get(item, 'quantity', 0))
                    }, 0)
                return itemsPriceInSection + totalPrice
            } else {
                return totalPrice
            }
        }, 0)
        const totalCstPrice = splitSections.reduce((totalPriceCalc, currentSection, key) => {
            const allAddonsSelectedPrice = Object.values(get(currentSection, 'add_on_groups', {})).reduce((addonPriceForSection, addonGroup) => {
                addonPriceForSection = addonPriceForSection + Object.values(get(addonGroup, 'items')).reduce((totalAddonPrice, addon) => {
                    if (get(addon, 'checked')) {
                        return totalAddonPrice + get(addon, 'price', 0)
                    } else {
                        return totalAddonPrice
                    }
                }, 0)
                return addonPriceForSection
            }, 0)
            const variantsSelectedPrice = Object.values(get(currentSection, 'variant_groups', {})).reduce((variantPriceForSection, variantGroup) => {
                const variantSelectedFromGroup = get(selectedVariants, `[${key}][${get(variantGroup, 'groupId', '')}]`)
                const variantFoundForVariantGroup = Object.values(get(variantGroup, 'items', {})).find(variant => variantSelectedFromGroup === get(variant, 'itemId'))
                const selectedVariantPrice = get(variantFoundForVariantGroup, 'price', 0)
                return (variantPriceForSection + selectedVariantPrice)
            }, 0)
            return totalPriceCalc + allAddonsSelectedPrice + variantsSelectedPrice
        }, 0)
        const totalPriceCalc = basePrice + totalItemsPrice + totalCstPrice
        return totalPriceCalc
    }

    const [priceCalculated, setPriceCalculated] = useState(getTotalPrice())

    useEffect(() => {
        setPriceCalculated(getTotalPrice())
    }, [selectedComboDetails, selectedVariants, activeComboSection])

    const [shakeText, setShakeText] = useState({
        shake: false,
        colored: false
    })

    useEffect(() => {
        if (get(shakeText, 'shake')) {
            setTimeout(() => {
                setShakeText({
                    ...shakeText,
                    shake: false
                })
            }, 200)
        }
    }, [shakeText])

    const shakeQtyText = (id) => {
        if (id) {
            setShakeText({
                shake: true,
                colored: true,
                id
            })
        } else {
            setShakeText({
                shake: false,
                colored: false,
                id: ''
            })
        }
    }

    const handleItemQty = (action, itemId, sectionIndex, itemsQuantity, itemsNeeded, maxItems, minItems) => {
        const modifyQty = () => {
            shakeQtyText()
            const updatedSelectedComboSection = [...get(selectedComboDetails, `sections`, [])]
            const qtyPath = `[${sectionIndex}].items[${itemId}].quantity`
            let updatedQuantity = get(updatedSelectedComboSection, qtyPath)
            updatedQuantity += action === '+' ? +1 : updatedQuantity > 0 ? -1 : 0
            set(updatedSelectedComboSection, qtyPath, updatedQuantity)
            setSelectedComboDetails({
                ...selectedComboDetails,
                sections: updatedSelectedComboSection
            })
        }
        if (action === '+') {
            if (itemsNeeded) {
                if (itemsQuantity === itemsNeeded) {
                    focusOnField(`item-qty-text-${sectionIndex}`, sectionIndex, shakeQtyText)
                } else {
                    modifyQty()
                }
            } else {
                if (maxItems && minItems) {
                    if (maxItems === minItems) {
                        if (itemsQuantity === minItems) {
                            focusOnField(`item-qty-text-${sectionIndex}`, sectionIndex, shakeQtyText)
                        } else {
                            modifyQty()
                        }
                    } else {
                        if (itemsQuantity === maxItems) {
                            focusOnField(`item-qty-text-${sectionIndex}`, sectionIndex, shakeQtyText)
                        } else {
                            modifyQty()
                        }
                    }
                } else if (minItems) {
                    modifyQty()
                } else if (maxItems) {
                    if (itemsQuantity === maxItems) {
                        focusOnField(`item-qty-text-${sectionIndex}`, sectionIndex, shakeQtyText)
                    } else {
                        modifyQty()
                    }
                } else {
                    modifyQty()
                }
            }
        } else if (itemId) {
            modifyQty()
        }
    }

    const getItems = (section, sectionIndex) => {
        const getLimiterQtyText = (minItems, maxItems) => {
            if (minItems && maxItems) {
                if (minItems === maxItems) {
                    return ComboCardConstants.itemsExactText(minItems, itemsQuantity);
                }
                return  ComboCardConstants.itemsAtleastUptoText(minItems, maxItems, itemsQuantity);
            } else if (minItems) {
                return ComboCardConstants.itemsAtleastText(minItems, itemsQuantity);
            } else if (maxItems) {
                return ComboCardConstants.itemsUptoText(maxItems, itemsQuantity);
            }
        }
        const addItemOnce = get(section, 'addItemOnce', false);
        const priceToBeAdded = get(section, 'priceToBeAdded', false);
        const allItemsObj = get(section, 'items', {});
        const itemsQuantity = getItemsQty(allItemsObj);
        const itemsNeeded = get(section, 'itemsNeeded', 0);
        const maxItems = get(section, 'maxItems', 0);
        const minItems = get(section, 'minItems', 0);

        const qtyText = itemsNeeded
            ? ComboCardConstants.itemsExactText(itemsNeeded, itemsQuantity)
            : (maxItems || minItems)
                ? getLimiterQtyText(minItems, maxItems)
                : ComboCardConstants.selectItemText(itemsQuantity)

        const getAddButton = (item) => {
            const itemDisabled = get(item, 'outOfStock')
            return addItemOnce ? <Checkbox
                id={`${get(item, 'itemId')}-item-section-${sectionIndex}`}
                key={`${get(item, 'itemId')}-item-section-${sectionIndex}`}
                label={get(item, 'outOfStock') ? i18n.t('OUT_OF_STOCK_MESSAGE') : ''}
                checked={get(item, 'quantity') === 1}
                disabled={get(item, 'outOfStock')}
                dontShowCheckbox={get(item, 'outOfStock')}
                onChange={() => handleItemQty(get(item, 'quantity', 0) === 0 ? '+' : '-', item.itemId, sectionIndex, itemsQuantity, itemsNeeded, maxItems, minItems)}
                inline
            /> : <CountContainer withLabelBefore={priceToBeAdded} adjustPriceLabel={get(item, 'quantity') > 0}>
                {priceToBeAdded && <span id='item-price'>{getPriceWithCurrency(get(item, 'price', 0))}</span>}
                    {get(item, 'quantity') > 0 ?
                        <AddItemButton
                            disabledPlus={((priceToBeAdded || addItemOnce) ? item.quantity === 1 : false)}
                            disabledMinus={item.quantity === 0}
                            itemQuantity={item.quantity}
                            handleAddItemClick={(action) => handleItemQty(action, item.itemId, sectionIndex, itemsQuantity, itemsNeeded)}
                            buttonSize='sm'
                            title={`${get(item, 'itemId')}-item-section-${sectionIndex}`}
                            popupButtons
                            invertColors
                        />
                        : itemDisabled ? <p id='item-status'>{i18n.t('OUT_OF_STOCK_MESSAGE')}</p> : <CountPlus onlyButton
                    id={`${get(item, 'itemId')}-item-section-${sectionIndex}`}
                    onClick={() => handleItemQty('+', item.itemId, sectionIndex, itemsQuantity, itemsNeeded, maxItems, minItems)}
                    restaurantColor={restaurantColor}
                    biggerFont
                >
                    <i className='fa fa-plus' />
                </CountPlus>}
            </CountContainer>
        }
        return Object.values(allItemsObj).length > 0 && <SubSection firstChild>
            <SectionHeading subheading withSubtext>
                <span id={`section-${sectionIndex}-items-heading`}>{i18n.t('CHOOSE_ITEMS')}</span>
                <QtyText shakeText={shakeText} id={`item-qty-text-${sectionIndex}`}>{qtyText}</QtyText>
            </SectionHeading>
            {Object.values(allItemsObj).map(item => {
                return <SelectionLabel itemLabel restaurantColor={restaurantColor} key={item.itemId}>
                    {getItemLabelWithFoodType(get(item, 'title'), get(item, 'foodType'), isRestaurantIndian, sectionIndex, get(item, 'itemId'), isLanguageArab)}
                    {getAddButton(item)}
                </SelectionLabel>
            })}
        </SubSection>
    }

    const handleVariantSelection = (itemId, groupId) => {
        const updatedSelectedVariants = [...selectedVariants]
        updatedSelectedVariants[activeComboSection][groupId] = itemId
        setSelectedVariants(updatedSelectedVariants)
    }

    const getVariants = (section, sectionIndex) => {
        const groupsObj = get(section, 'variant_groups', {})
        return Object.values(groupsObj).length > 0 && <SubSection>
            <SectionHeading id={`section-${sectionIndex}-variants-heading`} subheading>{i18n.t('CHOOSE_VARIANTS')}</SectionHeading>
            {Object.values(groupsObj).map((group, groupKey) => {
                const groupTitle = get(group, 'title', '')
                const groupItems = get(group, 'items', {})
                const groupId = get(group, 'groupId', '')
                return <SubSection groupSection firstChild={groupKey === 0} key={`${groupKey}`}>
                    <SectionHeading id={`section-${sectionIndex}-${groupId}-variants-heading`} restaurantColor={restaurantColor} subheading>{groupTitle}</SectionHeading>
                    {Object.values(groupItems).map(item => {
                        return <RadioButton
                            id={`${get(item, 'itemId')}-${groupId}-variant-section-${sectionIndex}`}
                            key={`${get(item, 'itemId')}-variant-section-${sectionIndex}`}
                            name={`${groupId}-variants-selection-section-${sectionIndex}`}
                            variant={'itemSelection'}
                            label={get(item, 'title')}
                            additionalLabel={get(item, 'outOfStock') ? i18n.t('OUT_OF_STOCK_MESSAGE') : getPriceWithCurrency(get(item, 'price', 0))}
                            value={get(item, 'title')}
                            checked={get(item, 'itemId') === get(selectedVariants, `[${sectionIndex}][${groupId}]`)}
                            onChange={() => handleVariantSelection(get(item, 'itemId'), groupId)}
                            disabled={get(item, 'outOfStock')}
                        />
                    })}
                </SubSection>

            })}
        </SubSection>
    }

    const getAddonsSelected = (groupItems) => Object.values(groupItems).reduce((qty, currentItem) => {
        return get(currentItem, 'checked') ? qty + 1 : qty
    }, 0)

    const validateAddonsSelection = (maximumAddons, addonSelectedQty) => {
        if (maximumAddons === -1) {
            return true
        } else {
            if (addonSelectedQty === maximumAddons) {
                return false
            }
            return true
        }
    }

    const handleAddonSelection = (itemId, sectionIndex, groupId, maximumAddons, addonSelectedQty) => {

        const updatedSelectedComboSection = [...get(selectedComboDetails, `sections`, [])]
        const checkedPath = `[${sectionIndex}].add_on_groups[${groupId}].items[${itemId}].checked`
        const checkedState = get(updatedSelectedComboSection, checkedPath)
        if (checkedState) {
            set(updatedSelectedComboSection, checkedPath, !checkedState)
            setSelectedComboDetails({
                ...selectedComboDetails,
                sections: updatedSelectedComboSection
            })
            shakeQtyText()
        } else {
            if (validateAddonsSelection(maximumAddons, addonSelectedQty)) {
                set(updatedSelectedComboSection, checkedPath, !checkedState)
                setSelectedComboDetails({
                    ...selectedComboDetails,
                    sections: updatedSelectedComboSection
                })
                shakeQtyText()
            } else {
                focusOnField(`${groupId}-addon-qty-text-${activeComboSection}`, activeComboSection, shakeQtyText)
            }
        }

    }

    const getAddons = (section, sectionIndex) => {

        const groupsObj = get(section, 'add_on_groups', {})
        return checkAllAddonGroupsHaveAddons(groupsObj) && <SubSection>
            <SectionHeading id={`section-${sectionIndex}-addons-heading`} subheading>{i18n.t('CHOOSE_ADDONS')}</SectionHeading>
            {Object.values(groupsObj).map((group, groupKey) => {
                const groupItems = get(group, 'items', {})
                if (Object.keys(groupItems).length > 0) {
                    const groupTitle = get(group, 'title', '')
                    const groupId = get(group, 'groupId', '')
                    const minimumAddons = get(group, 'minimumNeeded', 0)
                    const maximumAddons = get(group, 'maximumAllowed', -1)
                    const noMinimumMaximum = minimumAddons === 0 && maximumAddons === -1
                    const addonSelectedQty = getAddonsSelected(groupItems)
                    const qtyText = () => {
                        if (minimumAddons > 0 && maximumAddons !== -1) {
                            if (minimumAddons === maximumAddons) {
                                return ComboCardConstants.addonsExactText(minimumAddons, addonSelectedQty)
                            }
                            return ComboCardConstants.addonsAtleastUptoText(minimumAddons, maximumAddons, addonSelectedQty)
                        } else if (minimumAddons > 0 && maximumAddons === -1) {
                            return ComboCardConstants.addonsAtleastText(minimumAddons, addonSelectedQty)
                        } else if (minimumAddons === 0 && maximumAddons !== -1) {
                            return ComboCardConstants.addonsUptoText(maximumAddons, addonSelectedQty)
                        }
                    }
                    return <SubSection groupSection firstChild={groupKey === 0}>
                        <SectionHeading id={`section-${sectionIndex}-${groupId}-addons-heading`} restaurantColor={restaurantColor} subheading withSubtext={!noMinimumMaximum}>
                            {noMinimumMaximum
                                ? groupTitle
                                : (<React.Fragment>
                                    <span>{groupTitle}</span>
                                    <QtyText shakeText={shakeText} id={`${groupId}-addon-qty-text-${sectionIndex}`}>{qtyText()}</QtyText>
                                </React.Fragment>)}
                        </SectionHeading>
                        {Object.values(groupItems).map(item => {
                            return <Checkbox
                                id={`${get(item, 'itemId')}-${groupId}-addon-section-${sectionIndex}`}
                                key={`${get(item, 'itemId')}-addon-section-${sectionIndex}`}
                                label={get(item, 'title')}
                                additionalLabel={get(item, 'outOfStock') ? i18n.t('OUT_OF_STOCK_MESSAGE') : getPriceWithCurrency(get(item, 'price', 0))}
                                value={get(item, 'title')}
                                checked={item.checked ? item.checked : get(item, 'checked')}
                                disabled={get(item, 'outOfStock')}
                                onChange={() => handleAddonSelection(get(item, 'itemId'), sectionIndex, groupId, maximumAddons, addonSelectedQty)}
                            />
                        })}
                    </SubSection>
                }
            })}
        </SubSection>
    }

    const validateSectionInputs = (section) => {

        const validateAddons = () => {
            const addonsGroupsObj = get(section, 'add_on_groups', {})
            let areReqdAddonsSelected = true
            const allAddonGroupsKeys = Object.keys(addonsGroupsObj)
            if (allAddonGroupsKeys.length > 0) {
                for (let i = 0; i < allAddonGroupsKeys.length; i++) {
                    const group = get(addonsGroupsObj, `${allAddonGroupsKeys[i]}`, {})
                    const groupItems = get(group, 'items', {})
                    const minimumAddons = get(group, `minimumNeeded`, 0)
                    if (minimumAddons === 0) {
                        continue
                    } else {
                        const addonSelectedQty = getAddonsSelected(groupItems)
                        if (addonSelectedQty < minimumAddons) {
                            areReqdAddonsSelected = false
                            focusOnField(`${allAddonGroupsKeys[i]}-addon-qty-text-${activeComboSection}`, activeComboSection, shakeQtyText)
                            break
                        } else {
                            continue
                        }
                    }
                }
            }
            return areReqdAddonsSelected
        }

        const allItemsObj = get(section, `items`, {})
        const itemsQuantity = getItemsQty(allItemsObj)
        const itemsNeeded = get(section, 'itemsNeeded', 0)
        const minItems = get(section, 'minItems', 0)
        if (itemsNeeded) {
            if (itemsQuantity < itemsNeeded) {
                focusOnField(`item-qty-text-${activeComboSection}`, activeComboSection, shakeQtyText)
                return false
            } else {
                return validateAddons()
            }
        } else if (minItems) {
            if (itemsQuantity < minItems) {
                focusOnField(`item-qty-text-${activeComboSection}`, activeComboSection, shakeQtyText)
                return false
            } else {
                return validateAddons()
            }
        } else {
            return validateAddons()
        }
    }

    const handleSectionNavigation = (action, section) => {
        if (action === '+') {
            if (validateSectionInputs(section)) {
                shakeQtyText()
                setActiveComboSection(activeComboSection + 1)
            }
        } else {
            setActiveComboSection(activeComboSection - 1)
        }
    }

    const generatePayload = () => {
        return {
            title: get(selectedComboDetails, 'title', ''),
            comboDescription: get(selectedComboDetails, 'description', ''),
            bill_components: get(selectedComboDetails, 'bill_components'),
            ref_id: get(selectedComboDetails, 'code', ''),
            discount_mode: get(selectedComboDetails, 'discount_mode', false),
            code: get(selectedComboDetails, 'code', ''),
            discount_mode: get(selectedComboDetails, 'discount_mode', false),
            quantity: 1,
            food_type: get(selectedComboDetails, 'food_type', ''),
            price: priceCalculated,
            sections: get(selectedComboDetails, 'sections', []).map((section, sectionIndex) => ({
                items: Object.values(get(section, 'items', {})).filter(item => get(item, 'quantity') > 0).map(item => ({
                    ref_id: get(item, 'itemId'),
                    quantity: get(item, 'quantity')
                })),
                variants: Object.keys(selectedVariants[sectionIndex]).map((variantGroup) => ({
                    ref_id: get(selectedVariants[sectionIndex], `[${variantGroup}]`),
                    variant_group: variantGroup
                })),
                addons: Object.values(get(section, 'add_on_groups', {})).reduce((allAddons, addonGroup) => {
                    const addonsSelected = Object.values(get(addonGroup, 'items', {})).filter(addon => get(addon, 'checked')).map(addon => ({
                        ref_id: get(addon, 'itemId'),
                        add_on_group: get(addonGroup, 'groupId')
                    }))
                    return [...allAddons, ...addonsSelected]
                }, [])
            }))
        }
    }

    const handleComboAdd = () => {
        const sectionsLimit = get(selectedComboDetails, 'sections', []).length - 1
        const finalSection = get(selectedComboDetails, `sections[${sectionsLimit}]`, {})
        if (validateSectionInputs(finalSection)) {
            shakeQtyText()
            const payload = generatePayload()
            addCombo(payload)
        }
    }

    const getCombosSection = (section, sectionIndex) => <ComboDetailsWrapper id={`combo-items-list-${sectionIndex}`} isLanguageArab={isLanguageArab}>
        <BasePriceWrapper id='customized-combo-base-price'>
            <span>{i18n.t('BASE_PRICE')}</span>
            <span id='customized-combo-base-price-value'>{getPriceWithCurrency(get(selectedCombo, 'price', 0))}</span>
        </BasePriceWrapper>
        <SectionHeading id={`customized-combo-section-title-${sectionIndex}`} restaurantColor={restaurantColor}>{get(section, 'sectionTitle')}</SectionHeading>
        {getItems(section, sectionIndex)}
        {getVariants(section, sectionIndex)}
        {getAddons(section, sectionIndex)}
    </ComboDetailsWrapper>

    const getCombosSectionButtons = (section, sectionIndex, shouldFilterProductFromCart) => <React.Fragment>
        {sectionIndex !== 0 &&
            <AddItemButton
            buttonLabel={i18n.t('BACK_BUTTON')}
                handleAddItemClick={() => handleSectionNavigation('-')}
                title={`section-${sectionIndex}-back`}
                popupButtons
                invertColors
            />}
        <AddItemButton
            buttonLabel={<>{sectionIndex === totalSections ? shouldFilterProductFromCart.current ? i18n.t('UPDATE_BUTTON') : i18n.t('ADD_BUTTON') : i18n.t('NEXT_BUTTON')}&nbsp;-&nbsp;{getPriceWithCurrency(priceCalculated)}</>}
            handleAddItemClick={() => { sectionIndex === totalSections ? handleComboAdd() : handleSectionNavigation('+', section) }}
            buttonSize={sectionIndex === totalSections && shouldFilterProductFromCart.current ? 'xl' : 'lg'}
            title={`section-${sectionIndex}-next`}
            popupButtons
            invertColors
        />
    </React.Fragment>

    const getSections = (getButtons, shouldFilterProductFromCart) => {
        return get(selectedComboDetails, 'sections', []).map((section, sectionIndex) => getButtons ? getCombosSectionButtons(section, sectionIndex, shouldFilterProductFromCart) : getCombosSection(section, sectionIndex, shouldFilterProductFromCart))
    }

    const comboPopupProps = {
        closePopup,
        heading: get(selectedComboDetails, 'title'),
        hasMultipleSections: true,
        activeSectionIndex: activeComboSection,
        sections: getSections(),
        sectionButtons: getSections('withButtons', shouldFilterProductFromCart)
    }

    return <SlideUpPopup {...comboPopupProps} />
}

export const PayXGetYCombosPopup = ({ closePopup, selectedCombo, addCombo, restaurantColor, restaurantCurrency, products, comboInCart, shouldFilterProductFromCart, isRestaurantIndian }) => {
    const { isLanguageArab } = useRestaurantsInfoContext();

    const totalSections = get(selectedCombo, 'sections', []).length - 1

    const addAddonPrice = get(selectedCombo, 'addAddonPrice', false)

    const getPriceWithCurrency = price => <>{restaurantCurrency || ''}{roundOffToTwoPlaces(price)}</>

    const generateItemIndex = (sectionIndex, itemId) => {
        const itemsInSectionFromCart = get(comboInCart, `sections[${sectionIndex}].items`, [])
        const itemIndexFromCart = itemsInSectionFromCart.findIndex(item => get(item, 'ref_id') === itemId)
        return itemIndexFromCart;
    }

    const itemMapper = (itemId, type, sectionIndex, mainItemId, groupId) => {
        const itemDetails = get(products, `${type}[${itemId}]`);
        const itemIndex = generateItemIndex(sectionIndex, mainItemId);
        let comboItemTypeInCart = itemIndex !== -1 ? get(comboInCart, `sections[${sectionIndex}].items[${itemIndex}].${type}`, []) : null;

        const getChecked = (itemId) => {
            if (comboItemTypeInCart) {
                for (let i = 0; i < comboItemTypeInCart.length; i++) {
                    const item = comboItemTypeInCart[i];
                    if (get(item, 'ref_id', '') === itemId && get(item, 'add_on_group', '') === groupId) {
                        return true;
                    }
                }
                return false;
            }
            return false;
        }
        const mappedObj = () => ({
            [itemId]: {
                itemId,
                title: get(itemDetails, 'title'),
                ...({
                    'variants': {
                        price: get(itemDetails, 'price'),
                        outOfStock: !get(itemDetails, 'in_stock'),
                        add_on_groups: get(itemDetails, 'add_on_groups', [])
                    },
                    'addons': {
                        checked: comboInCart ? getChecked(itemId) : false,
                        price: get(itemDetails, 'price'),
                        outOfStock: !get(itemDetails, 'in_stock')
                    }
                })[type]
            }
        })
        return mappedObj()
    }

    const mappedItems = (items, itemType, sectionIndex, mainItemId, groupId) => items.reduce((itemsObj, itemId) => ({ ...itemsObj, ...itemMapper(itemId, itemType, sectionIndex, mainItemId, groupId) }), {})

    const getItemsForIds = (groups, groupField, itemType, getDefaultItem, sectionIndex, itemId) => {
        const itemsForGroups = (groups).reduce((itemObj, groupId) => {
            const itemsForEachGroup = get(products, `${groupField}[${groupId}].${itemType}`, [])
            const groupTitle = get(products, `${groupField}[${groupId}].title`, '')
            const addOnConstraints = itemType === 'addons' ? {
                minimumNeeded: get(products, `${groupField}[${groupId}].minimum_needed`, 0),
                maximumAllowed: get(products, `${groupField}[${groupId}].maximum_allowed`, -1),
            } : {}
            return {
                ...itemObj, [groupId]: {
                    groupId,
                    title: groupTitle,
                    items: mappedItems(itemsForEachGroup, itemType, sectionIndex, itemId, groupId),
                    ...addOnConstraints
                }
            }
        }, {})

        return getDefaultItem ? Object.keys(itemsForGroups).reduce((groupObj, groupId) => {
            return { ...groupObj, [groupId]: Object.keys(get(itemsForGroups, `[${groupId}].items`, {}))[0] }
        }, {}) : itemsForGroups
    }

    const getAddonDetails = (addonGroupsFromVariants, sectionIndex, itemId) => {
        const addonGroupDetails = Object.keys(addonGroupsFromVariants).reduce((addonGroupDetails, vt) => {
            const addonGroupsDetailsForVariant = { ...getItemsForIds(addonGroupsFromVariants[vt], 'add_on_groups', 'addons', false, sectionIndex, itemId) }
            const allAddonGroups = Object.keys(addonGroupsDetailsForVariant).reduce((grpDetails, adnGrp) => {
                return {
                    ...grpDetails,
                    [adnGrp]: {
                        ...addonGroupsDetailsForVariant[adnGrp],
                        forVariant: vt
                    }
                }
            }, {})
            return {
                ...addonGroupDetails,
                ...allAddonGroups
            }
        }, {})
        return addonGroupDetails
    }

    const getAllItemsForSection = (items, sectionIndex) => {
        return items.map((itemId) => {
            const itemDetails = get(products, `items[${itemId}]`, '')
            const variantGroupsForItem = get(itemDetails, 'variant_groups', [])
            const addonGroupsForItem = get(itemDetails, 'add_on_groups', [])
            const variantGroupsDetailsForItem = getItemsForIds(variantGroupsForItem, 'variant_groups', 'variants', false, sectionIndex)
            const addonGroupsFromVariants = Object.values(variantGroupsDetailsForItem).reduce((addonGrps, vGrp) => {
                const allAGrps = Object.values(get(vGrp, 'items', {})).reduce((aGrpForVGrp, vt) => {
                    return {
                        ...aGrpForVGrp, ...({
                            [get(vt, 'itemId')]: get(vt, 'add_on_groups', [])
                        })
                    }
                }, {})
                return { ...addonGrps, ...allAGrps }
            }, {})
            const addonGroupsDetailsForItem = getItemsForIds(addonGroupsForItem, 'add_on_groups', 'addons', false, sectionIndex, itemId)
            const addonGroupsDetailsFromVariant = getAddonDetails(addonGroupsFromVariants, sectionIndex, itemId)

            const generateItemQuantity = () => {
                const itemsInSectionFromCart = get(comboInCart, `sections[${sectionIndex}].items`, [])
                const itemFromCart = itemsInSectionFromCart.find(item => get(item, 'ref_id') === itemId)
                if (itemFromCart) {
                    return get(itemFromCart, 'quantity')
                } return 0
            }

            return {
                ...itemDetails,
                outOfStock: !get(itemDetails, 'in_stock'),
                itemId,
                quantity: comboInCart ? generateItemQuantity() : 0,
                variant_groups: variantGroupsDetailsForItem,
                add_on_groups: {
                    ...addonGroupsDetailsForItem,
                    ...addonGroupsDetailsFromVariant
                }
            }
        })
    }

    const [selectedComboDetails, setSelectedComboDetails] = useState({
        ...selectedCombo,
        sections: get(selectedCombo, 'sections', []).map((section, sectionIndex) => {
            const allItemsForSection = getAllItemsForSection(get(section, 'items', []), sectionIndex)
            return {
                sectionTitle: sectionIndex === 0 ? ComboCardConstants.SELECT_MAIN_ITEMS : ComboCardConstants.SELECT_FREE_ITEMS,
                items: allItemsForSection,
                itemsNeeded: get(section, 'items_needed', 0) + get(section, 'items_free', 0),
                itemsFree: get(section, 'items_needed', 0),
                addItemOnce: get(section, 'addItemOnce', false),
            }
        })
    })

    const [activeComboSection, setActiveComboSection] = useState(0)

    const generateAccordionsOpen = () => {
        return get(selectedCombo, 'sections', []).map((section, sectionIndex) => {
            const initState = get(section, 'items', []).map(() => false)
            const sectionItemsFromCart = get(comboInCart, `sections[${sectionIndex}].items`)
            sectionItemsFromCart.forEach(itemFromCartSection => {
                const indexOfItemInInitState = get(section, 'items', []).findIndex(itemRefId => itemRefId === get(itemFromCartSection, 'ref_id'))
                initState[indexOfItemInInitState] = true
            })
            return initState
        })
    }

    const [accordionsOpen, setAccordionsOpen] = useState(comboInCart ? generateAccordionsOpen() : get(selectedCombo, 'sections', []).map(section => {
        return get(section, 'items', []).map(() => false)
    }))

    const generateUpdatedInitialVariantState = () => {
        return get(selectedComboDetails, 'sections', []).map((section, sectionIndex) => {
            return get(section, 'items', []).map(itemFromCombo => {
                const itemFoundInCart = get(comboInCart, `sections[${sectionIndex}].items`, []).find(item => get(itemFromCombo, 'itemId') === get(item, 'ref_id'))
                if (itemFoundInCart) {
                    const variantsSelectedFromCart = get(itemFoundInCart, 'variants', []).reduce((variantObj, variant) => {
                        return {
                            ...variantObj,
                            [get(variant, 'variant_group')]: get(variant, 'ref_id')
                        }
                    }, {})
                    return variantsSelectedFromCart
                } else {
                    const variantGroupsForItem = { ...get(itemFromCombo, `variant_groups`, {}) }
                    return Object.values(variantGroupsForItem).reduce((allVgrps, vGrp) => {
                        const allVariantsDetails = get(vGrp, 'items', {})
                        const variantGroupId = get(vGrp, 'groupId')
                        return {
                            ...allVgrps,
                            [variantGroupId]: getFirstInStockItem(Object.keys(allVariantsDetails), allVariantsDetails)
                        }
                    }, {})
                }
            })
        })
    }

    const [variantsSelected, setVariantsSelected] = useState(comboInCart ? generateUpdatedInitialVariantState() : get(selectedComboDetails, 'sections', []).map(section => {
        return get(section, 'items', []).map(item => {
            const variantGroupsForItem = { ...get(item, `variant_groups`, {}) }
            return Object.values(variantGroupsForItem).reduce((allVgrps, vGrp) => {
                const allVariantsDetails = get(vGrp, 'items', {})
                const variantGroupId = get(vGrp, 'groupId')
                return {
                    ...allVgrps,
                    [variantGroupId]: getFirstInStockItem(Object.keys(allVariantsDetails), allVariantsDetails)
                }
            }, {})
        })
    }))

    const [shakeText, setShakeText] = useState({
        shake: false,
        colored: false
    })

    const shakeQtyText = (id) => {
        if (id) {
            setShakeText({
                shake: true,
                colored: true,
                id
            })
        } else {
            setShakeText({
                shake: false,
                colored: false,
                id: ''
            })
        }
    }

    const getTotalPrice = () => {
        const splitSections = get(selectedComboDetails, 'sections', []).slice(0, activeComboSection + 1)
        const totalCstPrice = splitSections.reduce((totalSectionsPrice, currentSection, key) => {
            const allItemsInSection = get(currentSection, 'items')
            let totalItemsPriceForSectionWithoutAddon = []
            let totalAddonsPriceForSection = []
            const totalItemsPriceForSection = allItemsInSection.reduce((totalItemsPrice, item, itemIndex) => {
                let totalItemCustomizationPrice = 0
                let totalItemCustomizationPriceWithoutAddon = 0
                const variantGroups = get(item, 'variant_groups', {})
                const addonGroups = get(item, 'add_on_groups', {})
                if (Object.keys(variantGroups).length > 0 && key === 0) {
                    const allVariantsGroupsPrice = Object.keys(variantGroups).reduce((totalVGrpsPrice, vGrp) => {
                        const selectedVariantForGroup = get(variantsSelected, `[${key}][${itemIndex}][${vGrp}]`, '')
                        return totalVGrpsPrice + get(variantGroups, `[${vGrp}].items[${selectedVariantForGroup}].price`, 0)
                    }, 0)
                    totalItemCustomizationPrice = totalItemCustomizationPrice + allVariantsGroupsPrice
                    totalItemCustomizationPriceWithoutAddon = totalItemCustomizationPriceWithoutAddon + allVariantsGroupsPrice
                }
                if (Object.keys(addonGroups).length > 0 && (addAddonPrice ? true : key === 0)) {
                    const allAddonGroupsPrice = Object.values(addonGroups).reduce((totalAGrpPrice, aGrp) => {
                        const selectedAddonsPrice = Object.values(get(aGrp, 'items', {})).filter(addon => get(addon, 'checked', false)).reduce((totalAddonsForAGrpPrice, addon) => {
                            return totalAddonsForAGrpPrice + get(addon, 'price', 0)
                        }, 0)
                        return selectedAddonsPrice + totalAGrpPrice
                    }, 0)
                    totalItemCustomizationPrice = totalItemCustomizationPrice + allAddonGroupsPrice
                    totalAddonsPriceForSection = [...totalAddonsPriceForSection, ...range(0, get(item, 'quantity', 0)).map(() => allAddonGroupsPrice)]
                }
                const netPriceForItem = (key === 0 ? get(item, 'price', 0) : 0) + totalItemCustomizationPrice;
                const netPriceForItemWithoutAddon = (key === 0 ? get(item, 'price', 0) : 0) + totalItemCustomizationPriceWithoutAddon;
                totalItemsPriceForSectionWithoutAddon = [...totalItemsPriceForSectionWithoutAddon, ...range(0, get(item, 'quantity', 0)).map(() => netPriceForItemWithoutAddon)]
                return [...totalItemsPrice, ...range(0, get(item, 'quantity', 0)).map(() => netPriceForItem)]
            }, [])
            const sortedArrs = sortOneArrayAlongWithAnother(totalItemsPriceForSection, totalItemsPriceForSectionWithoutAddon)
            const highestNPrices = sortedArrs.arr1.slice(0, get(currentSection, 'itemsFree', 0))
            const highestNPricesWithoutAddons = sortedArrs.arr2.slice(0, get(currentSection, 'itemsFree', 0))
            const allAddonsPrice = totalAddonsPriceForSection.reduce((totalAddonsCost, currentPrice) => {
                return totalAddonsCost + currentPrice
            }, 0)
            const highestNPriceTotal = addAddonPrice ? highestNPricesWithoutAddons.reduce((totalItemPrice, currentPrice) => {
                return totalItemPrice + currentPrice
            }, 0) + allAddonsPrice : highestNPrices.reduce((totalPriceForSection, currentPrice) => {
                return totalPriceForSection + currentPrice
            }, 0)
            return totalSectionsPrice + highestNPriceTotal
        }, 0)
        return totalCstPrice
    }

    const [priceCalculated, setPriceCalculated] = useState(getTotalPrice())

    useEffect(() => {
        setPriceCalculated(getTotalPrice())
    }, [selectedComboDetails, variantsSelected, activeComboSection])

    useEffect(() => {
        if (get(shakeText, 'shake')) {
            setTimeout(() => {
                setShakeText({
                    ...shakeText,
                    shake: false
                })
            }, 200)
        }
    }, [shakeText])

    const handleItemQty = (action, itemIndex, sectionIndex, itemsQuantity, itemsNeeded, itemQty) => {
        if (action === '+') {
            itemsQuantity !== itemsNeeded && handleShowItemDetails(sectionIndex, itemIndex, true)
        } else if (action === '-') {
            handleShowItemDetails(sectionIndex, itemIndex, itemQty !== 1)
        }
        if (action === '+' && (itemsQuantity === itemsNeeded)) {
            focusOnField(`item-qty-text-${sectionIndex}`, sectionIndex, shakeQtyText)
        }
        else {
            shakeQtyText()
            const updatedSelectedComboSection = [...get(selectedComboDetails, `sections`, [])]
            const qtyPath = `[${sectionIndex}].items[${itemIndex}].quantity`
            let updatedQuantity = get(updatedSelectedComboSection, qtyPath)
            updatedQuantity += action === '+' ? +1 : updatedQuantity > 0 ? -1 : 0
            set(updatedSelectedComboSection, qtyPath, updatedQuantity)
            setSelectedComboDetails({
                ...selectedComboDetails,
                sections: updatedSelectedComboSection
            })
        }
    }

    const handleShowItemDetails = (sectionIndex, itemIndex, showFlag) => {
        const updatedAccordionFlagArr = [...accordionsOpen]
        const flagPath = `[${sectionIndex}][${itemIndex}]`
        const currentFlag = get(updatedAccordionFlagArr, flagPath)
        showFlag !== currentFlag && set(updatedAccordionFlagArr, flagPath, showFlag)
        setAccordionsOpen(updatedAccordionFlagArr)
    }

    const handleVariantSelection = (variantId, groupId, itemIndex, sectionIndex) => {
        const updatedSelectedVariants = [...variantsSelected]
        const selectionHandlingPath = `[${sectionIndex}][${itemIndex}][${groupId}]`
        set(updatedSelectedVariants, selectionHandlingPath, variantId)
        setVariantsSelected(updatedSelectedVariants)
    }

    const validateAddonsSelection = (maximumAddons, addonSelectedQty) => {
        if (maximumAddons === -1) {
            return true
        } else {
            if (addonSelectedQty === maximumAddons) {
                return false
            }
            return true
        }
    }

    const handleAddonSelection = (addonId, itemIndex, sectionIndex, groupId, maximumAddons, addonSelectedQty, mainItemId) => {
        const updatedComboDetails = { ...selectedComboDetails }
        const selectionHandlingPath = `sections[${sectionIndex}].items[${itemIndex}].add_on_groups[${groupId}].items[${addonId}].checked`
        const checkedState = get(updatedComboDetails, selectionHandlingPath)
        if (checkedState) {
            set(updatedComboDetails, selectionHandlingPath, !checkedState)
            setSelectedComboDetails(updatedComboDetails)
            shakeQtyText()
        } else {
            if (validateAddonsSelection(maximumAddons, addonSelectedQty)) {
                set(updatedComboDetails, selectionHandlingPath, !checkedState)
                setSelectedComboDetails(updatedComboDetails)
                shakeQtyText()
            } else {
                focusOnField(`${groupId}-${mainItemId}-addon-qty-text-${activeComboSection}`, activeComboSection, shakeQtyText)
            }
        }
    }

    const getItems = (section, sectionIndex, itemsQuantity, itemsNeeded, addItemOnce) => {

        const getVariantsAndAddons = (item, itemIndex) => {
            const mainItemId = get(item, 'itemId', '')
            const allVariantGroups = get(item, 'variant_groups', {})
            const allAddonGroups = get(item, 'add_on_groups', {})

            const variantsInSelection = Object.values(get(variantsSelected, `[${sectionIndex}][${itemIndex}]`)).reduce((allVrsSelected, vrs) => {
                return {
                    ...allVrsSelected,
                    [vrs]: 1
                }
            }, {})

            const filteredAddonGroups = Object.keys(allAddonGroups).reduce((filteredGrps, aGrp) => {
                const grpDetails = get(allAddonGroups, `[${aGrp}]`, {})
                if (grpDetails.hasOwnProperty('forVariant')) {
                    return !!variantsInSelection[get(grpDetails, 'forVariant', '')] ? {
                        ...filteredGrps,
                        [aGrp]: grpDetails
                    } : filteredGrps
                } else {
                    return {
                        ...filteredGrps,
                        [aGrp]: grpDetails
                    }
                }
            }, {})

            const variantsForVariantGroups = Object.values(allVariantGroups).length > 0 && <SubSection bottomGap={Object.values(filteredAddonGroups).length === 0}>
                <SectionHeading subheading lessTopGap>{i18n.t('CHOOSE_VARIANTS')}</SectionHeading>
                {Object.values(allVariantGroups).map((group, groupKey) => {
                    const groupTitle = get(group, 'title', '')
                    const groupItems = get(group, 'items', {})
                    const groupId = get(group, 'groupId', '')
                    const selectedVariantForGroup = get(variantsSelected, `[${sectionIndex}][${itemIndex}][${groupId}]`, '')
                    return <SubSection groupSection firstChild={groupKey === 0}>
                        <SectionHeading restaurantColor={restaurantColor} subheading lessTopGap>{groupTitle}</SectionHeading>
                        {Object.values(groupItems).map(item => {
                            const variantId = get(item, 'itemId', '')
                            const variantTitle = get(item, 'title', '')
                            const variantOutOfStock = get(item, 'outOfStock')
                            return <RadioButton
                                id={`${variantId}-${groupId}-${mainItemId}-variant-section-${sectionIndex}`}
                                key={`${variantId}-${groupId}-${mainItemId}-variant-section-${sectionIndex}`}
                                name={`${groupId}-${mainItemId}-variants-selection-${sectionIndex}`}
                                variant={'itemSelection'}
                                label={variantTitle}
                                additionalLabel={variantOutOfStock ? i18n.t('OUT_OF_STOCK_MESSAGE') : sectionIndex === 0 && getPriceWithCurrency(get(item, 'price', 0))}
                                additionalLabelId={`${variantId}-${groupId}-${mainItemId}-variant-section-${sectionIndex}-price`}
                                value={variantTitle}
                                checked={variantId === selectedVariantForGroup}
                                disabled={variantOutOfStock}
                                onChange={() => handleVariantSelection(variantId, groupId, itemIndex, sectionIndex)}
                            />
                        })}
                    </SubSection>

                })}
            </SubSection>

            const addonsForAddonGroups = checkAllAddonGroupsHaveAddons(filteredAddonGroups) && <SubSection bottomGap>
                <SectionHeading subheading lessTopGap>{i18n.t('CHOOSE_ADDONS')}</SectionHeading>
                {Object.values(filteredAddonGroups).map((group, groupKey) => {
                    const groupItems = get(group, 'items', {})
                    if (Object.values(groupItems).length > 0) {
                        const groupTitle = get(group, 'title', '')
                        const groupId = get(group, 'groupId', '')
                        const minimumAddons = get(group, 'minimumNeeded', 0)
                        const maximumAddons = get(group, 'maximumAllowed', -1)
                        const noMinimumMaximum = minimumAddons === 0 && maximumAddons === -1
                        const addonSelectedQty = getAddonsSelected(groupItems)
                        const eligibleToShow = group.hasOwnProperty('forVariant') ? !!variantsInSelection[get(group, 'forVariant', '')] : true
                        const qtyText = () => {
                            if (minimumAddons > 0 && maximumAddons !== -1) {
                                if (minimumAddons === maximumAddons) {
                                    return ComboCardConstants.addonsExactText(minimumAddons, addonSelectedQty)
                                }
                                return ComboCardConstants.addonsAtleastUptoText(minimumAddons, maximumAddons, addonSelectedQty)
                            } else if (minimumAddons > 0 && maximumAddons === -1) {
                                return ComboCardConstants.addonsAtleastText(minimumAddons, addonSelectedQty)
                            } else if (minimumAddons === 0 && maximumAddons !== -1) {
                                return ComboCardConstants.addonsUptoText(maximumAddons, addonSelectedQty)
                            }
                        }
                        return eligibleToShow && <SubSection groupSection firstChild={groupKey === 0}>
                            <SectionHeading restaurantColor={restaurantColor} subheading lessTopGap withSubtext={!noMinimumMaximum}>
                                {noMinimumMaximum
                                    ? groupTitle
                                    : (<React.Fragment>
                                        <span>{groupTitle}</span>
                                        <QtyText shakeText={shakeText} id={`${groupId}-${mainItemId}-addon-qty-text-${sectionIndex}`}>{qtyText()}</QtyText>
                                    </React.Fragment>)}
                            </SectionHeading>
                            {Object.values(groupItems).map(item => {
                                const addonId = get(item, 'itemId', '')
                                const addonTitle = get(item, 'title', '')
                                const addonOutOfStock = get(item, 'outOfStock')
                                return <Checkbox
                                    id={`${addonId}-${groupId}-${mainItemId}-addon-section-${sectionIndex}`}
                                    key={`${addonId}-${groupId}-${mainItemId}-addon-section-${sectionIndex}`}
                                    label={addonTitle}
                                    additionalLabel={addonOutOfStock ? i18n.t('OUT_OF_STOCK_MESSAGE') : (sectionIndex === 0 || addAddonPrice) && getPriceWithCurrency(get(item, 'price', 0))}
                                    additionalLabelId={`${addonId}-${groupId}-${mainItemId}-addon-section-${sectionIndex}-price`}
                                    value={addonTitle}
                                    checked={get(item, 'checked')}
                                    disabled={addonOutOfStock}
                                    onChange={() => handleAddonSelection(addonId, itemIndex, sectionIndex, groupId, maximumAddons, addonSelectedQty, mainItemId)}
                                />
                            })}
                        </SubSection>
                    }
                })}
            </SubSection>
            return <React.Fragment>
                {variantsForVariantGroups}
                {addonsForAddonGroups}
            </React.Fragment>
        }

        const getAddButton = (item, itemIndex) => {
            return addItemOnce ? <Checkbox
                id={`${get(item, 'itemId')}-item-section-${sectionIndex}`}
                key={`${get(item, 'itemId')}-item-section-${sectionIndex}`}
                checked={get(item, 'quantity') === 1}
                onChange={() => handleItemQty(get(item, 'quantity', 0) === 0 ? '+' : '-', itemIndex, sectionIndex, itemsQuantity, itemsNeeded, get(item, 'quantity', 0) !== 0 && get(item, 'quantity'))}
                bgColor={'#e7e7e7'}
                inline
            /> : <CountContainer>
                    {get(item, 'quantity') > 0 ?
                        <AddItemButton
                            disabledPlus={addItemOnce ? item.quantity === 1 : false}
                            disabledMinus={item.quantity === 0}
                            itemQuantity={item.quantity}
                            handleAddItemClick={(action) => handleItemQty(action, itemIndex, sectionIndex, itemsQuantity, itemsNeeded, get(item, 'quantity'))}
                            buttonSize='sm'
                            title={`${get(item, 'itemId')}-item-section-${sectionIndex}`}
                            popupButtons
                            invertColors
                        />
                        :
                        <CountPlus
                    id={`${get(item, 'itemId')}-item-section-${sectionIndex}`}
                    onlyButton
                    onClick={() => handleItemQty('+', itemIndex, sectionIndex, itemsQuantity, itemsNeeded)}
                    restaurantColor={restaurantColor}
                    biggerFont
                >
                    <i className='fa fa-plus' />
                </CountPlus>}
            </CountContainer>
        }

        const checkAllVariantsOutOfStock = (item) => {
            const allVariantGroupsOfItem = Object.values(get(item, 'variant_groups', {}))
            if (allVariantGroupsOfItem.length > 0) {
                for (let i = 0; i < allVariantGroupsOfItem.length; i++) {
                    const variantsForGroupDetails = Object.values(get(allVariantGroupsOfItem[i], 'items', {}))
                    const inStockStatusForGroup = variantsForGroupDetails.reduce((netStatus, variant) => {
                        const variantStatus = get(variant, 'outOfStock', false)
                        return netStatus && variantStatus
                    }, true)
                    if (inStockStatusForGroup) {
                        return true
                    }
                }
            }
            return false
        }

        const checkAllNecessaryAddonsOutOfStock = item => {
            const allAddonGroupsOfItem = Object.values(get(item, 'add_on_groups', {}))
            if (allAddonGroupsOfItem.length > 0) {
                for (let i = 0; i < allAddonGroupsOfItem.length; i++) {
                    const addonsForGroupDetails = Object.values(get(allAddonGroupsOfItem[i], 'items', {}))
                    const minimumReqd = get(allAddonGroupsOfItem[i], 'minimumNeeded', 0)
                    if (minimumReqd > 0) {
                        const inStockCountForGroup = addonsForGroupDetails.reduce((netCount, addon) => {
                            return !get(addon, 'outOfStock', false) ? netCount + 1 : netCount
                        }, 0)
                        if (inStockCountForGroup >= minimumReqd) {
                            continue
                        } else {
                            return true
                        }
                    } else {
                        continue
                    }
                }
            }
            return false
        }

        return get(section, 'items', []).map((item, itemIndex) => {
            const itemTitle = get(item, 'title', '')
            const itemFoodType = get(item, 'food_type', '')
            const itemOutOfStock = get(item, 'outOfStock', false) || checkAllVariantsOutOfStock(item) || checkAllNecessaryAddonsOutOfStock(item)
            const itemCount = get(item, 'quantity', 0)
            const spacePadding = addItemOnce ? '' : '\u00A0\u00A0'
            const addActionLabel = itemOutOfStock ? i18n.t('OUT_OF_STOCK_MESSAGE') : (
                sectionIndex === 0
                    ? <>{getPriceWithCurrency(get(item, 'price', 0))}{itemCount > 0 ? spacePadding : ''}</>
                    : `Free${itemCount > 0 ? spacePadding : ''}`)
            return <Accordion
                heading={getItemLabelWithFoodType(itemTitle, itemFoodType, isRestaurantIndian, sectionIndex, get(item, 'itemId'), isLanguageArab)}
                headingBackground={'#e7e7e7'}
                headingBorder={'#9a9a9a'}
                firstChild={itemIndex === 0}
                actionLabel={addActionLabel}
                actionLabelId={`section-${sectionIndex}-${get(item, 'itemId', '')}-price`}
                accordionAction={!itemOutOfStock && getAddButton(item, itemIndex)}
                accordionOpen={get(accordionsOpen, `[${sectionIndex}][${itemIndex}]`)}
                accordionDisabled={itemOutOfStock}
            >
                {getVariantsAndAddons(item, itemIndex)}
            </Accordion>
        })
    }

    const getCombosSection = (section, sectionIndex) => {
        const sectionTitle = get(section, 'sectionTitle', '')
        const itemsSelected = get(section, 'items', []).reduce((totalQty, item) => {
            return totalQty + get(item, 'quantity', 0)
        }, 0)
        const itemsNeeded = get(section, 'itemsNeeded', 0)
        const addItemOnce = get(section, 'addItemOnce', false)
        const itemsQtyText = ComboCardConstants.selectItemComboText(itemsNeeded, itemsSelected);
        return <ComboDetailsWrapper id={`combo-items-list-${sectionIndex}`} isLanguageArab={isLanguageArab}>
            <SubSection firstChild>
                <SectionHeading restaurantColor={restaurantColor} subheading withSubtext lessTopGap isLanguageArab={isLanguageArab}>
                    <span id={`combo-section-title-${sectionIndex}`}>{sectionTitle}</span>
                    <QtyText shakeText={shakeText} id={`item-qty-text-${sectionIndex}`}>{itemsQtyText}</QtyText>
                </SectionHeading>
                <AccordionSection>
                    {getItems(section, sectionIndex, itemsSelected, itemsNeeded, addItemOnce)}
                </AccordionSection>
            </SubSection>
        </ComboDetailsWrapper>
    }

    const getSelectedAddonsFromGrps = (groups = []) => {
        return groups.reduce((allAddons, aGrp) => {
            const allAddonsSelectedForGrp = Object.values(get(aGrp, 'items', {}))
                .filter(addon => !!get(addon, 'checked'))
                .reduce((addonsForGrps, addon) => {
                    return [...addonsForGrps, {
                        ref_id: get(addon, 'itemId'),
                        add_on_group: get(aGrp, 'groupId')
                    }]
                }, [])
            return [...allAddons, ...allAddonsSelectedForGrp]
        }, [])
    }

    const generatePayload = () => {
        return {
            title: get(selectedComboDetails, 'title', ''),
            comboDescription: get(selectedComboDetails, 'description', ''),
            bill_components: get(selectedComboDetails, 'bill_components'),
            ref_id: get(selectedComboDetails, 'code', ''),
            code: get(selectedComboDetails, 'code', ''),
            quantity: 1,
            food_type: get(selectedComboDetails, 'food_type', ''),
            itemsNeeded: get(selectedComboDetails, 'itemsNeeded', 0),
            price: priceCalculated,
            sections: get(selectedComboDetails, 'sections', []).map((section, sectionIndex) => {
                const allItemsInsideSection = get(section, 'items', [])
                const selectedItems = allItemsInsideSection.filter(item => get(item, 'quantity', 0) > 0)
                const selectedItemsIndexes = allItemsInsideSection.reduce((filterArr, item, i) => {
                    if (get(item, 'quantity') > 0) {
                        return [...filterArr, i]
                    } return filterArr
                }, [])
                return {
                    items: selectedItems.map((item, itemIndex) => {
                        const variantsSelectedForThisItem = get(variantsSelected, `[${sectionIndex}][${selectedItemsIndexes[itemIndex]}]`, {})
                        const allAddonGroupsForThisItem = get(item, 'add_on_groups', {})
                        let normalAddonGroups = {}
                        let variantAddonGroups = {}
                        Object.keys(allAddonGroupsForThisItem).forEach(grpId => {
                            const addonGrpDetails = get(allAddonGroupsForThisItem, `[${grpId}]`, {})
                            if (addonGrpDetails.hasOwnProperty('forVariant')) {
                                variantAddonGroups = {
                                    ...variantAddonGroups,
                                    [grpId]: addonGrpDetails
                                }
                            } else {
                                normalAddonGroups = {
                                    ...normalAddonGroups,
                                    [grpId]: addonGrpDetails
                                }
                            }
                        })
                        return {
                            ref_id: get(item, 'itemId', ''),
                            quantity: get(item, 'quantity', 0),
                            addons: getSelectedAddonsFromGrps(Object.values(normalAddonGroups)),
                            variants: Object.keys(variantsSelectedForThisItem).map(vGrpId => {
                                const variantIdSelected = get(variantsSelectedForThisItem, `[${vGrpId}]`)
                                const addonGroupsForVariantId = Object.values(variantAddonGroups).filter(vGrpDet => get(vGrpDet, 'forVariant') === variantIdSelected)
                                const allAddonsForVariantIdSelected = getSelectedAddonsFromGrps(addonGroupsForVariantId)
                                return {
                                    ref_id: variantIdSelected,
                                    variant_group: vGrpId,
                                    addons: allAddonsForVariantIdSelected
                                }
                            })
                        }
                    })
                }
            })
        }
    }

    const handleComboAdd = () => {
        const finalSection = get(selectedComboDetails, `sections[${totalSections}]`, {})
        if (validateSectionInputs(finalSection)) {
            shakeQtyText()
            const payload = generatePayload()
            addCombo(payload)
        }
    }

    const validateAddons = (selectedItems, itemsNeeded, itemsQtySelected, selectedItemsIndexes) => {
        const addonGroupsForItems = selectedItems.map(item => ({
            itemId: get(item, 'itemId'),
            add_on_groups: get(item, 'add_on_groups')
        }))
        for (let i = 0; i < addonGroupsForItems.length; i++) {
            const addOnGroupDetailsForItem = addonGroupsForItems[i]
            const mainItemId = get(addOnGroupDetailsForItem, 'itemId')
            const addonGroupsForItem = get(addOnGroupDetailsForItem, 'add_on_groups')
            const addonGroupIdsForItem = Object.keys(addonGroupsForItem)
            if (addonGroupIdsForItem.length > 0) {
                for (let j = 0; j < addonGroupIdsForItem.length; j++) {
                    const addonGroupId = addonGroupIdsForItem[j]
                    const minimumAddons = get(addonGroupsForItem, `[${addonGroupId}].minimumNeeded`)
                    if (minimumAddons === 0) {
                        continue
                    } else {
                        const allAddonsInGroup = get(addonGroupsForItem, `[${addonGroupId}].items`)
                        const forVariantExists = get(addonGroupsForItem, `[${addonGroupId}]`, {}).hasOwnProperty('forVariant')
                        if (forVariantExists) {
                            const forVariantValue = get(addonGroupsForItem, `[${addonGroupId}].forVariant`, {})
                            const selectedVars = Object.values(get(variantsSelected, `[${activeComboSection}][${selectedItemsIndexes[i]}]`)).reduce((allVrsSelected, vrs) => {
                                return {
                                    ...allVrsSelected,
                                    [vrs]: 1
                                }
                            }, {})
                            if (selectedVars[forVariantValue]) {
                                const addonSelectedQty = getAddonsSelected(allAddonsInGroup)
                                if (addonSelectedQty < minimumAddons) {
                                    focusOnField(`${addonGroupId}-${mainItemId}-addon-qty-text-${activeComboSection}`, activeComboSection, shakeQtyText)
                                    return false
                                }
                                continue
                            }
                            continue
                        } else {
                            const addonSelectedQty = getAddonsSelected(allAddonsInGroup)
                            if (addonSelectedQty < minimumAddons) {
                                focusOnField(`${addonGroupId}-${mainItemId}-addon-qty-text-${activeComboSection}`, activeComboSection, shakeQtyText)
                                return false
                            }
                            continue
                        }

                    }
                }
            } else {
                continue
            }
        }
        if (itemsQtySelected < itemsNeeded) {
            focusOnField(`item-qty-text-${activeComboSection}`, activeComboSection, shakeQtyText)
        } else {
            return true
        }
    }

    const validateSectionInputs = (section) => {
        const itemsNeeded = get(section, 'itemsNeeded')
        const selectedItems = get(section, 'items', []).filter(item => get(item, 'quantity', 0) > 0)
        const selectedItemsIndexes = get(section, 'items', []).reduce((filterArr, item, i) => {
            if (get(item, 'quantity') > 0) {
                return [...filterArr, i]
            } return filterArr
        }, [])
        const itemsQtySelected = selectedItems.reduce((totalQty, item) => {
            return totalQty + get(item, 'quantity', 0)
        }, 0)
        if (itemsQtySelected === 0) {
            focusOnField(`item-qty-text-${activeComboSection}`, activeComboSection, shakeQtyText)
        } else {
            return validateAddons(selectedItems, itemsNeeded, itemsQtySelected, selectedItemsIndexes)
        }
    }

    const handleSectionNavigation = (action, section) => {
        if (action === '+') {
            if (validateSectionInputs(section)) {
                shakeQtyText()
                setActiveComboSection(activeComboSection + 1)
            }
        } else {
            setActiveComboSection(activeComboSection - 1)
        }
    }

    const getCombosSectionButtons = (section, sectionIndex, shouldFilterProductFromCart) => <React.Fragment>
        {sectionIndex !== 0 &&
            <AddItemButton
            buttonLabel={i18n.t('BACK_BUTTON')}
                handleAddItemClick={() => handleSectionNavigation('-')}
                title={`section-${sectionIndex}-back`}
                popupButtons
                invertColors
            />}
        <AddItemButton
            buttonLabel={<>{sectionIndex === totalSections ? shouldFilterProductFromCart.current ? i18n.t('UPDATE_BUTTON') : i18n.t('ADD_BUTTON') : i18n.t('NEXT_BUTTON')}&nbsp;-&nbsp;{getPriceWithCurrency(priceCalculated)}</>}
            handleAddItemClick={() => { sectionIndex === totalSections ? handleComboAdd() : handleSectionNavigation('+', section) }}
            buttonSize={sectionIndex === totalSections && shouldFilterProductFromCart.current ? 'xl' : 'lg'}
            title={`section-${sectionIndex}-next`}
            popupButtons
            invertColors
        />
    </React.Fragment>

    const getSections = (getButtons, shouldFilterProductFromCart, isLanguageArab) => {
        return get(selectedComboDetails, 'sections', []).map((section, sectionIndex) => getButtons ? getCombosSectionButtons(section, sectionIndex, shouldFilterProductFromCart) : getCombosSection(section, sectionIndex, shouldFilterProductFromCart))
    }

    const comboPopupProps = {
        closePopup,
        heading: get(selectedComboDetails, 'title'),
        hasMultipleSections: true,
        activeSectionIndex: activeComboSection,
        sections: getSections(),
        sectionButtons: getSections('withButtons', shouldFilterProductFromCart, isLanguageArab)
    }

    return <SlideUpPopup {...comboPopupProps} />
}


export const CombosPopup = (props) => {
    useEffect(() => {
        if (props?.replaceExistingFlag?.current) {
            props.replaceExistingFlag.current = false;
        }
        if (props?.customizeExistingFlag?.current) {
            props.customizeExistingFlag.current = false;
        }
    }, [])
    const selectedComboType = get(props, 'selectedCombo.combo_type')
    return ({
        [ComboCardConstants.CUSTOMIZED_COMBO]: <CustomizableCombosPopup {...props} />,
        [ComboCardConstants.PAY_X_GET_Y]: <PayXGetYCombosPopup {...props} />,
        [ComboCardConstants.BUY_X_GET_Y]: <PayXGetYCombosPopup {...props} />
    })[selectedComboType]
}
