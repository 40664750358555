import { clevertapEventPush, CT_EVENT_IDS } from "../../helpers/clevertapUtil";
import { SocialMediaIcon } from "../Header/Icons";
import {useTranslation } from 'react-i18next';
import { FacebookIcon, InstagramIcon, SocialContainer, SocialHeader, SocialMedia, TwitterIcon, YoutubeIcon } from "./styles";
import { Dropdown, DropdownMenu, DropdownWrapper, DropdownToggle } from "../BasicComponents";
import { useState } from "react";

const trackSocialInClevertap = (socialHandle) => {
  clevertapEventPush({
    eventId: CT_EVENT_IDS.VISITED_SOCIAL_HANDLES,
    eventData: {
      'Social media chosen': socialHandle
    }
  })
}

const SocialMediaDropdown = ({ socialHandles, restaurantColor, isLanguageArab }) => {
  const { t }=useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen)

  return (
    <DropdownWrapper isLanguageArab={isLanguageArab}>
      <div onClick={toggleDropdown}>
      <DropdownToggle id='social-media-button'>
        <SocialMediaIcon restaurantColor={restaurantColor}></SocialMediaIcon>
        </DropdownToggle>
      </div>
      {isOpen && <Dropdown isLanguageArab={isLanguageArab}>
        <DropdownMenu>
        <SocialHeader id='social-media-section-heading'> {t('FOLLOW_US')}</SocialHeader>
        <SocialContainer id='social-media-section'>
          {socialHandles.youtube ? <SocialMedia id='youtube-link' href={"https://www.youtube.com/c/" + socialHandles.youtube} socialMediaName={`youtube`} onClick={() => trackSocialInClevertap('Youtube')} target="_blank" rel="noreferrer">
            {YoutubeIcon}</SocialMedia> : ''}
          {socialHandles.facebook ? <SocialMedia id='facebook-link' href={"https://www.facebook.com/" + socialHandles.facebook} socialMediaName={`facebook`} onClick={() => trackSocialInClevertap('Facebook')} target="_blank" rel="noreferrer">
            {FacebookIcon}</SocialMedia> : ''}
          {socialHandles.twitter ? <SocialMedia id='twitter-link' href={"https://www.twitter.com/" + socialHandles.twitter} socialMediaName={`twitter`} onClick={() => trackSocialInClevertap('Twitter')} target="_blank" rel="noreferrer">
            {TwitterIcon}</SocialMedia> : ''}
          {socialHandles.instagram ? <SocialMedia id='instagram-link' href={"https://www.instagram.com/" + socialHandles.instagram} socialMediaName={`instagram`} onClick={() => trackSocialInClevertap('Instagram')} target="_blank" rel="noreferrer">
            {InstagramIcon}</SocialMedia> : ''}
        </SocialContainer>
        </DropdownMenu>
      </Dropdown>}
    </DropdownWrapper>
  );
};

export default SocialMediaDropdown;
