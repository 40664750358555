import { Col, Row, Button } from "react-bootstrap";
import styled, { css } from "styled-components";
import TextButton from "../../../Components/TextButton";
import styleVals from "../../../constants/styles";
import { Input } from "../../../Components/BasicComponents";
import { Loader as LocalLoader } from "../../../Components/BasicComponents";
import { darkenColor } from "../../../utils/common";

export const BreakdownContainer = styled.div`
  background: #ffffff;
  box-shadow: ${styleVals.measurements.spacing0} ${styleVals.measurements.spacing4} ${styleVals.measurements.spacing4} rgb(0 0 0 / 10%);
  border-radius: ${styleVals.measurements.spacing10};
  padding: ${styleVals.measurements.spacing10};
  font-size: ${styleVals.measurements.spacing14};
`;

export const BillDetailWrapper = styled.div`
  padding-bottom: ${styleVals.measurements.spacing90};
`;

BreakdownContainer.Hr = styled.hr`
  margin-top: ${styleVals.measurements.spacing20};
  margin-bottom: ${styleVals.measurements.spacing15};
  border: 0;
  border-top: 1px solid rgba(176, 176, 176, 0.5);
`;

export const AddressContainer = styled.div`
  position: fixed;
  height: 100vh;
  height: -webkit-fill-available;
  overflow: auto;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
`;

export const ModifyRepititions = styled.div`
  height: ${styleVals.measurements.spacing30};
  position: relative;
  width: 100%;
`;

ModifyRepititions.Button = styled.button`
  display: flex;
  justify-content: center;
  align-content: center;
  font-weight: 400;
  font-size: 0.8rem;
  border: 1px solid #fff;
  color: ${(props) => props.restaurantColor || "#ab0012"};
  margin: 0;
  padding: ${styleVals.measurements.spacing5} ${styleVals.measurements.spacing0};
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  transform: translate(-50%, -50%);
  span {
    border-bottom: 1px dotted #000000;
  }
`;
ModifyRepititions.b = styled.b`
  color: ${(props) => props.color || "#e55353"};
`;

export const AddInstructions = styled.div`
    margin-top: ${styleVals.measurements.spacing15};
    padding-top: ${styleVals.measurements.spacing20};
    padding-bottom: ${styleVals.measurements.spacing15};
    border-top: 1px solid rgba(176, 176, 176, 0.5);
    text-align: center;
    cursor: pointer;
}
`;
AddInstructions.Div = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${styleVals.measurements.spacing14};
  margin: 0 ${styleVals.measurements.spacing20};
  background-color: #efefef;
  padding: ${styleVals.measurements.spacing5} ${styleVals.measurements.spacing10};
`;
AddInstructions.Span = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  svg[id="instructions-icon"] {
    width: ${styleVals.measurements.spacing15};
    height: ${styleVals.measurements.spacing15};
    border-radius: 50%;
    background-color: green;
    color: white;
    padding: ${styleVals.measurements.spacing2};
    ${(props) =>
      props.isLanguageArab
        ? css`
            margin-left: ${styleVals.measurements.spacing10};
          `
        : css`
            margin-right: ${styleVals.measurements.spacing10};
          `}
  }
  b[id="clear-button"] {
    cursor: pointer;
    font-size: ${styleVals.measurements.spacing12};
    color: #e55353;
  }
`;

AddInstructions.Button = styled.button`
    background: none;
    text-align: center;
    font-size: 0.8rem;
    border: unset;
    padding: ${styleVals.measurements.spacing5} ${styleVals.measurements.spacing5};
    border-radius: ${styleVals.measurements.spacing5};
`;
AddInstructions.b = styled.b`
  color: ${(props) => props.color || "#e55353"};
`;

export const CouponApplicable = styled.strong`
  font-size: ${styleVals.measurements.spacing10};
    white-space: nowrap;
    display: block;
    width: 100%;
    opacity: ${(props) => props.isItemOutofStock && 0.5};
    color: ${styleVals.colors.greyText};
    ${(props) =>
      props.isLanguageArab &&
      css`
        text-align: right;
      `}
}`;
export const BillErrorDescription = styled.p`
  text-align: center;
  margin: 0;
  padding: 0;
  font-size: ${styleVals.measurements.spacing16};
  padding-bottom: ${(props) =>
    !props.firstChild && styleVals.measurements.spacing16};
  font-weight: bold;
`;
export const BillRetryButton = styled.button`
  background: ${(props) => props.restaurantColor};
  padding: ${styleVals.measurements.spacing8}
    ${styleVals.measurements.spacing24};
  font-size: ${styleVals.measurements.spacing16};
  color: white;
  outline: none;
  border: none;
  border-radius: ${styleVals.measurements.spacing24};
`;
export const ComboDescription = styled.div`
  padding-left: ${styleVals.measurements.spacing0};
  font-size: ${styleVals.measurements.spacing14};
  color: #707070;
  max-width: 100%;
  opacity: ${(props) => props.isItemOutofStock && 0.5};
  ${(props) =>
    props.isLanguageArab &&
    css`
      text-align: right;
    `}
  line-height: 1;
`;
export const VariantAddonDescription = styled.div`
  padding-left: ${styleVals.measurements.spacing0};
  font-size: ${styleVals.measurements.spacing14};
  color: #b0b0b0;
  max-width: 100%;
  opacity: ${(props) => props.isItemOutofStock && 0.5};
  ${(props) =>
    props.isLanguageArab &&
    css`
      text-align: right;
    `}
  ${(props) =>
    props.isDinein &&
    css`
      padding-left: ${styleVals.measurements.spacing22};
      padding-bottom: ${styleVals.measurements.spacing6};
    `} 
`;

export const ComboAlterButton = styled(TextButton)`
  padding-top: ${styleVals.measurements.spacing8};
  padding-left: ${styleVals.measurements.spacing0};
  font-size: ${styleVals.measurements.spacing14};
  max-width: 70%;
  opacity: ${(props) => props.isItemOutofStock && 0.5};
`;

export const OutOfStockMsg = styled.span`
  color: red;
  margin-left: ${styleVals.measurements.spacing4};
  font-size: ${styleVals.measurements.spacing12};
  white-space: nowrap;
  position: relative;
  top: 3px;
`;

export const CheckoutScroll = styled.div`
  padding-bottom: ${styleVals.measurements.spacin80};
  min-height: 50vh;
  max-height: calc(80vh - 40px);
  height: -webkit-fill-available;
  padding-left: ${styleVals.measurements.spacing10};
  padding-right: ${styleVals.measurements.spacing10};
`;

export const ScrollableSection = styled.div`
    overflow: scroll;
    overflow-x: hidden;
`;

export const SectionWrapper = styled.div`
  margin: ${styleVals.measurements.spacing28} ${styleVals.measurements.spacing0};
`;

export const CheckoutProducts = styled.div`
  background: #ffffff;
  ${props => !props.isSubOrder && css`box-shadow: 0px 4px 4px rgb(0 0 0 / 10%)`};
  border-radius: ${styleVals.measurements.spacing10};
  padding: ${styleVals.measurements.spacing12};
`;

export const ProductTitleContainer = styled.div`
  display: flex;
  margin-bottom: ${styleVals.measurements.spacing5};
`;
CheckoutProducts.Div = styled.div`
  padding: ${styleVals.measurements.spacing7} ${styleVals.measurements.spacing0};
  margin-bottom: ${styleVals.measurements.spacing7};
  text-align: ${(props) => props.isLanguageArab && "right"};
  display: flex;
  justify-content: space-between;
`;

CheckoutProducts.Container = styled.div``;

export const ProductTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 1rem;
  justify-content: space-between;
  ${(props) =>
    props.isDinein &&
    css`
      span:nth-child(2) {
        min-width: ${styleVals.measurements.spacing40};
        text-align: ${props.isLanguageArab ? "left" : "right"};
        margin-left: ${styleVals.measurements.spacing4};
        margin-right: ${styleVals.measurements.spacing4};
      }
    `};
  > div {
    display: flex;
    align-items: center;
    ${(props) =>
      props.isLanguageArab
        ? css`
            margin-right: ${styleVals.measurements.spacing8};
          `
        : css`
            margin-left: ${styleVals.measurements.spacing8};
          `}
    &:first-child {
      opacity: ${(props) => props.isItemOutofStock && 0.5};
    }
  }
  > span {
    ${(props) => props.isLanguageArab && css`margin-right: ${styleVals.measurements.spacing6}`};
  }
  line-height: ${styleVals.measurements.spacing24};
`;

export const ProductAddons = styled.div`
  font-size: ${styleVals.measurements.spacing14};
  color: #b0b0b0;
  margin-bottom: 0.2rem;
  opacity: ${(props) => props.isItemOutofStock && 0.5};
  ${(props) =>
    props.isLanguageArab &&
    css`
      text-align: right;
    `}
`;

export const ProductBody = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  flex-direction: ${props => props.isSubOrder ? 'row' :'column'};
`;

ProductBody.Div = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ProductPriceDiv = styled.div`
  opacity: ${(props) => props.isItemOutofStock && 0.5};
  padding-top: ${styleVals.measurements.spacing4};
`;

export const ProductPrice = styled.div`
  font-size: ${styleVals.measurements.spacing14};
`;

export const FoodType = styled.div`
  ${(props) =>
    props.type &&
    css`
      position: relative;
      flex-shrink: 0;
      margin-top: ${styleVals.measurements.spacing4};
      ${(props) =>
        props.withMarginBottom &&
        css`
          margin-bottom: ${styleVals.measurements.spacing6};
        `}
      border: 1px solid
    ${(props) =>
        props.type === 1 ? "#4f9541" : props.type === 2 ? "#A70202" : "#FF6F00"};
      margin-right: ${styleVals.measurements.spacing6};
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      width: ${styleVals.measurements.spacing14};
      height: ${styleVals.measurements.spacing14};
      line-height: ${styleVals.measurements.spacing14};
    `}
     margin-right: ${(props) => props.isLanguageArab && 0}!important;
`;

FoodType.foodIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${(props) =>
    props.type &&
    css`
      ${(props) =>
        props.type === 2 && props.isRestaurantIndian
          ? css`
              border-left: ${styleVals.measurements.spacing6} solid transparent;
              border-right: ${styleVals.measurements.spacing6} solid transparent;
              border-bottom: ${styleVals.measurements.spacing6} solid #a70202;
            `
          : css`
              background: ${props.type === 1
                ? "#4f9541"
                : props.type === 2
                ? "#A70202"
                : "#FF6F00"};
              border-radius: 50%;
              width: ${styleVals.measurements.spacing8};
              height: ${styleVals.measurements.spacing8};
            `}
    `}
`;

export const CheckoutContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  bottom: 0;
  left: 0;
  right: 0;
`;

CheckoutContainer.Div = styled.div`
  position: fixed;
  bottom: 0;
  top: 0;
  width: 100%;
  max-width: ${styleVals.measurements.spacing500};
  background: #f7f7fa;
  border-radius: 0;
  overflow: hidden;
  max-height: 100vh;
  height: 100vh;
  height: -webkit-fill-available;
`;

export const CheckoutHeader = styled.div`
  background-color: ${(props) => 
    props.restaurantColor ? props.restaurantColor : "#ab0012"};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem;
  border-bottom: 1px solid #cccccc;
  height: ${styleVals.measurements.spacing60};
  line-height: ${styleVals.measurements.spacing19};
  box-shadow: 0 1px 4px #e5e5e5;
  ${(props) => props.isLanguageArab && css` 
      flex-direction: row-reverse;
      justify-content: space-between;
    `}
  > span {
    height: ${styleVals.measurements.spacing30};
    text-align: center;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1 1;
    font-size: ${styleVals.measurements.spacing18};
    font-weight: 600;
    line-height: 1;
    color: #2B2C2D !important;
    white-space:pre;
  }
`;
export const CheckoutHeaderBackIconDiv = styled.div`
  font-weight: 400;
  margin: 0px 10px 0px 0px;
  cursor: pointer;
  line-height: 1;
`;

export const SubOrders = styled.div`
  border-bottom: none;
  padding: 2px;
`;
export const Separator = styled.p`
  border-color: ${(props) => props.restaurantColor};
  text-align: center;
  line-height: 0.1em;
  margin: 10px 0 20px;
  border: 1px dotted;
  > span {
    background: #fff;
    padding: 0 10px;
    font-weight: bold;
    color: ${(props) => props.restaurantColor};
  }
`;
export const CovidMessage = styled.div`
  background: #ffffff;
  overflow-y: auto;
  padding: 0.5rem 0.5rem 1rem;
  width: 95%;
  margin: 0px auto;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  background-color: #ffe4d9;
  border-radius: ${styleVals.measurements.spacing10};
  margin-top: ${(props) => props.isDineinSubOrder && "10px"};
  > svg {
    ${(props) =>
      props.isLanguageArab
        ? css`
            margin-left: ${styleVals.measurements.spacing10};
          `
        : css`
            margin-right: ${styleVals.measurements.spacing10};
          `}
  }
  > span {
    line-height: 1.2;
    font-weight: 600;
    font-size: ${styleVals.measurements.spacing14};
    color: #000;
    text-align: ${(props) => props.isLanguageArab && "right"};
  }
  margin-bottom: ${styleVals.measurements.spacing10};
`;

export const CheckoutOffer = styled.div`
  padding: ${styleVals.measurements.spacing20} ${styleVals.measurements.spacing15};
  margin: ${styleVals.measurements.spacing10} ${styleVals.measurements.spacing0};
  background: white;
  cursor: default;
  font-size: ${styleVals.measurements.spacing18};
  @media ${styleVals.breakpoints.mobile} {
    font-size: ${styleVals.measurements.spacing12} !important;
 };
  font-weight: normal;
  border-radius: ${styleVals.measurements.spacing10};
  box-shadow: ${styleVals.measurements.spacing0} ${styleVals.measurements.spacing4} ${styleVals.measurements.spacing4} rgb(0 0 0 / 10%);
`;
export const CheckoutOfferRow = styled(Row)`
  display: flex;
  flex-wrap: wrap;
  margin-right: -${styleVals.measurements.spacing15};
  margin-left: -${styleVals.measurements.spacing15};
`;
export const CheckoutOfferCol_1 = styled(Col)`
  display: flex;
  svg {
    flex-shrink: 0;
    height: ${styleVals.measurements.spacing24};
    width: ${styleVals.measurements.spacing24};
  }
  flex: 0 0 10%;
  max-width: 10%;
`;
export const CheckoutOfferCondition = styled.div`
  font-size: ${styleVals.measurements.spacing10};
  color: red;
`;
export const CheckoutOfferInstruction = styled.div`
        padding-top:${styleVals.measurements.spacing5};
        font-size: ${styleVals.measurements.spacing14};
        color:gray;
`

export const CheckoutOfferCol_2 = styled(Col)`
    justify-content: space-between;
    align-items: center;
    display:flex;
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
    ${props => props.isLanguageArab && css`   
      text-align: right;
    `}
`
export const AppliedCoupon = styled.div`
    display:block;
    @media ${styleVals.breakpoints.mobile} {
     width:60%;
  };
`;

export const CheckoutOfferChange = styled.div`
    margin-left: ${styleVals.measurements.spacing30};
    line-height: 1;
    text-shadow: 0 1px 0 #fff;
    float:right;
    font-size: ${styleVals.measurements.spacing14};
    color:#1589FF;
    cursor: pointer;
`
export const CheckoutOfferArrow = styled.div`
   color:grey;
`
export const CheckoutOfferModal = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  bottom: 0;
  left: 0;
  right: 0;
`;

CheckoutOfferModal.Div = styled.div`
  position: absolute;
  width: 100%;
  max-width: ${styleVals.measurements.spacing500};
  background: white;
  max-height: 90vh;
  height: 90vh;
  border-radius: 20px 20px 0px 0px;
`;

export const OfferModalHeader = styled.div`
  padding: ${styleVals.measurements.spacing10};
  margin: ${styleVals.measurements.spacing10};
  background: white;
  cursor: default;
  border: none;
  border-bottom: 1px solid #eaeaea;
`;
export const OfferModalCloseIcon = styled.div`
  width: ${styleVals.measurements.spacing30};
  height: ${styleVals.measurements.spacing30};
  cursor: pointer;
  border: 1px solid #000000;
  background-color: #ffffff;
  border-radius: ${styleVals.measurements.spacing15};
  text-align: center;
  font-size: ${styleVals.measurements.spacing21};
  line-height: 1.42857;
  position: absolute;
  top: -20px;
  font-weight: 600;
  right: ${styleVals.measurements.spacing10};
  margin: auto;
`;
export const CheckoutPayButtonSection = styled.div`
  position: fixed;
  bottom: 0;
  max-width: ${styleVals.measurements.spacing500};
  width: 100%;
  ${(props) =>
    css`
      background: linear-gradient(90deg, ${props.restaurantColor}, ${darkenColor(props.restaurantColor, 80)}) !important;`};`;
CheckoutPayButtonSection.Div = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: ${styleVals.measurements.spacing500};
  width: 100%;
  padding: ${styleVals.measurements.spacing10} ${styleVals.measurements.spacing5};
  div {
    margin-left: ${styleVals.measurements.spacing10};
    font-size: ${styleVals.measurements.spacing20};
    line-height: ${styleVals.measurements.spacing30};
    font-weight: 600;
    color: #0C0A0A;
  }
  small {
    display: block;
    line-height: 1;
    font-size: ${styleVals.measurements.spacing12};
    font-weight: 600;
  }
`;
export const CheckoutPayButton = styled(Button)`
  width: 50%;
  padding: ${styleVals.measurements.spacing11} ${styleVals.measurements.spacing24};
  border-radius: ${styleVals.measurements.spacing10};
  cursor: pointer;
  font-size: ${styleVals.measurements.spacing15};
  font-weight: 600;
  outline: none;
  font-family: inherit;
  border: unset;
  margin: ${styleVals.measurements.spacing0} ${styleVals.measurements.spacing5};
  :disabled {
    background-color: ${(props) => props.invertColors ? 'white' : props.restaurantColor} !important;
    border: none !important;
    color: ${(props) =>
      props.isRestaurantColorDark
        ? styleVals.colors.white
        : styleVals.colors.primaryText};
  };
  :active {
    background-color: ${(props) => props.restaurantColor}!important;
          color: ${(props) =>
            props.isRestaurantColorDark
              ? styleVals.colors.white
              : styleVals.colors.primaryText} !important;
              border: unset !important;
  };
  :focus {
    background-color: ${(props) => props.restaurantColor}!important;
    color: ${(props) => props.isRestaurantColorDark ? styleVals.colors.white : styleVals.colors.primaryText} !important;
    border: unset !important;
  };
  ${(props) =>
    props.invertColors
      ? css`
          color: #3f3f3f;
          background: white;
          border: 1px solid ${(props) => props.restaurantColor};
          &:hover {
            background-color: #fff;
            color: #3F3F3F;
            border-color: ${(props) => props.restaurantColor};
          }`
      : css`
          background: ${(props) => props.restaurantColor};
          color: ${(props) =>
            props.isRestaurantColorDark
              ? styleVals.colors.white
              : styleVals.colors.primaryText};
          border: none;
          &:hover {
            color: ${(props) =>
              props.isRestaurantColorDark
                ? styleVals.colors.white
                : styleVals.colors.primaryText} !important;
            background-color: ${(props) =>
              props.restaurantColor ? props.restaurantColor : "#ab0012"} !important;
              border: unset !important;
          }
        `}
`;

export const CheckoutAddNewAddressButton = styled(Button)`
  width: 50%;
  margin-right:${styleVals.measurements.spacing5};
  padding: ${styleVals.measurements.spacing12} ${styleVals.measurements.spacing23};
  font-weight: 600;
  background: ${(props) =>
    props.restaurantColor ? props.restaurantColor : "#ab0012"} !important;
  color: ${(props) =>
    props.isRestaurantColorDark
      ? styleVals.colors.white
      : styleVals.colors.primaryText};
  font-size: ${styleVals.measurements.spacing15};
  border-radius: ${styleVals.measurements.spacing10};
  :disabled {
    color: ${(props) =>
      props.isRestaurantColorDark
        ? styleVals.colors.white
        : styleVals.colors.primaryText};
  }
  &: hover {
    color: ${(props) =>
      props.isRestaurantColorDark
        ? styleVals.colors.white
        : styleVals.colors.primaryText};
    background: ${(props) =>
      props.restaurantColor ? props.restaurantColor : "#ab0012"};
  }
  border: unset;
`;

export const BillLoader = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${styleVals.measurements.spacing50};
`;

export const Loader = styled(LocalLoader)`
  color: ${props => props.restaurantColor};
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
`;
export const BillErrorSection = styled.div`
  font-size: ${styleVals.measurements.spacing12};
  color: #444444;
  padding: ${styleVals.measurements.spacing0} ${styleVals.measurements.spacing16};
  margin: ${styleVals.measurements.spacing30};
  cursor: default;
  padding-bottom: ${styleVals.measurements.spacing64};
  div {
    display: flex;
    justify-content: center;
  }
`;
export const SectionHeading = styled.div`
  ${props => props.isLanguageArab && css`text-align: right`};
  font-size: ${styleVals.measurements.spacing20};
  line-height: ${styleVals.measurements.spacing30};
  margin-bottom: ${styleVals.measurements.spacing5};
`;
export const ItemTotal = styled.div`
  font-weight: 600;
  font-size: 0.9rem;
  color: ${(props) =>
    props.restaurantColor ? props.restaurantColor : "#444444"};
`;
export const BillContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${styleVals.measurements.spacing6} ${styleVals.measurements.spacing0};
  div[ id="discount"]{
    span:last-child{
      padding: ${styleVals.measurements.spacing0} ${styleVals.measurements.spacing7};
    }
  }
`;
export const ChargesLabel = styled.div`
  color: #4776bb;
`;
export const GrandTotalSection = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${styleVals.measurements.spacing6} ${styleVals.measurements.spacing0};
  font-size: ${styleVals.measurements.spacing20};
  font-weight: 600;
  line-height: ${styleVals.measurements.spacing30};
`;
export const SuperSavings = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${styleVals.measurements.spacing6} ${styleVals.measurements.spacing0};
  span {
    font-size: ${styleVals.measurements.spacing16};
    line-height: ${styleVals.measurements.spacing24};
  }
  span: last-child {
    color: ${(props) => props.restaurantColor};
  }
  margin-bottom: ${styleVals.measurements.spacing10};
`;
export const GrandTotalValue = styled.div`
  color: ${(props) => props.restaurantColor};
  font-weight: 600;
`;
export const PriceValueDiscount = styled.span`
  color: #fcb415;
  padding: ${styleVals.measurements.spacing0} ${styleVals.measurements.spacing7};
`;
export const BoldHeading = styled.span`
  font-weight: 600;
  color: #3F3F3F;
`;
export const TextWithColor = styled.span`
  color: ${props => props.color ? props.color :'#3F3F3F'};
  direction: ltr;
`;

export const PriceValueDiscountText = styled.span`
  font-weight: 600;
`;

export const ChargesAndTaxes = styled.div`
  color: #768192 !important;
`;

export const CheckoutAddressLabel = styled.div` 
    background: #ffffff;
    margin: ${styleVals.measurements.spacing20} ${styleVals.measurements.spacing10};
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    justify-items: center;
    align-items: center;
    text-align: center;
    overflow: hidden;
    height:auto;
    padding: ${styleVals.measurements.spacing10} ${styleVals.measurements.spacing16}; 
    position: relative;
    height:auto;
    border-radius:  ${styleVals.measurements.spacing10};
`
CheckoutAddressLabel.Span = styled.span`
    max-width: calc(100% - 60px);
    text-align: left;
    >div{
      text-align: start;
      margin-bottom: ${styleVals.measurements.spacing4};
    }
    #address-edit-icon {
      position:absolute;
    ${props=> props.isLanguageArab ? css`
        left: ${styleVals.measurements.spacing6};
    `: css`
        right: ${styleVals.measurements.spacing6};
    `};
      bottom: ${styleVals.measurements.spacing5};

    }
`
export const CheckoutAddress = styled.span`   
    padding-bottom:${styleVals.measurements.spacing18};
    position:relative;
    display: flex;
    align-items: center;
    >span{
    display: block;
    font-size: ${styleVals.measurements.spacing16};
    font-weight: 400;
    max-width: calc(100% - 10px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    }
  
`
export const RoomNumber = styled.span`
  display: block;
  margin: ${styleVals.measurements.spacing8} ${styleVals.measurements.spacing10}
    0;
`;
export const RoomNumberContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 0px 20px 20px;
`;
export const RoomNumberTitle = styled.span`
  align-self: flex-end;
  display: flex;
  margin-right: ${styleVals.measurements.spacing20};
  font-size: 1rem;
  font-weight: 500;
`;
export const FreeDeliveryMessage = styled.div`
  background-color: #D3D5D6;
  align-content: center;
  justify-content: space-between;
  overflow-y: auto;
  align-items: center;
  display: block !important;
  margin: ${styleVals.measurements.spacing0} auto;
  text-align: center;
  flex-direction: row;
  > img {
    margin: ${styleVals.measurements.spacing2} ${styleVals.measurements.spacing8};
  }
  > span {
    font-weight: 600;
    font-size: ${styleVals.measurements.spacing14};
    color: #000;
  }
`;
export const DeliveryMessage = styled.span`
  color: ${(props) =>
    props.restaurantColor ? props.restaurantColor : "green"};
`;
export const NoDeliveryChargeMessage = styled.span`
  text-decoration: line-through;
`;

export const BillErrorMessage = styled.span`
  font-size: ${styleVals.measurements.spacing12};
  color: #444444;
  padding: ${styleVals.measurements.spacing0} ${styleVals.measurements.spacing16};
  margin: ${styleVals.measurements.spacing30};
  cursor: default;
  width: 100 % !important;
`;
BillErrorMessage.Div = styled.div`
  display: flex;
  justify-content: center;
`;

export const RoomNumberValue = styled(Input)`
  border: none;
  border-bottom: 1px solid black;
  width: 35%;
  border-radius: ${styleVals.measurements.spacing0};
`;
