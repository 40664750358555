import styled, { css } from 'styled-components';
import styleVals from '../../../constants/styles'

export const ModalContainer = styled.div`
  position: fixed;
  width: 100%;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  bottom: 0;
  left: 0;
  right: 0;
`;

ModalContainer.Div = styled.div`
  position: fixed;
  width: 100%;
  max-width: ${styleVals.measurements.spacing300};
  background: white;
  border-radius: ${styleVals.measurements.spacing20};
  bottom: -80%;
  transition: 0.5s;
  padding: ${styleVals.measurements.spacing16};
  position: relative;
`; 

export const ModalHeader = styled.h1`
  margin: 0;
  padding: 0;
  font-size: ${styleVals.measurements.spacing18}; 
  text-align: center;
  font-weight: bold;
  margin-top: ${styleVals.measurements.spacing6};
  color: ${styleVals.colors.primaryText};
`;

export const CloseIcon = styled.button`
  width: ${styleVals.measurements.spacing30};
  height: ${styleVals.measurements.spacing30};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  background-color: ${styleVals.colors.lightGrey};
  border-radius: 15px;
  text-align: center;
  font-size: ${styleVals.measurements.spacing24};
  position: absolute;
  padding-bottom: ${styleVals.measurements.spacing5};
  color: ${styleVals.colors.primaryText};
  top: -${styleVals.measurements.spacing12};
  ${props => props.isLanguageArab ? css`left: ${styleVals.measurements.spacing12};` : css`right: ${styleVals.measurements.spacing12};` };
`;