import styled from "styled-components";
import styleVals from "../../../constants/styles";

export const TextStyleButton = styled.button`
    border: 1px solid ${styleVals.colors.white};
    box-sizing: border-box;
    border-radius: ${styleVals.measurements.spacing10};
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: ${styleVals.measurements.spacing14};
    line-height:  ${styleVals.measurements.spacing18};
    color: ${styleVals.colors.white};
    background: transparent;
    padding: 4px 8px 4px 8px;
    display: inline-block;
    margin: ${styleVals.measurements.spacing8} 4px ${styleVals.measurements.spacing0};
`
export const ServiceableInfo = styled.div`  
    width: 100%;
    text-align: center;
    padding: ${styleVals.measurements.spacing14};
    background-image:${(props) => `url(${props.backgroundImg})`};
    border-radius: ${styleVals.measurements.spacing10};
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: ${styleVals.measurements.spacing24} 0 0;
    >div{
        background: ${props => props.restaurantColor}e6;
        border-radius: ${styleVals.measurements.spacing10};
        width: 100%;
        height: 100%;
        padding: ${styleVals.measurements.spacing16} ${styleVals.measurements.spacing16};
    }
`
export const ServiceableHeading = styled.div`
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: ${styleVals.measurements.spacing24};
    line-height: 33px;
    color: ${styleVals.colors.white};
    margin: 0 0 0px 0;
`;
export const ServiceableText = styled.div`
     font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: ${styleVals.measurements.spacing14};
    line-height: ${styleVals.measurements.spacing14};
    color: ${styleVals.colors.white};
    margin-top: ${styleVals.measurements.spacing8};
`;

export const ServiceabilityWrapper = styled.div`
    margin: 0 ${styleVals.measurements.spacing10};
`