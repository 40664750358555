import {mapOrdertype, markNotificationAsRead as markNotificationAsReadAPI , orderEstimation} from "../../utils/API";
import {ChargeTypes, DiscountTypes, OrderStatus, OrderType ,PaymentMode, PaymentStatus  } from "../../constants/variables";
import { checkDiscountAvailable } from "../../Components/OfferList/OfferList";
import { Notification } from "../../helpers/notification";
import { get, range } from '../../utils/common';
import i18n from "../../translations/i18n";
import { sortOneArrayAlongWithAnother } from "./ComboCard";

const notifocationsInRealTime= async (
  location,
  gipUsr,
  isGIPOpen,
  setNotifyIndicator,
  openGIPWindow,
  closeGIPWindow,
  getSuborders,
  clearDineInData,
  notifications
  ) => {
  var orderStatusArray = [OrderStatus.UPDATED,OrderStatus.ADDED,OrderStatus.CANCELLED,OrderStatus.CANCELLED_BY_RESTAURANT, OrderStatus.ORDER_COMPLETED ];

  let notif = notifications;
  if (notif && (!notif.lastSeen || notif.created > notif.lastSeen)) {
    if (notif.order_status === "placed") {
      openGIPWindow();
    } else if (notif.order_status === "Order More") {
      closeGIPWindow();
    }
    else if(orderStatusArray.includes(notif.order_status)){
          getSuborders();
          if(!isGIPOpen) {
              setNotifyIndicator(true);
          }
          if(notif.order_status === OrderStatus.ORDER_COMPLETED && 
              notif.order_no.split("-")[2] !== "so"){
                  clearDineInData();
          }
    } else if(!isGIPOpen) {
      setNotifyIndicator(true);
    }
  }
};
const updateNotifs = async (productId, setNotifyIndicator) => {
  await markNotificationAsReadAPI(productId)
    .then((result) => {
      setNotifyIndicator(false);
    })
    .catch((e) => {
      console.log(e);
    });
};
const markNotificationAsRead = async (productId, setNotifyIndicator) => {
  await updateNotifs(productId, setNotifyIndicator);
  setNotifyIndicator(false);
};

    // eslint-disable-next-line react-hooks/exhaustive-deps
const createOrderJson = (offers, metaData, orderType, products, cart, instructions) => {
  const tableReservationTime = {};
  let ordr = {
      items: [],
      meta: {
          fulfillment_mode: orderType,
          free_delivery: metaData.free_delivery ? metaData.free_delivery : false,
          addressReference: metaData ? metaData.addressReference : "",
          delivery_time : deliveryTime(orderType, metaData),
          table_reservation_time: tableReservationTime,
          callback_url: `${createCallbackUrl(orderType, metaData)}`,
          is_edit: "false",
          restaurant_name: products && products.meta_data ? products.meta_data.name : "",
          restaurant_bot: products && products.meta_data ? products.meta_data.botname : "",
          instructions: instructions,
          charges: [],
          order_level_charges: 0,
          item_level_charges: 0,
          item_level_taxes: 0,

          discount_code: offers ? offers.offer_id ? offers.offer_id :"":"",
          item_level_discount: offers && offers.offer_discount && offers.type === DiscountTypes.ITEMLVL ? offers.offer_discount :0,
          order_level_discount: offers && offers.offer_discount && offers.type === DiscountTypes.ORDERLVL ? offers.offer_discount :0,
          sub_total: 0,
          sub_total_offer: 0,
          total_charges: 0,
          total_discount: 0,
          total_taxes: 0,
          total: 0,
          ...metaData
      },
  };
  let items = [];

  let all_bill_components = {
      taxes: {},
      charges: {},
  }
  for (let i = 0; i < cart.length; i++) {
      let itm = processCartItems(cart[i], products, offers, orderType);
      items.push(itm);
      for (let j=0;itm.charges && j<itm.charges.length;j++) {
          let tchrg = itm.charges[j];
          if (all_bill_components.charges[tchrg.title]){
              all_bill_components.charges[tchrg.title].value += tchrg.value;
          } else {
              all_bill_components.charges[tchrg.title] = tchrg;
          }
          all_bill_components.charges[tchrg.title].value = parseFloat(all_bill_components.charges[tchrg.title].value.toFixed(2));

      }
      for (let j=0;itm.taxes && j<itm.taxes.length;j++) {
          let ttax = itm.taxes[j];
          if (all_bill_components.taxes[ttax.ref_id]){
              all_bill_components.taxes[ttax.ref_id].value += ttax.value;
          } else {
              all_bill_components.taxes[ttax.ref_id] = ttax;
          }
          all_bill_components.taxes[ttax.ref_id].value =  parseFloat(all_bill_components.taxes[ttax.ref_id].value.toFixed(2));
      }
      ordr.meta.item_level_charges += itm.totalCharges;
      ordr.meta.item_level_taxes += itm.totalTaxes;
      ordr.meta.sub_total += itm.subtotal;
      ordr.meta.sub_total_offer += itm.subtotal_offer;
      ordr.meta.sub_total = parseFloat(ordr.meta.sub_total.toFixed(2));
      ordr.meta.sub_total_offer = parseFloat(ordr.meta.sub_total_offer.toFixed(2));
  }
  ordr.items = items;
  let orderChargeArr = getOrderCharges(products,ordr.meta.sub_total,ordr.meta.item_level_discount,orderType, metaData);

  ordr.meta.charges = orderChargeArr[0];
  for (let j=0; j<ordr.meta.charges.length;j++) {
      let tchrg = ordr.meta.charges[j];
      all_bill_components.charges[tchrg.title] = tchrg;
  }
  ordr.all_bill_components = all_bill_components;
  ordr.meta.order_level_charges = parseFloat(orderChargeArr[1].toFixed(2));
  ordr.meta.total_charges = ordr.meta.item_level_charges + ordr.meta.order_level_charges;
  ordr.meta.item_level_taxes = parseFloat(ordr.meta.item_level_taxes.toFixed(2));
  ordr.meta.total_taxes = ordr.meta.item_level_taxes;
  ordr.meta.total_discount = ordr.meta.item_level_discount + ordr.meta.order_level_discount;

  ordr.meta.total = ordr.meta.sub_total + ordr.meta.total_taxes + ordr.meta.total_charges;
  ordr.meta.total -= ordr.meta.total_discount;
  ordr.meta.total = parseFloat(ordr.meta.total).toFixed(2);
  ordr.meta.free_delivery = metaData.freeDeliveryAmount && metaData.freeDeliveryAmount <= parseFloat(ordr.meta.sub_total.toFixed(2))? true : false;
  ordr["payment"] = {"mode" : PaymentMode.ONLINE , "status": PaymentStatus.PENDING};
  return ordr;
};

const checkOfferAvailable = async (productId, cart_array, offers, products, metaData,orderType) => {
  var sub_total_offer = 0;
  var sub_total = 0;
  for (let i = 0; i < cart_array.length; i++) {
      let itm = processCartItems(cart_array[i], products);
      sub_total_offer += parseInt(itm.subtotal_offer);
      sub_total += parseInt(itm.subtotal);
  }
  let offersdata = offers;
  if (offersdata.offer_id) {
      if (sub_total_offer < offersdata.min_order_value || sub_total_offer === 0) {
          Notification({
              type: "error",
              displayMessage: i18n.t('notificationMsg.COUPON_REMOVE_FROM_ORDER'),
              toasterOptions: {
                  toastId: "offer-remove",
              },
          });
          return {};
      }
      return new Promise((resolve, reject) => {
        let free_delivery = metaData?.free_delivery ? true : false ; 
        checkDiscountAvailable(productId, offersdata,sub_total,sub_total_offer, metaData,orderType,free_delivery)
           .then(response => resolve(response))
            .catch(error => resolve({}));
        });
  } else {
      return {};
  }
} 


function getOrderCharges(catalogue,subtotal, item_level_discount, orderType, metaData) {
  let chrgs = [];
  let totalCharges = 0;
  let allCharges = catalogue.bill_components.charges;
  if (!allCharges) {
      return [chrgs, totalCharges];
  }
  for (let i = 0; catalogue.order_bill_components && i < catalogue.order_bill_components.length; i++) {
      for (let j = 0; j < allCharges.length; j++) {
          if (catalogue.order_bill_components[i] === allCharges[j].ref_id && allCharges[j].fulfillment_modes.includes(orderType)) {
              let chrg = {};
              chrg.ref_id = allCharges[j].ref_id;
              chrg.title = allCharges[j].title;
              if (allCharges[j].type === ChargeTypes.FIXED) {
                  chrg.value = allCharges[j].value;
              } else if (allCharges[j].type === ChargeTypes.PERCENTAGE) {
                  chrg.value = parseInt(((subtotal-item_level_discount) * allCharges[j].value))/100;
                  chrg.percentage = allCharges[j].value;
              }
              totalCharges += chrg.value;
              chrgs.push(chrg);
          }
      }
  }
  if (orderType === OrderType.DELIVERY) {
      chrgs.push({ "title": "Delivery Charge", "value": metaData ? metaData.deliveryCharge : 0, "ref_id": "delivery" });
      totalCharges += metaData ? metaData.deliveryCharge : 0;
  }
  return [chrgs, totalCharges];
};

function processCartItems(cartItm, catalogue, offers, orderType) {
  let itm = {};
  itm.ref_id = cartItm.ref_id;
  itm.title = cartItm.title;
  itm.quantity = cartItm.cartcount;
  itm.food_type = cartItm.food_type;
  itm.categoryId = get(cartItm, 'categoryId', '');
  itm.subcategoryId = get(cartItm, 'subcategoryId', '');
  itm.discount_mode = "discount_mode" in cartItm && !cartItm.discount_mode ? false : true;
  itm.discount = 0;
  itm.price_per_unit = getItemPrice(cartItm);
  itm.price_per_unit_offer = getItemOfferPrice(cartItm);
  let varArr = getSelectedVariant(cartItm.variant_groups);
  itm.variants = varArr[0];
  itm.variantTotal = varArr[1];
  let addonArr = getSelectedAddOns(cartItm.add_on_groups);
  itm.addons = addonArr[0];
  itm.addonTotal = addonArr[1];
  itm.subtotal = (itm.price_per_unit + itm.addonTotal + itm.variantTotal) *cartItm.cartcount;
  itm.subtotal_offer = getTotalOfferPrice(itm) *cartItm.cartcount;
  itm.discount = offers && offers.offer_discount ? offers.offer_discount * (itm.subtotal / offers.order_subtotal) : 0;

  let chargeArr = processItemCharges(cartItm.bill_components?.charges, catalogue.bill_components.charges,itm.subtotal-itm.discount,itm.quantity,orderType);
  itm.charges = chargeArr[0];
  itm.totalCharges = chargeArr[1];
  let taxArr = processItemTaxes(
      itm.subtotal-itm.discount,
      cartItm.bill_components?.taxes,
      catalogue.bill_components?.taxes
  );
  itm.taxes = taxArr[0];
  itm.totalTaxes = taxArr[1];
  itm.total = itm.subtotal + taxArr[1] + chargeArr[1];
  if(cartItm.isComboItem) {
    itm.isComboItem = cartItm.isComboItem
    itm.comboDescription = cartItm.comboDescription
    itm.sections = cartItm.sections
  }
  return itm;
};

const getTotalOfferPrice = (item) =>{
  if("discount_mode" in item && !item.discount_mode){
    return 0;
  }
  else{
    return item.price_per_unit_offer + item.addonTotal + item.variantTotal;
  }
}

function getSelectedVariant(variantGroups) {
  let varnts = [];
  let varTotal = 0;
  if (!variantGroups) {
      return [varnts, varTotal];
  }
  for (let i = 0; i < variantGroups.length; i++) {
      let sel = variantGroups[i].selectedVariant;
      let vAddons = variantGroups[i].selectedVAdon;
      let vr = {};
      vr.ref_id = sel.ref_id;
      if(vAddons){
        vr.addons = vAddons.map(vAddon => {
            varTotal += vAddon.price;
            return vAddon.ref_id;
        } )
      }
      vr.title = sel.title;
      vr.price_per_unit = sel.price;
      varTotal += sel.price;
      varnts.push(vr);
  }
  return [varnts, varTotal];
};

function getSelectedAddOns(addOnGroups) {
  let addons = [];
  let addonTotal = 0;
  if (!addOnGroups || !checkIsItemCustomizable({add_on_groups: addOnGroups})) {
      return [addons, addonTotal];
  }
  for (let i = 0; i < addOnGroups.length; i++) {
      let sel = addOnGroups[i].selectedAddOn;
      for (let j = 0; j < sel.length; j++) {
          let addn = {};
          addn.ref_id = sel[j].ref_id;
          addn.title = sel[j].title;
          addn.price_per_unit = sel[j].price;
          addonTotal += addn.price_per_unit;
          addons.push(addn);
      }
  }
  return [addons, addonTotal];
};

function processItemCharges(itmCharges, allCharges, subtotal,quantity,orderType) {
  let charges = [];
  let totalCharges = 0;
  if (!itmCharges || !allCharges) {
      return [charges, totalCharges];
  }
  for (let i = 0; i < itmCharges.length; i++) {
      for (let j = 0; j < allCharges.length; j++) {
          if (
              itmCharges[i] === allCharges[j].ref_id &&
              allCharges[j].fulfillment_modes.includes(orderType)
          ) {
              let chrg = {};
              chrg.ref_id = allCharges[j].ref_id;
              chrg.title = allCharges[j].title;
              chrg.type = allCharges[j].type;
              if (chrg.type === ChargeTypes.FIXED) {
                  chrg.value = allCharges[j].value*quantity;
              } else if (chrg.type === ChargeTypes.PERCENTAGE) {
                  chrg.value = parseInt((subtotal * allCharges[j].value))/100;
                  chrg.percentage = allCharges[j].value;
              }
              charges.push(chrg);
              totalCharges += chrg.value;
              break;
          }
      }
  }
  return [charges, totalCharges];
};

function processItemTaxes(subtotal, itmTaxes, allTaxes) {
  let taxes = [];
  let totalTaxes = 0;
  if (!itmTaxes || !allTaxes) {
      return [taxes, totalTaxes];
  }
  for (let i = 0; i < itmTaxes.length; i++) {
      for (let j = 0; j < allTaxes.length; j++) {
          if (itmTaxes[i] === allTaxes[j].ref_id.toString()) {
              let tax = {};
              tax.ref_id = allTaxes[j].ref_id;
              tax.title = allTaxes[j].title;
              tax.percentage = allTaxes[j].value;
              tax.value = (subtotal * tax.percentage) / 100;
              taxes.push(tax);
              totalTaxes += tax.value;
              break;
          }
      }
  }
  return [taxes, totalTaxes];
};

function deliveryTime(orderType, metaData){
  let delivery_time = 0;
  if(orderType === OrderType.DELIVERY){
      delivery_time = parseFloat(metaData.estimated_delivery_time);
  }
  return delivery_time;
};

function createCallbackUrl(orderType, metaData) {
  let url = window.location.href.split('?')[0];
  if (orderType) {
      url += `?orderType=${orderType}`;
  }
  if (metaData.table_number && metaData.edit_disable) {
      url += `&table=${metaData.table_number}`;
  }
  if (metaData.room_number && metaData.edit_disable) {
      url += `&room=${metaData.room_number}`;
  }
  return url;
};

const mapOrderTypeInbot =(productId , data)=>{
    if((data.ordertype === OrderType.DINEIN && data.table_no ) || data.ordertype !== OrderType.DINEIN){
        mapOrdertype(productId,data)
        .catch((error) => {
            console.log("error", error);
        });
   }
}
const orderEstimate = async (productId, data) => {
    return new Promise((resolve, reject) => {
      orderEstimation(productId, data)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };

const getItemPrice = (item) =>{
  return item.discounted_price ? item.discounted_price : item.price;
}

const getItemOfferPrice = (item) =>{
  if("discount_mode" in item && !item.discount_mode){
    return 0;
  }
  else{
    return item.discounted_price ? item.discounted_price : item.price;
  }
}
const returnRefreshedAddons = (products, addons_groups) => {
  let rAddonGroups = addons_groups ? [...addons_groups] : addons_groups;
  let addonGroup = [];
  if (rAddonGroups && rAddonGroups.length>0) {
    for (let i = 0; i < rAddonGroups.length; i++) {
      let addons = [];
      for (let j = 0; j < rAddonGroups[i].addons.length; j++) {
        let data = products.addons[rAddonGroups[i].addons[j].ref_id];
        addons.push({ ...rAddonGroups[i].addons[j], price: data.price });
      }
      addonGroup.push({ ...rAddonGroups[i], addons: addons });
    }
  }
  return addonGroup.length > 0 ? addonGroup : null;
};
const returnRefreshedVariants = (products, variants_groups) => {
  let rVariantGroups = variants_groups ? [...variants_groups] : variants_groups;
  let variantGroup = [];
  if (rVariantGroups && rVariantGroups.length > 0) {
    for (let i = 0; i < rVariantGroups.length; i++) {
      let addons = [],
        variants = [];
      if (rVariantGroups[i].selectedVAdon) {
        for (let j = 0; j < rVariantGroups[i].selectedVAdon.length; j++) {
          let data = products.addons[rVariantGroups[i].selectedVAdon[j].ref_id];
          addons.push({
            ...rVariantGroups[i].selectedVAdon[j],
            price: data.price,
          });
        }
      }
      if (rVariantGroups[i]) {
        let data = products.variants[rVariantGroups[i].selectedVariant.ref_id];
        variants = {
          ...rVariantGroups[i].selectedVariant,
          price: data.price,
        };
      }
      variantGroup.push({ ...rVariantGroups[i], selectedVAdon: addons,selectedVariant:variants });
    }
  }
  return variantGroup.length > 0 ? variantGroup : null;
};

const getComboUpdatedPrice = (products, refreshedComboDetails, comboSections = []) => {

  const getTotalPriceOfList = (list = [], itemType) => {
    return list.reduce((totalListPrice, listItem) => {
      const listItemId = get(listItem, 'ref_id', '')
      const updatedListItemPrice = get(products, `[${itemType}][${listItemId}].price`, 0)
      return totalListPrice + updatedListItemPrice
    }, 0)
  }

  const basePrice = get(refreshedComboDetails, 'price', 0)
  const comboType = get(refreshedComboDetails, 'combo_type', 0)
  const addAddonPrice = get(refreshedComboDetails, 'addAddonPrice', false)
  if(['PAY_X_GET_Y', 'BUY_X_GET_Y'].includes(comboType)) {
    const totalSectionPrice = comboSections.reduce((totalPrice, section, sectionIndex) => {
      if(sectionIndex === 0) {
        const itemsNeeded = get(refreshedComboDetails, `sections[${sectionIndex}].items_needed`, 0)
        let itemsPriceInSectionWithoutAddon = []
        let totalAddonPriceForSection = 0
        const itemsPriceInSection = get(section, 'items', []).reduce((itemsPrices, itemDetails) => {
          const itemId = get(itemDetails, 'ref_id')
          const onlyItemPrice = get(products, `items[${itemId}].price`, 0)
          const onlyAddonsPrice = getTotalPriceOfList(get(itemDetails, 'addons', []), 'addons')
          const onlyVariantsPrice = getTotalPriceOfList(get(itemDetails, 'variants', []), 'variants')
          const netTotalPrice = (onlyItemPrice + onlyAddonsPrice + onlyVariantsPrice)
          if(addAddonPrice && comboType === 'PAY_X_GET_Y') {
            totalAddonPriceForSection = totalAddonPriceForSection + (get(itemDetails, 'quantity', 0) * onlyAddonsPrice)
            itemsPriceInSectionWithoutAddon = [...itemsPriceInSectionWithoutAddon, ...range(0, get(itemDetails, 'quantity', 0)).map(() => (onlyItemPrice + onlyVariantsPrice))]
          }
          return [...itemsPrices, ...range(0, get(itemDetails, 'quantity', 0)).map(() => netTotalPrice)]
        }, [])
        const getSortedItemPrices = () => (addAddonPrice
          ? sortOneArrayAlongWithAnother(itemsPriceInSection, itemsPriceInSectionWithoutAddon).arr2
          : itemsPriceInSection.sort((a,b) => b-a)).slice(0, itemsNeeded)
        const highestNPrices = comboType === 'BUY_X_GET_Y' ? itemsPriceInSection : getSortedItemPrices()
        const highestNPriceTotal = highestNPrices.reduce((totalPriceForSection, currentPrice) => {
            return totalPriceForSection + currentPrice
        }, 0)
        return totalPrice + highestNPriceTotal + totalAddonPriceForSection
      } else {
        const getAddonPriceInSection = () => get(section, 'items', []).reduce((totalAddonPrice, itemDetails) => {
          const onlyAddonsPrice = getTotalPriceOfList(get(itemDetails, 'addons', []), 'addons')
          return totalAddonPrice + (get(itemDetails, 'quantity', 0) * onlyAddonsPrice)
        }, 0)
        return totalPrice + (addAddonPrice ? getAddonPriceInSection() : 0)
      }
    }, 0)
    return basePrice + totalSectionPrice
  } else {
    const comboItemsPrice = comboType === 'CUSTOMIZED_COMBO' ? comboSections.reduce((totalPrice, section, sectionIndex) => {
      if (get(refreshedComboDetails, `sections[${sectionIndex}].priceToBeAdded`, false)) {
        const itemsPriceInSection = getTotalPriceOfList(get(section, 'items'), 'items')
        return itemsPriceInSection + totalPrice
      } else {
        return totalPrice
      }
    }, 0) : 0
    const totalSectionPrice = comboSections.reduce((totalPrice, section) => {
      const addonPriceForSection = getTotalPriceOfList(get(section, 'addons', []), 'addons')
      const variantPriceForSection = getTotalPriceOfList(get(section, 'variants', []), 'variants')
      return totalPrice + addonPriceForSection + variantPriceForSection
    }, 0)
    return basePrice + comboItemsPrice + totalSectionPrice
  }
}

const getOffers = (productId) =>{
  return sessionStorage.getItem(productId+'-offers')
}

const returnRefreshedCart = (products,cart) =>{
  let cartRefresh = [...cart];
  let cart_array= [];
  for(let i = 0;i<cartRefresh.length;i++){
      if(get(cartRefresh, `[${i}].isComboItem`)) {
        const comboId = get(cartRefresh, `[${i}].ref_id`)
        const refreshedComboDetails = get(products, `combos[${comboId}]`)
        const updatedPrice = getComboUpdatedPrice(products, refreshedComboDetails, cartRefresh[i].sections)
        cart_array.push({
          ...cartRefresh[i],
          title: get(refreshedComboDetails, 'title', ''),
          comboDescription: get(refreshedComboDetails, 'description', ''),
          price: updatedPrice
        })
      } else {
        let data = products.items[cartRefresh[i].ref_id];
        let refreshedAddons = returnRefreshedAddons(products,cartRefresh[i].add_on_groups);
        let refreshedVariants = returnRefreshedVariants(products, cartRefresh[i].variant_groups);
        cart_array.push({ ...cartRefresh[i], 
          price : data.price,
          discount_mode : 'discount_mode' in data && !data.discount_mode ? false : true,
          discounted_price : data.discounted_price,
          add_on_groups : refreshedAddons,
          variant_groups : refreshedVariants,
         });
      }   
  }
  return cart_array;
}

function productPrice(product, is_discount = false) {
  let price = is_discount ? (product.discounted_price || product.price) : product.price;

  if (product.variant_groups || product.add_on_groups) {
    if (product.variant_groups) {
      product.variant_groups.forEach(
        (item) => {
          (price += item.selectedVariant && item.selectedVariant.price ? item.selectedVariant.price : 0)
          if (item?.selectedVAdon && item?.selectedVAdon?.length) {
            item.selectedVAdon.forEach(
              it => price += it.price
            )
          }

        }
      );
    }

    if (product.add_on_groups) {
      for (let i = 0; i < product.add_on_groups.length; i++) {
        product.add_on_groups[i].selectedAddOn?.forEach(
          (item) => (price += item.price)
        );
      }
    }
  }
  return parseFloat(price.toFixed(2));
}


function productAllPrice(product) {
  let price = (!!product.discounted_price ? Number(productPrice(product, true)) : Number(productPrice(product, false))) * Number(product.cartcount);
  return price
}

const getFreedeliveryAmount = (products) => {
  let free_delivery_value = 0;
  if(get(products, 'configuration.gs_free_delivery.enabled')){
      free_delivery_value = get(products, 'configuration.gs_free_delivery.min_order_amt')
  }
  else if(get(products, 'configuration.free_delivery.enabled')) {
      free_delivery_value= get(products, 'configuration.free_delivery.min_order_amt')
  }
  return free_delivery_value;
}

const freeDelieryEnabled = (configuration)=>{
  return configuration?.gs_free_delivery?.enabled || configuration?.free_delivery?.enabled
}

const isItemAvailableNow = (item_offer_time , time) =>{
  let isAvailable = false;
    item_offer_time.forEach(item => {
    if(item.from === item.to || (getDate(item.from) <= getDate(time) && getDate(item.to) >= getDate(time))){
      isAvailable = true;
      return
    }
  });
return isAvailable;
}
const getDate = (time) => {
  let today = new Date();
  let _t = time.split(":");
  today.setHours(_t[0], _t[1], 0, 0);
  return today.getTime();
}

const checkIsItemCustomizable = (product) => {
  if(get(product, 'variant_groups') || get(product, 'add_on_groups')) {
    const addonGroups = get(product, 'add_on_groups', []) || []
    if(get(product, 'variant_groups')) {
      return true
    } else if(addonGroups.length > 0) {
      let noAddonsCounter = 0
      for(const addonGroup of addonGroups) {
        const allAddonsLength = get(addonGroup, 'addons.length', 0)
        if (allAddonsLength === 0) {
          noAddonsCounter += 1
        }
      }
      return !(noAddonsCounter === get(addonGroups, 'length', 0))
    } else {
      return false
    }
  }
  return false
}

export {
  notifocationsInRealTime,
  markNotificationAsRead,
  createOrderJson,
  checkOfferAvailable,
  createCallbackUrl,
  mapOrderTypeInbot,
  orderEstimate,
  getItemPrice,
  getItemOfferPrice,
  returnRefreshedCart,
  productPrice,
  productAllPrice,
  getFreedeliveryAmount,
  freeDelieryEnabled,
  isItemAvailableNow,
  getOffers,
  checkIsItemCustomizable,
};
