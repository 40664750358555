import styled, { css } from 'styled-components'
import styleVals from '../../constants/styles'
import { CloseIcon } from '../CustomModal/styles/CustomModal.style'

export const CarouselContainer = styled.div`
    padding: ${props => !props.hideCarousel && props.margin};
    position: relative;
    max-height: ${props => props.hideCarousel ? styleVals.measurements.spacing0 : styleVals.measurements.spacing420};
    overflow: hidden;
    transition: 0.2s all;
`
export const CarouselItemsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 0 ${styleVals.measurements.spacing10};
    height: ${styleVals.measurements.spacing240};
    @media ${styleVals.breakpoints.modernMobile} {
        height: ${styleVals.measurements.spacing210};
    }
    @media ${styleVals.breakpoints.smallMobile} {
        height: ${styleVals.measurements.spacing180};
    }
    @media ${styleVals.breakpoints.miniMobile} {
        height: ${styleVals.measurements.spacing150};
    }
    /* pointer-events: none; */
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
`
export const CarouselItem = styled.div`
    width: calc(100% - ${props => props.multipleImages ? styleVals.measurements.spacing24 : styleVals.measurements.spacing0});
    ${props => props.isLanguageArab ? css`
        margin-right: ${props => !props.firstChild && styleVals.measurements.spacing12};
    ` : css`
        margin-left: ${props => !props.firstChild && styleVals.measurements.spacing12};
    `}
    flex-shrink: 0;
    border-radius: ${styleVals.measurements.spacing10};
    overflow: hidden;
`
export const ScrollButton = styled(CloseIcon)`
    ${props => props.showButton ? css`
        opacity: 0.8;
        pointer-events: auto;
    ` : css`
        opacity: 0;
        pointer-events: none;
    `};
    transition: 0.2s opacity;
    top: 50%;
    ${props => props.isLanguageArab ? css`
        left: ${styleVals.measurements.spacing12};
    ` : css`
        right: ${styleVals.measurements.spacing12};
    `}
    span {
        position: absolute;
        top: -2px;
        ${props => props.isLanguageArab ? css`
            right: ${styleVals.measurements.spacing8};
        ` : css`
            left: ${styleVals.measurements.spacing8};
        `}
        font-size: ${styleVals.measurements.spacing30};
    }
`