import i18n from "../translations/i18n";

export const OrderStatus = Object.freeze({
    "CONFIRMED": "confirmed",
    "COMPLETED": "completed",
    "UPDATED": 'UPDATED',
    "ADDED": 'ADDED',
    "ACK": "acknowledged",
    "ACKNOWLEDGED": "ORDER_ACKNOWLEDGED",
    "ORDER_CREATED": "ORDER_CREATED",
    "PAYMENT_PENDING": "PAYMENT_PENDING",
    "PAYMENT_CONFIRMED": "PAYMENT_CONFIRMED",
    "PAYMENT_CONFIRMED_CASH": "PAYMENT_CONFIRMED_CASH",
    "ACCEPTED_BY_RESTAURANT": "ACCEPTED_BY_RESTAURANT",
    "ACCEPTED_BY_DP": "ACCEPTED_BY_DELIVERY_PARTNER",
    "PREPARING_ORDER": "PREPARING_ORDER",
    "READY_FOR_DISPATCH": "READY_FOR_DISPATCH",
    "ORDER_PICKED_UP": "ORDER_PICKED_UP",
    "DELIVERED": "DELIVERED",
    "CANCELLED_BY_RESTAURANT": "CANCELLED_BY_RESTAURANT",
    "CANCELLED_BY_DP": "CANCELLED_BY_DELIVERY_PARTNER",
    "CANCELLED": "CANCELLED",
    "ORDER_COMPLETED": "ORDER_COMPLETED",
    "WAITING": "Waiting for delivery partner to accept the order"
});

export const PaymentMode = Object.freeze({
    "ONLINE": "online",
    "CASH": "cash"
});
export const PaymentStatus = Object.freeze({
    "PENDING": "pending",
    "PAID": "paid"
});

export const OrderType = Object.freeze({
    "DELIVERY": "delivery",
    "TAKEAWAY": "takeaway",
    "DINEIN": "dinein",
    "INROOMDINEIN": "irdining",
    "TABLERES": "tableRes",
    "QDINEIN": "qdinein"
});

export const OrderTypeLabel = Object.freeze({
    "DELIVERY": i18n.t('Delivery'),
    "TAKEAWAY": i18n.t('Takeaway'),
    "DINEIN": "Dine in",
    "INROOMDINEIN": "In Room Dining",
    "IRDINING": "In Room Dining",
    "INROOM": "Inroom",
    "TABLERES": "Table Reservation",
    "QDINEIN": "Quick DineIn"
});

export const Color = Object.freeze({
    defaultColor: "#EE2F37",
    Green: "#54B95D",  // green
    Yellow: "#F8B121", // yellow
    Grey: "#C4C4C4",   // grey
    Red: "red",
    Black: "black"
});

export const ChargeTypes = Object.freeze({
    "FIXED": "fixed",
    "PERCENTAGE": "percentage"
});

export const DeliveryPartner = Object.freeze({
    "SELF_DELIVERY": "self_delivery"
});


export const paymentStatus = Object.freeze({
    "PENDING": "pending",
    "PAID": "paid",
    "REFUNDED": "refunded",
    "COMPLETED": "completed"
});

export const OfferTypes = Object.freeze({
    "FLAT": "flat_discount",
    "PERCENT": "percent",
    "FREEDELIVERY": "free_delivery"
});

export const DiscountTypes = Object.freeze({
    "ORDERLVL": "order_level",
    "ITEMLVL": "item_level"
});

export const ChargeTitles = Object.freeze({
    "DELIVERY_CHARGE": "Delivery Charge"
});

export const countryFlags = Object.freeze({
    "AQ": "qa",
    "GA": "in",
    "GS": "in",
    "PI": "in",
    "PO": "in",
    "PP": "in",
    "QA": "in",
    "UP": "in"
});

export const defaultWaitingTimeForResendOTP = 60;
export const average_delivery_time = 30;
export const reCAPTCHA_site_key = '6LfJUWQaAAAAALMPNWVsJ864vEIP42mlZyieSmNj';

export const RESTAURANT_TYPE = {
    BRAND: 'brand',
    OUTLET: 'outlet'
}

export const DEFAULT_CURRENCY = {
    name: "INR",
    symbol: "₹"
}

export const MENUID_DOMAIN = "menuDomain";

export const errorCodes = Object.freeze({
    INVALID_DISCOUNT_CODE: 'INVALID_DISCOUNT_CODE'
})

export const allPageRoutes = ['home', 'menu', 'checkout', 'tableReservation', 'viewAddress', 'checkout', 'order', 'payment'];

export const COUNTRY_CODE = {
    india: 'in',
    qatar: 'qa'
};

export const Days = {
    Monday: "الأثنين",
    Tuesday: "الثلاثاء",
    Wednesday: "الأربعاء",
    Thursday: "الخميس",
    Friday: "الجمعه",
    Saturday: "السبت",
    Sundady: "الأحد"
}

export const ClockNumber = {
    1: '١',
    2: '٢',
    3: '٣',
    4: '٤',
    5: '٥',
    6: '٦',
    7: '٧',
    8: '٨',
    9: '٩',
    10: '١٠',
    11: '١١',
    12: '١٢',
}

export const MeridiemInfo = {
    AM: "صباحا",
    PM: "مساء"
}

export const ChargesTitle = [
    {key: 'Service Charge' , label : i18n.t('SERVICE_CHARGE')},
    {key: 'Packaging Charge' , label : i18n.t('PACKAGING_CHARGE')},
    {key:'Delivery Charge' , label : i18n.t('DELIVERY_CHARGE')},
];

