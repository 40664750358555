import { useRestaurantsInfoContext } from "../../contexts/restaurantInfo"
import { CardDescription, StyledCard, StyledCardBody, StyledCardFooter, StyledCol, StyledContainer, StyledDropdownWrapper, StyledDropdown, StyledDropdownMenu, StyledDropdownToggle, StyledInputElement, StyledLoader, StyledRow } from "./styles"

export const Col = ({ children }) => {
    return (
        <StyledCol>{children}</StyledCol>
    )
}

export const Card = ({ children, ...props }) => {
    return (
        <StyledCard {...props}>{children}</StyledCard>
    )
}

export const CardBody = ({ children }) => {
    return (
        <StyledCardBody>{children}</StyledCardBody>
    )
}

export const CardText = ({ children, ...props }) => {
    return (
        <CardDescription {...props}>{children}</CardDescription>
    )
}

export const Dropdown = ({ children, ...props }) => {
    return (
        <StyledDropdown {...props}>{children}</StyledDropdown>
    )
}

export const DropdownToggle = ({ children, ...props }) => {
    return (
        <StyledDropdownToggle {...props}>{children}</StyledDropdownToggle>
    )
}

export const DropdownMenu = ({ children, ...props }) => {
    return (
        <StyledDropdownMenu {...props}>{children}</StyledDropdownMenu>
    )
}
export const DropdownWrapper = ({ children, ...props }) => {
    return (
        <StyledDropdownWrapper {...props}>{children}</StyledDropdownWrapper>
    )
}

export const CardFooter = ({ children, ...props }) => {
    return (
        <StyledCardFooter {...props}>{children}</StyledCardFooter>
    )
}

export const Input = ({ ...props }) => {
    return (
        <StyledInputElement {...props} />
    )
}




export const Container = ({ fluid, children, ...props }) => {
    return (
        <StyledContainer fluid={fluid} {...props}>{children}</StyledContainer>
    )
}

export const Row = ({ children, ...props }) => {
    return (
        <StyledRow {...props}>{children}</StyledRow>
    )
}

export const Loader = ({ children, ...props }) => {

    const { restaurantColor } = useRestaurantsInfoContext()

    return (
        <StyledLoader animation='border' {...props}>{children}</StyledLoader>
    )
}

