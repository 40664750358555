import React from 'react'
import {FoodType} from "../../Components/Product/styles";
import {useTranslation } from 'react-i18next';
import { AddCustomization, ModelcloseIcon, ProductPrice, ProductsLeftSection, ProductsRightSection, ProductsTitle, VariantAndAddonSection, VariantGroupPlyusContainer, VariantGroupPlyusHeader, VariantGroupPlyusName, VariantGroupPlyusProducts } from './styles';
import { AddItemButton } from '../Product/ItemCard';
import { useRestaurantsInfoContext } from '../../contexts/restaurantInfo';

export default function VariantGroupPlyus({ product, variantsAllProduct, plyusVariantGroup, productAllPrice, productPrice, addCartVariant, plyusVarintAddCart, minusVarintAddCart, restaurantColor, restaurantCurrency, roundOffToTwoPlaces, isRestaurantIndian }) {
    const { t }=useTranslation();
    const { isLanguageArab } = useRestaurantsInfoContext();
    
    const handleAddItemClick = (action, product) => {
        if (action === '+') {
            plyusVarintAddCart(product)
        } else if (action === '-') {
            minusVarintAddCart(product)
        }
    }

    return(
        <VariantGroupPlyusContainer id='add-more-customized-item-modal' isLanguageArab={isLanguageArab}>
            <div id='add-more-customized-item-wrapper'>
                <VariantGroupPlyusHeader>
                    <VariantGroupPlyusName id='add-more-customized-item-heading'>
                        {product.title}
                    </VariantGroupPlyusName>
                    <ModelcloseIcon isLanguageArab={isLanguageArab} id='close-add-more-customized-item' onClick={plyusVariantGroup}>X</ModelcloseIcon>
                </VariantGroupPlyusHeader>
                <hr id='title-separator' aria-hidden/>

                <VariantGroupPlyusProducts>
                {variantsAllProduct.map((product, index) => <div key={index}>
                    <ProductsLeftSection>
                        <ProductsTitle>
                            <FoodType id={`add-customized-item-food-type-${index+1}`} withMarginBottom type={product.food_type} >
                                <FoodType.foodIcon type={product.food_type} isRestaurantIndian={isRestaurantIndian} />
                            </FoodType>
                            <span id={`add-customized-item-title-${index+1}`}>{product.title}</span>
                        </ProductsTitle>
                        <span id={`add-customized-item-price-${index+1}`}>{restaurantCurrency}{productPrice(product)}</span>

                        {product.variant_groups
                        ? product.variant_groups.map((group, variantindex) =>
                            <VariantAndAddonSection key={variantindex}>
                                <span id={`add-customized-item-variant-${variantindex+1}-${index+1}`}>{group.title} - {group.selectedVariant.title}</span>
                                {group.selectedVAdon && group.selectedVAdon.length ? 
                                <div>{t('ADDON')} - {group.selectedVAdon.map(( vAddon, vaddonindex) => {
                                    return (<span id={`add-customized-item-vbasedaddon-${vaddonindex+1}-${variantindex}-${index}`} key={vaddonindex}>  {vAddon.title}{vaddonindex < group.selectedVAdon.length-1 ? ',' : ''} </span>)
                                })}</div>:""} {/* This returns zero if we add && condition */}
                            </VariantAndAddonSection>)
                        : ''}
                        {product.add_on_groups 
                        ? product.add_on_groups.map((group, addonIndex) => group.selectedAddOn[0] 
                            ? <VariantAndAddonSection id={`add-customized-item-addon-${addonIndex+1}-${index+1}`} key={addonIndex}>
                                    {group.title} - {group.selectedAddOn.map((selectaddon, selectaddonindex) => <span key={selectaddonindex}> {selectaddon.title}{selectaddonindex < group.selectedAddOn.length-1 ? ',' : ''} </span>)}
                            </VariantAndAddonSection> 
                            : '' ) 
                        : ''}
                    </ProductsLeftSection>
                       
                    <ProductsRightSection>
                        <AddItemButton
                            itemQuantity={product.cartcount}
                            handleAddItemClick={(action) => handleAddItemClick(action, product)}
                            buttonSize='md'
                            title={`${product.title}-${index}`}
                            popupButtons
                            invertColors
                        />
                        <ProductPrice id={`${product.title}-${index}-price`}>
                            {restaurantCurrency}{roundOffToTwoPlaces(productAllPrice(product))}
                        </ProductPrice>
                    </ProductsRightSection>

                </div>)}
                </VariantGroupPlyusProducts>

                <AddCustomization restaurantColor={restaurantColor}>
                    <div id='add-another-customization-button' onClick={() => addCartVariant(product)}>
                        {t('ADD_NEW_CUSTMZ')}
                    </div>
                </AddCustomization>
            </div>
        </VariantGroupPlyusContainer>
    )
}
