import styled, { css } from 'styled-components'
import styleVals from '../../constants/styles'

const radioButtonBorderStyles = (backgroundColor, smallerRadio) => css`
  ${smallerRadio ? css`
    width:  ${styleVals.measurements.spacing19};
    height:  ${styleVals.measurements.spacing19};
    top: -2.5px;
    left: -1.8px;
  ` : css`
    width: ${styleVals.measurements.spacing22};
    height: ${styleVals.measurements.spacing22};
    top: -${styleVals.measurements.spacing4};
    left: -${styleVals.measurements.spacing4};
  `}
  border-radius: 50%;
  border: ${styleVals.measurements.spacing2} solid ${backgroundColor};
  position: absolute;
  background-color: ${styleVals.colors.white};
  content: '';
  display: inline-block;
  visibility: visible;
`
const radioButtonStyles = (backgroundColor, checked, smallerRadio) => css`
  ${smallerRadio ? css`
    width:  ${styleVals.measurements.spacing15};
    height:  ${styleVals.measurements.spacing15};
    top: -0.6px;
    left: 0.1px;
  ` : css`
    width: ${styleVals.measurements.spacing18};
    height: ${styleVals.measurements.spacing18};
    top: -${styleVals.measurements.spacing2};
    left: -${styleVals.measurements.spacing2};
  `}
  border-radius: 50%;
  position: absolute;
  background-color: ${backgroundColor};
  content: '';
  display: inline-block;
  visibility: visible;
  border: ${styleVals.measurements.spacing2} solid ${styleVals.colors.white};
  transition: transform 0.1s ease;
  ${checked ? css`
    opacity: 1;
    transform: scale(1);
  ` : css`
    opacity: 0;
    transform: scale(0);
  `}
`

export const RadioLabel = styled.label`
  outline: none;
  background: ${styleVals.colors.white};
  box-sizing: border-box;
  color: #404040;
  padding: ${styleVals.measurements.spacing0} ${styleVals.measurements.spacing8};
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${styleVals.breakpoints.smallMobile} {
    padding: ${styleVals.measurements.spacing4} ${styleVals.measurements.spacing0};
  }

  > input {
    display: inline-block;
    ${props => props.isLanguageArab ? css`
        margin-left: ${props => props.smallerRadio ? styleVals.measurements.spacing8 : styleVals.measurements.spacing12}; 
    ` : css`
        margin-right: ${props => props.smallerRadio ? styleVals.measurements.spacing8 : styleVals.measurements.spacing12}; 
    `};
    position: relative;
    ::before, :checked:before {
      flex-shrink: 0;
      ${props => radioButtonBorderStyles(props.restaurantColor, props.smallerRadio)}
    }
    ::after {
      flex-shrink: 0;
      ${props => radioButtonStyles(props.restaurantColor, false, props.smallerRadio)}
    }
    :checked:after {
      flex-shrink: 0;
      ${props => radioButtonStyles(props.restaurantColor, true, props.smallerRadio)}
    }
  }

  &:hover {
    color: #000;
    background: #FFFFFF;

  }
`
export const SelectionLabel = styled.label`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
    width: 100%;
    ${props => props.isLanguageArab ? css`
      padding: ${styleVals.measurements.spacing10} ${styleVals.measurements.spacing20};
      padding-right: ${styleVals.measurements.spacing24};
      @media ${styleVals.breakpoints.smallMobile} {
          padding: ${styleVals.measurements.spacing10} ${styleVals.measurements.spacing8};
          padding-right: ${styleVals.measurements.spacing14};
      }
    ` : css`
      padding: ${styleVals.measurements.spacing10} ${styleVals.measurements.spacing20};
      padding-left: ${styleVals.measurements.spacing24};
      @media ${styleVals.breakpoints.smallMobile} {
          padding: ${styleVals.measurements.spacing10} ${styleVals.measurements.spacing8};
          padding-left: ${styleVals.measurements.spacing14};
      }
    `}
    border-bottom: 1px solid #CDCDCD;
    div {
        display: flex;
        flex-direction: row;
        max-width: ${props => props.additionalLabel ? '75%' : '100%'};
        @media ${styleVals.breakpoints.smallMobile} {
          max-width: ${props => props.additionalLabel ? '65%' : '100%'};
        }
        div {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          span{
              position: relative;
          }
          input ~ span::before {
              display: block;
              position: absolute;
              left: -${props => props.isLanguageArab ? styleVals.measurements.spacing10 : styleVals.measurements.spacing16};
              top: -${styleVals.measurements.spacing12};
              width: ${styleVals.measurements.spacing24};
              height: ${styleVals.measurements.spacing24};
              content: '';
              border:  ${styleVals.measurements.spacing2} solid ${props => props.restaurantColor};
              border-radius: 50%;
              background: #f3f3f3;
              box-shadow: 0 0 1px white;
          }
          input:disabled ~ span::before {
              display: block;
              position: absolute;
              left: -${props => props.isLanguageArab ? styleVals.measurements.spacing10 : styleVals.measurements.spacing16};
              top: -${styleVals.measurements.spacing12};
              width: ${styleVals.measurements.spacing24};
              height: ${styleVals.measurements.spacing24};
              content: '';
              border: ${styleVals.measurements.spacing2} solid ${styleVals.colors.disabledGrey};
              border-radius: 50%;
              background: #f3f3f3;
              box-shadow: 0 0 1px white;
          }
          input:checked ~ span::before {
              display: block;
              position: absolute;
              left: -${props => props.isLanguageArab ? styleVals.measurements.spacing10 : styleVals.measurements.spacing16};
              top: -${styleVals.measurements.spacing12};
              width: ${styleVals.measurements.spacing24};
              height: ${styleVals.measurements.spacing24};
              content: '';
              border: 2px solid ${props => props.restaurantColor};
              border-radius: 50%;
              background: ${props => props.restaurantColor};
              box-shadow: 0 0 1px white;
          }
          input:checked ~ span::after {
              display: block;
              position: absolute;
              content: "";
              left: -${props => props.isLanguageArab ? styleVals.measurements.spacing1 : styleVals.measurements.spacing7};
              top: -${styleVals.measurements.spacing8};
              width: ${styleVals.measurements.spacing6};
              height: ${styleVals.measurements.spacing14};
              border: solid white;
              border-width: 0 ${styleVals.measurements.spacing2} ${styleVals.measurements.spacing2} 0;
              transform: rotate(45deg);
          }
        }
        p {
          padding: 0;
          margin: 0;
          display: inline-block;
          ${props => props.isLanguageArab ? css`
            padding-right: ${styleVals.measurements.spacing16};
            @media ${styleVals.breakpoints.smallMobile} {
              padding-right: ${styleVals.measurements.spacing14};
            }
          ` : css`
            padding-left: ${styleVals.measurements.spacing16};
            @media ${styleVals.breakpoints.smallMobile} {
              padding-left: ${styleVals.measurements.spacing14};
            }
          `}
        }
    }
    p {
      margin: 0;
      padding: 0;
      color: ${props => props.isDisabled && styleVals.colors.greyText};
    }
`